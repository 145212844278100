import { ReactElement } from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { Container, Message, OrdersContainer } from './styles'
import UserOrder from '../../components/UserOrder'
import { Order } from '../../context/Orders/model'
import { ReactComponent as OrderImage } from '../../assets/ordersImage.svg'

interface UserOrdersListProps {
  orders: Order[]
}

const UserOrdersList = ({ orders }: UserOrdersListProps): ReactElement => {
  const { t } = useTranslation()
  return (
    <>
      {orders.length === 0 ? (
        <Container>
          <OrderImage />
          <Link to={'/'}>
            <Message>{t('myOrders.withoutOrders')}</Message>
          </Link>
        </Container>
      ) : (
        <OrdersContainer>
          {orders.map(
            ({
              createdAt,
              id,
              storeImage,
              storeName,
              total,
              deliveryPrice,
              transactionStatusId,
            }) => (
              <UserOrder
                createdAt={createdAt}
                id={id}
                key={`userOrder.${id}`}
                storeImage={storeImage}
                storeName={storeName}
                total={total}
                deliveryPrice={deliveryPrice}
                transactionStatusId={transactionStatusId}
              />
            )
          )}
        </OrdersContainer>
      )}
    </>
  )
}

export default UserOrdersList
