import { useHistory, useRouteMatch } from 'react-router-dom'
import { useRef, useEffect, useState, FormEvent } from 'react'

import {
  defaultCityData,
  defaultOriginData,
  defaultAddressData,
  defaultCustomerData,
  defaultDestinationData,
  defaultShippingDataError,
  InfoDeliveryProps,
  UseRouteMacth,
  AddressType,
} from '../contracts'
import { StepNames } from '../../../types/models'
import { SEARCH_TIME_INTERVAL } from '../constants'
import { FormShippingFields } from './FormShippingFields'
import useGAEventTracker from '../../../hooks/useGAEventTracker'
import { useGoogleAPIAddressService } from '../../../hooks/useGoogleAPIService'
import { cityType, suggestType } from '../../common/InputAutoComplete/contracts'
import { useGetStoreInfoService } from '../../../hooks/Store/useGetStoreInfoService'

export const FormShipping = ({
  state,
  setDeliveryState,
}: InfoDeliveryProps): JSX.Element => {
  const history = useHistory()
  const {
    url,
    params: { name: urlName },
  }: UseRouteMacth = useRouteMatch()
  const [showForm, setShowForm] = useState(false)
  const [openOrigin, setOpenOrigin] = useState(false)
  const [openDestin, setOpenDestin] = useState(false)
  const GAEventsTracker = useGAEventTracker('webshop')
  const [isValidForm, setIsValidForm] = useState(false)
  const { storeInfo: storeInfoOnDB } = useGetStoreInfoService({
    storeUrlName: urlName,
    authToken: state.authToken,
  })
  const intervalRef: { current: NodeJS.Timeout | null } = useRef(null)
  const [canShowError, setCanShowError] = useState(true)
  const [cityData, setCityData] = useState<cityType>(defaultCityData)
  const [customerData, setCustomerData] = useState(defaultCustomerData)
  const [originData, setOriginData] = useState<suggestType>(defaultOriginData)

  const [destinationData, setDestinationData] = useState<suggestType>(
    defaultDestinationData
  )
  const [shippingDataError, setShippingDataError] = useState(
    defaultShippingDataError
  )
  const [originAddressParams, setOriginAddressParams] = useState(
    defaultAddressData
  )
  const [destinationAddressParams, setDestinationAddressParams] = useState(
    defaultAddressData
  )
  const [originAddressSuggest, setOriginAddressSuggest] = useState<
    suggestType[]
  >([])
  const [destinationAddressSuggest, setDestinationAddressSuggest] = useState<
    suggestType[]
  >([])

  const {
    googleServiceState: originResponse,
    handleService: originHandleService,
  } = useGoogleAPIAddressService()

  const {
    handleService: destinationHandleService,
    googleServiceState: destinationResponse,
  } = useGoogleAPIAddressService()

  /* autocomplete Fields Functionallity */
  useEffect(() => {
    setOriginAddressSuggest(originResponse)
  }, [originResponse])

  useEffect(() => {
    setDestinationAddressSuggest(destinationResponse)
  }, [destinationResponse])

  // This hook set the context state data into local state
  useEffect(() => {
    if (!state || !state.origin) return

    setCityData({
      city_id: state.origin.city_id,
      country_id: state.origin.country_id,
      formatted_address: state.origin.formatted_address,
    })

    setOriginData({
      country_id: state.origin.country_id,
      country: state.origin.country,
      address: state.origin.address,
      addressDetail: state.origin.addressDetail,
      latitude: state.origin.latitude,
      longitude: state.origin.longitude,
      id: state.origin.id,
      label: state.origin.label,
    })

    setDestinationData({
      country_id: state.destination.country_id,
      country: state.destination.country,
      address: state.destination.address,
      addressDetail: state.destination.addressDetail,
      latitude: state.destination.latitude,
      longitude: state.destination.longitude,
      id: state.destination.id,
      label: state.destination.label,
    })

    setCustomerData({
      name: state.customer.name,
      cellPhone: state.customer.cellPhone,
    })

    setShowForm(true)
  }, [])

  const findAddressSuggestions = (address: string, addressType: string) => {
    if (!address || !address.length) {
      return
    }

    if (intervalRef.current) clearTimeout(intervalRef.current)
    intervalRef.current = setTimeout(() => {
      requestAddressData(address, addressType)
    }, SEARCH_TIME_INTERVAL)
  }

  const handleChangeAddress = (
    newValue: suggestType,
    addressType: AddressType
  ) => {
    if (!newValue) return
    try {
      const { address, id, label, latitude, longitude, city } = newValue
      setCityData({ ...cityData, formatted_address: city })

      addressType === 'origin'
        ? setOriginData({
            ...originData,
            address,
            id,
            label,
            latitude,
            longitude,
          })
        : setDestinationData({
            ...destinationData,
            address,
            id,
            label,
            latitude,
            longitude,
          })
    } catch (e) {
      console.error('Hay un error: ', e)
    }
  }

  const handleChangeAddressDetail = (
    newValue: string,
    addressType: AddressType
  ) => {
    addressType === 'origin'
      ? setOriginData({
          ...originData,
          addressDetail: newValue,
        })
      : setDestinationData({
          ...destinationData,
          addressDetail: newValue,
        })
  }

  const requestAddressData = (address: string, addressType: string) => {
    if (addressType == 'origin') {
      originHandleService({
        ...defaultAddressData,
        address: address,
      })
    } else {
      destinationHandleService({
        ...defaultAddressData,
        address: address,
      })
    }
  }

  const validateOriginAddress = (): boolean => {
    if (!originData.address) {
      setShippingDataError({
        ...shippingDataError,
        originAddresError: true,
      })
      return false
    }

    const hasError = String(originData.address).length < 3
    setShippingDataError({
      ...shippingDataError,
      originAddresError: hasError,
    })
    return !hasError
  }
  const validateDestinationAddress = (): boolean => {
    if (!destinationData.address) {
      setShippingDataError({
        ...shippingDataError,
        destinationAddressError: true,
      })
      return false
    }

    const hasError = String(destinationData.address).length < 3
    setShippingDataError({
      ...shippingDataError,
      destinationAddressError: hasError,
    })
    return !hasError
  }
  const validateName = (): boolean => {
    return true
  }
  const validatePhone = (): boolean => {
    if (!customerData.cellPhone.length) {
      setShippingDataError({
        ...shippingDataError,
        phoneError: false,
      })
      return true
    }

    const hasError = customerData.cellPhone.length != 10
    setShippingDataError({
      ...shippingDataError,
      phoneError: hasError,
    })
    return !hasError
  }

  const validateShippingForm = (): boolean => {
    const validationArray = [
      validateName(),
      validatePhone(),
      validateOriginAddress(),
      validateDestinationAddress(),
    ]
    const isValid = !validationArray.includes(false)
    setIsValidForm(isValid)
    return isValid
  }

  useEffect(() => {
    validateShippingForm()
  }, [
    originAddressParams,
    destinationAddressParams,
    originData,
    destinationData,
    customerData,
  ])

  /* handle Click Section */
  const handleClickNext = () => {
    if (isValidForm) {
      GAEventsTracker('shop_webdelivery_nat_fscreen_next')

      const { city_id, country_id, formatted_address: city } = cityData
      const newData = {
        ...state,
        currentStep: StepNames.PaymentMethod,
        origin: {
          ...originData,
          address: String(originData.address),
          city_id: String(city_id),
          country_id: String(country_id),
          city: String(city),
          longitude: Number(originData.longitude),
          latitude: Number(originData.latitude),
        },
        destination: {
          ...destinationData,
          address: String(destinationData.address),
          city_id: String(city_id),
          country_id: String(country_id),
          city: String(city),
          longitude: Number(destinationData.longitude),
          latitude: Number(destinationData.latitude),
        },
        customer: customerData,
      }
      setDeliveryState(newData)
    }
  }

  const preventDefault = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()
  }

  const handleClickBack = () => {
    GAEventsTracker('shop_webdelivery_nat_fscreen_back')
    setDeliveryState({
      ...state,
      currentStep: StepNames.Address,
    })
    history.push(url + '/mylist')
  }

  const handleClickClose = () => {
    GAEventsTracker('shop_webdelivery_nat_fscreen_close')
    setDeliveryState({
      ...state,
      currentStep: StepNames.Address,
    })
    history.push(url + '/mylist')
  }

  useEffect(() => {
    //Keep in mind: the second condition is added so that the response of the service does not overwrite the store info.
    if (storeInfoOnDB?.address && originData.address === '') {
      const placeService = async () => {
        const response = await originHandleService({
          ...defaultAddressData,
          address: `${storeInfoOnDB.address}, ${storeInfoOnDB.city}`,
        })
        setOriginAddressParams({
          ...defaultAddressData,
          address: response?.[0]?.address || '',
        })
        handleChangeAddress(response?.[0], 'origin')
      }
      placeService()
    }
  }, [storeInfoOnDB?.address])

  useEffect(() => {
    setOriginAddressParams({
      ...defaultAddressData,
      address: originData.address || '',
    })
  }, [originData.address])

  useEffect(() => {
    setDestinationAddressParams({
      ...defaultAddressData,
      address: destinationData.address || '',
    })
  }, [destinationData.address])

  return showForm ? (
    <FormShippingFields
      {...{
        handleClickBack,
        handleClickClose,
        handleChangeAddress,
        handleChangeAddressDetail,
        handleClickNext,
        preventDefault,
        findAddressSuggestions,
        isValidForm,
        canShowError,
        openOrigin,
        openDestin,
        originData,
        destinationData,
        customerData,
        defaultAddressData,
        originAddressParams,
        shippingDataError,
        setOriginAddressParams,
        setCanShowError,
        setShippingDataError,
        setCustomerData,
        setOpenOrigin,
        setOpenDestin,
        setDestinationAddressParams,
        originAddressSuggest,
        destinationAddressSuggest,
        destinationAddressParams,
      }}
    />
  ) : (
    <></>
  )
}
