import { DefaultTheme } from 'styled-components'

import { colors } from './colors'
import { fonts } from './fonts'
import { sizes } from './sizes'
import { layout } from './layout'

const mainTheme: DefaultTheme = {
  colors,
  sizes,
  fonts,
  layout,
}

export default mainTheme
