import styled from 'styled-components'
import { FontsName } from '../../styles/fonts'
import { Typography } from '../common/Typography'

export const MainProductsSlide = styled.div`
  width: 100%;
  height: auto;
  padding: ${({ theme: { sizes } }) => `${sizes.spacing.large} 0`};
`
export const TextTitle = styled(Typography)`
  width: 100%;
  ${({ theme }) => theme.fonts[FontsName.Nunito].mediumbold};
  padding-left: ${({ theme: { sizes } }) => sizes.spacing.medium};
  margin-bottom: ${({ theme: { sizes } }) => sizes.spacing.medium};
`
