import { useTranslation } from 'react-i18next'

import { countries } from '../../../config/countries'
import { formatterCurrency } from '../../../utils/functions'
import { PaymentMethodOptions, DeliveryStateType } from '../../../types/models'
import {
  Container,
  DetailContainer,
  DetailInfo,
  DollarIcon,
  Label,
  Value,
} from './styles'

export const ReceiptDetails = ({
  state,
}: {
  state: DeliveryStateType
}): JSX.Element => {
  const { t } = useTranslation()
  const currentCountry = countries.get('Colombia')

  const calculateTotalToPay = (): number => {
    return state.paymentMethod.saleValue > 0
      ? Number(
          Number(state.selectedMarketRates.deliveryPrice) +
            state.paymentMethod.saleValue
        )
      : Number(state.selectedMarketRates.deliveryPrice)
  }

  return (
    <Container>
      <DetailContainer>
        <DollarIcon />
        <DetailInfo>
          <Label>{t('delivery.summary.details.deliveryCost')}:</Label>
          <Value>
            {formatterCurrency(
              currentCountry,
              Number(state.selectedMarketRates.deliveryPrice)
            )}
          </Value>
        </DetailInfo>
      </DetailContainer>

      {state.paymentMethod.method === PaymentMethodOptions.UponDelivery && (
        <DetailContainer>
          <DollarIcon />
          <DetailInfo>
            <Label>{t('delivery.summary.details.valueOfSaleToGet')}:</Label>
            <Value>
              {formatterCurrency(
                currentCountry,
                Number(state.paymentMethod.saleValue)
              )}
            </Value>
          </DetailInfo>
        </DetailContainer>
      )}

      {state.paymentMethod.method === PaymentMethodOptions.UponDelivery && (
        <DetailContainer>
          <DollarIcon />
          <DetailInfo>
            <Label>{t('delivery.summary.details.totalToPay')}:</Label>
            <Value finalValue={true}>
              {formatterCurrency(currentCountry, calculateTotalToPay())}
            </Value>
          </DetailInfo>
        </DetailContainer>
      )}
    </Container>
  )
}
