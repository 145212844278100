import { useContext, useEffect } from 'react'

import { MainContainer } from './styles'
import CategoryButton from '../CategoryButton'
import CatalogContext from '../../context/Catalog/context'
import useGAEventTracker from '../../hooks/useGAEventTracker'
import { useHasDiscounts } from '../../hooks/useHasDiscounts'
import {
  DISCOUNTS_CATEGORY,
  ALL_PRODUCTS_CATEGORY,
  ICategory,
} from '../../types/models'

export interface CategoriesHorizontalSelectorProps {
  onActiveCategoryUpdate?: (newActiveCategory: ICategory | null) => void
}

const CategoriesHorizontalSelector = ({
  onActiveCategoryUpdate,
}: CategoriesHorizontalSelectorProps): JSX.Element => {
  const GAEventsTracker = useGAEventTracker('webshop')
  const {
    state: { activeCategory, availableCategories },
    setActiveCategory,
  } = useContext(CatalogContext)
  const hasDiscounts = useHasDiscounts()

  // TODO: REMOVE IT
  useEffect(() => {
    if (onActiveCategoryUpdate) {
      onActiveCategoryUpdate(activeCategory)
    }
  }, [activeCategory])

  const handleClickCategory = (category: ICategory) => {
    GAEventsTracker('webshop_category_view', category.name)
    setActiveCategory(category)
  }

  const categories = [
    ALL_PRODUCTS_CATEGORY,
    ...(hasDiscounts ? [DISCOUNTS_CATEGORY] : []),
    ...availableCategories,
  ]

  return (
    <MainContainer data-testid="categories-horizontal-selector-container">
      {categories.map((category) => (
        <CategoryButton
          active={activeCategory?.name === category.name}
          category={category}
          key={`catalog.availableCategory.${category.name}`}
          onClick={() => handleClickCategory(category)}
        />
      ))}
    </MainContainer>
  )
}

export default CategoriesHorizontalSelector
