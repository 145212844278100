import styled from 'styled-components'
import { Drawer as MaterialDrawer } from '@material-ui/core'

import { Typography } from '../Typography'
import CloseIcon from '../../../assets/icons/close.svg'

export const Drawer = styled(MaterialDrawer)`
  ${({ theme }) => `
    .MuiDrawer-paper {
      border-top-left-radius: ${theme.sizes.borderRadius.xxlarge};
      border-top-right-radius: ${theme.sizes.borderRadius.xxlarge};
      max-width: 500px;
      max-height: 91vh;
      margin: 0 auto;
    }
  `};
`

export const DrawerContentContainer = styled.div`
  ${({ theme }) => `
    padding: ${theme.sizes.spacing.medium} 0;
    padding-top: 0px;
  `};
`

export const DrawerHeader = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: ${({ theme: { sizes } }) => sizes.spacing.medium};
`

export const DrawerContent = styled.div`
  padding: ${({ theme: { sizes } }) => sizes.spacing.medium};
`

export const DrawerTitle = styled(Typography).attrs({
  variant: 'Largebold',
})`
  white-space: pre-line;
`

export const CloseButton = styled.button`
  background-color: ${({ theme }) => theme.colors.white};
  background-image: url(${CloseIcon});
  background-repeat: no-repeat;
  border: 0;
  cursor: pointer;
  height: 20px;
  width: 20px;
`
