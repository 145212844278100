import { ReactComponent as LensIcon } from '../../assets/lens.svg'
import { useState } from 'react'
import ImageViewer from 'react-simple-image-viewer'
import {
  HelpLabelContainer,
  FloatingContainer,
  Image,
  MainContainer,
  HelpLabel,
} from './styles'

export interface ImageWithViewerProps {
  src: string
  previewText?: string | null
}

const ImageWithViewer = ({
  previewText,
  src,
}: ImageWithViewerProps): JSX.Element => {
  const [viewerIsOpen, toggleViewer] = useState(false)

  return (
    <MainContainer>
      <Image src={src} />
      {previewText && (
        <FloatingContainer>
          <HelpLabelContainer
            data-testid="help-button"
            onClick={() => toggleViewer(true)}
          >
            <LensIcon />
            <HelpLabel data-testid="help-label">{previewText}</HelpLabel>
          </HelpLabelContainer>
        </FloatingContainer>
      )}

      {viewerIsOpen && (
        <ImageViewer
          closeOnClickOutside={true}
          onClose={() => toggleViewer(false)}
          src={[src]}
        />
      )}
    </MainContainer>
  )
}

export default ImageWithViewer
