import styled from 'styled-components'

export const MainContainer = styled.div`
  align-items: center;
  border-radius: ${({
    theme: {
      sizes: { borderRadius },
    },
  }) => borderRadius.medium};
  border: ${({ theme: { colors } }) => `1px solid ${colors.neutral600}`};
  display: flex;
  gap: ${({ theme: { sizes } }) => sizes.spacing.xxxsmall};
  height: 100%;
  justify-content: space-between;
  padding: ${({ theme: { sizes } }) => sizes.spacing.xxsmall};
  width: 100%;
`
