import { useEffect, useState } from 'react'

import { ProductList } from './ProductList'
import { ProductsSkeleton } from '../Skeleton'
import { ProductListLoadProps } from './types'
import { DetailOrder } from '../../../../types/models'
import { getTransactionDetail } from '../../service/orderConfirmed'

export const ProductListLoad = ({
  country,
  setStatus,
  handleError,
  transactionId,
  setOrderDetail,
}: ProductListLoadProps): JSX.Element => {
  const [products, setProducts] = useState<DetailOrder[]>()
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const getList = async () => {
    setIsLoading(true)
    const response = await getTransactionDetail(transactionId)
    if (!response?.transactionDetails) {
      handleError(true)
    } else {
      setOrderDetail(response)
      setProducts(response.transactionDetails)
      setStatus(response?.transaction?.transaction_status_id || null)
      setIsLoading(false)
    }
  }

  useEffect(() => {
    getList()
  }, [])

  return isLoading ? (
    <ProductsSkeleton />
  ) : (
    <ProductList country={country} products={products} />
  )
}
