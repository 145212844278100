import { useContext, useEffect } from 'react'

import CartQuantitySelector from '.'
import { IProduct } from '../../types/models'
import { CartQuantityShape } from './CartQuantitySelector'
import ShoppingCartContext from '../../context/ShoppingCart/context'
import { ShoppingCartProductsSet } from '../../context/ShoppingCart/model'

export interface ContextCartQuantitySelectorProps {
  product: IProduct
  shape?: CartQuantityShape
  onProductsSetUpdate?: (newProductsState: ShoppingCartProductsSet) => void
}

const ContextCartQuantitySelector = ({
  shape,
  product,
  onProductsSetUpdate,
}: ContextCartQuantitySelectorProps): JSX.Element => {
  const {
    state: { productsSet },
    updateProductQuantity,
  } = useContext(ShoppingCartContext)

  useEffect(() => {
    if (onProductsSetUpdate) {
      onProductsSetUpdate(productsSet)
    }
  }, [productsSet])

  const activeQty = productsSet[product.id]?.quantity || 0

  const setActiveQty = (newQty: number) => {
    updateProductQuantity(product, newQty)
  }

  return (
    <CartQuantitySelector
      shape={shape}
      product={product}
      activeQty={activeQty}
      setActiveQty={setActiveQty}
    />
  )
}

export default ContextCartQuantitySelector
