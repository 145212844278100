import { useState } from 'react'

import {
  getShipmentResume,
  ShipmentResumeResponse,
} from '../../services/Shipment/shipmentResumeService'

interface Request {
  authToken: string
}
interface Response {
  resumeShipmentData: ShipmentResumeResponse | undefined
  isLoadedResumeShipment: boolean
  hasErrorResumeShipment: boolean
  callGetShipmentResume: (idDelivery: string) => void
}

export const useGetShipmentResumeService = ({
  authToken,
}: Request): Response => {
  const [
    resumeShipmentData,
    setResumeShipmentData,
  ] = useState<ShipmentResumeResponse>()

  // states Services Hooks
  const [isLoadedResumeShipment, setIsLoadedResumeShipment] = useState(false)
  const [hasErrorResumeShipment, setHasErrorResumeShipment] = useState(false)

  const callService = (idDelivery: string) => {
    getShipmentResume(idDelivery, authToken)
      .then((resumeShipmentResponse) => {
        setResumeShipmentData(resumeShipmentResponse)
        setIsLoadedResumeShipment(true)
      })
      .catch(() => {
        setHasErrorResumeShipment(true)
      })
  }

  return {
    resumeShipmentData,
    isLoadedResumeShipment,
    hasErrorResumeShipment,
    callGetShipmentResume: callService,
  }
}
