import { FC, useReducer } from 'react'

import { actionTypes } from './contracts'
import { DeliveryContext } from './DeliveryContext'
import { DeliveryReducer } from './DeliveryReducer'
import { DeliveryStateType } from '../../types/models'
import { initialState } from '../../components/Delivery/contracts'

export const DeliveryState: FC = ({ children }) => {
  const [state, dispatch] = useReducer(DeliveryReducer, initialState)

  const setDeliveryState = (deliveryState: DeliveryStateType) => {
    dispatch({
      payload: deliveryState,
      type: actionTypes.SET_DELIVERY_STATE,
    })
  }

  return (
    <DeliveryContext.Provider
      value={{
        state,
        setDeliveryState,
      }}
    >
      {children}
    </DeliveryContext.Provider>
  )
}
