import styled from 'styled-components'

import { Typography } from '../../../common/Typography'

export const Container = styled(Typography).attrs(() => ({
  variant: 'XSmall',
  forwardedAs: 'div',
}))`
  display: flex;
  align-items: center;
  ${({ theme }) => `
    color: ${theme.colors.neutral700};
    margin-bottom: ${theme.sizes.spacing.xsmall} 
  `}
`

export const WrapperIcon = styled.span`
  display: flex;
  ${({ theme }) => `
    margin-right: ${theme.sizes.spacing.xxsmall};
  `}
`

export const Label = styled(Typography).attrs(() => ({
  variant: 'XSmall',
  forwardedAs: 'span',
}))<{ isOpen: boolean }>`
  ${({ theme, isOpen }) => `
    color: ${isOpen ? theme.colors.tertiary600 : theme.colors.error500};
  `}
`

export const Divider = styled.span`
  ${({ theme }) => `
    margin: 0 ${theme.sizes.spacing.xxsmall};
  `}
`

export const LabelTime = styled(Typography).attrs(() => ({
  variant: 'XSmall',
  forwardedAs: 'span',
}))`
  ${({ theme }) => `
    color: ${theme.colors.neutral700};
  `}
`

export const LabelFullTime = styled(Typography).attrs(() => ({
  variant: 'XSmall',
  forwardedAs: 'span',
}))`
  ${({ theme }) => `
    color: ${theme.colors.neutral700};
    margin-left: ${theme.sizes.spacing.xxxsmall};
  `}
`
