import styled from 'styled-components'
import { inputProps, INPUTS_NUMBER } from './contracts'
import { Typography } from '../../../../components/common/Typography'

export const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
`
export const Message = styled(Typography)`
  text-align: center;
  margin-bottom: 1rem;
  color: ${({ theme }) => theme.colors.secondary700};
`
export const ErrorMessage = styled(Typography)`
  text-align: center;
  margin-bottom: 0.5rem;
  color: ${({ theme }) => theme.colors.error500};
`
export const MessageWrapper = styled.div`
  cursor: pointer;
`
export const Subtitle = styled(Typography)`
  text-align: center;
  margin-bottom: 1rem;
  color: ${({ theme }) => theme.colors.neutral800};
`
export const Form = styled.form`
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
  flex-direction: column;
`
export const Input = styled.input<inputProps>`
  height: 4rem;
  outline: 0rem;
  font-weight: 700;
  line-height: 2rem;
  font-size: 1.25rem;
  font-style: normal;
  text-align: center;
  border-radius: 14px;
  font-family: Nunito Sans;
  border: 0.5px solid ${({ theme }) => theme.colors.neutral600};
  &-webkit-outer-spin-button,
  &-webkit-inner-spin-button {
    margin: 0rem;
    appearance: none;
  }
  :focus {
    caret-color: ${({ theme }) => theme.colors.secondary700};
    border-color: ${({ theme }) => theme.colors.secondary700};
  }
  margin-bottom: 0.5rem;
  ${({ error, theme }) => error && `border-color: ${theme.colors.error500}`}
`
export const Grid = styled.div`
  gap: 0.5rem;
  display: grid;
  justify-content: center;
  grid-template-columns: repeat(${INPUTS_NUMBER}, max(50px));
`
