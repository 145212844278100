import Axios from 'axios'

import {
  ALL_PRODUCTS_CATEGORY,
  DISCOUNTS_CATEGORY,
  GetProductsListRequest,
  IProduct,
} from '../types/models'
import { DEFAULT_PAGE, PRODUCT_LIST_DEFAULT_SIZE } from '../utils/constants'

export const getProductsList = async ({
  page,
  size,
  search,
  storeId,
  category,
}: GetProductsListRequest): Promise<IProduct[]> => {
  try {
    const response = await Axios({
      url: `${
        process.env.REACT_APP_TREINTA_MICROSERVICES_API_URL
      }/shop/products/list/?limit=${size || PRODUCT_LIST_DEFAULT_SIZE}&page=${
        page || DEFAULT_PAGE
      }&storeId=${storeId}${
        search ? `&search=${search}` : ''
      }&filter=withDiscountVisible${
        category &&
        ![ALL_PRODUCTS_CATEGORY.name, DISCOUNTS_CATEGORY.name].includes(
          category
        )
          ? `&category=${encodeURIComponent(category)}`
          : ''
      }`,
      headers: {
        'X-API-Key': process.env.REACT_APP_TREINTA_MICROSERVICES_API_KEY,
      },
    })
    const { data } = response
    return data
  } catch (error: any) {
    return error
  }
  return []
}
