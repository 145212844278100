export const SLIDE_PROPS = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3.2,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2.5,
  },
  mobile: {
    breakpoint: { max: 464, min: 320 },
    items: 2.3,
  },
  miniMobile: {
    breakpoint: { max: 320, min: 0 },
    items: 2.1,
  },
}

export const SLIDE_STYLE = {
  width: 'auto',
}
