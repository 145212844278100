import { useTranslation } from 'react-i18next'

import { Label, WrapperIcon, LabelFullTime } from '../styles'
import { ReactComponent as IconClockOpen } from '../../../../../assets/icons/clock-open.svg'

export const Open24 = (): JSX.Element => {
  const { t } = useTranslation()

  return (
    <>
      <WrapperIcon>
        <IconClockOpen />
      </WrapperIcon>
      <Label isOpen>{t('scheduler.open')}</Label>
      <LabelFullTime>{t('scheduler.fullTime')}</LabelFullTime>
    </>
  )
}
