import { useTranslation } from 'react-i18next'

import {
  Image,
  Title,
  Price,
  FirstRow,
  Quantity,
  Container,
  SubContainer,
} from './styles'
import { ProductListProps } from './types'
import noPicture from './../../../../assets/empty.svg'
import { formatterCurrency } from '../../../../utils/functions'

export const ProductList = ({
  country,
  products,
}: ProductListProps): JSX.Element => {
  const { t } = useTranslation()
  return (
    <>
      {products?.map(
        ({ name, sale_price, quantity, imageUrl, image_url }, index) => (
          <Container key={`index-${index}-${name}`}>
            <FirstRow>
              <Image src={imageUrl || image_url || noPicture} />
              <SubContainer>
                <Title>{name}</Title>
                <Price>{formatterCurrency(country, sale_price)}</Price>
              </SubContainer>
            </FirstRow>
            <Quantity>
              {quantity === 1
                ? `${quantity} ${t(
                    'orderConfirmed.purchaseProducts.productUnit'
                  )}`
                : `${quantity} ${t(
                    'orderConfirmed.purchaseProducts.productUnits'
                  )}`}
            </Quantity>
          </Container>
        )
      )}
    </>
  )
}
