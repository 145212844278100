import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    height: '3.5rem',
    padding: '10px 0px',
    fontFamily: 'roboto',
  },
  detailsTypographyContainer: {
    marginLeft: theme.spacing(2),
    [theme.breakpoints.between(0, 375)]: {
      width: 'auto',
      textAlign: 'left',
      overflow: 'hidden',
    },
  },
  detailNameTypographyStyle: {
    flexGrow: 1,
    marginTop: 0,
    overflow: 'hidden',
    fontSize: '18px',
    maxHeight: '1.8rem',
    alignSelf: 'center',
    whiteSpace: 'nowrap',
    justifySelf: 'center',
    textOverflow: 'ellipsis',
  },
  containerAddButton: {
    display: 'flex',
    alignSelf: 'center',
    justifySelf: 'end',
    paddingRight: '5px',
  },
  containerProductImage: {
    alignSelf: 'center',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
  },
  containerInfo: {
    width: '100%',
    display: 'grid',
    alignItems: 'center',
    gridTemplateColumns: '1fr 1fr',
  },
}))

export { useStyles }
