import styled from 'styled-components'
import { Typography } from '../common/Typography'

export const ButtomWrapper = styled.div`
  width: 100%;
  bottom: 0px;
  position: sticky;
  ${({ theme }) => `    
    border-top: 1px solid ${theme.colors.neutral300};
    box-shadow: ${theme.sizes.shadows.soft}; 
    background: ${theme.colors.white};
    padding: ${theme.sizes.spacing.xsmall} ${theme.sizes.spacing.medium};  
  `}
`
export const CustomButton = styled.button`
  width: 100%;
  cursor: pointer;
  z-index: 1;
  ${({ theme }) => `
    border-radius: ${theme.sizes.borderRadius.medium};
    height: ${theme.sizes.spacing.xxlarge};       
    background: ${theme.colors.primary500};
    border: 1px solid ${theme.colors.neutral300};
    box-shadow: ${theme.sizes.shadows.soft};
    padding: 6px ${theme.sizes.spacing.xsmall};
  `}
`
export const ContentText = styled.div`
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`
export const ContentCuantity = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
`
export const QuantityProducts = styled(Typography).attrs({
  variant: 'XSmallbold',
})`
  color: ${({ theme: { colors } }) => colors.primary900};
`

export const LabelButton = styled(Typography).attrs({
  variant: 'Mediumbold',
})`
  color: ${({ theme: { colors } }) => colors.primary900};
`

export const ProductsPrice = styled(Typography).attrs({
  variant: 'Mediumbold',
})`
  color: ${({ theme: { colors } }) => colors.primary900};
`
