import { useTranslation } from 'react-i18next'

import { WhatsappButton } from './WhatsappButton'
import { countries } from '../../config/countries'
import { useShoppingCart } from '../ShoppingCart/hooks'
import { URL_WHATSAPP_API } from '../../config/constants'
import { useStoreConfig } from '../../context/AppContext'
import useGAEventTracker from '../../hooks/useGAEventTracker'

export const WhatsappButtonLoad = (): JSX.Element => {
  const { t } = useTranslation()
  const { store } = useStoreConfig()
  const country = countries.get(store?.country)
  const phoneNumber = country.code + store?.phone
  const { totalProductsInCart } = useShoppingCart()
  const GAEventsTracker = useGAEventTracker('webshop')

  const urlToSendMessageToWA = `${URL_WHATSAPP_API}/send/?phone=${phoneNumber}&text=${t(
    'footer.whatsappMessage'
  )}`

  const handleClick = () => {
    window.open(urlToSendMessageToWA)
    GAEventsTracker('webshop_whatsapp')
  }

  return store?.phone ? (
    <WhatsappButton
      handleClick={handleClick}
      productsNumber={totalProductsInCart}
    />
  ) : (
    <></>
  )
}
