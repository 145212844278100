import styled from 'styled-components'

import { FontsName } from '../../styles/fonts'
import { Typography } from '../common/Typography'
import { ReactComponent as NoImagePhoto } from './../../assets/empty.svg'

export const MainContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  box-shadow: 2px -4px 8px rgba(34, 38, 42, 0.05);
  background-color: ${({ theme: { colors } }) => colors.white};
`

export const DetailContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: ${({ theme: { sizes } }) =>
    `${sizes.spacing.medium} ${sizes.spacing.large}`};
  min-height: calc(100vh - 134px);
`

export const ContentContainer = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  flex-direction: column;
`

export const ImageContainer = styled.div`
  width: 264px;
  height: 222px;
  position: relative;
  background-color: ${({ theme }) => theme.colors.neutral200};
  border-radius: ${({ theme }) => theme.sizes.borderRadius.large};
  padding-left: ${({ theme: { sizes } }) => sizes.spacing.xlarge};
  margin-bottom: ${({ theme: { sizes } }) => sizes.spacing.xlarge};
  padding-right: ${({ theme: { sizes } }) => sizes.spacing.xlarge};
`

export const DiscountContainer = styled.div`
  left: 0;
  right: 0;
  z-index: 9;
  width: 100%;
  display: flex;
  top: 0.875rem;
  margin-left: auto;
  margin-right: auto;
  position: absolute;
  align-items: center;
  justify-content: center;
`
export const TextExpiration = styled(Typography)`
  display: flex;
  padding: ${({ theme: { sizes } }) =>
    `${sizes.spacing.xxxsmall} ${sizes.spacing.xsmall}`};
  color: ${({ theme: { colors } }) => colors.tertiary600};
  ${({ theme }) => theme.fonts[FontsName.Nunito].xsmallbold};
  background-color: ${({ theme: { colors } }) => colors.white};
  border-radius: ${({ theme: { sizes } }) => sizes.borderRadius.medium};
  padding: ${({ theme: { sizes } }) =>
    `${sizes.spacing.xxxsmall} ${sizes.spacing.xsmall}`};
  color: ${({ theme: { colors } }) => colors.tertiary600};
  display: flex;
  position: absolute;
  width: auto;
  top: 0.875rem;
  right: 100px;
  z-index: 9;
`

export const TextDiscount = styled(Typography)`
  display: flex;
  color: ${({ theme: { colors } }) => colors.white};
  padding: ${({ theme: { sizes } }) =>
    `${sizes.spacing.xxxsmall} ${sizes.spacing.xsmall}`};
  ${({ theme }) => theme.fonts[FontsName.Nunito].xsmallbold};
  background-color: ${({ theme: { colors } }) => colors.tertiary600};
  border-radius: ${({ theme: { sizes } }) => sizes.borderRadius.medium};
  padding: ${({ theme: { sizes } }) =>
    `${sizes.spacing.xxxsmall} ${sizes.spacing.xsmall}`};
  color: ${({ theme: { colors } }) => colors.white};
  display: flex;
  position: absolute;
  width: auto;
  top: 0.875rem;
  right: 50px;
  z-index: 9;
`

export const PriceAndCategoryContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: ${({ theme: { sizes } }) => sizes.spacing.xlarge};
`

export const AddToCartButtonContainer = styled.div`
  height: 100%;
  width: 200px;
`

export const CategoryLabel = styled(Typography).attrs({
  variant: 'Small',
})`
  width: 100%;
  text-transform: capitalize;
  color: ${({ theme: { colors } }) => colors.neutral700};
`

export const PriceLabel = styled(Typography).attrs({
  variant: 'Small',
})<{ lineThrough?: boolean }>`
  width: 100%;
  text-decoration-line: ${({ lineThrough }) =>
    lineThrough ? 'line-through' : 'none'};
  color: ${({ theme: { colors } }) => colors.secondary700};
  margin-right: ${({ theme }) => theme.sizes.spacing.small};
`

export const DescriptionLabel = styled(Typography).attrs({
  variant: 'Small',
})`
  width: 100%;
  color: ${({ theme: { colors } }) => colors.neutral800};
`

export const NoImage = styled(NoImagePhoto)`
  width: 100%;
  height: 100%;
`

export const Grid = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${({ theme }) => theme.sizes.spacing.small} 0;
`
export const Column = styled.div`
  display: flex;
  min-width: 30%;
  align-items: center;
  flex-direction: column;
`

export const DiscountLabel = styled(Typography).attrs({
  variant: 'Largebold',
})`
  width: 100%;
  color: ${({ theme: { colors } }) => colors.tertiary600};
`
export const Row = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
`

export const NameLabel = styled(Typography).attrs({
  variant: 'Smallbold',
})`
  width: 100%;
  color: ${({ theme: { colors } }) => colors.secondary700};
`

export const Divider = styled.div`
  height: 1px;
  width: 100%;
  margin-bottom: ${({ theme }) => theme.sizes.spacing.small};
  background-color: ${({ theme: { colors } }) => colors.neutral400};
`
export const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const ContainerShopingCartButton = styled.div`
  padding-right: ${({ theme }) => theme.sizes.spacing.medium};
`
export const AlertLabel = styled(Typography).attrs({
  variant: 'XSmall',
})`
  text-align: center;
  color: ${({ theme: { colors } }) => colors.error500};
  margin: ${({ theme: { sizes } }) => sizes.spacing.small} 0;
`
