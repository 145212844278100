import { SignUpPropsType } from './contracts'
import { useTranslation } from 'react-i18next'

import logo from '../../assets/treintaLogo.png'
import { SignUpForm } from './components/SignUpForm'
import { Recaptcha } from '../../components/Recaptcha'
import { SignUpValidation } from './components/SignUpValidation'
import { Menu } from '../../components/StoreHeader/components/Menu'
import { Logo, Title, Container, ViewContainer, MenuContainer } from './styles'
import { ReactComponent as GoBacK } from '../../assets/arrowBack.svg'

export const SignUp = ({
  step,
  name,
  phone,
  goBack,
  isLoading,
  isDisabled,
  handleName,
  handleNext,
  handlePhone,
  handleCountry,
  handleSignInWithPhone,
}: SignUpPropsType): JSX.Element => {
  const { t } = useTranslation()
  const formProps = {
    name,
    phone,
    isLoading,
    handleName,
    isDisabled,
    handlePhone,
    handleCountry,
    handleNext: handleSignInWithPhone,
  }

  const getStep = () => {
    switch (step) {
      case 1:
        return <SignUpForm {...formProps} />
      case 2:
        return <SignUpValidation handleNext={handleNext} />
      default:
        break
    }
  }

  return (
    <ViewContainer>
      <MenuContainer>
        {step === 1 ? <Menu /> : <GoBacK onClick={goBack} />}
      </MenuContainer>
      <Recaptcha id="recaptcha-container" />
      <Container>
        <Logo src={logo} alt="logo" />
        <Title>{t('signUp.title')}</Title>
        {getStep()}
      </Container>
    </ViewContainer>
  )
}
