import { useState } from 'react'
import {
  CancelResponse,
  removeShipmentService,
} from '../../services/Shipment/removeShipmentService'

interface Request {
  deliveryId: string
  authToken: string
  callback: () => void
}
interface Response {
  removeShipmentData: CancelResponse | undefined
  hasErrorRemoveShipment: boolean
  removeShipment: () => void
}

export const useRemoveShipmentService = ({
  deliveryId,
  authToken,
  callback,
}: Request): Response => {
  const [removeShipmentData, setRemoveShipmentData] = useState<CancelResponse>()

  // states Services Hooks
  const [hasErrorRemoveShipment, setHasErrorRemoveShipment] = useState(false)

  const callService = async () => {
    if (deliveryId !== '') {
      try {
        const resumeShipmentResponse = await removeShipmentService(
          authToken,
          deliveryId
        )
        setRemoveShipmentData(resumeShipmentResponse)
        callback()
      } catch (err) {
        console.log(err)
        setHasErrorRemoveShipment(true)
      }
    }
  }

  return {
    removeShipmentData,
    hasErrorRemoveShipment,
    removeShipment: callService,
  }
}
