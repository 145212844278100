import styled from 'styled-components'
import { Typography } from '../../components/common/Typography'

export const MainContainer = styled.div`
  width: 100%;
  margin: 0rem;
  height: 100%;
  display: flex;
  height: 100vh;
  padding: ${({ theme }) => `0rem ${theme.sizes.spacing.medium}`};
  position: relative;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.white};
`
export const Header = styled.div`
  width: 100%;
  display: flex;
  margin: 1rem 0rem;
  align-items: center;
`
export const Title = styled(Typography)`
  text-align: start;
  padding-left: 1rem;
  color: ${({ theme }) => theme.colors.secondary700};
`
export const ContentContainer = styled.div`
  flex: 1;
  width: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
`
export const LoadingMessage = styled(Typography)``
