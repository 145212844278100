import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { CSSProperties, useState } from 'react'
import { useRouteMatch } from 'react-router-dom'

import {
  AlertLabel,
  ProductName,
  TextDiscount,
  ProductPrices,
  OriginalPrice,
  DiscountPrice,
  MainContainer,
  ProductPicture,
  WrapperMetaData,
  MetadataContainer,
  NotAvailableProduct,
  QtySelectorContainer,
  ProductPictureContainer,
} from './styles'
import {
  formatterCurrency,
  calculateDiscountPercent,
} from '../../../utils/functions'
import { ProductProps } from '../types'
import noPicture from '../../../assets/empty.svg'
import { STOCK_CONFIG } from '../../../config/stock-config'
import { useStoreConfig } from '../../../context/AppContext'
import { ShoppingCartProductsSet } from '../../../context/ShoppingCart/model'
import { CART_QUANTITY_LARGE_SHAPE } from '../../CartQuantitySelector/CartQuantitySelector'
import ContextCartQuantitySelector from '../../CartQuantitySelector/ContextCartQuantitySelector'

const ProductAsListItem = ({
  country,
  product,
  onClick,
}: ProductProps): JSX.Element => {
  const { stockConfig } = useStoreConfig()
  const { t: translate } = useTranslation()
  const {
    path,
    params: { name },
  }: { path: string; params: { name: string } } = useRouteMatch()
  const [showAlerLabel, setShowAlerLabel] = useState<boolean>(false)

  const isDisabledProduct = () => {
    return stockConfig === STOCK_CONFIG.SHOW_DISABLED && product.stock === 0
  }

  const disabledStyles: CSSProperties = {
    pointerEvents: 'none',
    cursor: 'not-allowed',
  }

  const handleChange = (productSet: ShoppingCartProductsSet) => {
    const quantity = productSet[product.id]?.quantity || 0
    setShowAlerLabel(quantity >= product.stock)
  }

  return (
    <MainContainer
      onClick={onClick}
      isDisabled={isDisabledProduct()}
      data-testid="list-item-container"
    >
      <Link
        to={`${path.replace(':name', name)}/${product.id}`}
        style={isDisabledProduct() ? disabledStyles : {}}
      >
        <WrapperMetaData isDisabled={isDisabledProduct()}>
          <ProductPictureContainer>
            {product.discount && (
              <TextDiscount>
                {calculateDiscountPercent(
                  product.discount?.originalPrice,
                  product.discount?.finalPrice
                )}
              </TextDiscount>
            )}
            <ProductPicture
              src={product?.imageUrl || noPicture}
              alt={product.name}
            />
          </ProductPictureContainer>
          <MetadataContainer>
            <ProductName>{product.name}</ProductName>
            <ProductPrices>
              <OriginalPrice lineThrough={!!product?.discount?.finalPrice}>
                {formatterCurrency(country, product.price)}
              </OriginalPrice>
              {product?.discount?.finalPrice && (
                <DiscountPrice>
                  {formatterCurrency(country, product.discount?.finalPrice)}
                </DiscountPrice>
              )}
            </ProductPrices>
            {showAlerLabel &&
              stockConfig !== STOCK_CONFIG.SHOW_NOT_LIMIT &&
              product.stock > 0 && (
                <AlertLabel>
                  {translate('shoppingCart.stockAlertLabel', {
                    quantity: product.stock,
                  })}
                </AlertLabel>
              )}
            {isDisabledProduct() && (
              <NotAvailableProduct>
                {translate('catalog.notAvailable')}
              </NotAvailableProduct>
            )}
          </MetadataContainer>
        </WrapperMetaData>
      </Link>
      <QtySelectorContainer>
        <ContextCartQuantitySelector
          product={product}
          shape={CART_QUANTITY_LARGE_SHAPE}
          onProductsSetUpdate={handleChange}
        />
      </QtySelectorContainer>
    </MainContainer>
  )
}

export default ProductAsListItem
