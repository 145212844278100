import { MainContainer } from './styles'
import { ReactComponent as GridIcon } from '../../assets/layout-grid.svg'
import { ReactComponent as ListIcon } from '../../assets/layout-list.svg'

export const LAYOUT_GRID_TYPE = 'grid'
export const LAYOUT_LIST_TYPE = 'list'

export type LayoutType = typeof LAYOUT_GRID_TYPE | typeof LAYOUT_LIST_TYPE

export interface LayoutTypeButtonProps {
  active: boolean
  dataTestId?: string
  onClick: () => void
  type: LayoutType
}

const LayoutTypeButton = ({
  active,
  dataTestId = 'icon-container',
  onClick,
  type,
}: LayoutTypeButtonProps): JSX.Element => (
  <MainContainer
    active={active}
    data-testid={dataTestId}
    onClick={(e) => {
      e.stopPropagation()
      onClick()
    }}
  >
    {type === LAYOUT_GRID_TYPE && <GridIcon />}
    {type === LAYOUT_LIST_TYPE && <ListIcon />}
  </MainContainer>
)

export default LayoutTypeButton
