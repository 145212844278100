import styled from 'styled-components'

import mainTheme from '../../../styles/theme'
import { Typography } from '../../common/Typography'
import { ReactComponent as GoBackIcon } from '../../../assets/arrowBack.svg'
import { ReactComponent as DoorIcon } from '../../../assets/icons/door-icon.svg'
import { ReactComponent as CloseIcon } from '../../../assets/icons/header-close.svg'
import { ReactComponent as Whatsapp } from '../../../assets/icons/whatsapp-icon.svg'

export const dynamicFill = (disabled: boolean): string => {
  return disabled ? mainTheme.colors.neutral600 : mainTheme.colors.tertiary600
}

export const MainWrapper = styled.div`
  padding: 0 ${({ theme }) => theme.sizes.spacing.medium};
  overflow-x: hidden;
  position: relative;
  height: 100vh;
`

export const Header = styled.div`
  display: grid;
  grid-template-columns: 35px 1fr 15px;
  margin-top: ${({ theme }) => theme.sizes.spacing.medium};
  margin-bottom: 24px;
`

export const Icon = styled(GoBackIcon)`
  margin-right: 20px;
  color: ${({ theme }) => theme.colors.secondary700};
  align-self: center;
`

export const IconClose = styled(CloseIcon)`
  margin-right: 20px;
  color: ${({ theme }) => theme.colors.secondary700};
  align-self: center;
  cursor: pointer;
`

export const Title = styled(Typography)`
  color: ${({ theme }) => theme.colors.secondary700};
  align-self: center;
`

export const Divider = styled.div`
  width: 110%;
  height: 0.5px;
  background-color: ${({ theme }) => theme.colors.neutral500};
  margin: ${({ theme }) => theme.sizes.spacing.large} 0
    ${({ theme }) => theme.sizes.spacing.large} -${({ theme }) => theme.sizes.spacing.medium};
`

export const ShareContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 20%;
`

export const ShareCopy = styled(Typography)<{ disabled: boolean }>`
  font-size: ${({ theme }) => theme.sizes.spacing.medium};
  font-weight: 700;
  color: ${({ theme, disabled }) =>
    disabled ? theme.colors.neutral600 : theme.colors.tertiary600};
  margin-left: ${({ theme }) => theme.sizes.spacing.small};
`

export const WhatsappIcon = styled(Whatsapp)`
  align-self: center;
`

export const ButtonContainer = styled.div`
  width: 100%;
  padding: 8px 16px;
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: 2px -4px 8px rgba(34, 38, 42, 0.05);
  position: absolute;
  bottom: 0;
  left: 0;
`
export const AlertDoorIcon = styled(DoorIcon)`
  align-self: center;
  margin-bottom: 18px;
`
