import { useEffect, useState } from 'react'

import { DiscountResponse } from '../types/models'
import { getDiscountsList } from '../services/discounts'
import { compareRangeWithCurrentDay } from '../utils/datefunctions'

interface UseGetDiscountList {
  dataDiscounts: DiscountResponse[]
}

export const useGetDiscountList = (storeId: string): UseGetDiscountList => {
  const [dataDiscounts, setDataDiscounts] = useState<DiscountResponse[]>([])

  useEffect(() => {
    getDiscountsList(storeId).then((serviceDiscounts) => {
      const filteredDiscounts = serviceDiscounts.filter(
        ({ startDate, endDate }) => {
          return compareRangeWithCurrentDay(startDate, endDate)
        }
      )
      setDataDiscounts(filteredDiscounts)
    })
  }, [])

  return { dataDiscounts }
}
