import { AxiosRequestConfig } from 'axios'

import httpRequest from '../../components/Delivery/httpClient'
import { SCROLL_LIMIT_PAGE } from '../../components/Delivery/constants'

const urlBaseService = `${process.env.REACT_APP_TREINTA_MICROSERVICES_API_URL}/delivery/list`

export interface ListChipmentType {
  id: string
  createdAt: string
  customerName: string
  customerPhone: string
  statusId: number
}

export const getListShipmentService = async (
  currentPage: number,
  authToken: string,
  storeId: string
): Promise<ListChipmentType[]> => {
  const requestData: AxiosRequestConfig = {
    url: urlBaseService,
    method: 'GET',
    params: {
      limit: SCROLL_LIMIT_PAGE,
      page: currentPage,
      storeId: storeId,
    },
  }
  return httpRequest(requestData, authToken)
}
