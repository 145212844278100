export const countries = new Map()

countries.set('Colombia', {
  id: 1,
  name: 'Colombia',
  acronym: 'CO',
  code: '+57',
  digits: [10],
  support: '+573187901432',
  locale: 'es-CO',
  currency: 'COP',
  decimals: false,
  whatsappUrl: 'https://chat.whatsapp.com/FBZEEOUAszuHUgQW753lOP',
  timeZone: 'America/Bogota',
  flag: require('./../assets/countries/colombia.svg'),
})

countries.set('México', {
  id: 2,
  name: 'México',
  acronym: 'MX',
  code: '+52',
  digits: [10],
  support: '+573187901432',
  locale: 'es-MX',
  currency: 'MXN',
  decimals: true,
  whatsappUrl: 'https://chat.whatsapp.com/FJiDtDtxD0w7HBndsORn8o',
  timeZone: '	America/Mexico_City',
  flag: require('./../assets/countries/mexico.svg'),
})
countries.set('Argentina', {
  id: 3,
  name: 'Argentina',
  acronym: 'AR',
  code: '+54',
  digits: [10, 11],
  support: '+573187901432',
  locale: 'es-AR',
  currency: 'ARS',
  decimals: true,
  whatsappUrl: 'https://chat.whatsapp.com/LoxXOBNZrOp5L88vpTcex6',
  timeZone: 'America/Argentina/Buenos_Aires',
  flag: require('./../assets/countries/argentina.svg'),
})
countries.set('Perú', {
  id: 4,
  name: 'Perú',
  acronym: 'PE',
  code: '+51',
  digits: [9],
  support: '+573187901432',
  locale: 'es-PE',
  currency: 'PEN',
  decimals: true,
  whatsappUrl: 'https://chat.whatsapp.com/CZ3HpVBhgB30G90Um7LXAI',
  timeZone: 'America/Lima',
  flag: require('./../assets/countries/peru.svg'),
})
countries.set('Venezuela', {
  id: 5,
  name: 'Venezuela',
  acronym: 'VE',
  code: '+58',
  digits: [10],
  support: '+573187901432',
  locale: 'en-US',
  currency: 'USD',
  decimals: false,
  whatsappUrl: 'https://chat.whatsapp.com/FUaCmkhQX9T5iwcQz5a8gR',
  timeZone: 'America/Caracas',
  flag: require('./../assets/countries/venezuela.svg'),
})
countries.set('Chile', {
  id: 6,
  name: 'Chile',
  acronym: 'CL',
  code: '+56',
  digits: [9],
  support: '+573187901432',
  locale: 'es-CL',
  currency: 'CLP',
  decimals: false,
  whatsappUrl: 'https://chat.whatsapp.com/F6Pw16GMmFCGxsVq08olqj',
  timeZone: 'America/Santiago',
  flag: require('./../assets/countries/chile.svg'),
})
countries.set('Guatemala', {
  id: 7,
  name: 'Guatemala',
  acronym: 'GT',
  code: '+502',
  digits: [8],
  support: '+573187901432',
  locale: 'es-GT',
  currency: 'GTQ',
  decimals: true,
  whatsappUrl: 'https://chat.whatsapp.com/KmlaoorJCSUGdMP5MEqM0p',
  timeZone: 'America/Guatemala',
  flag: require('./../assets/countries/guatemala.svg'),
})
countries.set('Ecuador', {
  id: 8,
  name: 'Ecuador',
  acronym: 'EC',
  code: '+593',
  digits: [9],
  support: '+573187901432',
  locale: 'es-EC',
  currency: 'USD',
  decimals: true,
  whatsappUrl: 'https://chat.whatsapp.com/EnvLUeov8dL4l4w5hN23no',
  timeZone: 'America/Guayaquil',
  flag: require('./../assets/countries/ecuador.svg'),
})
countries.set('Bolivia', {
  id: 9,
  name: 'Bolivia',
  acronym: 'BO',
  code: '+591',
  digits: [8],
  support: '+573187901432',
  locale: 'es-BO',
  currency: 'BOB',
  decimals: true,
  whatsappUrl: 'https://chat.whatsapp.com/HQO85kt1aXtITEmgGqxB80',
  timeZone: 'America/La_Paz',
  flag: require('./../assets/countries/bolivia.svg'),
})
countries.set('República Dominicana', {
  id: 10,
  name: 'República Dominicana',
  acronym: 'DO',
  code: '+1',
  digits: [10],
  support: '+573187901432',
  locale: 'es-DO',
  currency: 'DOP',
  decimals: true,
  whatsappUrl: 'https://chat.whatsapp.com/I7jvk2CjZku6W6ECEOnu4e',
  timeZone: 'America/Santo_Domingo',
  flag: require('./../assets/countries/republica-dominicana.svg'),
})
countries.set('Honduras', {
  id: 11,
  name: 'Honduras',
  acronym: 'HN',
  code: '+504',
  digits: [8],
  support: '+573187901432',
  locale: 'es-HN',
  currency: 'HNL',
  decimals: true,
  whatsappUrl: 'https://chat.whatsapp.com/IvjAL8CssGGEf9Wf9TFW6J',
  timeZone: 'America/Tegucigalpa',
  flag: require('./../assets/countries/honduras.svg'),
})
countries.set('Paraguay', {
  id: 12,
  name: 'Paraguay',
  acronym: 'PY',
  code: '+595',
  digits: [8, 9],
  support: '+573187901432',
  label: '985954732',
  locale: 'es-PY',
  currency: 'PYG',
  decimals: false,
  timeZone: 'America/Asuncion',
  flag: require('./../assets/countries/paraguay.svg'),
})
countries.set('Uruguay', {
  id: 13,
  name: 'Uruguay',
  acronym: 'UY',
  code: '+598',
  digits: [8],
  support: '+573187901432',
  locale: 'es-UY',
  currency: 'UYU',
  decimals: true,
  whatsappUrl: 'https://chat.whatsapp.com/GAOisbhniRMLfiyuKFoNrj',
  timeZone: 'America/Montevideo',
  flag: require('./../assets/countries/uruguay.svg'),
})
countries.set('El Salvador', {
  id: 14,
  name: 'El Salvador',
  acronym: 'SV',
  code: '+503',
  digits: [8],
  support: '+573187901432',
  locale: 'es-SV',
  currency: 'USD',
  decimals: true,
  whatsappUrl: 'https://chat.whatsapp.com/KwKOLmgsQzuC5NNoRyTRKN',
  timeZone: 'America/El_Salvador',
  flag: require('./../assets/countries/el-salvador.svg'),
})
countries.set('Nicaragua', {
  id: 15,
  name: 'Nicaragua',
  acronym: 'NI',
  code: '+505',
  digits: [8],
  support: '+573187901432',
  locale: 'es-NI',
  currency: 'NIO',
  decimals: true,
  whatsappUrl: 'https://chat.whatsapp.com/DqBhpBPuKqSIJ2dr4puE40',
  timeZone: 'America/Managua',
  flag: require('./../assets/countries/nicaragua.svg'),
})
countries.set('Panamá', {
  id: 16,
  name: 'Panamá',
  acronym: 'PA',
  code: '+507',
  digits: [8],
  support: '+573187901432',
  locale: 'en-US',
  currency: 'USD',
  decimals: true,
  whatsappUrl: 'https://chat.whatsapp.com/DD9Zcq3dp7dFd0X815K1C8',
  timeZone: 'America/Panama',
  flag: require('./../assets/countries/panama.svg'),
})
countries.set('Costa Rica', {
  id: 17,
  name: 'Costa Rica',
  acronym: 'CR',
  code: '+506',
  digits: [8],
  support: '+573187901432',
  locale: 'es-CR',
  currency: 'CRC',
  decimals: false,
  whatsappUrl: 'https://chat.whatsapp.com/Cwr9923tykA3G8AAMyV19V',
  timeZone: 'America/Costa_Rica',
  flag: require('./../assets/countries/costa-rica.svg'),
})
countries.set('Brasil', {
  id: 18,
  name: 'Brasil',
  acronym: 'BR',
  code: '+55',
  digits: [11],
  support: '+17867890781',
  locale: 'pt-BR',
  currency: 'BRL',
  decimals: true,
  whatsappUrl: 'https://chat.whatsapp.com/EkzBUsm6zpuIUIefksMc2E',
  timeZone: 'America/Sao_Paulo',
  flag: require('./../assets/countries/brasil.svg'),
})
