import styled from 'styled-components'
import { FormControl } from '@material-ui/core'
import { Typography } from '../../components/common/Typography'

export const SignUpMessage = styled(Typography).attrs({ variant: 'Small' })`
  font-size: 14;
  text-align: center;
  margin: 0rem 0rem 1rem 0rem;
`
export const FormContainer = styled(FormControl)`
  width: 100%;
  height: 40px;
`
export const NameContainer = styled.div`
  width: 100%;
  margin-bottom: 1rem;
`
export const PhoneContainer = styled.div`
  width: 100%;
`
export const InputContainer = styled.form`
  width: 100%;
  display: flex;
  margin-bottom: 8;
  align-items: center;
  flex-direction: column;
`
export const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  margin-bottom: 0.5rem;
`
export const Button = styled.button`
  width: 100%;
  height: 40px;
  border: none;
  display: flex;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5rem;
  align-items: center;
  border-radius: 0.25rem;
  justify-content: center;
  color: ${({ theme }) => theme.colors.primary900};
  background-color: ${({ theme }) => theme.colors.primary500};
  :disabled {
    color: ${({ theme }) => theme.colors.neutral600};
    background-color: ${({ theme }) => theme.colors.neutral300};
  }
`
export const Title = styled(Typography).attrs({
  forwardedAs: 'h1',
  variant: 'Largebold',
})`
  margin: 0;
  font-weight: bold;
  line-height: 2rem;
  font-size: 1.25rem;
  margin-bottom: 0.5rem;
  color: ${({ theme }) => theme.colors.secondary500};
`
export const Logo = styled.img`
  width: 2rem;
  height: auto;
  margin-bottom: 1.5rem;
`
export const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
`
export const ViewContainer = styled.div`
  width: 100%;
  margin: 0rem;
  height: 100%;
  display: flex;
  height: 100vh;
  padding: 0rem 1rem;
  position: relative;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.white};
`
export const SpinnerContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: 'center';
`
export const Subtitle = styled(Typography).attrs({ variant: 'Small' })`
  text-align: center;
  margin-bottom: 1.5rem;
`
export const MenuContainer = styled.div`
  left: 1rem;
  top: 2rem;
  position: absolute;
`
