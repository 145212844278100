import styled from 'styled-components'

import { FontsName } from '../../../styles/fonts'
import { Typography } from '../../common/Typography'
import { ReactComponent as DateIconImage } from '../../../assets/icons/date-icon.svg'
import { ReactComponent as ListIconImage } from '../../../assets/icons/list-icon.svg'
import { ReactComponent as PhoneIconImage } from '../../../assets/icons/phone-icon.svg'
import { ReactComponent as TrackIconImage } from '../../../assets/icons/track-icon.svg'
import { ReactComponent as DollarIconImage } from '../../../assets/icons/dollar-icon.svg'
import { ReactComponent as PaymentIconImage } from '../../../assets/icons/payment-icon.svg'
import { ReactComponent as DownloadIconImage } from '../../../assets/icons/download-icon.svg'
import { ReactComponent as WhatsAppIconImage } from '../../../assets/icons/whatsapp-icon.svg'
import { ReactComponent as ArrowGreenIconImage } from '../../../assets/icons/arrowgreen-icon.svg'
import { ReactComponent as ExclamationIconImage } from '../../../assets/icons/exclamationred-icon.svg'

// General Styles
export const MainContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`

export const TitleContainer = styled.div`
  margin-bottom: ${({ theme: { sizes } }) => sizes.spacing.xsmall};
  display: flex;
  align-items: center;
  justify-content: space-between;
`
// Header Styles
export const HeaderContainer = styled.div`
  padding: ${({ theme: { sizes } }) =>
    `${sizes.spacing.small} ${sizes.spacing.medium}`};
  width: 100%;
  display: flex;
  align-items: center;
`
export const DataSectionHeader = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`
export const ImageSection = styled.section`
  width: 24px;
  height: 24px;
  border-radius: 50%;
  overflow: hidden;
  align-self: center;
`

export const ProviderImage = styled.img`
  width: 100%;
  height: 100%;
`

export const TextHeader = styled(Typography)`
  ${({ theme }) => theme.fonts[FontsName.Nunito].largebold}
  margin-left: ${({ theme: { sizes } }) => sizes.spacing.small};
  max-width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

export const SectionMyAccount = styled.div`
  ${({ theme }) => theme.fonts[FontsName.Nunito].smallbold}
  color: ${({ theme }) => theme.colors.tertiary600};
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
`
export const ArrowGreenIcon = styled(ArrowGreenIconImage)`
  width: ${({ theme: { sizes } }) => sizes.spacing.xsmall};
  margin-left: ${({ theme: { sizes } }) => sizes.spacing.xsmall};
  cursor: pointer;
  height: auto;
`

// General Sections Styles
export const ConfirmSection = styled.div`
  padding: ${({ theme: { sizes } }) => sizes.spacing.medium};
  border-bottom: ${({ theme }) => `0.5px solid ${theme.colors.neutral500}`};
`
export const ConfirmSectionWB = styled.div`
  padding: ${({ theme: { sizes } }) => sizes.spacing.medium};
`

export const TextTitleSection = styled(Typography)`
  margin-bottom: ${({ theme: { sizes } }) => sizes.spacing.xsmall};
  ${({ theme }) => theme.fonts[FontsName.Nunito].largebold}
`

export const InfoContainer = styled.div`
  display: flex;
  align-items: center;
`
export const InfoWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const Label = styled(Typography)`
  margin-left: ${({ theme: { sizes } }) => sizes.spacing.xxsmall};
  font-size: ${({ theme }) => theme.sizes.spacing.small};
  color: ${({ theme }) => theme.colors.neutral700};
`

export const Value = styled(Typography)<{
  isHighlighted: boolean
}>`
  ${({ theme }) => theme.fonts[FontsName.Nunito].smallbold}
  color: ${({ theme, isHighlighted }) =>
    isHighlighted ? theme.colors.tertiary600 : theme.colors.secondary600};
`

export const DollarIcon = styled(DollarIconImage)`
  align-self: center;
  justify-self: center;
`
export const DateIcon = styled(DateIconImage)`
  align-self: center;
  justify-self: center;
`
export const ListIcon = styled(ListIconImage)`
  align-self: center;
  justify-self: center;
`
export const PaymentIcon = styled(PaymentIconImage)`
  align-self: center;
  justify-self: center;
`

// Status Section Styles
export const TextStatus = styled(Typography)`
  ${({ theme }) => theme.fonts[FontsName.Nunito].small};
  color: ${({ theme }) => theme.colors.neutral700};
`

export const DeliveryStatus = styled.div`
  ${({ theme }) => theme.fonts[FontsName.Nunito].small};
  padding: ${({ theme: { sizes } }) =>
    `${sizes.spacing.xxsmall} ${sizes.spacing.small}`};
  background-color: ${({ theme }) => theme.colors.neutral400};
  border-radius: 4px;
`

// Tracking Section Styles
export const DownloadContainer = styled.div`
  margin-top: ${({ theme: { sizes } }) => sizes.spacing.xsmall};
  display: flex;
  align-items: center;
  justify-content: space-between;
`
export const TrackIcon = styled(TrackIconImage)`
  margin-right: ${({ theme: { sizes } }) => sizes.spacing.xsmall};
`
export const DownloadIcon = styled(DownloadIconImage)`
  margin-right: ${({ theme: { sizes } }) => sizes.spacing.xsmall};
`
export const DownloadGuide = styled.div`
  display: flex;
`
export const TrackingButton = styled.button`
  padding: ${({ theme: { sizes } }) => sizes.spacing.xsmall};
  border: 1px solid #1a2732;
  border-radius: 4px;
  cursor: pointer;
`

// Cancel Section Styles
export const CancelSection = styled.div`
  margin-top: ${({ theme: { sizes } }) => sizes.spacing.small};
  margin-bottom: ${({ theme: { sizes } }) => sizes.spacing.small};
  width: 100%;
  display: flex;
  justify-content: center;
`
export const CancelButton = styled.button`
  ${({ theme }) => theme.fonts[FontsName.Nunito].mediumbold}
  padding: ${({ theme: { sizes } }) => `${sizes.spacing.xsmall} 0`};
  color: ${({ theme }) => theme.colors.error500};
  background-color: transparent;
  cursor: pointer;
  border: none;
`
export const ExclamationIcon = styled(ExclamationIconImage)`
  align-self: center;
  margin-bottom: 18px;
  width: 52px;
  height: auto;
  color: green;
  fill: green;
`

// WhatsApp Section Styles
export const WhatsAppLink = styled.div`
  margin-top: ${({ theme: { sizes } }) => sizes.spacing.small};
  margin-bottom: ${({ theme: { sizes } }) => sizes.spacing.small};
  ${({ theme }) => theme.fonts[FontsName.Nunito].mediumbold}
  color: ${({ theme }) => theme.colors.tertiary600};
  display: flex;
  justify-content: center;
`
export const WhatsAppIcon = styled(WhatsAppIconImage)`
  margin-right: ${({ theme: { sizes } }) => sizes.spacing.xsmall};
`

// Contact Section Styles
export const ContactInfo = styled.div`
  border: ${({ theme }) => `0.5px solid ${theme.colors.neutral500}`};
  border-radius: ${({ theme: { sizes } }) => sizes.spacing.xsmall};
  margin-top: ${({ theme: { sizes } }) => sizes.spacing.medium};
  padding: ${({ theme: { sizes } }) => sizes.spacing.xsmall};
  display: flex;
  align-items: center;
`
export const ImageSectionContact = styled.section`
  border-radius: 50%;
  overflow: hidden;
  align-self: center;
  width: 40px;
  height: 40px;
`
export const DataSectionContact = styled.a`
  padding-left: ${({ theme: { sizes } }) => sizes.spacing.xsmall};
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`
export const TextContact = styled(Typography)`
  ${({ theme }) => theme.fonts[FontsName.Nunito].smallbold}
`
export const PhoneIcon = styled(PhoneIconImage)`
  display: flex;
`
