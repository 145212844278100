import i18next from 'i18next'

// define Status By ID
export const defineDeliveryStatusName = (statusId: number): string => {
  let statusName = ''
  switch (statusId) {
    case 1:
      statusName = i18next.t('delivery.status.inCollection')
      break
    case 2:
      statusName = i18next.t('delivery.status.onTheWay')
      break
    case 3:
      statusName = i18next.t('delivery.status.delivered')
      break
    case 4:
      statusName = i18next.t('delivery.status.canceled')
      break
    case 5:
      statusName = i18next.t('delivery.status.returnProcess')
      break
    case 6:
      statusName = i18next.t('delivery.status.returned')
      break
    default:
      break
  }
  return statusName
}
