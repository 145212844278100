import { useContext } from 'react'
import { useTranslation } from 'react-i18next'

import { Button } from './styles'
import { useCatalogState } from '../../../../context/ShoppingCartContext'
import { useShoppingCart } from '../../../../components/ShoppingCart/hooks'
import { ShoppingCartContext } from '../../../../context/ShoppingCartContext/ShoppingCartContext'
import useGAEventTracker from '../../../../hooks/useGAEventTracker'

export const SummaryButton = (): JSX.Element => {
  const { t } = useTranslation()
  const {
    state: { validateFormPersonalData },
  } = useCatalogState()
  const { totalProductsInCart } = useShoppingCart()
  const emptyCart = totalProductsInCart === 0
  const {
    setCheckoutStep,
    state: { checkoutStep },
  } = useContext(ShoppingCartContext)
  const GAEventsTracker = useGAEventTracker('webshop')
  const handleClick = () => {
    GAEventsTracker('webshop_continue_personaldata')
    setCheckoutStep(checkoutStep + 1)
  }
  return (
    <Button
      data-testid="next-summary-button"
      disabled={emptyCart || validateFormPersonalData}
      onClick={handleClick}
    >
      {t('commons.next')}
    </Button>
  )
}
