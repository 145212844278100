import { Route, useHistory } from 'react-router-dom'
import LoadingPage from '../../modules/Pages/Loading'
import { ProtectedRouteType } from '../../types/models'
import { useStoreConfig } from '../../context/AppContext'

export const ProtectedRoute = ({
  path,
  component,
  redirectPath,
  unAuthRedirectPath,
  ...othersProps
}: ProtectedRouteType): JSX.Element => {
  const history = useHistory()
  const { setAuthRedirectPath, isUserAuthenticated } = useStoreConfig()

  const redirectOnUnAthentication = async () => {
    await setAuthRedirectPath(redirectPath)
    history.push(unAuthRedirectPath)
  }

  if (isUserAuthenticated === false) {
    redirectOnUnAthentication()
  }
  return isUserAuthenticated === undefined ? (
    <LoadingPage />
  ) : (
    <Route {...othersProps} path={path} component={component} />
  )
}
