import {
  FormEvent,
  useRef,
  useState,
  useEffect,
  createRef,
  RefObject,
  useContext,
} from 'react'
import {
  Grid,
  Input,
  Message,
  Subtitle,
  Container,
  ErrorMessage,
  MessageWrapper,
  Form,
} from './styled'
import { CountDown } from '../CountDown'
import {
  isValidSMSCode,
  signInWithPhone,
} from '../../../../common/services/signIn'
import { useTranslation } from 'react-i18next'
import { CodeInputProps, INPUTS_NUMBER } from './contracts'
import { Recaptcha } from '../../../../components/Recaptcha'
import { StoreAuthContext } from '../../../../context/StoreAuthContext/StoreAuthContext'
import useGAEventTracker from '../../../../hooks/useGAEventTracker'

const CodeInput = ({ handleNext }: CodeInputProps): JSX.Element => {
  const { t } = useTranslation()
  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<boolean>(false)
  const [restartTimer, setRestartTimer] = useState<boolean>(false)
  const formRef: any = useRef()
  const elementsRef = useRef<RefObject<HTMLInputElement>[]>(
    Array(INPUTS_NUMBER)
      .fill(undefined)
      .map(() => createRef())
  )
  const {
    state: { phone, country },
  } = useContext(StoreAuthContext)

  const GAEventsTracker = useGAEventTracker('webshop')

  const fullPhone = `${country.code}${phone}`
  const handleKeyUpInput = (e: FormEvent<HTMLInputElement>) => {
    const { value, id } = e.currentTarget
    if (!value) {
      return
    }

    const currentElement = document.getElementById(`${id}`) as HTMLInputElement
    const nextSiblingElement = currentElement?.nextElementSibling as HTMLInputElement
    if (!nextSiblingElement) {
      const code = getCode()
      validateCode(code)
    } else {
      nextSiblingElement.focus()
    }
  }

  const validateCode = async (code: string) => {
    setLoading(true)
    setError(false)
    const isValidCode = await isValidSMSCode(code)

    if (isValidCode) {
      handleNext()
    } else {
      setError(true)
    }
    setLoading(false)
  }

  const getCode = () =>
    elementsRef.current.map((ref: any) => ref.current.value).join('')

  const resendCode = () => {
    GAEventsTracker('webshop_track_resend')
    setRestartTimer(true)
    signInWithPhone(fullPhone, 'resend-recaptcha-container')
  }

  useEffect(() => {
    const firstInput = document.getElementById('input-0') as HTMLInputElement
    firstInput.focus()
  }, [])

  return (
    <Container>
      <Recaptcha id="resend-recaptcha-container" />
      <Subtitle data-testid="title" variant="Small">
        {t('codeInput.title')}
        <b>{` ${fullPhone}`}</b>
      </Subtitle>

      <Form autoComplete="off" ref={formRef}>
        <Grid>
          {Array(INPUTS_NUMBER)
            .fill(undefined)
            .map((_, i) => (
              <Input
                key={i}
                required
                type="string"
                error={error}
                maxLength={1}
                pattern="[0-9]*"
                id={`input-${i}`}
                disabled={loading}
                inputMode="numeric"
                onKeyUp={handleKeyUpInput}
                ref={elementsRef.current[i]}
                autoComplete="one-time-code"
              />
            ))}
        </Grid>
        {!loading && error && (
          <ErrorMessage role="alert" forwardedAs="span" variant="XSmall">
            {t('codeInput.error')}
          </ErrorMessage>
        )}
      </Form>

      <CountDown restart={restartTimer}>
        <MessageWrapper onClick={resendCode}>
          <Message variant="Smallbold">{t('codeInput.resendCode')}</Message>
        </MessageWrapper>
      </CountDown>
    </Container>
  )
}
export default CodeInput
