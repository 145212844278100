import styled from 'styled-components'

import { Input } from '../../Input'
import { FontsName } from '../../../styles/fonts'
import { Typography } from '../../common/Typography'

export const Container = styled.div`
  width: 100%;
`

export const InputTextForm = styled(Input)`
  height: auto;
  margin: ${({ theme }) => theme.sizes.spacing.xxsmall} 0
    ${({ theme }) => theme.sizes.spacing.xsmall} 0;
  border: 0.5px solid #8f99a3;
  padding: ${({ theme: { sizes } }) =>
    `${sizes.spacing.xsmall} ${sizes.spacing.medium} `};
`

export const MainWrapper = styled.div`
  padding: 0 ${({ theme }) => theme.sizes.spacing.medium};
`

export const InputContainer = styled.form`
  margin-bottom: ${({ theme: { sizes } }) => sizes.spacing.medium};
  ${({ theme }) => theme.fonts[FontsName.Nunito].medium}
  color: ${({ theme }) => theme.colors.neutral700};
  width: 100%;
`

export const LabelInputForm = styled.label`
  ${({ theme }) => theme.fonts[FontsName.Nunito].mediumbold}
  color: ${({ theme }) => theme.colors.neutral800};
  width: 100%;
`

export const Subtitle = styled(Typography)`
  color: ${({ theme }) => theme.colors.secondary700};
  align-self: center;
  font-size: ${({ theme }) => theme.sizes.spacing.medium};
  font-weight: 700;
  margin-top: 26px;
  margin-bottom: 10px;
`
export const ErrorMessage = styled(Typography)`
  margin-top: ${({ theme: { sizes } }) => sizes.spacing.xxsmall};
  color: ${({ theme }) => theme.colors.error500};
`
