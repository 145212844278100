import { useTranslation } from 'react-i18next'

import {
  ButtonResume,
  IconContainer,
  ModalContainer,
  ModalExpiredProducts,
  TextExpiredDiscounts,
  TextValidateDiscounts,
} from './styles'
import { CONFIG } from '../../../components-v0/PhoneValidationDrawer/contracts'
import { ReactComponent as AlertIcon } from '../../../assets/icons/alert-icon.svg'

export interface ExpiredDiscountsModalProps {
  handleExpired: () => void
  showModalExpired: boolean
}

export const ModalExpiredDiscounts = ({
  handleExpired,
  showModalExpired,
}: ExpiredDiscountsModalProps): JSX.Element => {
  const { t } = useTranslation()

  const handleButton = () => {
    handleExpired()
  }

  return (
    <ModalContainer
      anchor={CONFIG.Anchor}
      open={showModalExpired}
      data-testid="modalexpireddiscounts-alert-container"
    >
      <ModalExpiredProducts>
        <IconContainer>
          <AlertIcon />
        </IconContainer>
        <TextExpiredDiscounts>
          {t('discounts.modalExpired.textExpiredDiscounts')}
        </TextExpiredDiscounts>
        <TextValidateDiscounts>
          {t('discounts.modalExpired.textValidateDiscounts')}
        </TextValidateDiscounts>
        <ButtonResume onClick={handleButton}>
          {t('discounts.modalExpired.textButtonResume')}
        </ButtonResume>
      </ModalExpiredProducts>
    </ModalContainer>
  )
}
