import { AxiosRequestConfig } from 'axios'

import httpRequest from '../../components/Delivery/httpClient'
import { menuType } from '../../components/common/MenuSelect/contracts'

const urlBaseService = `${process.env.REACT_APP_TREINTA_MICROSERVICES_API_URL}/delivery/document-types`

export const getDocumentTypesList = async (
  authToken: string
): Promise<menuType[]> => {
  const requestData: AxiosRequestConfig = {
    url: urlBaseService,
    method: 'GET',
  }
  return httpRequest(requestData, authToken)
}
