import { CatalogState } from './model'
import { IProduct } from '../../types/models'
import { removeDuplicatesById } from '../../utils/functions'

export const SET_CATALOG_AVAILABLE_CATEGORIES =
  'CATALOG/SET_CATALOG_AVAILABLE_CATEGORIES'
export const SET_CATALOG_PRICING_SORT_DIRECTION =
  'CATALOG/SET_CATALOG_PRICING_SORT_DIRECTION'
export const SET_SEARCH = 'CATALOG/SET_SEARCH'
export const SET_IS_FINAL_PAGE = 'CATALOG/SET_IS_FINAL_PAGE'
export const SET_CATALOG_LAYOUT = 'CATALOG/SET_CATALOG_LAYOUT'
export const SET_ACTIVE_STORE_ID = 'CATALOG/SET_ACTIVE_STORE_ID'
export const SET_CATALOG_NEXT_PAGE = 'CATALOG/SET_CATALOG_NEXT_PAGE'
export const SET_NEXT_PAGE_LOADING = 'CATALOG/SET_NEXT_PAGE_LOADING'
export const TURN_ON_CATALOG_LOADER = 'CATALOG/TURN_ON_CATALOG_LOADER'
export const ADD_PRODUCTS_TO_CATALOG = 'CATALOG/ADD_PRODUCTS_TO_CATALOG'
export const TURN_OFF_CATALOG_LOADER = 'CATALOG/TURN_OFF_CATALOG_LOADER'
export const SET_CATALOG_ACTIVE_CATEGORY = 'CATALOG/SET_CATALOG_ACTIVE_CATEGORY'

const prepareProductsWithNoDuplicates = (products: IProduct[]) => {
  return removeDuplicatesById(products)
}

const CatalogReducer = (
  state: CatalogState,
  action: { payload: any; type: string }
): CatalogState => {
  const { payload, type } = action

  switch (type) {
    case ADD_PRODUCTS_TO_CATALOG:
      return {
        ...state,
        products: prepareProductsWithNoDuplicates(payload),
      }
    case SET_ACTIVE_STORE_ID:
      return {
        ...state,
        activeStoreId: payload,
      }
    case SET_CATALOG_AVAILABLE_CATEGORIES:
      return {
        ...state,
        availableCategories: payload,
      }
    case SET_CATALOG_NEXT_PAGE:
      return {
        ...state,
        nextPage: payload,
      }
    case SET_CATALOG_ACTIVE_CATEGORY:
      return {
        ...state,
        activeCategory: payload,
      }
    case SET_CATALOG_PRICING_SORT_DIRECTION:
      return {
        ...state,
        activeSortDirection: payload,
      }
    case TURN_ON_CATALOG_LOADER:
      return {
        ...state,
        loading: true,
      }
    case TURN_OFF_CATALOG_LOADER:
      return {
        ...state,
        loading: false,
      }
    case SET_CATALOG_LAYOUT:
      return {
        ...state,
        activeLayout: payload,
      }
    case SET_NEXT_PAGE_LOADING:
      return {
        ...state,
        nextPageLoading: payload,
      }
    case SET_SEARCH:
      return {
        ...state,
        search: payload,
      }
    case SET_IS_FINAL_PAGE:
      return {
        ...state,
        isFinalPage: payload,
      }
    default:
      return state
  }
}

export default CatalogReducer
