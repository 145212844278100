import styled from 'styled-components'
import { Typography } from '../../common/Typography'
import { ReactComponent as PencilIcon } from '../../../assets/icons/edit-icon.svg'
import { ReactComponent as PinIcon } from '../../../assets/icons/pin-icon.svg'
import { ReactComponent as PersonIcon } from '../../../assets/icons/person-icon.svg'

export const Container = styled.div``

export const Summary = styled(Typography)`
  font-size: ${({ theme }) => theme.sizes.spacing.medium};
  font-weight: 700;
`

export const SummaryContainer = styled.section`
  display: grid;
  grid-template-columns: 1fr 80px;
`

export const EditContainer = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  cursor: pointer;
`

export const EditIcon = styled(PencilIcon)`
  align-self: center;
`

export const EditCopy = styled(Typography)`
  color: ${({ theme }) => theme.colors.tertiary600};
  font-size: ${({ theme }) => theme.sizes.spacing.medium};
  font-weight: 700;
`

export const LocationsContainer = styled.section`
  margin-top: ${({ theme }) => theme.sizes.spacing.medium};
  width: 100%;
`

export const SingleLocation = styled.div`
  display: grid;
  grid-template-columns: 20px 1fr;
  margin-bottom: ${({ theme }) => theme.sizes.spacing.xsmall};
`

export const LocationIcon = styled(PinIcon)`
  align-self: center;
  font-size: ${({ theme }) => theme.sizes.spacing.medium};
`

export const PersonLocationIcon = styled(PersonIcon)`
  align-self: center;
`

export const LocationInfo = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const Label = styled(Typography)`
  font-size: ${({ theme }) => theme.sizes.spacing.small};
  font-weight: 400;
  color: ${({ theme }) => theme.colors.neutral700};
`

export const Value = styled(Typography)`
  font-size: ${({ theme }) => theme.sizes.spacing.small};
  font-weight: 700;
  color: ${({ theme }) => theme.colors.secondary600};
`
