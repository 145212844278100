import styled from 'styled-components'

export const ScreenContainer = styled.div`
  display: flex;
  justify-content: center;
  min-height: calc(100vh - 78px);
`

export const MainContainer = styled.div`
  width: 100%;
  max-width: 500px;
  position: relative;
  padding-bottom: 10rem;
  background-color: white;
  box-shadow: 2px 4px 8px rgba(34, 38, 42, 0.05);
`
