import { ChangeEvent, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { FormPaymentMethod } from './FormPaymentMethod'
import { PaymentType } from '../../../../config/payment-type'
import { useStoreConfig } from '../../../../context/AppContext'
import { DeliveryMethod } from '../../../../config/delivery-method'
import { PaymentMethodOptionProps } from '../PaymentMethodOption/contracts'
import { useGetEnablePaymentMethods } from '../../../../hooks/useGetEnablePaymentMethods'
import { useCatalogState } from '../../../../context/ShoppingCartContext/ShoppingCartState'

export const FormPaymentMethodLoad = (): JSX.Element => {
  const { t } = useTranslation()
  const { store }: any = useStoreConfig()
  const { setSelectedPaymentMethod, state } = useCatalogState()
  const { enablePaymentMethods } = useGetEnablePaymentMethods({
    storeId: store.id,
  })
  const changePaymentMethod = (event: ChangeEvent<HTMLInputElement>): void => {
    setSelectedPaymentMethod(+event.target.value)
  }

  const enableAgainstDelivery = !!enablePaymentMethods?.find(
    (item) => item.payment_type_id === PaymentType.AgainstDelivery
  )?.status

  const enableOnlinePayments = !!enablePaymentMethods?.find(
    (item) => item.payment_type_id === PaymentType.OnlinePayments
  )?.status

  const enableCoordinateWithTheClient = !!enablePaymentMethods?.find(
    (item) => item.payment_type_id === PaymentType.CoordinateWithTheClient
  )?.status

  if (store.country !== 'Colombia') {
    return <div />
  }

  const paymentMethodOptions: PaymentMethodOptionProps[] = []

  if (enableOnlinePayments) {
    paymentMethodOptions.push({
      text: t('sectionForm.onlinePayment'),
      value: PaymentType.OnlinePayments,
    })
  }

  if (enableCoordinateWithTheClient) {
    paymentMethodOptions.push({
      text: t('sectionForm.coordinateWithTheClient'),
      value: PaymentType.CoordinateWithTheClient,
    })
  }

  useEffect(() => {
    if (
      enableAgainstDelivery ||
      state.selectedDeliveryMethod === DeliveryMethod.HOME_DELIVERY
    ) {
      paymentMethodOptions.push({
        text: t('sectionForm.paymentCash'),
        value: PaymentType.AgainstDelivery,
      })
    }
  }, [state.selectedDeliveryMethod])

  return (
    <FormPaymentMethod
      showPaymentLinkMethod={false}
      paymentMethods={paymentMethodOptions}
      changePaymentMethod={changePaymentMethod}
      paymentMethod={state.selectedPaymentMethod}
    />
  )
}
