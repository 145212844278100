import { useContext } from 'react'
import { Route, Switch } from 'react-router-dom'
import { SplitFactory } from '@splitsoftware/splitio-react'

import Loading from '../components/common/Loading'
import BaseDelivery from '../components/Delivery/BaseDelivery'
import { DeliveryContext } from '../context/Delivery/DeliveryContext'
import MyResumeDelivery from '../components/Delivery/MyResumeDelivery'
import { useDeliveryInitConfig } from '../hooks/InitConfig/useDeliveryInitConfig'
export const DeliveryRouter = (): JSX.Element => {
  const isInitConfigReady = useDeliveryInitConfig()
  const { state, setDeliveryState } = useContext(DeliveryContext)

  const sdkConfig: SplitIO.IBrowserSettings = {
    core: {
      authorizationKey: process.env.REACT_APP_API_KEY_SPLIT_IO || '',
      key: state.userId,
    },
  }

  if (!isInitConfigReady) return <Loading />

  return (
    <SplitFactory config={sdkConfig}>
      <Switch>
        <Route exact path="/:name/delivery">
          <BaseDelivery />
        </Route>
        <Route exact path="/:name/delivery/mylist">
          <MyResumeDelivery state={state} setDeliveryState={setDeliveryState} />
        </Route>
      </Switch>
    </SplitFactory>
  )
}

export default DeliveryRouter
