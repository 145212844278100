import { useTranslation } from 'react-i18next'
import { useMemo, useState, useRef, useEffect } from 'react'

import {
  AddIcon,
  AddLabel,
  QtyInput,
  TrashIcon,
  DecreaseIcon,
  MainContainer,
} from './styles'
import { STOCK_CONFIG } from '../../config/stock-config'
import { useStoreConfig } from '../../context/AppContext'
import useGAEventTracker from '../../hooks/useGAEventTracker'
import useGTMEventTracker from '../../hooks/useGTMEventTracker'
import { ContextCartQuantitySelectorProps } from './ContextCartQuantitySelector'

export const CART_QUANTITY_SMALL_SHAPE = 'small'
export const CART_QUANTITY_LARGE_SHAPE = 'large'

export type CartQuantityShape =
  | typeof CART_QUANTITY_SMALL_SHAPE
  | typeof CART_QUANTITY_LARGE_SHAPE

export interface CartQuantitySelectorProps
  extends ContextCartQuantitySelectorProps {
  activeQty: number
  setActiveQty: (newQty: number) => void
}

const CartQuantitySelector = ({
  product,
  activeQty,
  setActiveQty,
  shape = CART_QUANTITY_SMALL_SHAPE,
}: CartQuantitySelectorProps): JSX.Element => {
  const { t: translate } = useTranslation()
  const GAEventsTracker = useGAEventTracker('webshop')
  const GTMEventTracker = useGTMEventTracker()
  const { stockConfig } = useStoreConfig()
  const [showAdd, setShowAdd] = useState(false)
  const [isFocused, setIsFocused] = useState(false)
  const [isActive, setIsActive] = useState(false)
  const inputRef = useRef<HTMLInputElement>(null)

  const isDisabled = (): boolean => {
    return (
      (stockConfig === STOCK_CONFIG.SHOW_DISABLED && product.stock === 0) ||
      (activeQty >= product.stock &&
        stockConfig !== STOCK_CONFIG.SHOW_NOT_LIMIT)
    )
  }

  const isAddEnabled = useMemo(() => {
    if (stockConfig === STOCK_CONFIG.SHOW_NOT_LIMIT) {
      return true
    }

    if (isDisabled()) {
      return false
    }
    return true
  }, [activeQty, product])

  const handleQtyChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const qtyItem = Number(e.target.value)
    const newQty = qtyItem > 0 ? qtyItem : 0
    setActiveQty(newQty)
    setShowAdd(newQty === 0)
  }

  const handleFocus = (focus: boolean): void => {
    setIsFocused(focus)
  }

  const handleBlur = (): void => {
    setShowAdd(Number(activeQty) == 0 ? false : showAdd)
    setIsActive(Number(activeQty) == 0 ? false : isActive)
    handleFocus(false)
  }

  useEffect(() => {
    if (isFocused) {
      inputRef.current?.focus()
    }
  }, [isFocused])

  return (
    <MainContainer
      disabled={isDisabled()}
      data-testid="button-container"
      shape={shape}
      isActive={isActive}
      activeQty={Number(activeQty)}
      onClick={(e) => {
        if (activeQty === 0 && isAddEnabled) {
          e.stopPropagation()
          setActiveQty(activeQty + 1)
          setIsActive(true)
          handleFocus(true)
        }
      }}
    >
      {!showAdd && activeQty === 0 && (
        <AddIcon data-testid="add-to-cart-button" disabled={!isAddEnabled} />
      )}

      {!showAdd && activeQty === 0 && shape === CART_QUANTITY_LARGE_SHAPE && (
        <AddLabel disabled={!isAddEnabled} variant="XSmallbold">
          {translate('catalog.add')}
        </AddLabel>
      )}

      {activeQty === 1 && (
        <TrashIcon
          data-testid="remove-from-cart-button"
          onClick={(e) => {
            e.stopPropagation()
            setActiveQty(0)
            setIsActive(false)
          }}
        />
      )}

      {activeQty > 1 && (
        <DecreaseIcon
          data-testid="decrease-quantity-button"
          onClick={(e) => {
            GAEventsTracker('webshop_product_preview_substract')
            GAEventsTracker('webshop_editorder_personaldata')
            e.stopPropagation()
            setActiveQty(activeQty - 1)
          }}
        />
      )}

      {(activeQty >= 1 || showAdd) && (
        <QtyInput
          ref={inputRef}
          type="number"
          value={String(activeQty)}
          data-testid="quantity-label"
          onChange={handleQtyChange}
          isFocus={isFocused}
          onFocus={() => handleFocus(true)}
          onBlur={() => handleBlur()}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              handleBlur()
            }
          }}
        />
      )}

      {(showAdd || activeQty >= 1) && (
        <AddIcon
          data-testid="increase-quantity-button"
          disabled={!isAddEnabled}
          onClick={(e) => {
            if (isAddEnabled) {
              GTMEventTracker('click', 'click_add_product', 1)
              GAEventsTracker('webshop_product_preview_add')
              console.log('webshop_editorder_personaldata')
              GAEventsTracker('webshop_editorder_personaldata')
              e.stopPropagation()
              setActiveQty(activeQty + 1)
            }
          }}
        />
      )}
    </MainContainer>
  )
}
export default CartQuantitySelector
