import { FC, useReducer } from 'react'

import ShoppingCartReducer, {
  TOGGLE_SHOPPING_CART_DRAWER,
  TOGGLE_SHOPPING_SUMMARY_DRAWER,
  UPDATE_PRODUCTS_SET_AT_SHOPPING_CART,
} from './reducer'
import ShoppingCartContext from './context'
import { IProduct } from '../../types/models'
import { getUpdatedProductsQuantitySet } from './helpers'
import useGTMEventTracker from '../../hooks/useGTMEventTracker'
import { ShoppingCartState as IShoppingCartState } from './model'
import { compareDateLessCurrentDay } from '../../utils/datefunctions'

export const shoppingCartDefaultState: IShoppingCartState = {
  productsSet: {},
  isDrawerOpen: false,
  isSummaryOpen: false,
}

interface ShoppingCartStateProps {
  initialState?: IShoppingCartState
}

export const ShoppingCartState: FC<ShoppingCartStateProps> = ({
  children,
  initialState = shoppingCartDefaultState,
}) => {
  const [state, dispatch] = useReducer(ShoppingCartReducer, initialState)
  const GTMEventTracker = useGTMEventTracker()

  const updateProductQuantity = async (
    product: IProduct,
    newQuantity: number
  ) => {
    dispatch({
      payload: getUpdatedProductsQuantitySet({
        previousProductsSet: state.productsSet,
        product,
        newQuantity,
      }),
      type: UPDATE_PRODUCTS_SET_AT_SHOPPING_CART,
    })
  }

  const getProductQuantityAtShoppingCart = (product: IProduct) => {
    return state.productsSet[product.id]?.quantity || 0
  }

  const setDrawerOpen = (state: boolean) => {
    dispatch({
      payload: state,
      type: TOGGLE_SHOPPING_CART_DRAWER,
    })
  }

  const setSummaryOpen = (state: boolean) => {
    dispatch({
      payload: state,
      type: TOGGLE_SHOPPING_SUMMARY_DRAWER,
    })
  }

  const handleClickShoppingCart = () => {
    if (Object.keys(state.productsSet).length > 0) {
      GTMEventTracker('click', 'click_shopping_car', 1)
      setSummaryOpen(true)
    } else {
      setDrawerOpen(true)
    }
  }

  const removeExpiredDiscounts = () => {
    const newProductSet = { ...state.productsSet }
    Object.keys(newProductSet).forEach((productId) => {
      if (!newProductSet[productId]?.discount?.endDate) {
        return
      }

      const isExpired = compareDateLessCurrentDay(
        newProductSet[productId]?.discount?.endDate || String(new Date())
      )

      if (isExpired) {
        delete newProductSet[productId].discount
      }
    })

    dispatch({
      payload: newProductSet,
      type: UPDATE_PRODUCTS_SET_AT_SHOPPING_CART,
    })
  }

  return (
    <ShoppingCartContext.Provider
      value={{
        getProductQuantityAtShoppingCart,
        handleClickShoppingCart,
        removeExpiredDiscounts,
        updateProductQuantity,
        setSummaryOpen,
        setDrawerOpen,
        state,
      }}
    >
      {children}
    </ShoppingCartContext.Provider>
  )
}
