import { AxiosRequestConfig } from 'axios'

import httpRequest from '../../components/Delivery/httpClient'

const urlBaseService = `${process.env.REACT_APP_TREINTA_MICROSERVICES_API_URL}/delivery`

export interface CancelResponse {
  deliveryId: string
  providerId: number
}

export const removeShipmentService = async (
  authToken: string,
  deliveryId: string
): Promise<CancelResponse> => {
  const requestData: AxiosRequestConfig = {
    url: `${urlBaseService}/delete/${deliveryId}`,
    method: 'DELETE',
  }
  return httpRequest(requestData, authToken)
}
