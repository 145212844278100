import { ICategory } from '../../types/models'
import { Label, MainContainer } from './styles'

export interface CategoryButtonProps {
  active: boolean
  category: ICategory
  onClick: () => void
}

const CategoryButton = ({
  active,
  category,
  onClick,
}: CategoryButtonProps): JSX.Element => (
  <MainContainer active={active} onClick={onClick}>
    <Label active={active}>{category.name}</Label>
  </MainContainer>
)

export default CategoryButton
