import styled from 'styled-components'
import { Drawer } from '@material-ui/core'

import { Typography } from '../../common/Typography'

export const ModalContainer = styled(Drawer)`
  .MuiDrawer-paperAnchorBottom {
    border: 0rem;
    margin: auto;
    padding: 0rem;
    display: flex;
    max-width: 500px;
    box-shadow: none;
    align-items: center;
    justify-content: center;
    border-radius: 16px 16px 0px 0px;
  }
`

export const ModalExpiredProducts = styled.div`
  border-radius: ${({ theme: { sizes } }) =>
    `${sizes.borderRadius.xxlarge} ${sizes.borderRadius.xxlarge} 0 0`};
  background-color: ${({ theme: { colors } }) => colors.white};
  padding: 3rem 1rem 1rem 1rem;
  width: 100%;
  display: 'flex';
  text-align: center;
`

export const IconContainer = styled.div`
  margin-bottom: ${({ theme }) => theme.sizes.spacing.small};
  text-align: center;
`

export const AlertIcon = styled.img`
  width: ${({ theme }) => theme.sizes.spacing.large};
  cursor: pointer;
`

export const TextExpiredDiscounts = styled(Typography).attrs({
  variant: 'XLargebold',
})`
  color: ${({ theme: { colors } }) => colors.secondary700};
  margin-bottom: ${({ theme }) => theme.sizes.spacing.xsmall};
  text-align: center;
`
export const TextValidateDiscounts = styled(Typography).attrs({
  variant: 'Small',
})`
  color: ${({ theme: { colors } }) => colors.neutral800};
  padding-bottom: ${({ theme }) => theme.sizes.spacing.large};
  margin: 0 auto;
  width: 50%;
  text-align: center;
`
export const ButtonResume = styled.button`
  padding: ${({ theme: { sizes } }) =>
    `${sizes.borderRadius.large} ${sizes.borderRadius.medium}`};
  border-radius: ${({ theme: { sizes } }) => sizes.borderRadius.medium};
  color: ${({ theme: { colors } }) => colors.secondary700};
  background-color: ${({ theme: { colors } }) => colors.primary500};
  cursor: pointer;
  width: 100%;
  font-size: 1rem;
  font-weight: 400;
  border: none;
`
