import { useTranslation } from 'react-i18next'

import { RadioGroup, FormControlLabel } from '@material-ui/core'

import { Button } from '../../common/Button'
import BottomDrawer from '../../common/BottomDrawer'
import { restrictInputNumbers } from '../../../utils/forms'
import { GreenCheckbox, GreenRadio } from '../../common/GreenElements'
import {
  ErrorMessage,
  FormContainer,
  InputContainer,
  InfoButton,
  InputTextForm,
  DrawerContent,
  LabelRadio,
  LabelInputForm,
  LabelCheckbox,
  TextLabelName,
  TextLabelCbLink,
  TextLabelDescription,
  TextLabelCbDescription,
  CloseIcon,
  BackIcon,
  SubTitle,
  Title,
  InfoButtonContainer,
} from './styles'
import { FormShippingSecondProps } from '../contracts'

export const FormShippingSecondFields = ({
  handleClickBack,
  handleClickClose,
  handleClickNext,
  handleDrawerStatus,
  handleBlurOnInputChange,
  handleChangeOnInputChange,
  preventDefault,
  checkHandleChange,
  validateShippingForm,
  selectHandleChange,
  defaultShippingDataError,
  paymentData,
  PaymentMethodOptions,
  drawerIsOpen,
  formattedValue,
}: FormShippingSecondProps): JSX.Element => {
  const { t } = useTranslation()
  return (
    <FormContainer>
      <Title>
        <BackIcon onClick={handleClickBack} />
        {t('delivery.sectionForm.shippingFormTitlePaymentMethod')}
        <CloseIcon onClick={handleClickClose} />
      </Title>

      <SubTitle>
        {t('delivery.sectionForm.shippingFormSubTitlePaymentMethod')}
      </SubTitle>

      {/* Payment Methods Field */}
      <InputContainer>
        <RadioGroup
          defaultValue={PaymentMethodOptions.Cash}
          name="radio-buttons-nethods"
          onChange={selectHandleChange}
        >
          <FormControlLabel
            control={
              <GreenRadio
                size="small"
                checked={paymentData.method === PaymentMethodOptions.Cash}
              />
            }
            value={PaymentMethodOptions.Cash}
            label={
              <LabelRadio>
                <TextLabelName>
                  {t('delivery.sectionForm.shippingFormPaymentMethodTypeCash')}*
                </TextLabelName>
                <TextLabelDescription>
                  {t(
                    'delivery.sectionForm.shippingFormPaymentMethodTypeCashText'
                  )}
                </TextLabelDescription>
              </LabelRadio>
            }
          />
          <FormControlLabel
            control={
              <GreenRadio
                size="small"
                checked={
                  paymentData.method === PaymentMethodOptions.UponDelivery
                }
              />
            }
            value={PaymentMethodOptions.UponDelivery}
            label={
              <LabelRadio>
                <TextLabelName>
                  {t('delivery.sectionForm.shippingFormPaymentMethodTypeAD')}*
                </TextLabelName>
                <TextLabelDescription>
                  {t(
                    'delivery.sectionForm.shippingFormPaymentMethodTypeADText'
                  )}
                </TextLabelDescription>
              </LabelRadio>
            }
          />
          {defaultShippingDataError.paymentMethodError && (
            <ErrorMessage variant="XSmall">
              {t('delivery.sectionForm.errors.errorPaymentMethod')}
            </ErrorMessage>
          )}
        </RadioGroup>
      </InputContainer>

      {/* Sale Value Field */}
      {paymentData.method === PaymentMethodOptions.UponDelivery && (
        <InputContainer autoComplete="off" onSubmit={preventDefault}>
          <LabelInputForm>
            {t('delivery.sectionForm.shippingFormPaymentMethodTypeCashValue')}
          </LabelInputForm>
          <InputTextForm
            type="text"
            value={formattedValue}
            minLength={3}
            maxLength={10}
            id="sale-input"
            onKeyPress={(e: React.KeyboardEvent<HTMLInputElement>) => {
              if (!restrictInputNumbers(e)) e.preventDefault()
            }}
            onBlur={handleBlurOnInputChange}
            onChange={handleChangeOnInputChange}
            placeholder={t(
              'delivery.sectionForm.shippingFormPaymentMethodTypeCashValue'
            )}
          />
          {defaultShippingDataError.saleValueError && (
            <ErrorMessage variant="XSmall">
              {t('delivery.sectionForm.errors.errorSaleValue')}
            </ErrorMessage>
          )}
        </InputContainer>
      )}

      <SubTitle>
        {t('delivery.sectionForm.shippingFormPaymentLegalDeclaration')}
      </SubTitle>

      {/* Declaration Field */}
      <InputContainer>
        <FormControlLabel
          control={<GreenCheckbox onChange={checkHandleChange} />}
          value={1}
          label={
            <LabelCheckbox>
              <TextLabelCbDescription>
                {t(
                  'delivery.sectionForm.shippingFormPaymentLegalDeclarationText'
                )}
                <TextLabelCbLink onClick={() => handleDrawerStatus(true)}>
                  {t(
                    'delivery.sectionForm.shippingFormPaymentLegalDeclarationLink'
                  )}
                </TextLabelCbLink>
              </TextLabelCbDescription>
            </LabelCheckbox>
          }
        />
        {defaultShippingDataError.declareError && (
          <ErrorMessage variant="XSmall">
            {t('delivery.sectionForm.errors.errorDeclare')}
          </ErrorMessage>
        )}
      </InputContainer>

      <InfoButtonContainer>
        <Button
          text={t('delivery.sectionForm.shippingFormNext') as string}
          style={InfoButton}
          disabled={validateShippingForm()}
          onClick={handleClickNext}
        />
      </InfoButtonContainer>

      <BottomDrawer
        title={t('delivery.drawers.merchAllow.title')}
        open={drawerIsOpen}
        onClose={() => handleDrawerStatus(false)}
      >
        <DrawerContent data-testid="categories-drawer-content">
          <div
            dangerouslySetInnerHTML={{
              __html: t('delivery.drawers.merchAllow.content'),
            }}
          />
        </DrawerContent>
      </BottomDrawer>
    </FormContainer>
  )
}
