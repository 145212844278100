import styled from 'styled-components'
import { ReactComponent as AddCircledIcon } from '../../assets/add-circled.svg'
import { ReactComponent as TrashBinIcon } from '../../assets/red-trash-bin.svg'
import { ReactComponent as LessCircledIcon } from '../../assets/less-circled.svg'
import { Typography } from '../common/Typography'
import { FontsName } from '../../styles/fonts'
import { MainContainerProps, AddIconProps, AddLabelProps, QtyInputProps } from './contracts'

export const DISABLED_OPACITY = 0.65;

export const MainContainer = styled.div<MainContainerProps>`
  width: ${({ shape, isActive, activeQty }) => ((shape == 'large' || isActive || activeQty > 0) ? '104px' : '45px')};
  max-width: ${({ shape, isActive, activeQty }) => ((shape == 'large' || isActive || activeQty > 0) ? '104px' : '45px')};
  background-color: ${({ theme: { colors } }) => colors.white};
  border-radius: ${({ theme: { sizes } }) => sizes.borderRadius.medium};
  border: ${({ theme: { colors } }) => `1px solid ${colors.neutral500}`};
  box-shadow: 2px 4px 8px rgba(34, 38, 42, 0.05);
  color: ${({ theme: { colors } }) => colors.secondary500};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  align-items: center;
  display: flex;
  height: 45px;
  max-height: 45px;
  justify-content: center;
  padding: ${({ theme: { sizes } }) => sizes.spacing.xsmall};
`

export const AddIcon = styled(AddCircledIcon)<AddIconProps>`
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  opacity: ${({ disabled }) => (disabled ? DISABLED_OPACITY : 1)};
  width: ${({ theme: { sizes } }) => sizes.spacing.medium};
  min-width: ${({ theme: { sizes } }) => sizes.spacing.medium};
  height: auto;
`

export const DecreaseIcon = styled(LessCircledIcon)`
  cursor: pointer;
  width: ${({ theme: { sizes } }) => sizes.spacing.medium};
  min-width: ${({ theme: { sizes } }) => sizes.spacing.medium};
  height: auto;
`

export const TrashIcon = styled(TrashBinIcon)`
  cursor: pointer;
  width: ${({ theme: { sizes } }) => sizes.spacing.xlarge};
  height: ${({ theme: { sizes } }) => sizes.spacing.xlarge};
`

export const AddLabel = styled(Typography)<AddLabelProps>`
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  margin-left: ${({ theme: { sizes } }) => sizes.spacing.xsmall};
  opacity: ${({ disabled }) => (disabled ? DISABLED_OPACITY : 1)};
`

export const QtyLabel = styled(Typography)`
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-user-select: none;
  margin-left: ${({ theme: { sizes } }) => sizes.spacing.medium};
  margin-right: ${({ theme: { sizes } }) => sizes.spacing.medium};
  user-select: none;
`

export const QtyInput = styled.input<QtyInputProps>`
  ${({ theme }) => theme.fonts[FontsName.Nunito].small}
  margin-left: ${({ theme }) => theme.sizes.spacing.xsmall}};
  margin-right: ${({ theme }) => theme.sizes.spacing.xsmall}};
  padding-top: ${({ theme: { sizes } }) => sizes.spacing.small};
  padding-bottom: ${({ theme: { sizes } }) => sizes.spacing.small};
  caret-color: ${({ isFocus, theme: { colors } }) => (isFocus ? colors.secondary500 : '')};
  border: ${({ isFocus, theme: { colors } }) => (isFocus ? `1px solid ${colors.secondary500}` : 0)};
  color: ${({ isFocus, theme: { colors } }) => (isFocus ? colors.secondary700 : '')};
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  -moz-appearance: textfield;
  width: 100%;
  height: 100%;
  text-align: center;
  border: 0;
`