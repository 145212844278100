import { FormControlLabel } from '@material-ui/core'

import { PaymentMethodOptionProps } from './contracts'
import { StyledRadio } from '../../styles/StyledRadio/index'
import { Wrapper, PaymentLabel, ImgPaymentLogo } from './styles'

export const PaymentMethodOption = ({
  text,
  value,
  image,
}: PaymentMethodOptionProps): JSX.Element => (
  <FormControlLabel
    value={value}
    control={<StyledRadio />}
    label={
      <Wrapper>
        <PaymentLabel>{text}</PaymentLabel>
        {image && (
          <ImgPaymentLogo
            src={image}
            alt={String(value)}
            data-testid="payment-method-image"
          />
        )}
      </Wrapper>
    }
  />
)
