import { AxiosRequestConfig } from 'axios'

import httpRequest from '../../components/Delivery/httpClient'

export interface BankListResponse {
  id: number
  name: string
  characters: string
  accountTypes: string[]
}

const urlBaseService = `${process.env.REACT_APP_TREINTA_MICROSERVICES_API_URL}/delivery/banks`

export const getBanksList = async (
  authToken: string
): Promise<BankListResponse[]> => {
  const requestData: AxiosRequestConfig = {
    url: urlBaseService,
    method: 'GET',
  }
  return httpRequest(requestData, authToken)
}
