import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'
import { Grid, Button, Paper, Typography } from '@material-ui/core'

import { useStyles } from './styles'
import InactiveServiceImage from './../../../assets/inactiveService.png'

const InactiveService = (): JSX.Element => {
  const { t } = useTranslation()
  const props: any = {
    image: InactiveServiceImage,
  }
  const classes = useStyles(props)
  return (
    <div className={classes.root}>
      <Helmet>
        <meta name="description" content={t('notFound.title')} />
      </Helmet>
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignContent="center"
        className={classes.gridContainer}
      >
        <Grid item>
          <Grid
            container
            justifyContent="center"
            alignContent="center"
            className={classes.gridContainerImage}
          >
            <Paper elevation={0} className={classes.paperImage} />
          </Grid>
        </Grid>
        <Grid item className={classes.textContainer}>
          <Typography variant="h5" align="center" className={classes.textStyle}>
            {t('notFound.title')}
          </Typography>
        </Grid>

        <Button
          variant="contained"
          color="primary"
          href="/"
          classes={{
            root: classes.goBackButtonRoot,
            contained: classes.goBackButton,
          }}
        >
          {t('notFound.goBack')}
        </Button>
      </Grid>
    </div>
  )
}

export default InactiveService
