import { Container } from '../styles'
import { SchedulerComponents } from '../contracts'
import { CloseTime, OpenTime, Open24 } from '.'
import { ISchedule } from '../../../../../types/models'

interface Props {
  hourIn: string
  hourOut: string
  nextOpenTime: ISchedule | null
  isOpen: boolean
  isOpenAllDay?: boolean
  hasNextDayOpen?: boolean
}

export const Factory = ({
  hourIn,
  hourOut,
  nextOpenTime,
  isOpen,
  isOpenAllDay,
  hasNextDayOpen,
}: Props): JSX.Element => {
  let status = SchedulerComponents.Empty
  if (isOpenAllDay) {
    status = SchedulerComponents.OpenFullTime
  } else if (hasNextDayOpen) {
    status = isOpen ? SchedulerComponents.Open : SchedulerComponents.Closed
  }

  const components = {
    [SchedulerComponents.Empty]: <></>,
    [SchedulerComponents.Open]: <OpenTime start={hourIn} end={hourOut} />,
    [SchedulerComponents.Closed]: (
      <CloseTime
        day={nextOpenTime?.day || ''}
        start={nextOpenTime?.scheduleIn || ''}
        end={nextOpenTime?.scheduleOut || ''}
      />
    ),
    [SchedulerComponents.OpenFullTime]: <Open24 />,
  }

  if (status === SchedulerComponents.Empty) return <></>

  return (
    <Container data-testid="scheduler-container">
      {components[status]}
    </Container>
  )
}
