import { ChangeParamsType } from '../contracts'

export const handleChangeInputField = ({
  event,
  isNumber,
  fieldName,
  validationMethod,
  bankInformationData,
  setBankInformationData,
}: ChangeParamsType): void => {
  let formattedValue = isNumber
    ? Number(event.target.value)
    : String(event.target.value)
  formattedValue =
    fieldName === 'accountNumber' || fieldName === 'accountNumberConfirm'
      ? String(formattedValue).replace(/[^0-9]/g, '')
      : formattedValue
  setBankInformationData({
    ...bankInformationData,
    [fieldName]: formattedValue,
  })
  validationMethod(formattedValue)
}
