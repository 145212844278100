import { Alert } from '@material-ui/lab'
import { Snackbar } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { useContext, useEffect, useState } from 'react'

import {
  getOrder,
  getRequestBody,
  redirectToWhatsAppAPI,
  redirectToTreintaPayments,
  validateExpiredProductPrices,
} from './helper'
import { Button } from './styles'
import WhatsAppIcon from '@material-ui/icons/WhatsApp'
import { countries } from '../../../../config/countries'
import { useSendOrder } from '../../../../hooks/useSendOrder'
import { getOrderDetails } from '../../../../utils/functions'
import { useStoreConfig } from '../../../../context/AppContext'
import { MINIMUM_AMOUNT } from '../../../../config/payment-link'
import { DeliveryMethod } from '../../../../config/delivery-method'
import useGAEventTracker from '../../../../hooks/useGAEventTracker'
import useGTMEventTracker from '../../../../hooks/useGTMEventTracker'
import { useCatalogState } from '../../../../context/ShoppingCartContext'
import { useShoppingCart } from '../../../../components/ShoppingCart/hooks'
import { PaymentMethods, PaymentType } from '../../../../config/payment-type'
import { StoreAuthContext } from '../../../../context/StoreAuthContext/StoreAuthContext'
import { useGetEnablePaymentMethods } from '../../../../hooks/useGetEnablePaymentMethods'
import { ModalExpiredDiscounts } from '../../../../components/Modals/ModalExpiredDiscounts'

export const PaymentMethodButton = (): JSX.Element => {
  const { t } = useTranslation()
  const GTMEventTracker = useGTMEventTracker()
  const {
    state: {
      selectedDeliveyArea,
      selectedPaymentMethod,
      selectedDeliveryMethod,
      buyersComments: comment,
      deliveryAddress: address,
    },
  } = useCatalogState()

  const {
    products,
    totalDiscounts,
    removeExpiredDiscounts,
    totalPayable: totalProducts,
  } = useShoppingCart()
  const {
    isFromMarketplace,
    store: { config, country: storeCountry, id: storeId, phone: storePhone },
  } = useStoreConfig()
  const {
    state: { phone, name, country },
  } = useContext(StoreAuthContext)

  const { enablePaymentMethods } = useGetEnablePaymentMethods({
    storeId: storeId || '',
  })
  const GAEventsTracker = useGAEventTracker('webshop')

  // State
  const [error, setError] = useState<string>()
  const [isDisabled, setIsDisabled] = useState<boolean>(false)
  const [showModalExpired, setShowModalExpired] = useState<boolean>(false)
  const deliveryPrice = config?.deliveryPrice || 0
  const phoneNumber = countries.get(storeCountry).code + storePhone
  const finalDeliveryPrice = selectedDeliveyArea
    ? selectedDeliveyArea?.price
    : deliveryPrice
  const totalPayable =
    totalProducts -
    totalDiscounts +
    Number(
      selectedDeliveryMethod === DeliveryMethod.HOME_DELIVERY
        ? finalDeliveryPrice
        : 0
    )

  const existActivePaymentMethod = enablePaymentMethods?.some(
    ({ status }) => status === true
  )

  const createOrder = async (): Promise<{
    transactionId: string
    orderNumber: string
  } | void> => {
    if (
      selectedPaymentMethod === PaymentType.PaymentLink &&
      totalPayable < MINIMUM_AMOUNT
    ) {
      setError(t('formPaymentMethod.errorInvalidAmount'))
      return
    }

    setIsDisabled(true)
    if (!validateExpiredProductPrices(products)) {
      setShowModalExpired(true)
      return
    }
    const body = getRequestBody({
      name,
      phone,
      country,
      storeId,
      address,
      comment,
      products,
      totalProducts,
      finalDeliveryPrice,
      selectedPaymentMethod,
      selectedDeliveryMethod,
      areaName: selectedDeliveyArea?.nameArea,
    })
    const { transaction } = await useSendOrder(body)
    return {
      transactionId: transaction.id,
      orderNumber: transaction.transaction_count,
    }
  }

  const sendWhatsAppMessage = (
    orderNumber: string,
    windowReference: Window | null
  ) => {
    const order = getOrder(products)
    const messageOrder = getOrderDetails({
      name,
      phone,
      order,
      address,
      comment,
      country,
      orderNumber,
      totalPayable,
      totalProducts,
      totalDiscounts,
      selectedPaymentMethod,
      selectedDeliveryMethod,
      deliveryPrice: finalDeliveryPrice,
      translations: t('sectionForm.messageOrder', { returnObjects: true }),
    })
    const messageOrderDetails = messageOrder.replace(/[#%+^&[\]\\]/g, '')

    const paymentMethodName = PaymentMethods.filter(
      (item) => item.id == selectedPaymentMethod
    ).shift()?.value
    GAEventsTracker('webshop_sendorder', `deliveryaddress=${paymentMethodName}`)
    redirectToWhatsAppAPI({
      storeId,
      phoneNumber,
      windowReference,
      isFromMarketplace,
      messageOrderDetails,
    })
  }

  const handlerCheckout = async () => {
    GTMEventTracker('click', 'click_ask_for_whatsapp', 1)
    const windowReference = window.open()
    const response = await createOrder()
    if (response) {
      const { transactionId, orderNumber } = response
      if (selectedPaymentMethod === PaymentType.OnlinePayments) {
        redirectToTreintaPayments({ transactionId, windowReference })
      } else {
        sendWhatsAppMessage(orderNumber, windowReference)
      }
    }
  }

  const handleExpired = () => {
    removeExpiredDiscounts()
    setIsDisabled(false)
    setShowModalExpired(false)
  }

  useEffect(() => {
    if (
      existActivePaymentMethod &&
      selectedPaymentMethod === PaymentType.Pending
    ) {
      setIsDisabled(true)
    } else {
      setIsDisabled(false)
    }
  }, [selectedPaymentMethod])

  return (
    <>
      <Snackbar
        open={!!error}
        autoHideDuration={6000}
        onClose={() => setError('')}
      >
        <Alert severity="error">{error}</Alert>
      </Snackbar>
      <ModalExpiredDiscounts
        handleExpired={handleExpired}
        showModalExpired={showModalExpired}
      />
      <Button
        onClick={handlerCheckout}
        disabled={isDisabled}
        data-testid="next-payment-method-button"
      >
        {selectedPaymentMethod === PaymentType.OnlinePayments ? (
          t('delivery.sectionForm.goToPayment')
        ) : (
          <>
            <WhatsAppIcon className="icon" />
            {t('sectionForm.buttonConfirmOrder')}
          </>
        )}
      </Button>
    </>
  )
}
