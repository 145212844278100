import styled from 'styled-components'
import { ReactComponent as ArrowRightIcon } from '../../assets/app-menu-arrow-right.svg'
import { Typography } from '../common/Typography'

const ICON_SIZE = 22

export const MainContainer = styled.li`
  align-items: center;
  cursor: pointer;
  display: flex;
  height: 100%;
  justify-content: space-between;
  width: 100%;
  ${({ theme }) => `padding: ${theme.sizes.spacing.xsmall} 0;
    &:not(:last-child) {
      margin-bottom: ${theme.sizes.spacing.xsmall};
  `};
`

export const IconContainer = styled.div`
  height: ${ICON_SIZE}px;
  width: ${ICON_SIZE}px;
`

export const GoToIcon = styled(ArrowRightIcon)``

export const Label = styled(Typography).attrs({
  variant: 'Medium',
})`
  flex: 1;
  margin-left: ${({ theme: { sizes } }) => sizes.spacing.medium};
  margin-right: ${({ theme: { sizes } }) => sizes.spacing.medium};
  margin-top: 2px;
`
