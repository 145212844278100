import styled from 'styled-components'

type MainContainerProps = {
  active: boolean
}

export const MainContainer = styled.div<MainContainerProps>`
  align-items: center;
  background-color: ${({ active, theme: { colors } }) =>
    active ? colors.neutral400 : 'transparent'};
  border-radius: ${({ theme: { sizes } }) => sizes.borderRadius.medium};
  cursor: pointer;
  display: flex;
  height: 100%;
  justify-content: center;
  opacity: ${({ active }) => (active ? 1 : 0.5)};
  padding: ${({ theme: { sizes } }) => sizes.spacing.xsmall};
  width: 100%;

  &:hover {
    background-color: ${({ theme: { colors } }) => colors.neutral400};
    opacity: 1;
  }
`
