import styled, { css, DefaultTheme } from 'styled-components'

import { ButtonSizes, ButtonVariants } from '.'
import { FontsName } from '../../../styles/fonts'

const variantStyles = (theme: DefaultTheme, variant: ButtonVariants) =>
  ({
    [ButtonVariants.Primary]: css`
      background: ${theme.colors.primary500};
      color: ${theme.colors.primary900};

      &:hover {
        background: ${theme.colors.primary400};
      }
      &:disabled {
        background: ${theme.colors.neutral300};
        color: ${theme.colors.neutral600};
      }
    `,
    [ButtonVariants.Secondary]: css`
      border: 1px solid ${theme.colors.secondary700};
      background: ${theme.colors.white};
      color: ${theme.colors.secondary700};

      &:hover {
        background: ${theme.colors.secondary100};
      }
      &:disabled {
        background: ${theme.colors.neutral200};
        color: ${theme.colors.neutral600};
      }
    `,
    [ButtonVariants.Destructive]: css`
      background: ${theme.colors.primary500};
    `,
    [ButtonVariants.Ghost]: css`
      background: ${theme.colors.primary500};
    `,
  }[variant])

const sizeStyles = (theme: DefaultTheme, sizes: ButtonSizes) =>
  ({
    [ButtonSizes.Large]: css`
      padding: ${theme.sizes.spacing.small} ${theme.sizes.spacing.large};
      & i {
        width: 22px;
        height: 22px;
      }
      & span {
        ${theme.fonts[FontsName.Nunito].largebold};
      }
    `,
    [ButtonSizes.Medium]: css`
      padding: ${theme.sizes.spacing.xsmall} ${theme.sizes.spacing.large};
      & i {
        width: 15px;
        height: 15px;
      }
      & span {
        ${theme.fonts[FontsName.Nunito].mediumbold};
      }
    `,
    [ButtonSizes.Small]: css`
      padding: ${theme.sizes.spacing.xxsmall} ${theme.sizes.spacing.large};
      & i {
        width: 15px;
        height: 15px;
      }
      & span {
        ${theme.fonts[FontsName.Nunito].smallbold};
      }
    `,
  }[sizes])

interface Props {
  variant: ButtonVariants
  size: ButtonSizes
  disabled: boolean
  isLoading: boolean
}

export const BaseButton = styled.button<Props>`
  border: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:disabled {
    cursor: not-allowed;
  }

  ${({ theme, size, variant }) => [
    ...variantStyles(theme, variant),
    ...sizeStyles(theme, size),
    `border-radius: ${theme.sizes.borderRadius.medium}`,
  ]}
`

export const Icon = styled.i<{ src: string }>`
  ${({ theme, src }) => `
    margin-right: ${theme.sizes.spacing.small};
    background: url(${src});
  `}
`
