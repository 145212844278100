import styled from 'styled-components'
import { colors } from '../../styles/colors'
import { Typography } from '../../components/common/Typography'

export const ContainerItems = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 1rem 0rem;
`
export const ContainerProduct = styled.div`
  display: flex;
`
export const ContainerDescription = styled.div`
  margin-left: ${({ theme }) => theme.sizes.spacing.medium};
`
export const Text = styled(Typography)`
  color: ${colors.neutral800};
`
export const TextQuantity = styled(Typography)`
  color: ${colors.neutral700};
`
export const TextPrice = styled(Typography)`
  color: ${colors.secondary700};
`
export const ImageProduct = styled.img`
  ${({ theme }) => `
    height: ${theme.sizes.spacing.xxlarge};
    min-width: ${theme.sizes.spacing.xlarge};
    border-radius: ${theme.sizes.borderRadius.large};  
  `}
  align-self: center;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
`






