import { useContext } from 'react'
import { useTranslation } from 'react-i18next'

import { Button } from './styles'
import { ShoppingCartContext } from '../../../../context/ShoppingCartContext/ShoppingCartContext'
import { useCatalogState } from '../../../../context/ShoppingCartContext'
import useGAEventTracker from '../../../../hooks/useGAEventTracker'

export const DeliveryMethodButton = (): JSX.Element => {
  const { t } = useTranslation()
  const {
    setCheckoutStep,
    state: { checkoutStep, deliveryAddress },
  } = useContext(ShoppingCartContext)

  const GAEventsTracker = useGAEventTracker('webshop')
  const handleClick = () => {
    GAEventsTracker(
      'webshop_continue_deliverycomplete',
      `deliveryaddress=${deliveryAddress}`,
    )
    setCheckoutStep(checkoutStep + 1)
  }

  const {
    state: { validateFormDeliveryMethod },
  }: any = useCatalogState()
  return (
    <Button
      disabled={validateFormDeliveryMethod}
      data-testid="next-delivery-method-button"
      onClick={handleClick}
    >
      {t('commons.next')}
    </Button>
  )
}
