import { Route, Switch, useRouteMatch } from 'react-router-dom'

import NotFoundPage from '../Pages/NotFound'
import { StoreContainer } from '../../components/StoreContainer'

export const Store = ({
  metaDescription,
}: {
  metaDescription: any
}): JSX.Element => {
  const { path } = useRouteMatch()

  return (
    <Switch>
      <Route exact path={path}>
        <StoreContainer metaDescription={metaDescription} />
      </Route>
      <Route path="/" component={NotFoundPage} />
    </Switch>
  )
}
