import styled from 'styled-components'

import { Typography } from '../../../../components/common/Typography'

export const Container = styled.div`
  margin-bottom: ${({ theme }) => theme.sizes.spacing.large};
`

export const AlertTitle = styled(Typography).attrs({
  variant: 'XSmallbold',
})`
  color: ${(props) => props.theme.colors.info700};
  margin-bottom: ${({ theme }) => theme.sizes.spacing.small};
`

export const AlertText = styled(Typography).attrs({
  variant: 'XSmall',
})`
  color: ${({ theme }) => theme.colors.info700};
`
