import { TextToastMessage } from './style'

export type itemProps = {
  textItem: string
}

export const ToastMessage = ({ textItem }: itemProps): JSX.Element => {
  return (
    <TextToastMessage>
      <div
        dangerouslySetInnerHTML={{
          __html: textItem,
        }}
      />
    </TextToastMessage>
  )
}
