export const PRICE_ASC_SORT_CRITERIA = 'ASC'
export const PRICE_DESC_SORT_CRITERIA = 'DESC'
export const PRICE_NONE_SORT_CRITERIA = 'NONE'

export type PriceSortingDirection =
  | typeof PRICE_ASC_SORT_CRITERIA
  | typeof PRICE_DESC_SORT_CRITERIA
  | typeof PRICE_NONE_SORT_CRITERIA

export const PriceSortingDirectionTranslationKeys: Record<
  PriceSortingDirection,
  string
> = {
  ASC: 'catalog.orderByPriceAsc',
  DESC: 'catalog.orderByPriceDesc',
  NONE: 'catalog.orderBy',
}
