import { useContext, useEffect, useState } from 'react'
import { SplitContext, useTreatments } from '@splitsoftware/splitio-react'

import { SplitIOTreatmentOptions } from '../types/models'

type TreatmentWithConfig = {
  treatment: string
  config: string | null
}

export const useSplitIO = (
  treatmentName: SplitIOTreatmentOptions
): { clientStateOn: boolean } => {
  const { isReady } = useContext(SplitContext)
  const treatmentWithConfig = useTreatments([treatmentName])
  const [treatmentStatus, setTreatmentStatus] = useState<null | boolean>(null)

  const checkTreatment = (treatmentWithConfig: TreatmentWithConfig) => {
    const { treatment } = treatmentWithConfig
    return treatment === 'on'
  }

  useEffect(() => {
    if (!isReady) {
      return
    }

    const newTreatmentStatus = checkTreatment(
      treatmentWithConfig[treatmentName]
    )
    setTreatmentStatus(newTreatmentStatus)
  }, [isReady])

  return { clientStateOn: treatmentStatus as boolean }
}
