import { useState } from 'react'
import {
  PaymentMethodsType,
  getPaymentMethodsList,
} from '../../services/BankInformation/paymentMethodsService'

interface Request {
  authToken: string
}
interface Response {
  paymentMethodsData: PaymentMethodsType[] | undefined
  isLoadedPaymentMethods: boolean
  hasErrorPaymentMethods: boolean
  callGetPaymentMethodsList: () => void
}

export const useGetPaymentMethodsService = ({
  authToken,
}: Request): Response => {
  const [paymentMethodsData, setPaymentMethodsData] = useState<
    PaymentMethodsType[]
  >([])

  // states Services Hooks
  const [isLoadedPaymentMethods, setIsLoadedPaymentMethods] = useState(false)
  const [hasErrorPaymentMethods, setHasErrorPaymentMethods] = useState(false)

  const callService = () => {
    getPaymentMethodsList(authToken)
      .then((paymentMethodsDataResponse) => {
        setPaymentMethodsData(paymentMethodsDataResponse)
        setIsLoadedPaymentMethods(true)
      })
      .catch(() => {
        setIsLoadedPaymentMethods(false)
        setHasErrorPaymentMethods(true)
      })
  }

  return {
    paymentMethodsData,
    isLoadedPaymentMethods,
    hasErrorPaymentMethods,
    callGetPaymentMethodsList: callService,
  }
}
