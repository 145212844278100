import styled from 'styled-components'

import InputBase from '@material-ui/core/InputBase'
import {
  createStyles,
  withStyles,
  makeStyles,
  Theme,
} from '@material-ui/core/styles'

import mainTheme from '../../../styles/theme'
import { FontsName } from '../../../styles/fonts'

export const SelectInput = withStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    input: {
      fontSize: mainTheme.sizes.spacing.medium,
      borderRadius: mainTheme.sizes.spacing.xxsmall,
      padding: '10px 12px',
      position: 'relative',
      border: '0.5px solid #8F99A3',
      transition: theme.transitions.create(['border-color', 'box-shadow']),
      '&:hover': {
        border: `1px solid ${mainTheme.colors.neutral700}`,
      },
    },
  })
)(InputBase)

export const useStyles = makeStyles({
  select: {
    '& ul': {
      border: `1px solid ${mainTheme.colors.neutral700}`,
      borderRadius: mainTheme.sizes.spacing.xxxsmall,
      marginTop: mainTheme.sizes.spacing.xxxsmall,
    },
  },
})

export const InputContainer = styled.div`
  ${({ theme }) => theme.fonts[FontsName.Nunito].medium}
  color: ${({ theme }) => theme.colors.neutral700};
  width: 100%;
`
export const LabelInputForm = styled.div`
  ${({ theme }) => theme.fonts[FontsName.Nunito].mediumbold}
  margin-bottom: ${({ theme: { sizes } }) => sizes.spacing.xxsmall}
  color: ${({ theme }) => theme.colors.neutral800}
  width: 100%
`
