import Axios, { AxiosRequestConfig } from 'axios'
import httpRequest from '../components/Delivery/httpClient'
import { IStore } from '../types/models'

export const getStoreData = async (name: string): Promise<any> => {
  try {
    const response = await Axios({
      url: `${process.env.REACT_APP_TREINTA_MICROSERVICES_API_URL}/shop/stores/${name}`,
      headers: {
        'X-API-Key': process.env.REACT_APP_TREINTA_MICROSERVICES_API_KEY,
      },
    })
    const { data } = response
    return data
  } catch (error: any) {
    return null
  }
}

export const getStoreInfo = async (
  urlName: string,
  authToken: string
): Promise<any> => {
  const requestData: AxiosRequestConfig = {
    method: 'GET',
    url: `${process.env.REACT_APP_TREINTA_MICROSERVICES_API_URL}/shop/stores/${urlName}`,
  }
  return httpRequest(requestData, authToken)
}

export const updateStoreInfo = async (
  storeInfo: Partial<IStore>,
  authToken: string
): Promise<any> => {
  const { id, address, city } = storeInfo
  const requestData: AxiosRequestConfig = {
    method: 'PUT',
    data: [
      {
        id,
        city,
        address,
      },
    ],
    url: `${process.env.REACT_APP_TREINTA_MICROSERVICES_API_URL}/store/update`,
  }
  return httpRequest(requestData, authToken)
}
