import { useEffect, useState } from 'react'

import {
  CancelRequest,
  CancelResponse,
  cancelShipmentService,
} from '../../services/Shipment/cancelShipmentService'

interface Request {
  requestCancel: CancelRequest
  authToken: string
  setIsLoadedCancelShipment: (value: boolean) => void
  setHasErrorCancelShipment: (value: boolean) => void
}
interface Response {
  cancelShipmentData: CancelResponse | undefined
}

export const useCancelShipmentService = ({
  requestCancel,
  authToken,
  setIsLoadedCancelShipment,
  setHasErrorCancelShipment,
}: Request): Response => {
  const [cancelShipmentData, setCancelShipmentData] = useState<CancelResponse>()

  useEffect(() => {
    if (requestCancel.deliveryId !== '') {
      cancelShipmentService(authToken, requestCancel)
        .then((resumeShipmentResponse) => {
          setCancelShipmentData(resumeShipmentResponse)
          setIsLoadedCancelShipment(true)
        })
        .catch(() => {
          setHasErrorCancelShipment(true)
        })
    }
  }, [requestCancel])

  return { cancelShipmentData }
}
