import { StoreAuthStateType } from '../../types/models'
import {
  SET_COUNTRY,
  SET_PHONE,
  SET_NAME,
  SET_USER_HAS_BEEN_CREATED,
} from './constants'

export const storeAuthReducer = (
  state: StoreAuthStateType,
  action: { payload: any; type: string }
): any => {
  const { payload, type } = action

  switch (type) {
    case SET_NAME:
      return { ...state, name: payload }
    case SET_PHONE:
      return { ...state, phone: payload }
    case SET_COUNTRY:
      return { ...state, country: payload }
    case SET_USER_HAS_BEEN_CREATED:
      return { ...state, userHasBeenCreated: payload }
    default:
      return state
  }
}
