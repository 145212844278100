import { useContext } from 'react'

import { countries } from '../../config/countries'
import { useShoppingCart } from '../ShoppingCart/hooks'
import { useStoreConfig } from '../../context/AppContext'
import { LowerShoppingButton } from './LowerShoppingButton'
import ShoppingCartContext from '../../context/ShoppingCart/context'

export const LowerShoppingButtonLoad = (): JSX.Element => {
  const {
    totalPayable,
    totalDiscounts,
    totalProductsInCart,
  } = useShoppingCart()
  const { store } = useStoreConfig()
  const country = countries.get(store.country)
  const { handleClickShoppingCart } = useContext(ShoppingCartContext)

  return (
    <LowerShoppingButton
      country={country}
      productsNumber={totalProductsInCart}
      totalPayable={totalPayable - totalDiscounts}
      setOpenShoppingCart={handleClickShoppingCart}
    />
  )
}
