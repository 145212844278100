import styled from 'styled-components'

import { ReactComponent as Pending } from '../../../../assets/payment-pending.svg'
import { ReactComponent as Declined } from '../../../../assets/payment-declined.svg'
import { ReactComponent as Payments } from '../../../../assets/treinta-payments.svg'
import { ReactComponent as Success } from '../../../../assets/payment-successful.svg'

import { Typography } from '../../../../components/common/Typography'

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${({ theme }) => theme.colors.neutral400};
  margin-bottom: ${({ theme }) => theme.sizes.spacing.medium};
`

export const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  padding: ${({ theme }) => theme.sizes.spacing.medium};
`

export const SuccessIcon = styled(Success)`
  margin-bottom: ${({ theme }) => theme.sizes.spacing.small};
`

export const PendingIcon = styled(Pending)`
  margin-bottom: ${({ theme }) => theme.sizes.spacing.small};
`

export const DeclinedIcon = styled(Declined)`
  margin-bottom: ${({ theme }) => theme.sizes.spacing.small};
`

export const PaymentsLogo = styled(Payments)`
  margin-bottom: ${({ theme }) => theme.sizes.spacing.medium};
`

export const Title = styled(Typography).attrs({ variant: 'Mediumbold' })`
  color: ${({ theme }) => theme.colors.secondary500};
  margin-bottom: ${({ theme }) => theme.sizes.spacing.small};
`

export const Message = styled(Typography).attrs({ variant: 'XSmall' })`
  color: ${({ theme }) => theme.colors.secondary500};
  margin-bottom: ${({ theme }) => theme.sizes.spacing.small};
`
