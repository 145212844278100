import { useContext, useEffect, useState } from 'react'

import { SummaryButton } from '../SummaryButton'
import { PaymentMethodButton } from '../PaymentMethodButton'
import { PaymentType } from '../../../../config/payment-type'
import { DeliveryMethodButton } from '../DeliveryMethodButton'
import { CheckoutStepperFooter } from './CheckoutStepperFooter'
import { useStoreConfig } from '../../../../context/AppContext'
import { DeliveryMethod } from '../../../../config/delivery-method'
import { useCatalogState } from '../../../../context/ShoppingCartContext'
import { useGetEnablePaymentMethods } from '../../../../hooks/useGetEnablePaymentMethods'
import { ShoppingCartContext } from '../../../../context/ShoppingCartContext/ShoppingCartContext'
import { countries } from '../../../../config/countries'

export const CheckoutStepperFooterLoad = (): JSX.Element => {
  const [buttonComponent, setButtonComponent] = useState<JSX.Element>(<></>)
  const {
    state: { checkoutStep },
  } = useContext(ShoppingCartContext)

  const [
    enablePaymentMethodCount,
    setEnablePaymentMethodCount,
  ] = useState<number>(0)

  const {
    store: { id, country },
  } = useStoreConfig()

  const {
    state: { selectedDeliveryMethod },
    setSelectedPaymentMethod,
  } = useCatalogState()

  const { enablePaymentMethods } = useGetEnablePaymentMethods({
    storeId: id || '',
  })

  const existActivePaymentMethod = enablePaymentMethods?.some(
    ({ status }) => status === true
  )

  const currentCountry = countries.get(country)

  useEffect(() => {
    if (!existActivePaymentMethod) {
      setSelectedPaymentMethod(PaymentType.CoordinateWithTheClient)
    } else {
      let count = 0
      enablePaymentMethods?.forEach(({ status }) => {
        status && count++
      })
      setEnablePaymentMethodCount(count)
    }
  }, [existActivePaymentMethod])

  useEffect(() => {
    switch (checkoutStep) {
      case 1:
        setButtonComponent(<SummaryButton />)
        break
      case 2:
        if (currentCountry.id !== 1) {
          setButtonComponent(<PaymentMethodButton />)
          break
        }
        if (
          !existActivePaymentMethod ||
          (enablePaymentMethodCount === 1 &&
            enablePaymentMethods?.[0].payment_type_id ===
              PaymentType.AgainstDelivery &&
            selectedDeliveryMethod === DeliveryMethod.PICK_UP_IN_STORE)
        ) {
          setButtonComponent(<PaymentMethodButton />)
          break
        } else {
          setButtonComponent(<DeliveryMethodButton />)
          break
        }
      default:
        setButtonComponent(<PaymentMethodButton />)
        break
    }
  }, [checkoutStep])

  return <CheckoutStepperFooter buttonComponent={buttonComponent} />
}
