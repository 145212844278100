import { Grid, Button, Paper, Typography } from '@material-ui/core'
import { useStyles } from './styles'
import Image404 from './../../../assets/404.png'
import { Helmet } from 'react-helmet'

const NotFoundPage = (): JSX.Element => {
  const props: any = {
    image: Image404,
  }
  const classes = useStyles(props)
  return (
    <div className={classes.root}>
      <Helmet>
        <meta
          name="description"
          content="Página no encontrada. Asegúrese de que la dirección sea correcta y que la página no se
          haya movido."
        />
      </Helmet>
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignContent="center"
        className={classes.gridContainer}
      >
        <Grid item>
          <Grid
            container
            justifyContent="center"
            alignContent="center"
            className={classes.gridContainerImage}
          >
            <Paper elevation={0} className={classes.paperImage} />
          </Grid>
        </Grid>
        <Grid item className={classes.textContainer}>
          <Typography variant="h5" align="center">
            Página no encontrada
          </Typography>
          <Typography
            variant="subtitle1"
            align="center"
            className={classes.subtitleText}
          >
            Asegúrese de que la dirección sea correcta y que la página no se
            haya movido.
          </Typography>
        </Grid>

        <Button
          variant="contained"
          color="primary"
          href="/"
          classes={{
            root: classes.goBackButtonRoot,
            contained: classes.goBackButton,
          }}
        >
          VOLVER
        </Button>
      </Grid>
    </div>
  )
}

export default NotFoundPage
