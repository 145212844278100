import styled from 'styled-components'

import { Typography } from '../../../../components/common/Typography'
import { ReactComponent as CashIcon } from '../../../../assets/cash.svg'
import { ReactComponent as BicicleIcon } from '../../../../assets/bike.svg'
import { ReactComponent as AddressIcon } from '../../../../assets/address.svg'
import { ReactComponent as PinLocationIcon } from '../../../../assets/pin-location.svg'

export const Subcontainer = styled.div`
  width: 40%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`

export const Container = styled.div`
  padding: ${({ theme: { sizes } }) => sizes.spacing.medium};
  margin-bottom: ${({ theme: { sizes } }) => sizes.spacing.medium};
`

export const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: ${({ theme: { sizes } }) => sizes.spacing.small};
`
export const PinLocation = styled(PinLocationIcon)`
  width: ${({ theme: { sizes } }) => sizes.spacing.medium};
  height: ${({ theme: { sizes } }) => sizes.spacing.medium};
  margin-right: ${({ theme: { sizes } }) => sizes.spacing.xsmall};
`

export const Bike = styled(BicicleIcon)`
  width: ${({ theme: { sizes } }) => sizes.spacing.medium};
  height: ${({ theme: { sizes } }) => sizes.spacing.medium};
  margin-right: ${({ theme: { sizes } }) => sizes.spacing.xsmall};
`

export const Address = styled(AddressIcon)`
  width: ${({ theme: { sizes } }) => sizes.spacing.medium};
  height: ${({ theme: { sizes } }) => sizes.spacing.medium};
  margin-right: ${({ theme: { sizes } }) => sizes.spacing.xsmall};
`

export const Cash = styled(CashIcon)`
  width: ${({ theme: { sizes } }) => sizes.spacing.medium};
  height: ${({ theme: { sizes } }) => sizes.spacing.medium};
  margin-right: ${({ theme: { sizes } }) => sizes.spacing.xsmall};
`

export const TextLabel = styled(Typography).attrs({ variant: 'XSmall' })`
  width: 100%;
  color: ${({ theme: { colors } }) => colors.neutral700};
`

export const Title = styled(Typography).attrs({ variant: 'Mediumbold' })`
  margin-bottom: ${({ theme: { sizes } }) => sizes.spacing.medium};
`

export const TextValue = styled(Typography).attrs({ variant: 'XSmallbold' })`
  width: 60%;
  text-align: end;
`
