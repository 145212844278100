import { useState } from 'react'

import { getAddressGeoLocation } from '../services/googleAPIService'
import { suggestType } from '../components/common/InputAutoComplete/contracts'

interface Response {
  googleServiceState: suggestType[]
  handleService: (requestParams: {
    [key: string]: string | number
  }) => Promise<suggestType[]>
}

export const useGoogleAPIAddressService = (): Response => {
  const [googleServiceState, setGoogleServiceState] = useState<suggestType[]>(
    []
  )

  const handleService = async (requestParams: {
    [key: string]: string | number
  }) => {
    return getAddressGeoLocation(requestParams).then((response) => {
      const suggestionsList = formatAddressResponse(response)
      setGoogleServiceState(suggestionsList)
      return suggestionsList
    })
  }

  return { googleServiceState, handleService }
}

const formatAddressResponse = (response: any): suggestType[] => {
  const suggestionList: suggestType[] = []
  if (response.results && response.results.length) {
    response.results.map((address: any) => {
      suggestionList.push({
        id: address.place_id,
        label: address.formatted_address,
        address: address.formatted_address,
        latitude: address?.geometry?.location?.lat,
        longitude: address?.geometry?.location?.lng,
        city: address?.address_components?.find(({ types }: any) =>
          types.includes('locality')
        )?.long_name,
      })
    })
  }
  return suggestionList
}
