import { useTranslation } from 'react-i18next'
import { useContext, useEffect, useState } from 'react'

import {
  Row,
  Grid,
  Column,
  NoImage,
  Divider,
  NameLabel,
  PriceLabel,
  TextDiscount,
  CategoryLabel,
  DiscountLabel,
  MainContainer,
  ImageContainer,
  TextExpiration,
  HeaderContainer,
  DetailContainer,
  DescriptionLabel,
  ContentContainer,
  DiscountContainer,
  ContainerShopingCartButton,
} from './styles'
import {
  formatterCurrency,
  calculateDiscountPercent,
} from '../../utils/functions'
import {
  calculateDaysBetweenDates,
  compareRangeWithCurrentDay,
} from '../../utils/datefunctions'
import { Thumbnails } from '../Thumbnail'
import ScreensHeader from '../ScreensHeader'
import { ProductDetailProps } from './types'
import ImageWithViewer from '../ImageWithViewer'
import { useShoppingCart } from '../ShoppingCart/hooks'
import CatalogContext from '../../context/Catalog/context'
import useGAEventTracker from '../../hooks/useGAEventTracker'
import ShoppingCartContext from '../../context/ShoppingCart/context'
import { ShopingCartButton } from '../StoreHeader/components/ShoppingCartButton'
import { CART_QUANTITY_LARGE_SHAPE } from '../CartQuantitySelector/CartQuantitySelector'
import ContextCartQuantitySelector from '../CartQuantitySelector/ContextCartQuantitySelector'

const ProductDetail = ({
  country,
  onGoBack,
  product,
}: ProductDetailProps): JSX.Element => {
  const { t: translate } = useTranslation()
  const GAEventsTracker = useGAEventTracker()
  const {
    state: { activeStoreId },
  } = useContext(CatalogContext)
  const [selectedThumb, setSelectedThumb] = useState(product.imageUrl)

  const { handleClickShoppingCart } = useContext(ShoppingCartContext)
  const { totalProductsInCart } = useShoppingCart()

  useEffect(() => {
    if (product?.id && activeStoreId) {
      GAEventsTracker('productos_mas_vistos', `${activeStoreId}:${product.id}`)
    }
  }, [activeStoreId])

  const activeDiscountDays = product?.discount?.endDate
    ? calculateDaysBetweenDates(String(product?.discount?.endDate))
    : 0

  const discountPrice = product?.discount?.finalPrice || 0

  const handelThumbnail = (selected: string) => {
    setSelectedThumb(selected)
  }
  const hasActiveDiscount =
    product?.discount?.startDate &&
    compareRangeWithCurrentDay(
      product?.discount?.startDate,
      product?.discount?.endDate
    )

  return (
    <MainContainer>
      <HeaderContainer>
        <ScreensHeader
          onGoBack={onGoBack}
          label={translate('productDetail.header')}
        />
        <ContainerShopingCartButton>
          <ShopingCartButton
            productsNumber={totalProductsInCart}
            setOpenShoppingCart={handleClickShoppingCart}
          />
        </ContainerShopingCartButton>
      </HeaderContainer>
      <DetailContainer>
        <ContentContainer>
          <ImageContainer>
            <>
              <DiscountContainer>
                {activeDiscountDays > 0 && (
                  <TextExpiration>
                    {translate('discounts.daysLeft', {
                      numDays: activeDiscountDays,
                    })}
                  </TextExpiration>
                )}
                {hasActiveDiscount && (
                  <TextDiscount>
                    {calculateDiscountPercent(
                      Number(product.discount?.originalPrice),
                      Number(product.discount?.finalPrice)
                    )}
                  </TextDiscount>
                )}
              </DiscountContainer>
              {product?.imageUrl ? (
                <ImageWithViewer
                  previewText={translate('catalog.imageZoomClick')}
                  src={selectedThumb || ''}
                />
              ) : (
                <NoImage />
              )}
            </>
          </ImageContainer>
          {product?.images && (
            <Thumbnails
              images={product.images}
              handleThumbnail={handelThumbnail}
            />
          )}
          <Grid>
            <Column>
              <Row>
                <PriceLabel lineThrough={!!discountPrice}>
                  {formatterCurrency(country, product.price)}
                </PriceLabel>
                {!!discountPrice && (
                  <DiscountLabel>
                    {formatterCurrency(country, discountPrice)}
                  </DiscountLabel>
                )}
              </Row>
              <Row>
                <NameLabel>{product.name}</NameLabel>
              </Row>
            </Column>
            <Column>
              <ContextCartQuantitySelector
                product={product}
                shape={CART_QUANTITY_LARGE_SHAPE}
              />
            </Column>
          </Grid>
          <Divider />
          <Grid>
            <Column>
              <CategoryLabel>{`${translate('productDetail.category')} ${
                product.categories
              }`}</CategoryLabel>
            </Column>
          </Grid>

          {product.notes && (
            <DescriptionLabel>{`${translate('catalog.descriptionLabel')} ${
              product.notes
            }`}</DescriptionLabel>
          )}
        </ContentContainer>
      </DetailContainer>
    </MainContainer>
  )
}

export default ProductDetail
