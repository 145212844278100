import { useTranslation } from 'react-i18next'

import {
  ContentContainer,
  Header,
  MainContainer,
  ContentIconBack,
  ImageHeader,
  Title,
  TitleHeader,
  Text,
  TextPrice,
  TextTotal,
  ContainerTotal,
  DetailLine,
  TextTotalPrice,
} from './styles'
import image from '../../assets/imageDetailOrder.png'
import { formatterCurrency } from '../../utils/functions'
import { DetailOrder, ICountry } from '../../types/models'
import { ReactComponent as IconGoBack } from './../../assets/goBack.svg'
import { OrderDetailList } from '../../components/OrderDetailList'
import { ButtonWsOrderDetail } from '../../components/ButtonWsOrderDetail'
import { DeliveryTypeId } from '../../context/Orders/model'

export interface Props {
  data: DetailOrder[]
  country: ICountry
  totalOrder: number
  address: string
  transactionCount: string
  buttonBack: () => void
  contactStore: () => void
  deliveryPrice: number
  deliveryTypeId: DeliveryTypeId
}

export const OrderDetail = ({
  data,
  country,
  totalOrder,
  address,
  transactionCount,
  buttonBack,
  contactStore,
  deliveryPrice,
  deliveryTypeId,
}: Props): JSX.Element => {
  const { t } = useTranslation()
  return (
    <MainContainer>
      <ContentIconBack>
        <IconGoBack onClick={buttonBack} />
      </ContentIconBack>
      <Header>
        <ImageHeader src={image} />
        <TitleHeader forwardedAs="h1" variant="XLargebold">
          {`Pedido #${transactionCount}`}
        </TitleHeader>
      </Header>
      <ContentContainer>
        <Title forwardedAs="p" variant="Mediumbold">
          {t('orderDetail.products')}
        </Title>
        <OrderDetailList products={data} country={country} />
        <ContainerTotal>
          {deliveryTypeId === DeliveryTypeId.HOME_DELIVERY && (
            <DetailLine data-testid="delivery-container">
              <TextTotal variant="Small">{t('orderDetail.delivery')}</TextTotal>
              <TextPrice variant="Small">{`${formatterCurrency(
                country,
                deliveryPrice
              )} ${country.currency}`}</TextPrice>
            </DetailLine>
          )}
          <DetailLine>
            <TextTotal forwardedAs="p" variant="Small">
              {t('orderDetail.totalPurchase')}
            </TextTotal>
            <TextTotalPrice
              forwardedAs="p"
              variant="Smallbold"
            >{`${formatterCurrency(country, totalOrder + deliveryPrice)} ${
              country.currency
            }`}</TextTotalPrice>
          </DetailLine>
        </ContainerTotal>
        {address && (
          <>
            <Title forwardedAs="p" variant="Mediumbold">
              {t('orderDetail.addressOrder')}
            </Title>
            <Text forwardedAs="p" variant="Small">
              {address}
            </Text>
          </>
        )}
      </ContentContainer>
      <ButtonWsOrderDetail contactStore={contactStore} />
    </MainContainer>
  )
}
