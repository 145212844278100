import styled from 'styled-components'
import { Typography } from '../Typography'
import { getLogoFooterFromLanguage } from '../../../common/services/utils'
import { FontsName } from '../../../styles/fonts'
import { ReactComponent as LockSquareIcon } from './../../../assets/icons/lock-square.svg'

export const Container = styled.footer`
  display: flex;
  flex-direction: column;
  height: ${({ theme: { layout } }) => layout.footer.height};
  width: 100%;
`
export const Info = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${({ theme }) => `
    background: ${theme.colors.secondary500};
    padding: ${theme.sizes.spacing.medium};
  `}
`

export const ContainerSecure = styled.div`
  display: flex;
  align-items: center;
`

export const SecureText = styled(Typography).attrs(() => ({
  variant: 'XSmallbold',
  forwardedAs: 'span',
}))<{ primaryColor?: boolean }>`
  ${({ theme, primaryColor }) => `
    color: ${primaryColor ? theme.colors.primary500 : theme.colors.white};
  `}
`
export const LinkCreatedBy = styled.a`
  display: flex;
  align-items: center;
`

export const CreatedBy = styled(Typography).attrs(() => ({
  variant: 'XSmall',
  forwardedAs: 'span',
}))`
  ${({ theme }) => `
    color: ${theme.colors.secondary200};
    margin-right: ${theme.sizes.spacing.xxsmall};
  `}
`

export const ContainerTerms = styled.div`
  text-align: center;
  ${({ theme }) => `
    background: ${theme.colors.secondary600};
    padding: ${theme.sizes.spacing.xxsmall}
  `}
`

export const LinkTerms = styled.a`
  ${({ theme }) => [
    theme.fonts[FontsName.Nunito].xsmall,
    `border-radius: ${theme.sizes.borderRadius.medium};
    color: ${theme.colors.white};
    text-align: center;`,
  ]}
`

export const Logo = styled(getLogoFooterFromLanguage())`
  width: 60px;
`
export const LockIcon = styled(LockSquareIcon)`
  align-self: baseline;
  ${({ theme }) => `
    margin-right: ${theme.sizes.spacing.xxsmall};
  `}
`
