import { Drawer } from '@material-ui/core'
import MenuLink from '../MenuLink'
import { ReactComponent as HomeIcon } from '../../assets/app-menu-store.svg'
import { ReactComponent as MyOrdersIcon } from '../../assets/app-menu-truck.svg'
import { ReactComponent as FooterContent } from '../../assets/app-menu-footer-content.svg'
import {
  CloseIcon,
  FooterContainer,
  LinksContainer,
  MainContainer,
  TopContainer,
  TreintaShopLogo,
  MenuLinks,
} from './styles'
import { useStoreConfig } from '../../context/AppContext'
import { useTranslation } from 'react-i18next'

export interface AppMenuProps {
  isOpen: boolean
  onClose: () => void
}

const AppMenu = ({ isOpen, onClose }: AppMenuProps): JSX.Element => {
  const {
    store: { urlName },
  } = useStoreConfig()

  const { t: translate } = useTranslation()

  return (
    <Drawer anchor={'left'} open={isOpen} onClose={onClose}>
      <MainContainer>
        <TopContainer>
          <CloseIcon onClick={onClose} />
          <TreintaShopLogo />

          <LinksContainer>
            <MenuLinks>
              <MenuLink
                label={translate('menu.home')}
                goTo={`/${urlName}`}
                Icon={HomeIcon}
                eventName="webshop_menu_home"
                onClose={onClose}
              />
              <MenuLink
                label={translate('menu.myOrders')}
                goTo={`/${urlName}/orders`}
                Icon={MyOrdersIcon}
                eventName="webshop_my_orders"
                onClose={onClose}
              />
            </MenuLinks>
          </LinksContainer>
        </TopContainer>

        <FooterContainer>
          <FooterContent />
        </FooterContainer>
      </MainContainer>
    </Drawer>
  )
}
export default AppMenu
