import { useContext } from 'react'
import { useParams } from 'react-router-dom'

import { useHistory } from 'react-router-dom'
import ProductDetail from '../../components/ProductDetail'
import { useShoppingCartproductsById } from '../../components/ProductDetail/hooks'
import { countries } from '../../config/countries'
import { useStoreConfig } from '../../context/AppContext'
import CatalogContext from '../../context/Catalog/context'
import ShoppingCartContext from '../../context/ShoppingCart/context'
import LoadingPage from '../Pages/Loading'

const ProductDetailPage = (): JSX.Element => {
  const {
    state: { products },
  } = useContext(CatalogContext)
  const { store } = useStoreConfig()
  const { updateProductQuantity } = useContext(ShoppingCartContext)

  const history = useHistory()
  const { productId } = useParams() as any
  const product = products?.find((product) => product.id === productId)

  const goBack = () => {
    history.length <= 2 ? history.push(`/${store.urlName}`) : history.goBack()
  }

  const currentQuanitty = useShoppingCartproductsById(productId)

  return (
    <>
      {product ? (
        <ProductDetail
          country={countries.get(store.country)}
          onGoBack={goBack}
          product={product}
          currentQuantity={currentQuanitty}
          updateProductQuantity={updateProductQuantity}
        />
      ) : (
        <LoadingPage />
      )}
    </>
  )
}

export default ProductDetailPage
