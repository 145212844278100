import { useTranslation } from 'react-i18next'

import { CheckoutStepperFooterType } from './contracts'
import { countries } from '../../../../config/countries'
import { useStoreConfig } from '../../../../context/AppContext'
import { formatterCurrency } from '../../../../utils/functions'
import { DeliveryMethod } from '../../../../config/delivery-method'
import { Typography } from '../../../../components/common/Typography'
import { useCatalogState } from '../../../../context/ShoppingCartContext'
import { useShoppingCart } from '../../../../components/ShoppingCart/hooks'
import { Container, TextValue, TextContainer, ButtonContainer } from './styles'

export const SHOPPING_CART_SUCCESS_ALERT_TIMEOUT = 2000

export const CheckoutStepperFooter = ({
  buttonComponent,
}: CheckoutStepperFooterType): JSX.Element => {
  const {
    state: { selectedDeliveryMethod, selectedDeliveyArea },
  } = useCatalogState()
  const { t } = useTranslation()
  const { store } = useStoreConfig()
  const { config, country: storeCountry } = store
  const deliveryPrice = config?.deliveryPrice || 0
  const finalDeliveryPrice = selectedDeliveyArea
    ? selectedDeliveyArea?.price
    : deliveryPrice
  const { totalDiscounts, totalPayable: totalProducts } = useShoppingCart()
  const totalPayable =
    totalProducts -
    totalDiscounts +
    Number(
      selectedDeliveryMethod === DeliveryMethod.HOME_DELIVERY
        ? finalDeliveryPrice
        : 0
    )
  const getRow = ({
    text,
    value,
    special,
    isDiscount,
  }: {
    text: string
    value: string
    special?: boolean
    isDiscount?: boolean
  }) => (
    <>
      <TextContainer>
        <Typography forwardedAs="p" variant="Small">
          {text}
        </Typography>
        <TextValue special={special} isDiscount={isDiscount}>
          {value}
        </TextValue>
      </TextContainer>
    </>
  )

  return (
    <Container>
      {(selectedDeliveryMethod === DeliveryMethod.HOME_DELIVERY ||
        !!totalDiscounts) &&
        getRow({
          text: t('sectionForm.labelProducts'),
          value: `${formatterCurrency(
            countries.get(storeCountry),
            Number(totalProducts)
          )}`,
        })}
      {selectedDeliveryMethod === DeliveryMethod.HOME_DELIVERY &&
        getRow({
          text: t('sectionForm.delivery'),
          value: `${formatterCurrency(
            countries.get(storeCountry),
            finalDeliveryPrice
          )}`,
        })}
      {!!totalDiscounts &&
        getRow({
          text: t('sectionForm.discount'),
          value: `${formatterCurrency(
            countries.get(storeCountry),
            totalDiscounts
          )}`,
          isDiscount: true,
        })}
      {getRow({
        text: t('sectionForm.totalPurchase'),
        value: `${formatterCurrency(
          countries.get(storeCountry),
          totalPayable
        )}`,
        special: true,
      })}

      <ButtonContainer>{buttonComponent}</ButtonContainer>
    </Container>
  )
}
