import { useState, useEffect, FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Typography } from '../../../../components/common/Typography'
import { TIMER } from './contracts'

interface Props {
  restart?: boolean
}

export const CountDown: FC<Props> = ({
  children,
  restart = false,
}): JSX.Element => {
  const { t } = useTranslation()
  const [currentTime, setCurrentTime] = useState(TIMER)
  const [runTimer, setRunTimer] = useState<boolean>(true)

  useEffect(() => {
    const timerId = setInterval(() => {
      setCurrentTime((prevState) => prevState - 1)
    }, 1000)

    if (!runTimer && timerId) {
      clearInterval(timerId)
    }

    return () => clearInterval(timerId)
  }, [runTimer])

  useEffect(() => {
    currentTime <= 0 && setRunTimer(false)
  }, [currentTime])

  useEffect(() => {
    if (restart) {
      setCurrentTime(TIMER)
      setRunTimer(true)
    }
  }, [restart])

  return (
    <>
      {runTimer ? (
        <Typography forwardedAs="span" variant="Smallbold">
          {t('codeInput.resendCode')} {currentTime}
        </Typography>
      ) : (
        children
      )}
    </>
  )
}
