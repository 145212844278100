import { Link, Logo } from './styles'

interface Props {
  name: string
  url: string
  isSticky: boolean
  logo?: string
}

export const StoreLogo = ({
  url,
  logo,
  isSticky,
  name,
}: Props): JSX.Element => (
  <>
    {logo && (
      <Link to={url}>
        <Logo src={logo} isSticky={isSticky} alt={`Logo ${name}`} />
      </Link>
    )}
  </>
)
