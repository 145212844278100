import styled from 'styled-components'

import { FontsName } from '../../../styles/fonts'
import { Typography } from '../../common/Typography'

const IMAGE_WIDTH = 72
const IMAGE_HEIGHT = 62
const IMAGE_CONTAINER_HEIGHT = 62

interface DisabledProp {
  isDisabled: boolean
}

export const MainContainer = styled.li<DisabledProp>`
  align-items: center;
  background-color: ${({ theme: { colors }, isDisabled }) =>
    isDisabled ? colors.neutral200 : colors.white};
  display: flex;
  justify-content: space-between;
  padding: ${({ theme: { sizes } }) => sizes.spacing.medium} 0;
  width: 100%;
  &:not(:last-child) {
    border-bottom: ${({ theme: { colors } }) =>
      `1px solid ${colors.neutral400}`};
  }
  & > a {
    width: 100%;
  }
`

export const MetadataContainer = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: ${({ theme: { sizes } }) => sizes.spacing.medium};
  margin-right: ${({ theme: { sizes } }) => sizes.spacing.medium};
  min-height: ${IMAGE_HEIGHT}px;
  flex: 1;
`

export const QtySelectorContainer = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: flex-end;
`

export const ProductPictureContainer = styled.div`
  height: ${IMAGE_CONTAINER_HEIGHT}px;
  position: relative;
`

export const TextDiscount = styled(Typography)`
  top: 0;
  right: 0;
  width: 36px;
  position: absolute;
  text-align: center;
  color: ${({ theme: { colors } }) => colors.white};
  ${({ theme }) => theme.fonts[FontsName.Nunito].xsmallbold};
  padding: ${({ theme: { sizes } }) => `${sizes.spacing.xxxsmall}`};
  background-color: ${({ theme: { colors } }) => colors.tertiary600};
  border-radius: ${({ theme: { sizes } }) => sizes.borderRadius.medium};
`

export const ProductPicture = styled.img.attrs({
  width: IMAGE_WIDTH,
  height: IMAGE_HEIGHT,
})`
  object-fit: contain;
`

export const ProductName = styled(Typography).attrs({
  variant: 'Small',
})`
  color: ${({ theme: { colors } }) => colors.neutral700};
  margin-bottom: ${({ theme }) => theme.sizes.spacing.xxsmall};
`

export const ProductPrices = styled.div`
  width: 100%;
`

export const OriginalPrice = styled(Typography).attrs({
  variant: 'XSmall',
})<{ lineThrough?: boolean }>`
  color: ${({ theme: { colors } }) => colors.neutral600};
  text-decoration-line: ${({ lineThrough }) =>
    lineThrough ? 'line-through' : 'none'};
  display: inline-block;
  margin-right: 4px;
`

export const DiscountPrice = styled(Typography).attrs({
  variant: 'Smallbold',
})`
  color: ${({ theme: { colors } }) => colors.secondary700};
  display: inline-block;
`

export const WrapperMetaData = styled.div<DisabledProp>`
  align-items: center;
  justify-content: center;
  display: flex;
  flex: 1;
  cursor: ${({ isDisabled }) => (isDisabled ? 'not-allowed' : 'pointer')};

  && .lazy-wrapper > img {
    object-fit: cover;
    border-radius: ${({ theme: { sizes } }) => sizes.borderRadius.medium};
    margin: 0px;
  }
`

export const NotAvailableProduct = styled(Typography).attrs({
  variant: 'XSmall',
})`
  margin: 0;
  padding: 0;
  color: ${({ theme }) => theme.colors.error700};
`
export const AlertLabel = styled(Typography).attrs({
  variant: 'XSmall',
})`
  display: inline-block;
  color: ${({ theme: { colors } }) => colors.error500};
`
