import { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useRouteMatch } from 'react-router-dom'

import {
  TextDays,
  TextDiscount,
  DiscountName,
  DiscountItem,
  TextRealPrice,
  DiscountPrice,
  DiscountImage,
  DiscountHeader,
  DiscountButton,
  TextDiscountPrice,
  DiscountItemWrapper,
} from './styles'
import {
  formatterCurrency,
  calculateDiscountPercent,
} from '../../../utils/functions'
import { ProductDiscountProps } from '../types'
import noPicture from './../../../assets/empty.svg'
import { IProduct, ProductItem } from '../../../types/models'
import CatalogContext from '../../../context/Catalog/context'
import { calculateDaysBetweenDates } from '../../../utils/datefunctions'
import { CART_QUANTITY_LARGE_SHAPE } from '../../CartQuantitySelector/CartQuantitySelector'
import ContextCartQuantitySelector from '../../CartQuantitySelector/ContextCartQuantitySelector'

const ProductDiscount = ({
  endDate,
  country,
  onClick,
  product: { originalPrice, finalPrice, product },
}: ProductDiscountProps): JSX.Element => {
  const {
    state: { products },
  } = useContext(CatalogContext)

  const { t: translate } = useTranslation()
  const productData = product
  const {
    path,
    params: { name },
  }: { path: string; params: { name: string } } = useRouteMatch()

  const parseProductIProduct = (product: ProductItem): IProduct => {
    return (
      products?.find(({ id }) => id === product.id) || {
        sku: '',
        originId: 1,
        storeId: '',
        createdAt: '',
        updatedAt: '',
        deletedAt: '',
        id: product.id,
        categories: '',
        price: finalPrice,
        name: product.name,
        stock: product.stock,
      }
    )
  }

  return (
    <DiscountItemWrapper>
      <DiscountItem data-testid="discount-item-container" onClick={onClick}>
        <DiscountHeader>
          {endDate && (
            <TextDays>
              {translate('discounts.daysLeft', {
                numDays: calculateDaysBetweenDates(endDate),
              })}
            </TextDays>
          )}
          <TextDiscount>
            {calculateDiscountPercent(originalPrice, finalPrice)}
          </TextDiscount>
        </DiscountHeader>
        <Link to={`${path.replace(':name', name)}/${product.id}`}>
          <DiscountImage
            src={productData.image_url ? productData.image_url : noPicture}
          />
        </Link>
        <DiscountName>{productData.name}</DiscountName>
        <DiscountPrice>
          <TextRealPrice>
            {formatterCurrency(country, originalPrice)}
          </TextRealPrice>
          <TextDiscountPrice>
            {formatterCurrency(country, finalPrice)}
          </TextDiscountPrice>
        </DiscountPrice>
        <DiscountButton>
          <ContextCartQuantitySelector
            product={parseProductIProduct(product)}
            shape={CART_QUANTITY_LARGE_SHAPE}
          />
        </DiscountButton>
      </DiscountItem>
    </DiscountItemWrapper>
  )
}
export default ProductDiscount
