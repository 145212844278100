import { ALL_PRODUCTS_CATEGORY } from '../../types/models'
import { ArrowHeadIcon, Button, Container, TogglerContainer } from './styles'
import { CategoriesDrawer } from '../CategoriesDrawer'
import { useState, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import CatalogContext from '../../context/Catalog/context'
import CategoriesHorizontalSelector from '../CategoriesHorizontalSelector'

export const Categories = (): JSX.Element => {
  const [openDrawer, setOpenDrawer] = useState<boolean>(false)
  const { t } = useTranslation()
  const {
    state: { activeCategory, availableCategories },
    setActiveCategory,
  } = useContext(CatalogContext)

  const handleClickSeeAllCategories = () => setOpenDrawer(true)
  const handleCloseButton = () => setOpenDrawer(false)

  return (
    <Container data-testid="categories-container">
      <CategoriesHorizontalSelector />

      <TogglerContainer>
        <Button
          onClick={handleClickSeeAllCategories}
          data-testid="categories-drawer-toggler-button"
        >
          {activeCategory?.name === ALL_PRODUCTS_CATEGORY.name
            ? t('categories.seeMoreCategories')
            : activeCategory?.name}
          <ArrowHeadIcon />
        </Button>
      </TogglerContainer>

      {openDrawer && (
        <CategoriesDrawer
          activeCategory={activeCategory}
          categories={availableCategories}
          handleClose={handleCloseButton}
          isOpen={openDrawer}
          setActiveCategory={setActiveCategory}
        />
      )}
    </Container>
  )
}
