import { Input } from '../Input'
import { useTranslation } from 'react-i18next'
import { FormEvent, useEffect, useState } from 'react'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

import {
  CodeWrapper,
  FlagWrapper,
  ErrorMessage,
  SelectWrapper,
  MainContainer,
  SelectContainer,
  SelectItemWrapper,
  SelectItemContainer,
} from './styled'
import { ICountry } from '../../types/models'
import { CountryInputProps } from './contracts'
import { countries } from '../../config/countries'
import { useStoreConfig } from '../../context/AppContext'

export const PhoneInput = ({
  phone,
  onBlur,
  onFocus,
  onChangePhone,
  defaultCountry,
  onChangeCountry,
}: CountryInputProps): JSX.Element => {
  const { t } = useTranslation()
  const { store }: any = useStoreConfig()
  const [inputError, setInputError] = useState(false)
  const [countrySelected, setCountrySelected] = useState<ICountry>(
    defaultCountry || countries.get(store.country)
  )
  const [firstTimeSelected, setFirstTimeSelected] = useState(false)
  const [phoneDigits, setPhoneDigits] = useState<string>(countrySelected?.name)

  useEffect(() => {
    // This logic assures that calls the current country when the component first renders. Then it can just changed manually
    // This is included because we want to have the current selected country in the father level in case we keep the default store country.
    if (!firstTimeSelected) {
      onChangeCountry(countrySelected)
      setFirstTimeSelected(true)
    }
  }, [countrySelected])

  useEffect(() => {
    const newDefaultCountrie = defaultCountry || countries.get(store.country)
    setCountrySelected(newDefaultCountrie)
    setPhoneDigits(newDefaultCountrie.name)
  }, [defaultCountry])

  const countryCodes = Array.from(countries.values()).map((country) => {
    return {
      name: country.name,
      flag: country.flag.default,
      countryCode: country.code,
      digits: country.digits,
    }
  })
  const handleChange = (event: any) => {
    setPhoneDigits(event.target.value)
    setCountrySelected(countries.get(event.target.value))
    onChangeCountry(countries.get(event.target.value))
  }

  const handleInputChange = (e: FormEvent<HTMLInputElement>): void => {
    const numericValue = String(e.currentTarget.value).replace(/[^0-9]/g, '')
    countrySelected.digits.includes(numericValue.length)
      ? setInputError(false)
      : setInputError(true)
    onChangePhone(numericValue)
  }

  return (
    <MainContainer>
      <SelectContainer>
        <SelectWrapper
          select
          error={inputError}
          id="select-country"
          data-testid="select-country"
          value={phoneDigits}
          onChange={handleChange}
          SelectProps={{
            IconComponent: ExpandMoreIcon,
          }}
        >
          {countryCodes.map((option) => {
            return (
              <SelectItemContainer key={option.name} value={option.name}>
                <SelectItemWrapper>
                  <FlagWrapper alt="ds" src={option.flag} />
                  <CodeWrapper>{option.countryCode}</CodeWrapper>
                </SelectItemWrapper>
              </SelectItemContainer>
            )
          })}
        </SelectWrapper>
        <Input
          append
          type="number"
          onBlur={onBlur}
          onFocus={onFocus}
          id="phone-input"
          error={inputError}
          value={phone || ''}
          data-testid="phone-input"
          onChange={handleInputChange}
          minLength={countrySelected?.digits[0] || 0}
          placeholder={t('phoneInput.phonePlaceHolder')}
          maxLength={
            countrySelected?.digits[countrySelected.digits.length - 1] || 0
          }
        />
      </SelectContainer>
      {inputError && (
        <ErrorMessage variant="XSmall">
          {t('phoneInput.errorMessage', {
            quantity: countrySelected?.digits[0],
          })}
        </ErrorMessage>
      )}
    </MainContainer>
  )
}
