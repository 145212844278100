import styled from 'styled-components'

import { ButtonSizes, ButtonVariants, Button } from '../../../common/Button'
import icon from '../../../../assets/icons/cart.svg'

export const Link = styled.a`
  display: block;
`

export const CustomButton = styled(Button).attrs(() => ({
  variant: ButtonVariants.Primary,
  size: ButtonSizes.Medium,
  iconPath: icon,
}))<{ isEmpty: boolean }>`
  ${({ theme }) =>
    `padding: ${theme.sizes.spacing.xsmall} ${theme.sizes.spacing.small};`}
  ${({ theme, isEmpty }) =>
    isEmpty &&
    `
        padding: ${theme.sizes.spacing.xsmall} ${theme.sizes.spacing.small};
        background: ${theme.colors.neutral400};
        &: hover {
          background: ${theme.colors.neutral400};
        }
    `}
`
