import { ICategory, IProduct } from '../types/models'

export interface GetCategoriesWithProductsProps {
  products: IProduct[]
  categories: ICategory[]
}

export const getCategoriesWithProducts = ({
  products,
  categories,
}: GetCategoriesWithProductsProps): ICategory[] => {
  const categoriesFromProducts = products.map(({ categories }) => categories)
  const filteredCategoriesFromProducts = Array.from(
    new Set(categoriesFromProducts)
  )

  const filteredCategories = categories.filter(({ name }) =>
    filteredCategoriesFromProducts.includes(name)
  )
  return filteredCategories
}

export interface filterCategoriesProps {
  categories: ICategory[]
}
export const filterCategories = ({
  categories,
}: filterCategoriesProps): ICategory[] =>
  categories.filter(({ name }) => name !== '')
