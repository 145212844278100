import styled from 'styled-components'

import { Typography } from '../common/Typography'

export const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  transition: all 0.25s ease;
  opacity: 1;
  ${({ theme }) => `
    padding: 0 ${theme.sizes.spacing.medium} ${theme.sizes.spacing.small};
  `}
`

export const StoreName = styled(Typography).attrs(() => ({
  variant: 'Largebold',
  forwardedAs: 'h1',
}))`
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 28ch;

  ${({ theme }) => `
    color: ${theme.colors.secondary700};
    margin-bottom: ${theme.sizes.spacing.xxsmall};
  `}
`
