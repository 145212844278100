import { ReactElement } from 'react'
import { DetailOrder, ICountry } from '../../types/models'
import { OrderDetailProduct } from '../OrderDetailProduct'

interface Props {
  products: DetailOrder[]
  country: ICountry
}

export const OrderDetailList = ({ products, country }: Props): ReactElement => {
  return (
    <>
      {products.map((product) => (
        <OrderDetailProduct
          key={product.id}
          product={product}
          country={country}
        />
      ))}
    </>
  )
}