import styled from 'styled-components'
import { FormControl } from '@material-ui/core'

import { FontsName } from '../../../styles/fonts'
import { Input } from '../../../components/Input'
import { Typography } from '../../common/Typography'
import { ReactComponent as BackIconImage } from '../../../assets/arrowBack.svg'
import { ReactComponent as CloseIconImage } from '../../../assets/icons/simpleClose.svg'
import { ReactComponent as InputIconImage } from '../../../assets/icons/searchMini.svg'
import { ReactComponent as AlertIconImage } from '../../../assets/icons/alertInfo.svg'
import { ReactComponent as ExclamationIconImage } from '../../../assets/icons/exclamationred-icon.svg'

export const MainContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`
export const WrapperContainer = styled.div`
  padding: ${({ theme: { sizes } }) => sizes.spacing.medium};
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`

export const FormContainer = styled(FormControl)`
  display: flex;
  width: 100%;
  height: auto;
  min-height: 100vh;
  margin-bottom: 1rem;
  flex-direction: column;
`
export const Header = styled.div`
  display: grid;
  grid-template-columns: 35px 1fr 15px;
  margin-top: 1rem;
  margin-bottom: 24px;
`
export const Title = styled(Typography)`
  color: ${({ theme }) => theme.colors.secondary700};
  align-self: center;
`
export const SubTitle = styled(Typography)`
  ${({ theme }) => theme.fonts[FontsName.Nunito].xlargebold};
  margin-bottom: ${({ theme: { sizes } }) => sizes.spacing.medium};
  color: ${({ theme }) => theme.colors.secondary700};
`
export const InputTextForm = styled(Input)`
  border: ${({ theme }) => `0.5px solid ${theme.colors.neutral600}`};
  padding: ${({ theme: { sizes } }) =>
    `${sizes.spacing.xsmall} ${sizes.spacing.medium} `};
  height: auto;
`
export const LabelInputForm = styled.div`
  ${({ theme }) => theme.fonts[FontsName.Nunito].mediumbold}
  margin-bottom: ${({ theme: { sizes } }) => sizes.spacing.xxsmall};
  color: ${({ theme }) => theme.colors.neutral800};
  width: 100%;
`
export const LabelRadio = styled.div`
  margin-left: ${({ theme: { sizes } }) => sizes.spacing.xsmall};
  width: 100%;
`

export const InputContainer = styled.form`
  margin-bottom: ${({ theme: { sizes } }) => sizes.spacing.medium};
  ${({ theme }) => theme.fonts[FontsName.Nunito].medium}
  color: ${({ theme }) => theme.colors.neutral700};
  width: 100%;
`
export const SelectPlaceholder = styled(Typography)`
  ${({ theme }) => theme.fonts[FontsName.Nunito].medium}
  color: ${({ theme }) => theme.colors.neutral700};
`

export const ErrorMessage = styled(Typography)`
  margin-top: ${({ theme: { sizes } }) => sizes.spacing.xxsmall};
  color: ${({ theme }) => theme.colors.error500};
`
export const InfoButton = styled.button`
  ${({ theme }) => theme.fonts[FontsName.Nunito].mediumbold}
  padding: ${({ theme: { sizes } }) =>
    `${sizes.spacing.xsmall} ${sizes.spacing.small} `};
  margin-bottom: ${({ theme: { sizes } }) => sizes.spacing.medium};
  background: ${({ theme }) => theme.colors.primary500};
  color: ${({ theme }) => theme.colors.primary900};
  border: 1px solid rgba(143, 153, 163, 0.2) !important;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  justify-content: center;
  cursor: pointer;
  display: flex;
  width: 100%;
  border: 0;
  :disabled {
    color: ${({ theme }) => theme.colors.neutral600};
    background-color: ${({ theme }) => theme.colors.neutral300};
  }
`
export const BackIcon = styled(BackIconImage)`
  margin-right: 20px;
  color: ${({ theme }) => theme.colors.secondary700};
  align-self: center;
`
export const CloseIcon = styled(CloseIconImage)`
  margin-right: 20px;
  color: ${({ theme }) => theme.colors.secondary700};
  align-self: center;
`
export const InputIcon = styled(InputIconImage)`
  width: ${({ theme: { sizes } }) => sizes.spacing.small};
  min-width: ${({ theme: { sizes } }) => sizes.spacing.small};
  top: ${({ theme: { sizes } }) => sizes.spacing.xlarge};
  left: ${({ theme: { sizes } }) => sizes.spacing.small};
  position: relative;
  height: auto;
  z-index: 9;
`

export const InfoIcon = styled(AlertIconImage)`
  width: ${({ theme: { sizes } }) => sizes.spacing.large};
  min-width: ${({ theme: { sizes } }) => sizes.spacing.large};
  margin-right: ${({ theme: { sizes } }) => sizes.spacing.medium};
`

export const AlertMessage = styled.div`
  ${({ theme }) => theme.fonts[FontsName.Nunito].xsmall};
  background-color: ${({ theme }) => theme.colors.info100};
  padding: ${({ theme: { sizes } }) =>
    `${sizes.spacing.xsmall} ${sizes.spacing.small}`};
  align-items: center;
  display: flex;
  width: 100%;
`

export const TextAlert = styled(Typography)`
  ${({ theme }) => theme.fonts[FontsName.Nunito].small};
  color: ${({ theme }) => theme.colors.info700};
  white-space: pre-line;
  text-align: left;
`
export const ExclamationIcon = styled(ExclamationIconImage)`
  align-self: center;
  margin-bottom: 18px;
  width: 52px;
  height: auto;
  color: green;
  fill: green;
`
