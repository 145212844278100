import styled from 'styled-components'

import { fonts, FontsName, FontVariant, Variants } from '../../../styles/fonts'

export const BaseTypography = styled.p<{
  font: FontsName
  variant: FontVariant
}>`
  ${({ variant, font }) => ({
    ...fonts[font][Variants[variant]],
  })}
`
