import { makeStyles } from '@material-ui/core'
import mainTheme from '../../../styles/theme'

const useStyles = makeStyles({
  root: {
    backgroundColor: '#F2F2F2',
    boxShadow: 'rgb(238 238 238) 0px 0px 3px 0px',
    maxWidth: '500px',
    margin: '0px auto',
    minHeight: `calc(100vh - ${mainTheme.layout.footer.height}px)`,
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    boxSizing: 'border-box',
    justifyContent: 'center',
  },
  gridContainer: {
    width: 'auto',
    zIndex: 10,
  },
  paperImage: {
    width: '300px',
    height: '300px',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    backgroundPosition: 'center',
    backgroundColor: '#F2F2F2',
    backgroundImage: (props: any) => `url(${props.image})`,
  },
  goBackButtonRoot: {
    borderRadius: '15px',
    margin: '0rem 2rem',
  },
  gridContainerImage: {
    marginTop: 30,
  },
  progressContainer: {
    margin: 20,
  },
  progressStyle: {
    width: 100,
    height: 100,
  },
})

export { useStyles }
