import styled from 'styled-components'

import TextField from '@material-ui/core/TextField'

import { Typography } from '../Typography'
import { FontsName } from '../../../styles/fonts'
import { ReactComponent as InputIconImage } from '../../../assets/icons/searchMini.svg'

export const InputContainer = styled.div`
  ${({ theme }) => theme.fonts[FontsName.Nunito].medium}
  color: ${({ theme }) => theme.colors.neutral700};
  width: 100%;
`

export const LabelInputForm = styled.label`
  ${({ theme }) => theme.fonts[FontsName.Nunito].mediumbold}
  margin-bottom: ${({ theme: { sizes } }) => sizes.spacing.xxsmall};
  color: ${({ theme }) => theme.colors.neutral800};
  width: 100%;
`

export const InputSearch = styled(TextField)`
  ${({ theme }) => theme.fonts[FontsName.Nunito].medium}
  padding: ${({ theme: { sizes } }) =>
    `${sizes.spacing.medium} ${sizes.spacing.medium}`};
  color: ${({ theme }) => theme.colors.secondary700};
  background: ${({ theme }) => theme.colors.white};
  flex: 1;
  width: 100%;
  height: 100%;
  margin: 0rem;
  min-height: 22px;
  border-radius: 0.25rem;
`

export const ErrorMessage = styled(Typography)`
  margin-top: ${({ theme: { sizes } }) => sizes.spacing.xxsmall};
  color: ${({ theme }) => theme.colors.error500};
`

export const InputIcon = styled(InputIconImage)`
  width: ${({ theme: { sizes } }) => sizes.spacing.small};
  min-width: ${({ theme: { sizes } }) => sizes.spacing.small};
  margin-right: ${({ theme: { sizes } }) => sizes.spacing.xxxsmall};
  height: auto;
`
