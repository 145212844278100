import { useTranslation } from 'react-i18next'

import { ChevronRight } from '@material-ui/icons'

import mainTheme from '../../styles/theme'
import { countries } from '../../config/countries'
import { ProviderResponse } from '../Delivery/Providers/contracts'
import {
  formatterCurrency,
  convertMillisecondsToMinutes,
} from '../../utils/functions'
import {
  ChevronSection,
  DeliveredAt,
  DeliveredBy,
  Ensurance,
  ImageSection,
  InfoSection,
  PriceSection,
  ProviderContainer,
  ProviderImage,
} from './style'

export const ProviderItem = ({
  provider,
  onSelectProvider,
}: {
  provider: ProviderResponse
  onSelectProvider: (provider: ProviderResponse) => void
}): JSX.Element => {
  const { t } = useTranslation()
  const country = countries.get('Colombia')

  const handleSelectProvider = () => {
    onSelectProvider(provider)
  }

  return (
    <ProviderContainer
      data-testid="provider-list-item"
      onClick={handleSelectProvider}
    >
      <ImageSection>
        <ProviderImage src={provider.provider.image} />
      </ImageSection>

      <InfoSection>
        <DeliveredAt>
          {provider.deliveryInfo.eta > 0 &&
            t('delivery.providers.keepInMind.etaValue', {
              minutes: convertMillisecondsToMinutes(provider.deliveryInfo.eta),
            })}
        </DeliveredAt>
        <Ensurance>
          {provider.deliveryInfo.insured
            ? t('delivery.providers.deliveryEnsurance')
            : t('delivery.providers.deliveryNoEnsurance')}
        </Ensurance>
        <DeliveredBy>
          {t('delivery.providers.deliveredBy')}: {provider.provider.name}
        </DeliveredBy>
      </InfoSection>

      <PriceSection>
        <b>{formatterCurrency(country, provider.deliveryInfo.price)}</b>
      </PriceSection>

      <ChevronSection>
        <ChevronRight htmlColor={mainTheme.colors.neutral500} />
      </ChevronSection>
    </ProviderContainer>
  )
}
