import { ThemeProvider } from 'styled-components'
import App from './App'
import React from 'react'
import ReactDOM from 'react-dom'
import MainTreintaTheme from './styles/theme'
import GlobalStyle from './styles/globalStyles'

// require('dotenv').config()

ReactDOM.render(
  <React.StrictMode>
    <GlobalStyle />
    <ThemeProvider theme={MainTreintaTheme}>
      <App />
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root')
)
