import styled from 'styled-components'

import { FontsName } from '../../../styles/fonts'
import { Typography } from '../../common/Typography'

interface DisabledProp {
  isDisabled: boolean
}

const IMAGE_CONTAINER_HEIGHT = 152

export const MainContainer = styled.li<DisabledProp>`
  background-color: ${({ theme: { colors }, isDisabled }) =>
    isDisabled ? colors.neutral200 : colors.white};
  width: 100%;
  position: relative;
`

export const MetadataContainer = styled.div`
  margin-top: ${({ theme: { sizes } }) => sizes.spacing.small};
  width: 100%;
`

export const QtySelectorContainer = styled.div`
  right: ${({ theme: { sizes } }) => sizes.spacing.small};
  bottom: 4rem;
  position: absolute;
`

export const ProductPictureContainer = styled.div`
  height: ${IMAGE_CONTAINER_HEIGHT}px;
  position: relative;
  width: 100%;

  && .lazy-wrapper {
    height: 100%;
    & > img {
      border-radius: ${({ theme: { sizes } }) => sizes.borderRadius.medium};
      height: 100%;
      margin: 0px;
      object-fit: cover;
      width: 100%;
    }
  }
`

export const ProductPicture = styled.img.attrs({
  height: IMAGE_CONTAINER_HEIGHT,
  width: IMAGE_CONTAINER_HEIGHT,
})``

export const TextDiscount = styled(Typography)`
  width: 36px;
  position: absolute;
  text-align: center;
  color: ${({ theme: { colors } }) => colors.white};
  ${({ theme }) => theme.fonts[FontsName.Nunito].xsmallbold};
  top: ${({ theme: { sizes } }) => `${sizes.spacing.xsmall}`};
  right: ${({ theme: { sizes } }) => `${sizes.spacing.xsmall}`};
  padding: ${({ theme: { sizes } }) => `${sizes.spacing.xxxsmall}`};
  background-color: ${({ theme: { colors } }) => colors.tertiary600};
  border-radius: ${({ theme: { sizes } }) => sizes.borderRadius.medium};
`

export const NotAvailableProduct = styled(Typography).attrs({
  variant: 'XSmall',
})`
  color: ${({ theme }) => theme.colors.error700};
  margin: 0;
  padding: 0;
`
export const AlertLabel = styled(Typography).attrs({
  variant: 'XSmall',
})`
  color: ${({ theme: { colors } }) => colors.error500};
  display: inline-block;
`
