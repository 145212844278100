import styled from 'styled-components'
import { Typography } from '../../../../components/common/Typography'

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: ${({ theme: { sizes } }) => sizes.spacing.large};
  margin-top: ${({ theme: { sizes } }) => sizes.spacing.large};
`

export const Label = styled(Typography).attrs({
  variant: 'Mediumbold',
  forwardedAs: 'h1',
})`
  margin-bottom: ${({ theme: { sizes } }) => sizes.spacing.medium};
`
