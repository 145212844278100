import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import {
  ResumeBanner,
  SectionTextBanner,
  TextDescription,
  TextNumberSents,
  TextCongratulations,
  TextWinner,
  TextTYC,
} from '../styles'
import {
  NUMBER_DELIVERIES_CHALLENGE,
  NUMBER_DELIVERIES_FIRSTCHALLENGE,
} from '../../../Delivery/constants'
import { useSplitIO } from '../../../../hooks/useSplitIO'
import { DeliveryStateType } from '../../../../types/models'
import { useDeliveryUserInformationService } from '../../../../hooks/Shipment/useDeliveryUserInformationService'

interface ResumeBannerProps {
  state: DeliveryStateType
  handleDrawerStatus: (status: boolean) => void
}

const ResumeBannerDelivery = ({
  state,
  handleDrawerStatus,
}: ResumeBannerProps): JSX.Element => {
  const { t } = useTranslation()
  const {
    getDeliveryUser,
    userDeliveries,
    isLoadingUserDeliveries,
  } = useDeliveryUserInformationService({
    authToken: state.authToken,
    storeId: state.storeId,
  })

  const { clientStateOn: challengeStateOn } = useSplitIO(
    'shop_deliveryBannerRewardShopWeb'
  )
  const [pendingDeliveries, setPendingDeliveries] = useState(
    NUMBER_DELIVERIES_CHALLENGE
  )
  const [deliveriesChallenge, setDeliveriesChallenge] = useState(
    NUMBER_DELIVERIES_CHALLENGE
  )

  useEffect(() => {
    // load Counter Deliveries User
    if (state.authToken && state.storeId && !userDeliveries) getDeliveryUser()
  }, [state.authToken, state.storeId])

  // define Pending Deliveries Challenge
  useEffect(() => {
    if (userDeliveries && userDeliveries.totalDelivery >= 0) {
      // first Challenge Values
      if (userDeliveries.totalDelivery <= NUMBER_DELIVERIES_FIRSTCHALLENGE) {
        setPendingDeliveries(
          NUMBER_DELIVERIES_FIRSTCHALLENGE - userDeliveries.totalDelivery
        )
        setDeliveriesChallenge(NUMBER_DELIVERIES_FIRSTCHALLENGE)
      } else {
        // validate User Exceeded Number Challenge delivery months
        const actualDeliveries =
          userDeliveries.totalDeliveryMonthly > 10
            ? userDeliveries.totalDeliveryMonthly % 10
            : userDeliveries.totalDeliveryMonthly
        setPendingDeliveries(NUMBER_DELIVERIES_CHALLENGE - actualDeliveries)
        setDeliveriesChallenge(NUMBER_DELIVERIES_CHALLENGE)
      }
    }
  }, [userDeliveries])

  return (
    <>
      {challengeStateOn && !isLoadingUserDeliveries && (
        <ResumeBanner onClick={() => handleDrawerStatus(true)}>
          <SectionTextBanner>
            {pendingDeliveries > 0 ? (
              <>
                <TextDescription>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: t('delivery.banners.challengeShop.description', {
                        numberDeliveries: deliveriesChallenge,
                      }),
                    }}
                  />
                </TextDescription>
                <TextNumberSents>
                  {t('delivery.banners.challengeShop.deliveryLeft', {
                    numberDeliveries: pendingDeliveries,
                  })}
                </TextNumberSents>
                <TextTYC>* {t('delivery.banners.challengeShop.tyc')}</TextTYC>
              </>
            ) : (
              <>
                <TextCongratulations>
                  {t('delivery.banners.challengeShop.congratulations')}
                </TextCongratulations>
                <TextWinner>
                  {t('delivery.banners.challengeShop.winner', {
                    numberDeliveries: deliveriesChallenge,
                    numberDeliveriesNC: NUMBER_DELIVERIES_CHALLENGE,
                  })}
                </TextWinner>
              </>
            )}
          </SectionTextBanner>
        </ResumeBanner>
      )}
    </>
  )
}
export default ResumeBannerDelivery
