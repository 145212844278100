import { FC, useReducer } from 'react'
import { countries } from '../../config/countries'
import { StoreAuthContext } from './StoreAuthContext'
import { storeAuthReducer } from './StoreAuthReducer'
import { ICountry, StoreAuthStateType } from '../../types/models'
import {
  SET_COUNTRY,
  SET_PHONE,
  SET_NAME,
  SET_USER_HAS_BEEN_CREATED,
} from './constants'

export const initialState: StoreAuthStateType = {
  name: '',
  phone: '',
  userHasBeenCreated: null,
  country: countries.get('Colombia'),
}

export const StoreAuthState: FC = ({ children }) => {
  const [state, dispatch] = useReducer(storeAuthReducer, initialState)

  const setCountry = (country: ICountry) => {
    dispatch({
      payload: country,
      type: SET_COUNTRY,
    })
  }

  const setPhone = (phone: string) => {
    dispatch({
      payload: phone,
      type: SET_PHONE,
    })
  }

  const setName = (name: string) => {
    dispatch({
      payload: name,
      type: SET_NAME,
    })
  }

  const setUserHasBeenCreated = (HasBeenCreated: boolean) => {
    dispatch({
      payload: HasBeenCreated,
      type: SET_USER_HAS_BEEN_CREATED,
    })
  }

  return (
    <StoreAuthContext.Provider
      value={{
        state,
        setName,
        setPhone,
        setCountry,
        setUserHasBeenCreated,
      }}
    >
      {children}
    </StoreAuthContext.Provider>
  )
}
