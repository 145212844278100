import { useState } from 'react'

import {
  AccountDataRequest,
  AccountDataResponse,
  getAccountDataInformation,
  saveAccountDataInformation,
} from '../../services/BankInformation/accountDataService'

interface Request {
  authToken: string
}
interface Response {
  accountData: AccountDataResponse | undefined
  isLoadedAccountData: boolean
  hasErrorAccountData: boolean
  callGetAccountDataInformation: () => void
}
interface ResponseSave {
  savedAccountData: AccountDataResponse | undefined
  callSaveAccountDataInformation: (
    accountDataRequest: AccountDataRequest | undefined
  ) => void
}

// hook Load Account Data
export const useGetAccountDataService = ({ authToken }: Request): Response => {
  const [accountData, setAccountData] = useState<AccountDataResponse>()

  // states Services Hooks
  const [isLoadedAccountData, setIsLoadedAccountData] = useState(false)
  const [hasErrorAccountData, setHasErrorAccountData] = useState(false)

  const callService = () => {
    getAccountDataInformation(authToken)
      .then((accountDataResponse) => {
        setAccountData(accountDataResponse)
        setIsLoadedAccountData(true)
      })
      .catch(() => {
        setIsLoadedAccountData(false)
        setHasErrorAccountData(true)
      })
  }

  return {
    accountData,
    isLoadedAccountData,
    hasErrorAccountData,
    callGetAccountDataInformation: callService,
  }
}

// hook Save Account Data
export const useSaveAccountDataService = (authToken: string): ResponseSave => {
  const [
    savedAccountData,
    setSavedAccountData,
  ] = useState<AccountDataResponse>()

  const callService = (accountDataRequest: AccountDataRequest | undefined) => {
    saveAccountDataInformation(authToken, accountDataRequest).then(
      (accountDataResponse) => {
        setSavedAccountData(accountDataResponse)
      }
    )
  }
  return {
    savedAccountData,
    callSaveAccountDataInformation: callService,
  }
}
