import { useTranslation } from 'react-i18next'

import BottomDrawer from '../common/BottomDrawer'
import { CatalogActiveCategory } from '../../context/Catalog/model'
import { ALL_PRODUCTS_CATEGORY, ICategory } from '../../types/models'
import { List, ListItem, DrawerContent, CategoryLabel } from './styles'

interface CategoriesDrawerProps {
  activeCategory: CatalogActiveCategory
  categories: ICategory[]
  handleClose: () => void
  isOpen: boolean
  setActiveCategory: (category: ICategory) => void
}

export const CategoriesDrawer = ({
  activeCategory,
  categories,
  handleClose,
  isOpen,
  setActiveCategory,
}: CategoriesDrawerProps): JSX.Element => {
  const { t } = useTranslation()

  const handleClickCategory = (category: ICategory) => {
    handleClose()

    if (category.name !== activeCategory?.name) {
      setActiveCategory(category)
      window.scrollTo({
        behavior: 'smooth',
        top: 0,
      })
    }
  }

  return (
    <BottomDrawer
      open={isOpen}
      title={t('categories.self')}
      onClose={handleClose}
    >
      <DrawerContent data-testid="categories-drawer-content">
        <List>
          <ListItem
            data-testid="all-products-item"
            onClick={() => handleClickCategory(ALL_PRODUCTS_CATEGORY)}
          >
            <CategoryLabel isBold>{t('categories.allProducts')}</CategoryLabel>
          </ListItem>

          {categories.map((category) => (
            <ListItem
              data-testid="category-option-at-drawer"
              key={`categoriesSelector.drawer.${category.name}`}
              onClick={() => handleClickCategory(category)}
            >
              <CategoryLabel>{`${category.name} (${category.products})`}</CategoryLabel>
            </ListItem>
          ))}
        </List>
      </DrawerContent>
    </BottomDrawer>
  )
}
