import { CircularProgress } from '@material-ui/core'
import { FC } from 'react'
import { useBottomScrollListener } from 'react-bottom-scroll-listener'
import { LoadingContainer, MainContainer } from './styles'

export interface BottomScrollContainerProps {
  debounceWait?: number
  offset?: number
  onBottomScroll: () => void
  nextPageLoading: boolean
}

const BottomScrollContainer: FC<BottomScrollContainerProps> = ({
  children,
  offset = 0,
  onBottomScroll,
  nextPageLoading = false,
}): JSX.Element => {
  const scrollRef = useBottomScrollListener<HTMLDivElement>(onBottomScroll, {
    offset,
  })

  return (
    <MainContainer data-testid="scrollable-container" ref={scrollRef}>
      {children}
      {nextPageLoading && (
        <LoadingContainer>
          <CircularProgress data-testid="loading-scrollable-container" />
        </LoadingContainer>
      )}
    </MainContainer>
  )
}

export default BottomScrollContainer
