import { useState } from 'react'

import { menuType } from '../../components/common/MenuSelect/contracts'
import { getDocumentTypesList } from '../../services/BankInformation/documentTypesService'

interface Request {
  authToken: string
}
interface Response {
  documentTypesData: menuType[]
  isLoadedDocumentTypes: boolean
  hasErrorDocumentTypes: boolean
  callGetDocumentTypesList: () => void
}

export const useGetDocumentTypesService = ({
  authToken,
}: Request): Response => {
  const [documentTypesData, setDocumentTypesData] = useState<menuType[]>([])

  // states Services Hooks
  const [isLoadedDocumentTypes, setIsLoadedDocumentTypes] = useState(false)
  const [hasErrorDocumentTypes, setHasErrorDocumentTypes] = useState(false)

  const callService = () => {
    getDocumentTypesList(authToken)
      .then((documentTypesDataResponse) => {
        setDocumentTypesData(documentTypesDataResponse)
        setIsLoadedDocumentTypes(true)
      })
      .catch(() => {
        setIsLoadedDocumentTypes(false)
        setHasErrorDocumentTypes(true)
      })
  }

  return {
    documentTypesData,
    isLoadedDocumentTypes,
    hasErrorDocumentTypes,
    callGetDocumentTypesList: callService,
  }
}
