import styled from 'styled-components'
import { FormControl, RadioGroup } from '@material-ui/core'

import { Typography } from '../../../../components/common/Typography'

export const Container = styled.div`
  margin-bottom: ${({ theme }) => theme.sizes.spacing.large};
`

export const Title = styled(Typography).attrs({
  forwardedAs: 'h2',
  variant: 'Mediumbold',
})`
  margin-bottom: ${({ theme }) => theme.sizes.spacing.small};
  color: ${({ theme }) => theme.colors.secondary700};
`

export const Group = styled(RadioGroup).attrs({
  name: 'payment-method-radios',
  defaultValue: 'paymentMethod',
})``

export const CustomFormControl = styled(FormControl)`
  width: 100%;
  .MuiFormGroup-root {
    gap: 16px;
  }
  .MuiTypography-root {
    width: 100%;
  }
  .MuiFormControlLabel-label {
    width: 100%;
  }
`

export const ImgPaymentLogo = styled.img`
  width: 25%;
  margin-left: ${({ theme }) => theme.sizes.spacing.small};
`

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`
export const PaymentLabel = styled(Typography).attrs({
  forwardedAs: 'p',
  variant: 'Medium',
})`
  width: 100%;
`
