import styled from 'styled-components'

import mainTheme from '../../../styles/theme'
import { FontsName } from '../../../styles/fonts'
import { Typography } from '../../common/Typography'
import { ReactComponent as CloseBlackIcon } from '../../../assets/icons/close-black-icon.svg'
import { ReactComponent as CalendarMiniIcon } from '../../../assets/icons/mini-calendar-icon.svg'

export const ButtonStyles = {
  color: mainTheme.colors.error500,
  border: 'none',
  alignSelf: 'center',
}

export const ButtonWidth = {
  maxWidth: 'none',
  width: '100%',
  alignSelf: 'center',
}

export const MainContainer = styled.div`
  width: 100%;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
`

export const Opacity = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.5);
`

export const Content = styled.section`
  width: 100%;
  padding: 16px 16px 0 16px;
  height: 90vh;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 16px 16px 0px 0px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  position: absolute;
  bottom: -20%;
  z-index: 10;
`

export const RelativeWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  max-height: 600px;
  overflow-y: scroll;
  padding-bottom: 5%;
  ::-webkit-scrollbar {
    display: none;
  }
`

export const ProviderImageContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 18px;
`

export const ProviderImage = styled.img`
  border-radius: 50%;
`

export const CloseIcon = styled(CloseBlackIcon)`
  align-self: center;
  position: absolute;
  top: ${({ theme }) => theme.sizes.spacing.xxxsmall};
  right: 0;
  cursor: pointer;
`

export const Title = styled.h2`
  color: ${({ theme }) => theme.colors.secondary700};
  font-size: 1.125rem;
  font-weight: 700;
`

export const ListSection = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 32px;
`

export const ItemLayout = styled.div`
  display: grid;
  grid-template-columns: 20px 1fr;
  margin-bottom: ${({ theme }) => theme.sizes.spacing.xsmall};
`

export const ItemText = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const Label = styled(Typography)`
  font-size: ${({ theme }) => theme.sizes.spacing.small};
  font-weight: 400;
  color: ${({ theme }) => theme.colors.neutral700};
`

export const Value = styled(Typography)`
  font-size: ${({ theme }) => theme.sizes.spacing.small};
  font-weight: 700;
  color: ${({ theme }) => theme.colors.secondary600};
`

export const ButtonsContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  bottom: 0%;
  left: 0;
  padding: 8px 16px;
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: 2px -4px 8px rgba(34, 38, 42, 0.05);
`

export const CalendarIcon = styled(CalendarMiniIcon)`
  align-self: center;
`

export const InfoSection = styled.section`
  margin-top: 16px;
`

export const InfoTitle = styled(Typography)`
  font-size: ${({ theme }) => theme.sizes.spacing.medium};
  font-weight: 700;
  margin-bottom: ${({ theme }) => theme.sizes.spacing.large};
`

export const InfoDescription = styled(Typography)`
  margin-bottom: ${({ theme }) => theme.sizes.spacing.xxxsmall};
  color: ${({ theme }) => theme.colors.neutral600};
  font-size: ${({ theme }) => theme.sizes.spacing.medium};
  white-space: pre-line;
  font-weight: 400;
  ul {
    margin-left: ${({ theme }) => `-${theme.sizes.spacing.small}`};
    list-style-type: disc;
    li {
      list-style: disc;
    }
  }
`

export const InputContainer = styled.form`
  margin-bottom: ${({ theme: { sizes } }) => sizes.spacing.medium};
  ${({ theme }) => theme.fonts[FontsName.Nunito].medium}
  color: ${({ theme }) => theme.colors.neutral700};
  width: 100%;
`
export const LabelRadio = styled.div`
  font-size: ${({ theme }) => theme.sizes.spacing.small};
  margin-left: ${({ theme: { sizes } }) => sizes.spacing.xsmall};
  margin-bottom: ${({ theme: { sizes } }) => sizes.spacing.medium};
  white-space: pre-line;
  width: 100%;
`
export const ErrorMessage = styled(Typography)`
  margin-top: ${({ theme: { sizes } }) => sizes.spacing.xxsmall};
  color: ${({ theme }) => theme.colors.error500};
`
