import { useTranslation } from 'react-i18next'

import { Container, Image, Message } from './styles'
import image from '../../../../assets/errorBox.png'

export const Error = (): JSX.Element => {
  const { t } = useTranslation()
  return (
    <Container>
      <Image src={image} />
      <Message>{t('commons.error')}</Message>
    </Container>
  )
}
