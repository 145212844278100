import styled from 'styled-components'
import { Typography } from '../../../common/Typography'

export const Container = styled.div`
  display: flex;
  align-items: center;
`

export const WrapperIcon = styled.span`
  display: flex;
  ${({ theme }) => `
    margin-right: ${theme.sizes.spacing.xxsmall};
  `}
`

export const Label = styled(Typography).attrs(() => ({
  variant: 'XSmall',
  forwardedAs: 'span',
}))`
  ${({ theme }) => `
      color: ${theme.colors.neutral700};
    `}
`
