import { Grid, Paper, CircularProgress } from '@material-ui/core'
import { useStyles } from './styles'
import LoadingImage from './../../../assets/loading.png'

const LoadingPage = (): JSX.Element => {
  const props: any = {
    image: LoadingImage,
  }
  const classes = useStyles(props)
  return (
    <div data-testid="loading-page" className={classes.root}>
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignContent="center"
        className={classes.gridContainer}
      >
        <Grid item>
          <Grid
            container
            justifyContent="center"
            alignContent="center"
            className={classes.gridContainerImage}
          >
            <Paper elevation={0} className={classes.paperImage} />
          </Grid>
        </Grid>
        <Grid item className={classes.progressContainer}>
          <Grid container justifyContent="center" alignContent="center">
            <CircularProgress className={classes.progressStyle} />
          </Grid>
        </Grid>
      </Grid>
    </div>
  )
}

export default LoadingPage
