import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useRouteMatch } from 'react-router-dom'

import { StepNames } from '../../../types/models'
import { ProviderResponse, ProvidersProps } from './contracts'
import useGAEventTracker from '../../../hooks/useGAEventTracker'
import { ProvidersList } from '../../ProvidersList/ProvidersList'
import { AlertDrawer } from '../../common/AlertDrawer/AlertDrawer'
import { useGetProviders } from '../../../hooks/Shipment/useGetProviders'
import {
  Container,
  Header,
  Icon,
  IconClose,
  MainWrapper,
  Message,
  Title,
  Toaster,
  ToasterIcon,
  AlertDoorIcon,
  LoadingMessage,
  EmptyResponse,
  TextEmptyResponse,
  CarrierIcon,
} from './styles'

const Providers = ({
  state,
  setDeliveryState,
}: ProvidersProps): JSX.Element => {
  const { t } = useTranslation()
  const history = useHistory()
  const { url } = useRouteMatch()
  const GAEventsTracker = useGAEventTracker('webshop')
  const { getProviders, isLoading } = useGetProviders(state)
  const [openAlertDrawer, setOpenAlertDrawer] = useState(false)
  const [providers, setProviders] = useState<ProviderResponse[]>([])

  const getProvidersList = async () => {
    const providers = await getProviders()
    setProviders(providers)
  }

  useEffect(() => {
    getProvidersList()
  }, [])

  const onClose = (): void => {
    GAEventsTracker('shop_webdelivery_loc_quot_close')
    setOpenAlertDrawer(true)
  }

  const handleSelectProvider = (provider: ProviderResponse): void => {
    GAEventsTracker('shop_webdelivery_loc_quot_select')
    setDeliveryState({
      ...state,
      currentStep: StepNames.Summary,
      providerId: String(provider.id),
      selectedMarketRates: {
        ...state.selectedMarketRates,
        deliveryPrice: provider.deliveryInfo.price,
        providerName: provider.provider.name,
        providerId: String(provider.id),
        providerImg: provider.provider.image,
        eta: provider.deliveryInfo.eta,
      },
    })
  }

  const handleCloseAlertDrawer = (): void => {
    setOpenAlertDrawer(false)
  }

  const handleExit = (): void => {
    history.push(url + '/mylist')
    setDeliveryState({
      ...state,
      currentStep: StepNames.Address,
    })
  }

  const handleGoBack = (): void => {
    GAEventsTracker('shop_webdelivery_loc_quot_back')
    setDeliveryState({ ...state, currentStep: state.currentStep - 1 })
  }

  const defineResponseProviders = () => {
    if (providers.length <= 0) {
      GAEventsTracker('shop_webdelivery_loc_quotation_no_cover')
      return (
        <EmptyResponse>
          <CarrierIcon />
          <TextEmptyResponse>
            {t('delivery.providers.withoutCoverageMessage')}
          </TextEmptyResponse>
        </EmptyResponse>
      )
    } else {
      return (
        <ProvidersList
          providers={providers}
          onSelectProvider={handleSelectProvider}
        />
      )
    }
  }

  return (
    <Container>
      {providers.length > 0 && (
        <Toaster>
          <ToasterIcon />
          <Message>{t('delivery.providers.sizeMessage')}</Message>
        </Toaster>
      )}
      <MainWrapper>
        <Header>
          <Icon onClick={handleGoBack} />
          <Title forwardedAs="h1" variant="XLargebold">
            {t('delivery.providers.headerTitle')}
          </Title>
          <IconClose onClick={onClose} />
        </Header>

        {isLoading ? (
          <LoadingMessage>{t('delivery.providers.loading')}</LoadingMessage>
        ) : (
          defineResponseProviders()
        )}
      </MainWrapper>

      {openAlertDrawer && (
        <AlertDrawer
          textTitle={t('delivery.providers.alerts.title')}
          textSbTitle={t('delivery.providers.alerts.message')}
          textConfirm={t('delivery.providers.alerts.confirmation')}
          textLeftButton={t('delivery.providers.alerts.exit')}
          textRightButton={t('delivery.providers.alerts.cancel')}
          onEventLeftButton={handleExit}
          onEventRightButton={handleCloseAlertDrawer}
          onEventOpacitySection={handleCloseAlertDrawer}
          eventLeft={'shop_webdelivery_loc_quot_close_exit'}
          eventRight={'shop_webdelivery_loc_quot_close_cancel'}
          iconAlert={<AlertDoorIcon />}
        />
      )}
    </Container>
  )
}

export default Providers
