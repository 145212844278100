import { useContext } from 'react'

import ProductsSlide from '../ProductsSlide'
import { useStoreConfig } from '../../context/AppContext'
import CatalogContext from '../../context/Catalog/context'
import { ALL_PRODUCTS_CATEGORY } from '../../types/models'
import { useGetDiscountList } from '../../hooks/getDiscountsList.hook'

const ProductsSlideLoad = (): JSX.Element => {
  const { store } = useStoreConfig()
  const {
    state: { activeCategory },
  } = useContext(CatalogContext)
  const { dataDiscounts } = useGetDiscountList(String(store.id))

  if (
    dataDiscounts.length &&
    activeCategory?.name === ALL_PRODUCTS_CATEGORY.name
  ) {
    return (
      <ProductsSlide
        discounts={dataDiscounts}
        data-testid="slide-items-container"
      />
    )
  } else {
    return <></>
  }
}

export default ProductsSlideLoad
