import { useTranslation } from 'react-i18next'
import { RadioGroup, FormControlLabel } from '@material-ui/core'

import {
  InfoSection,
  InfoTitle,
  InputContainer,
  LabelRadio,
  ErrorMessage,
} from './style'
import { GreenRadio } from '../../common/GreenElements'
import useGAEventTracker from '../../../hooks/useGAEventTracker'
import { DepositOptions, DeliveryStateType } from '../../../types/models'

export const DepositTypeForm = ({
  state,
  setDeliveryState,
  depositTypeId,
  setDepositTypeId,
}: {
  depositTypeId: number
  state: DeliveryStateType
  setDeliveryState: (newState: DeliveryStateType) => void
  setDepositTypeId: (newType: number) => void
}): JSX.Element => {
  const { t } = useTranslation()
  const GAEventsTracker = useGAEventTracker('webshop')

  const handleChangeConsignmentType = (
    e: React.ChangeEvent<{ value: string }>
  ): void => {
    setDepositTypeId(Number(e.target.value))
    setDeliveryState({
      ...state,
      depositTypeId: Number(e.target.value),
    })
    const eventName =
      DepositOptions.Express === Number(e.target.value)
        ? 'shop_webdelivery_loc_notes_confirm_bexp'
        : 'shop_webdelivery_loc_notes_confirm_bstan'
    GAEventsTracker(eventName)
  }

  return (
    <InfoSection>
      <InfoTitle>
        {t('delivery.providers.keepInMind.whenWantGetMoneyTitle')}
      </InfoTitle>
      <InputContainer>
        <RadioGroup
          name="radio-buttons-methods"
          onChange={(e) => handleChangeConsignmentType(e)}
        >
          <FormControlLabel
            value={DepositOptions.Express}
            control={
              <GreenRadio
                size="small"
                checked={depositTypeId === DepositOptions.Express}
              />
            }
            label={
              <LabelRadio>
                <div
                  dangerouslySetInnerHTML={{
                    __html: `${t(
                      'delivery.providers.keepInMind.consignmentTypeExpress'
                    )}
                      * ${t(
                        'delivery.providers.keepInMind.consignmentTypeExpressText'
                      )}`,
                  }}
                />
              </LabelRadio>
            }
          />
          <FormControlLabel
            value={DepositOptions.Standard}
            control={
              <GreenRadio
                size="small"
                checked={depositTypeId === DepositOptions.Standard}
              />
            }
            label={
              <LabelRadio>
                <div
                  dangerouslySetInnerHTML={{
                    __html: `${t(
                      'delivery.providers.keepInMind.consignmentTypeStandard'
                    )}
                      ${t(
                        'delivery.providers.keepInMind.consignmentTypeStandardText'
                      )}`,
                  }}
                />
              </LabelRadio>
            }
          />

          {depositTypeId <= 0 && (
            <ErrorMessage variant="XSmall">
              {t('delivery.providers.keepInMind.consignmentTypeError')}
            </ErrorMessage>
          )}
        </RadioGroup>
      </InputContainer>
    </InfoSection>
  )
}
