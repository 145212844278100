import ReactGA from 'react-ga'
import { Helmet } from 'react-helmet'
import TagManager from 'react-gtm-module'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'

import './translations'
import Home from './modules/Home'
import { Container } from './styles'
import gAnalytics from './config/g-analytics'
import gTagManager from './config/g-tag-manager'
import StoreRouter from './routers/StoreRouter'
import { IMetaDescription } from './types/models'
import NotFoundPage from './modules/Pages/NotFound'
import { Footer } from './components/common/Footer'
import { OrdersState } from './context/Orders/state'
import { StoreConfigProvider } from './context/AppContext'
import { DeliveryRouter } from './routers/DeliveryRouter'
import { StoreAuthState } from './context/StoreAuthContext'
import { CatalogState } from './context/ShoppingCartContext'
import { DeliveryState } from './context/Delivery/DeliveryState'
import { ShoppingCartState } from './context/ShoppingCart/state'
import { CatalogState as NewCatalogState } from './context/Catalog/state'

function App(): JSX.Element {
  const { t } = useTranslation()
  const [isDelivery, setIsDelivery] = useState(false)
  const [metaDescription, setMetaDescription] = useState({} as IMetaDescription)

  useEffect(() => {
    if (process.env.NODE_ENV || process.env.NODE_ENV !== 'development') {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      ReactGA.initialize(gAnalytics.GOOGLE.GA_TRACKING_CODE!, {
        debug: false,
        titleCase: false,
        gaOptions: {
          siteSpeedSampleRate: 100,
        },
      })
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      TagManager.initialize({ gtmId: gTagManager.GOOGLE.GTM_TRACKING_CODE! })
    }
  }, [])

  useEffect(() => {
    if (process.env.NODE_ENV || process.env.NODE_ENV !== 'development') {
      ReactGA.ga('send', 'pageview', window.location.pathname)
    }
    setIsDelivery(validateValueInActualPath('delivery'))
  }, [window.location.pathname])

  const validateValueInActualPath = (sectionName: string): boolean => {
    const actualPath = window.location.pathname.toLowerCase()
    const pathSections = actualPath.split('/')
    return pathSections.length > 1 ? pathSections.includes(sectionName) : false
  }

  return (
    <Container>
      {!isDelivery ? (
        <>
          <Helmet>
            <link
              rel="icon"
              type="image/png"
              href={`${metaDescription.imageUrl}`}
            />
            <title>
              {`${
                metaDescription?.store_title
                  ? metaDescription?.store_title + t('header.titleStore')
                  : t('header.titleShop')
              }`}
            </title>
          </Helmet>
          <StoreAuthState>
            <OrdersState>
              <Router>
                <Switch>
                  <Route exact path="/" component={Home} />

                  <StoreConfigProvider>
                    <CatalogState>
                      <NewCatalogState>
                        <ShoppingCartState>
                          <StoreRouter metaDescription={setMetaDescription} />
                        </ShoppingCartState>
                      </NewCatalogState>
                    </CatalogState>
                  </StoreConfigProvider>

                  <Route path="/" component={NotFoundPage} />
                </Switch>
              </Router>
            </OrdersState>
          </StoreAuthState>
          <Footer />
        </>
      ) : (
        <DeliveryState>
          <Router>
            <DeliveryRouter />
          </Router>
        </DeliveryState>
      )}
    </Container>
  )
}
export default App
