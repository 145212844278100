import styled from 'styled-components'
import { colors } from '../../styles/colors'
import { Typography } from '../../components/common/Typography'

export const MainContainer = styled.div`
  width: 100%;
  margin: 0rem;
  height: 100%;
  display: flex;
  height: 100vh;
  padding: 0rem 1rem;
  position: relative;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: ${colors.white};
`
export const ContentIconBack = styled.div`
  left: ${({ theme }) => theme.sizes.spacing.medium};
  cursor: pointer;
  position: absolute;
  top: ${({ theme }) => theme.sizes.spacing.medium};
`
export const Header = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  margin: 3rem 0rem 1rem 0rem;
`
export const ImageHeader = styled.img`
  margin-bottom: ${({ theme }) => theme.sizes.spacing.medium};
  width: 108px;
`
export const TitleHeader = styled(Typography)`
  color: ${colors.secondary700};
`

export const Text = styled(Typography)`
  color: ${colors.neutral800};
`
export const TextPrice = styled(Typography)`
  color: ${({ theme }) => theme.colors.secondary700};
`
export const TextTotal = styled(Typography)`
  color: ${colors.neutral700};
`
export const Title = styled(Typography)`
  text-align: start;
  color: ${colors.secondary700};
`
export const ContainerTotal = styled.div`
  border-top: 0.5px solid ${colors.neutral500};
  padding: 1rem 0rem 1.5rem 0rem;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
`
export const ContentContainer = styled.div`
  flex: 1;
  width: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
  margin-bottom: 30px;
`

export const DetailLine = styled.div`
  display: flex;
  justify-content: space-between;
  &:not(:last-child) {
    margin-bottom: ${({ theme }) => theme.sizes.spacing.small};
  }
`

export const TextTotalPrice = styled(Typography)`
  color: ${({ theme }) => theme.colors.tertiary600};
`
