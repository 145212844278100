import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import {
  Container,
  AlertLabel,
  ProductName,
  ProductInfo,
  TextDiscount,
  ProductImage,
  ProductPrices,
  OriginalPrice,
  DiscountPrice,
  ContainerImage,
  ContainerButton,
} from './styles'
import {
  formatterCurrency,
  calculateDiscountPercent,
} from '../../../../utils/functions'
import {
  ProductInCart,
  ShoppingCartProductsSet,
} from '../../../../context/ShoppingCart/model'
import { ICountry } from '../../../../types/models'
import noPicture from './../../../../assets/empty.svg'
import { STOCK_CONFIG } from '../../../../config/stock-config'
import { useStoreConfig } from '../../../../context/AppContext'
import { compareRangeWithCurrentDay } from '../../../../utils/datefunctions'
import ContextCartQuantitySelector from '../../../CartQuantitySelector/ContextCartQuantitySelector'
import { CART_QUANTITY_SMALL_SHAPE } from '../../../CartQuantitySelector/CartQuantitySelector'

export interface ShoppingListItemProps {
  product: ProductInCart
  country: ICountry
}

export const ShoppingListItem = ({
  product,
  country,
}: ShoppingListItemProps): JSX.Element => {
  const { t } = useTranslation()
  const { stockConfig } = useStoreConfig()
  const { name, price, imageUrl } = product
  const [showAlerLabel, setShowAlerLabel] = useState<boolean>(false)

  const hasActiveDiscount =
    product.discount?.startDate &&
    compareRangeWithCurrentDay(
      product.discount?.startDate,
      product.discount?.endDate
    )

  const handleChange = (productSet: ShoppingCartProductsSet) => {
    const quantity = productSet[product.id]?.quantity || 0
    setShowAlerLabel(quantity >= product.stock)
  }

  return (
    <Container>
      <ContainerImage>
        {hasActiveDiscount && (
          <TextDiscount>
            {calculateDiscountPercent(
              Number(product.discount?.originalPrice),
              Number(product.discount?.finalPrice)
            )}
          </TextDiscount>
        )}
        <ProductImage src={imageUrl || noPicture} />
      </ContainerImage>

      <ProductInfo>
        <ProductName>{name}</ProductName>
        <ProductPrices>
          <OriginalPrice>{formatterCurrency(country, price)}</OriginalPrice>
          {product.discount && (
            <DiscountPrice>
              {formatterCurrency(country, product.discount?.finalPrice)}
            </DiscountPrice>
          )}
        </ProductPrices>
        {showAlerLabel &&
          stockConfig !== STOCK_CONFIG.SHOW_NOT_LIMIT &&
          product.stock > 0 && (
            <AlertLabel>
              {t('shoppingCart.stockAlertLabel', { quantity: product.stock })}
            </AlertLabel>
          )}
      </ProductInfo>
      <ContainerButton>
        <ContextCartQuantitySelector
          product={product}
          shape={CART_QUANTITY_SMALL_SHAPE}
          onProductsSetUpdate={handleChange}
        />
      </ContainerButton>
    </Container>
  )
}
