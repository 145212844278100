import { AxiosRequestConfig } from 'axios'

import httpRequest from '../../components/Delivery/httpClient'

export interface DeliveryInformationRequest {
  authToken: string
  storeId: string
}

export const getDeliveryUserInformation = async ({
  authToken,
  storeId,
}: DeliveryInformationRequest): Promise<any> => {
  const baseUrl = process.env.REACT_APP_TREINTA_MICROSERVICES_API_URL

  const requestData: AxiosRequestConfig = {
    url: `${baseUrl}/delivery/totals/${storeId}`,
    method: 'GET',
  }

  return httpRequest(requestData, authToken)
}
