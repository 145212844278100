export const PAGES = {
  Orders: 3,
  Contact: 2,
  Inventory: 0,
  LandingPage: 1,
}

export const BR_COUNTRY = 'Brasil'

// Get prodducts list default values
export const DEFAULT_PAGE = 1
export const DEFAULT_SIZE = 2000
export const PRODUCT_LIST_DEFAULT_SIZE = 15
