import { OriginIds, TextLabels } from '../../../../config/order'
import { DeliveryMethod } from '../../../../config/delivery-method'
import { ProductInCart } from '../../../../context/ShoppingCart/model'
import { compareRangeWithCurrentDay } from '../../../../utils/datefunctions'
import {
  GetRequestBody,
  Order,
  RedirectToTreintaPayments,
  RedirectToWhatsAppAPI,
} from './types'
import useGAEventTracker from '../../../../hooks/useGAEventTracker'

export const validateExpiredProductPrices = (
  products: ProductInCart[]
): boolean => {
  if (products.length > 0) {
    return products.every((product) => {
      if (product.discount) {
        return compareRangeWithCurrentDay(
          product.discount.startDate,
          product.discount.endDate
        )
      } else {
        return true
      }
    })
  } else {
    return true
  }
}

export const getOrder = (products: ProductInCart[]): Order[] => {
  const order: any = []
  if (products.length > 0) {
    products.forEach(({ id, name, price, quantity, cost }) =>
      order.push({
        id,
        name,
        sale_price: price,
        quantity,
        cost,
      })
    )
  }
  return order
}

export const getRequestBody = ({
  name,
  phone,
  country,
  storeId,
  address,
  comment,
  products,
  areaName,
  totalProducts,
  finalDeliveryPrice,
  selectedPaymentMethod,
  selectedDeliveryMethod,
}: GetRequestBody): any => {
  const order = getOrder(products)

  const transactionDetails = () =>
    order.map(({ id, quantity, sale_price, cost }) => ({
      quantity,
      salePrice: sale_price,
      productId: id,
      cost,
    }))

  const deliveryType = () => {
    switch (selectedDeliveryMethod) {
      case DeliveryMethod.HOME_DELIVERY:
        return 'HOME_DELIVERY'
      case DeliveryMethod.PICK_UP_IN_STORE:
        return 'PICK_UP_IN_STORE'
      default:
        return ''
    }
  }

  const requestBody = {
    contact: {
      name,
      phone,
      country_id: country.id,
      customer_type_id: 2,
      origin_id: 2,
    },
    store: {
      id: storeId,
    },
    transaction: {
      orderAddress: {
        address,
        areaName: areaName || '',
      },
      ...(selectedDeliveryMethod === DeliveryMethod.HOME_DELIVERY && {
        deliveryPrice: Number(finalDeliveryPrice),
      }),
      value: totalProducts,
      description: comment,
      delivery_type: deliveryType(),
      originId: OriginIds.Shop,
      origin_type: TextLabels.OriginType,
      deliveryTypeId: selectedDeliveryMethod,
      paymentTypeId: selectedPaymentMethod,
    },
    transaction_details: transactionDetails(),
  }
  return requestBody
}

export const redirectToWhatsAppAPI = ({
  storeId,
  phoneNumber,
  windowReference,
  isFromMarketplace,
  messageOrderDetails,
}: RedirectToWhatsAppAPI): void => {
  const GAEventsTracker = useGAEventTracker('webshop')
  windowReference?.location.assign(
    `https://api.whatsapp.com/send?phone=${phoneNumber}&text=${encodeURI(
      messageOrderDetails
    )}`
  )

  GAEventsTracker('webshop_order_send', storeId)
  if (isFromMarketplace) {
    GAEventsTracker('marketplace_a_shop_order_send', `${storeId}_marketplace`)
  }

  window.location.reload()
}

export const redirectToTreintaPayments = ({
  windowReference,
  transactionId,
}: RedirectToTreintaPayments): void => {
  windowReference?.location.assign(
    `${process.env.REACT_APP_URL_PAYMENTS}/${transactionId}`
  )

  window.location.reload()
}
