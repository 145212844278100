import { useTranslation } from 'react-i18next'
import { Container, Title } from './styles'

export const Header = (): JSX.Element => {
  const { t } = useTranslation()
  return (
    <Container>
      <Title>{t('orderConfirmed.title')}</Title>
    </Container>
  )
}
