import {
  LayoutType,
  LAYOUT_GRID_TYPE,
  LAYOUT_LIST_TYPE,
} from '../LayoutTypeButton/LayoutTypeButton'
import useGAEventTracker from '../../hooks/useGAEventTracker'
import { MainContainer } from './styles'
import LayoutTypeButton from '../LayoutTypeButton'

export interface LayoutTogglerProps {
  onChange: (newType: LayoutType) => void
  selectedLayout: LayoutType
}

const LayoutToggler = ({
  onChange,
  selectedLayout,
}: LayoutTogglerProps): JSX.Element => {
  const GAEventsTracker = useGAEventTracker('webshop') 

  return(  
    <MainContainer>
      <LayoutTypeButton
        active={selectedLayout === LAYOUT_GRID_TYPE}
        dataTestId="grid-type-button"
        type={LAYOUT_GRID_TYPE}
        onClick={() => {
          if (selectedLayout !== LAYOUT_GRID_TYPE) {
            GAEventsTracker('webshop_grid_view')
            onChange(LAYOUT_GRID_TYPE)
          }
        }}
      />
      <LayoutTypeButton
        active={selectedLayout === LAYOUT_LIST_TYPE}
        dataTestId="list-type-button"
        type={LAYOUT_LIST_TYPE}
        onClick={() => {
          if (selectedLayout !== LAYOUT_LIST_TYPE) {
            GAEventsTracker('webshop_list_view')
            onChange(LAYOUT_LIST_TYPE)
          }
        }}
      />
    </MainContainer>
  )

}


export default LayoutToggler
