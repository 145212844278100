import TagManager from 'react-gtm-module'

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const useGTMEventTracker = () => {
  const trackEvent = (
    eventName = 'gtm_click_default',
    eventType = 'click',
    value = 0
  ) => {
    if (process.env.NODE_ENV || process.env.NODE_ENV !== 'development') {
      TagManager.dataLayer({
        dataLayer: {
          event: eventName,
          eventType,
          value,
        },
      })
    }
  }
  return trackEvent
}

export default useGTMEventTracker
