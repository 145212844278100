import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import { isBRDomain } from '../utils/functions'
import esTranslation from './languages/es.json'
import ptTranslation from './languages/pt.json'

const resources = {
  es: {
    translation: esTranslation,
  },
  pt: {
    translation: ptTranslation,
  },
}

export const PT_LANGUAGE = 'pt'

const languaje = isBRDomain ? 'pt' : navigator?.language?.split('-')[0] || 'es'

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    fallbackLng: 'es',
    resources,
    lng: languaje,

    interpolation: {
      escapeValue: false,
    },
  })

export default i18n
