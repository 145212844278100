import ReactGA from 'react-ga'

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const useGAEventTracker = (category = 'category') => {
  const trackEvent = (action = 'action', label = 'label', value = 0) => {
    if (process.env.NODE_ENV || process.env.NODE_ENV !== 'development') {
      ReactGA.event({
        category,
        action,
        label,
        value,
      })
    }
  }
  return trackEvent
}

export default useGAEventTracker
