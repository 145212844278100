import { FC } from 'react'
import {
  CloseButton,
  Drawer,
  DrawerContent,
  DrawerContentContainer,
  DrawerHeader,
  DrawerTitle,
} from './styles'

export interface BottomDrawerProps {
  open: boolean
  onClose: () => void
  title: string
}

const BottomDrawer: FC<BottomDrawerProps> = ({
  children,
  open,
  onClose,
  title,
}) => (
  <Drawer anchor="bottom" onClose={onClose} open={open}>
    <DrawerContentContainer>
      <DrawerHeader>
        <DrawerTitle>{title}</DrawerTitle>
        <CloseButton
          data-testid="bottom-drawer-close-button"
          onClick={onClose}
        />
      </DrawerHeader>

      <DrawerContent data-testid="bottom-drawer-content">
        {children}
      </DrawerContent>
    </DrawerContentContainer>
  </Drawer>
)

export default BottomDrawer
