import styled from 'styled-components'

import { Typography } from '../../../../components/common/Typography'

export const Container = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
`

export const Message = styled(Typography).attrs({ variant: 'Mediumbold' })`
  color: ${({ theme }) => theme.colors.neutral700};
`

export const Image = styled.img`
  margin: 0px;
  width: 250px;
  height: 250px;
`
