import { ReactElement, useContext, useEffect } from 'react'
import { useBottomScrollListener } from 'react-bottom-scroll-listener'

import { SCROLL_OFFSET } from './contracts'
import OrdersContext from '../../context/Orders/context'
import UserOrdersList from '../../components-v0/UserOrdersList'
import {
  Title,
  Header,
  MainContainer,
  LoadingMessage,
  ContentContainer,
} from './styles'
import { useTranslation } from 'react-i18next'
import { Menu } from '../../components/StoreHeader/components/Menu'

const MyOrders = (): ReactElement => {
  const { t } = useTranslation()
  const {
    state: { loading, orders },
    getMoreOrders,
  } = useContext(OrdersContext)

  useEffect(() => {
    getMoreOrders()
  }, [])

  const scrollRef = useBottomScrollListener<HTMLDivElement>(getMoreOrders, {
    offset: SCROLL_OFFSET,
  })

  return (
    <MainContainer>
      <Header>
        <Menu />
        <Title forwardedAs="h1" variant="Largebold">
          {t('myOrders.title')}
        </Title>
      </Header>

      <ContentContainer ref={scrollRef}>
        <UserOrdersList orders={orders} />
        {loading && (
          <LoadingMessage forwardedAs="p" variant="Small">
            {t('myOrders.loadingMore')}
          </LoadingMessage>
        )}
      </ContentContainer>
    </MainContainer>
  )
}

export default MyOrders
