import { MenuItem, Select } from '@material-ui/core'
import FormControl from '@material-ui/core/FormControl'

import { selectProps } from './contracts'
import {
  InputContainer,
  LabelInputForm,
  SelectInput,
  useStyles,
} from './styles'

const MenuSelect = ({
  id,
  showLabel,
  labelText,
  menuItems,
  displayEmpty,
  defaultValue,
  renderValue,
  onChange,
}: selectProps): JSX.Element => {
  return (
    <FormControl variant="outlined" fullWidth>
      {showLabel && <LabelInputForm>{labelText}</LabelInputForm>}
      <InputContainer>
        <Select
          id={id}
          value={defaultValue}
          onChange={onChange}
          input={<SelectInput />}
          displayEmpty={displayEmpty}
          renderValue={renderValue}
          inputProps={{ 'aria-label': 'Without label' }}
          MenuProps={{
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left',
            },
            transformOrigin: {
              vertical: 'top',
              horizontal: 'left',
            },
            getContentAnchorEl: null,
            classes: { paper: useStyles().select },
          }}
        >
          {menuItems.length > 0 &&
            menuItems.map((item) => (
              <MenuItem value={item.id} key={item.id}>
                {item.name}
              </MenuItem>
            ))}
        </Select>
      </InputContainer>
    </FormControl>
  )
}
export default MenuSelect
