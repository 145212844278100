import { useTranslation } from 'react-i18next'

import { ExclamationIcon } from './styles'
import { REDIRECT_TIME } from '../constants'
import { StepNames } from '../../../types/models'
import { useHistory, useRouteMatch } from 'react-router-dom'
import { SuccessAlert } from '../../common/SuccessAlert'
import { ConfirmShipmentAlertsProps } from '../contracts'
import { AlertDrawer } from '../../common/AlertDrawer/AlertDrawer'

const ConfirmShipmentAlerts = ({
  state,
  setDeliveryState,
  alertShipmentIsOpen,
  alertCancelShipmentIsOpen,
  setAlertShipmentIsOpen,
  setAlertCancelShipmentIsOpen,
  openAlertCancelDrawer,
  handleClickPreventCancelShipment,
  handleClickConfirmCancelShipment,
}: ConfirmShipmentAlertsProps): JSX.Element => {
  const { t } = useTranslation()
  const { url } = useRouteMatch()
  const history = useHistory()

  const handleCloseAlertSuccess = () => {
    setAlertShipmentIsOpen(false)
  }

  const handleCloseAlertCancel = () => {
    setAlertCancelShipmentIsOpen(false)
    history.push(url + '/mylist')
    setDeliveryState({
      ...state,
      currentStep: StepNames.Address,
    })
  }

  return (
    <>
      {/* ALERT SUCCESS SHIPMENT - SHOW RESUME */}
      <SuccessAlert
        title={t('delivery.confirm.deliverySuccessTitle')}
        content={t('delivery.confirm.deliverySuccessText')}
        isOpen={alertShipmentIsOpen}
        istop={true}
        timeout={REDIRECT_TIME}
        onClose={handleCloseAlertSuccess}
      />
      {/* ALERT SUCCESS CANCEL SHIPMENT - SHOW RESUME */}
      <SuccessAlert
        title={t('delivery.confirms.cancelShipment.cancelSuccessTitle')}
        content={t('delivery.confirms.cancelShipment.cancelSuccessText')}
        isOpen={alertCancelShipmentIsOpen}
        istop={true}
        timeout={REDIRECT_TIME}
        onClose={handleCloseAlertCancel}
      />
      {/* ALERT DRAWER - CONFIRM CANCEL SHIPMENT */}
      {openAlertCancelDrawer && (
        <AlertDrawer
          textTitle={t('delivery.confirm.cancelDelivery')}
          textSbTitle={''}
          textConfirm={t('delivery.confirm.alerts.deliveryCancelSbText')}
          textLeftButton={t('delivery.confirm.alerts.cancel')}
          textRightButton={t('delivery.confirm.alerts.send')}
          onEventLeftButton={handleClickConfirmCancelShipment}
          onEventRightButton={handleClickPreventCancelShipment}
          onEventOpacitySection={handleClickPreventCancelShipment}
          eventLeft={'shop_webdelivery_guide_cancel_confirm'}
          eventRight={'shop_webdelivery_guide_cancel_reject'}
          iconAlert={<ExclamationIcon />}
        />
      )}
    </>
  )
}
export default ConfirmShipmentAlerts
