import { Typography } from '../common/Typography'
import { GoBackIcon, MainContainer } from './styles'

export interface ScreensHeaderProps {
  onGoBack: () => void
  label: string
}

const ScreensHeader = ({
  onGoBack,
  label,
}: ScreensHeaderProps): JSX.Element => (
  <MainContainer>
    <GoBackIcon data-testid="go-back-arrow" onClick={onGoBack} />
    <Typography variant="Largebold">{label}</Typography>
  </MainContainer>
)

export default ScreensHeader
