import 'intl'
import 'intl/locale-data/jsonp/es-CO'
import 'intl/locale-data/jsonp/es-MX'
import 'intl/locale-data/jsonp/es-AR'
import 'intl/locale-data/jsonp/es-PE'
import 'intl/locale-data/jsonp/es-VE'
import 'intl/locale-data/jsonp/es-CL'
import 'intl/locale-data/jsonp/es-GT'
import 'intl/locale-data/jsonp/es-EC'
import 'intl/locale-data/jsonp/es-BO'
import 'intl/locale-data/jsonp/es-DO'
import 'intl/locale-data/jsonp/es-HN'
import 'intl/locale-data/jsonp/es-PY'
import 'intl/locale-data/jsonp/es-UY'
import 'intl/locale-data/jsonp/es-SV'
import 'intl/locale-data/jsonp/es-NI'
import 'intl/locale-data/jsonp/es-PA'
import 'intl/locale-data/jsonp/en-US'
import { BR_COUNTRY } from './constants'
import { TextLabels } from '../config/order'
import { PaymentType } from '../config/payment-type'
import { DeliveryMethod } from '../config/delivery-method'
import { ICountry, ServiceTransaction } from '../types/models'

export const formatterCurrency = (
  country: ICountry,
  value: number
): string | number => {
  try {
    const formatter = new Intl.NumberFormat(country.locale, {
      style: 'currency',
      currency: country.currency,
      minimumFractionDigits: 0,
    })
    return formatter.format(value)
  } catch (error) {
    return value
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const removeDuplicates = (originalArray: any, prop: any): Array<any> => {
  const newArray: any = []
  const lookupObject: any = {}

  for (const i in originalArray) {
    lookupObject[originalArray[i][prop]] = originalArray[i]
  }

  for (const i in lookupObject) {
    newArray.push(lookupObject[i])
  }
  return newArray
}

export const deleteTildes = (toDelete: string): string => {
  return toDelete.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
}

export const getOrderDetails = ({
  name,
  order,
  phone,
  address,
  comment,
  country,
  orderNumber,
  totalPayable,
  translations,
  totalProducts,
  deliveryPrice,
  totalDiscounts,
  selectedDeliveryMethod,
  selectedPaymentMethod,
}: {
  order: any[]
  name: string
  phone: string
  address: string
  comment: string
  country: ICountry
  translations: any
  deliveryPrice: any
  orderNumber: string
  totalPayable: number
  totalProducts: number
  totalDiscounts: number
  selectedDeliveryMethod: any
  selectedPaymentMethod: PaymentType
}): string => {
  const itemsOrder = () => {
    let items = ''
    for (const item of order) {
      items += `\n${item.name}\t\tX${item.quantity}`
    }
    return items
  }

  const getDeliveryPrice = () => {
    return deliveryPrice
      ? formatterCurrency(country, deliveryPrice)
      : TextLabels.DefaultPrice
  }

  const getTotalDiscounts = () => {
    return formatterCurrency(country, totalDiscounts ? -totalDiscounts : 0)
  }

  const getTotalProducts = () => {
    return totalProducts ? formatterCurrency(country, totalProducts) : 0
  }

  const {
    welcome,
    contactInfo,
    contactName,
    contactPhone,
    contactAddress,
    contactComment,
    contactOrderItems,
    contactOrderPrice,
    contactOrderNumber,
    contactTotalPayable,
    contactDeliveryType,
    contactDiscountPrice,
    contactDeliveryPrice,
    contactDeliveryTypeOptions,
  } = translations

  const getPaymentMethodMessage = () => {
    switch (selectedPaymentMethod) {
      case PaymentType.AgainstDelivery:
        return `\n${translations.paymentMethodAgainstDelivery}`
      case PaymentType.PaymentLink:
        return `\n${translations.paymentMethodPaymentLink}`
      case PaymentType.CoordinateWithTheClient:
        return `\n${translations.paymentMethodCoordinateWithTheClient}`
      case PaymentType.Pending:
      default:
        return ''
    }
  }

  const messageObject = {
    welcome: welcome,
    orderNumber: contactOrderNumber.replace('_orderNumber_', orderNumber),
    contactInfo: contactInfo,
    contactName: contactName.replace('_name_', name),
    contactPhone: contactPhone.replace('_phone_', phone),
    contactAddress:
      address.length > 0 ? contactAddress.replace('_address_', address) : '',
    contactComment:
      comment.length > 0 ? contactComment.replace('_comment_', comment) : '',
    textPaymentMethod: getPaymentMethodMessage(),
    contactDeliveryType: contactDeliveryType,
    contactDeliveryTypeValue:
      selectedDeliveryMethod === DeliveryMethod.HOME_DELIVERY
        ? contactDeliveryTypeOptions.delivery
        : contactDeliveryTypeOptions.pickUp,
    contactOrderItems: contactOrderItems.replace('_items_', itemsOrder()),
    totalProducts: contactOrderPrice.replace('_products_', getTotalProducts()),
    deliveryPrice:
      selectedDeliveryMethod === DeliveryMethod.HOME_DELIVERY
        ? contactDeliveryPrice.replace('_price_', getDeliveryPrice())
        : '',
    totalDiscounts:
      getTotalDiscounts() > 0
        ? contactDiscountPrice.replace('_discount_', getTotalDiscounts())
        : '',
    totalPayable: contactTotalPayable.replace(
      '_total_',
      formatterCurrency(country, Number(totalPayable))
    ),
  }

  return Object.values(messageObject).join(' ')
}

export const waitFor = async (time: number): Promise<undefined> => {
  return new Promise((resolve) => setTimeout(resolve, time))
}

export const removeDuplicatesById = (items: any[]): any[] => {
  const idSet: { [key: string]: boolean } = {}
  const noDuplicatesArray: any[] = []

  items.forEach((item) => {
    if (!idSet[item.id]) {
      noDuplicatesArray.push(item)
      idSet[item.id] = true
    }
  })

  return noDuplicatesArray
}

export const getAddressOrderDetail = ({
  transaction,
}: {
  transaction: ServiceTransaction
}): ServiceTransaction => {
  if (!transaction) {
    return {
      order_address: '',
      delivery_price: 0,
      delivery_type_id: 0,
      transaction_count: '',
      transaction_status_id: 0,
    }
  }
  if (transaction?.order_address) {
    let address = ''
    try {
      const orderAddress = JSON.parse(transaction.order_address)
      address = orderAddress.address
    } catch {
      address = transaction.order_address?.address
    }

    return {
      ...transaction,
      order_address: address,
    }
  }
  return { ...transaction, order_address: '' }
}

export const convertMillisecondsToMinutes = (msValue: number): number =>
  Math.floor(msValue / 60000)

export const calculateDiscountPercent = (
  originalPrice: number,
  finalPrice: number
): string => {
  return finalPrice < originalPrice
    ? `-${Math.round(100 - (finalPrice * 100) / originalPrice)}%`
    : '0%'
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const generateContentText = (objectContent: any): string => {
  let textContent = ''
  Object.entries(objectContent).forEach(([, content]) => {
    textContent += content
  })
  return textContent
}

export const isBRDomain =
  window.location.origin === process.env.REACT_APP_BR_DOMAIN

export const isBRStore = (country: string): boolean => country === BR_COUNTRY
