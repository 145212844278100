export const layout = {
  footer: {
    height: 78,
  },
  stickyBottomContainer: {
    height: 56,
  },
}

export type Layout = {
  footer: typeof layout.footer
  stickyBottomContainer: typeof layout.stickyBottomContainer
}
