import ProductAsListItem from '../../Product/ProductAsListItem'
import { ProductsCatalogProps } from '../types'
import { MainContainer } from './styles'

const ProductsList = ({
  country,
  products,
}: ProductsCatalogProps): JSX.Element => {
  return (
    <MainContainer>
      {products.map((product) => (
        <ProductAsListItem
          country={country}
          product={product}
          key={`productList.item.${product.id}`}
        />
      ))}
    </MainContainer>
  )
}

export default ProductsList
