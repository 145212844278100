import { FC, useReducer } from 'react'
import OrdersReducer, {
  PUSH_ORDERS,
  SET_NEXT_PAGE,
  TURN_OFF_LOADER,
  TURN_ON_LOADER,
} from './reducer'
import OrdersContext from './context'
import { OrdersState as IOrdersState } from './model'
import { fetchOrders } from './services'

const initialState: IOrdersState = {
  nextPage: 1,
  loading: false,
  orders: [],
}

export const OrdersState: FC = ({ children }) => {
  const [state, dispatch] = useReducer(OrdersReducer, initialState)

  const getOrders = async () => {
    if (!state.loading && state.nextPage) {
      dispatch({
        payload: {},
        type: TURN_ON_LOADER,
      })

      try {
        const { items, nextPage } = await fetchOrders(state.nextPage)

        dispatch({
          payload: items,
          type: PUSH_ORDERS,
        })

        dispatch({
          payload: nextPage,
          type: SET_NEXT_PAGE,
        })
      } catch (error) {
        console.error('Error fetching user orders: ', error)
      }

      dispatch({
        payload: {},
        type: TURN_OFF_LOADER,
      })
    }
  }

  const getMoreOrders = () => {
    getOrders()
  }

  return (
    <OrdersContext.Provider
      value={{
        getMoreOrders,
        getOrders,
        state,
      }}
    >
      {children}
    </OrdersContext.Provider>
  )
}
