import { useEffect } from 'react'
import { Route, Switch, useRouteMatch } from 'react-router-dom'

import firebase from '../firebase'
import { PAGES } from '../utils/constants'
import { SignUpLoad } from '../modules/SignUp'
import { useStoreConfig } from '../context/AppContext'
import { OrderDetailLoad } from '../modules/OrderDetail'
import { ProtectedRoute } from '../components-v0/ProtectedRoute'
import { MyOrdersLoad } from '../modules/MyOrders/MyOrders.load'

export const OrdersRouter = (): JSX.Element => {
  const { path, url } = useRouteMatch()
  const { setActiveSection, setIsUserAuthenticated } = useStoreConfig()

  const checkAuth = async () => {
    await setActiveSection(PAGES.Orders)
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        setIsUserAuthenticated(true)
      } else {
        setIsUserAuthenticated(false)
      }
    })
  }

  useEffect(() => {
    checkAuth()
  }, [])

  return (
    <>
      <Switch>
        <ProtectedRoute
          exact
          path={path}
          redirectPath={url}
          component={MyOrdersLoad}
          unAuthRedirectPath={`${url}/signin`}
        />
        <Route path={`${path}/signin`} component={SignUpLoad} />
        <ProtectedRoute
          exact
          path={`${path}/:orderId`}
          redirectPath={url}
          component={OrderDetailLoad}
          unAuthRedirectPath={`${url}/signin`}
        />
      </Switch>
    </>
  )
}
