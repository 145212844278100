import styled from 'styled-components'
import { Dialog } from '@material-ui/core'

import { Typography } from '../../components/common/Typography'
import { ReactComponent as IconGoBack } from './../../assets/arrowBack.svg'

export const CustomDialog = styled(Dialog)`
  .MuiDialog-paper {
    margin: 0;
    display: flex;
    max-width: 500px;
    border-radius: 0;
    flex-direction: column;
  }
`
export const Container = styled.div<{
  isDeliveryHome: boolean
}>`
  overflow-y: inherit;
  padding: 0rem 1rem;
  margin-bottom: 6rem;
`
export const Header = styled.div`
  display: flex;
  margin-top: 1rem;
  align-items: center;
  margin-bottom: 0.5rem;
`
export const Icon = styled(IconGoBack)`
  margin-right: 20px;
  color: ${({ theme }) => theme.colors.secondary700};
`
export const Title = styled(Typography)`
  color: ${({ theme }) => theme.colors.secondary700};
`
