import styled from 'styled-components'
import { TextValueType } from './contracts'
import { Typography } from '../../../../components/common/Typography'

export const Container = styled.div`
  bottom: 0px;
  width: 100%;
  padding: 1rem;
  max-width: 500px;
  position: absolute;
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: 0px -5px 10px 0px rgba(0, 0, 0, 0.05);
`
export const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  margin-bottom: 0.5rem;
`
export const Button = styled.button`
  width: 100%;
  height: 40px;
  border: none;
  display: flex;
  cursor: pointer;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5rem;
  align-items: center;
  text-transform: none;
  border-radius: 0.25rem;
  justify-content: center;
  color: ${({ theme }) => theme.colors.primary900};
  background-color: ${({ theme }) => theme.colors.primary500};
  :disabled {
    color: ${({ theme }) => theme.colors.neutral600};
    background-color: ${({ theme }) => theme.colors.neutral300};
  }
  .icon {
    height: 1rem;
    line-height: 1.5rem;
  }
`
export const TextContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 0.75rem;
  justify-content: space-between;
`
export const TextValue = styled(Typography).attrs({
  variant: 'Mediumbold',
})<TextValueType>`
  color: ${({ theme: { colors }, special, isDiscount }) =>
    special
      ? colors.tertiary600
      : isDiscount
      ? colors.error500
      : colors.secondary700};
`
export const Message = styled(Typography).attrs({ variant: 'Small' })`
  text-align: center;
  color: ${({ theme: { colors } }) => colors.neutral800};
`
