import { useTranslation } from 'react-i18next'
import { useRouteMatch, useHistory } from 'react-router-dom'

import { ExclamationIcon } from './styles'
import { REDIRECT_TIME } from '../constants'
import { StepNames } from '../../../types/models'
import { SuccessAlert } from '../../common/SuccessAlert'
import { BankInformationAlertsProps } from '../contracts'
import { AlertDrawer } from '../../common/AlertDrawer/AlertDrawer'

const FormBankInformationFieldsAlerts = ({
  state,
  setDeliveryState,
  setAlertInfoBankIsOpen,
  alertInfoBankIsOpen,
  openAlertWsaveDrawer,
  handleClickExitEditForm,
  handleClickContinueEditForm,
}: BankInformationAlertsProps): JSX.Element => {
  const history = useHistory()
  const { t } = useTranslation()
  const { url } = useRouteMatch()

  const callbackAlertSuccess = () => {
    let nextStep = StepNames.Address
    if (state.isNewDelivery && state.currentDeliveryId) {
      // reset Step
      nextStep = StepNames.Confirm
    } else {
      // go To My Delivery
      history.push(url + '/mylist')
    }
    setDeliveryState({
      ...state,
      currentStep: nextStep,
    })
  }

  return (
    <>
      {/* ALERT SUCCESS - BANK INFORMATION SAVED */}
      <SuccessAlert
        title={t('delivery.sectionForm.bankFormModalAccountSaved')}
        content={t('delivery.sectionForm.bankFormModalAccountSavedText')}
        isOpen={alertInfoBankIsOpen}
        istop={true}
        timeout={REDIRECT_TIME}
        timeoutCallback={callbackAlertSuccess}
        onClose={() => {
          setAlertInfoBankIsOpen(false)
        }}
      />

      {/* ALERT DRAWER - CONFIRM CANCEL SHIPMENT */}
      {openAlertWsaveDrawer && (
        <AlertDrawer
          textTitle={t('delivery.drawers.dontSaveAccount.title')}
          textSbTitle={''}
          textConfirm={t('delivery.drawers.dontSaveAccount.content')}
          textLeftButton={t('delivery.confirm.alerts.exit')}
          textRightButton={t('delivery.confirm.alerts.edit')}
          onEventLeftButton={handleClickExitEditForm}
          onEventRightButton={handleClickContinueEditForm}
          onEventOpacitySection={handleClickContinueEditForm}
          eventLeft={'shop_webdelivery_guide_cancel_confirm'}
          eventRight={'shop_webdelivery_guide_cancel_reject'}
          iconAlert={<ExclamationIcon />}
        />
      )}
    </>
  )
}
export default FormBankInformationFieldsAlerts
