import { ButtomWrapper, CustomButton, Icon } from './styles'
import iconWhatsapp from '../../assets/iconWhatsapp.svg'

export const WhatsappButton = ({
  handleClick,
  productsNumber
}: {
  handleClick: () => void
  productsNumber: number
}): JSX.Element => {
  const isEmpty = productsNumber === 0  
  return (
    <ButtomWrapper isEmpty={isEmpty}>
      <CustomButton data-testid="buttonWhatsapp" onClick={handleClick}>
        <Icon src={iconWhatsapp} alt="whatsapp" />
      </CustomButton>
    </ButtomWrapper>
  )
}
