import { useEffect } from 'react'
import { Container, CustomDialog, Logo, SubTitle, Title } from './styles'

export interface BaseSuccessAlertProps {
  content: string | React.ReactElement<any>
  isOpen: boolean
  onClose: () => void
  title: string
  istop: boolean
}

export interface TimedOutAlertProps extends BaseSuccessAlertProps {
  timeout: number
  timeoutCallback?: () => void
}

export type SuccessAlertProps = BaseSuccessAlertProps | TimedOutAlertProps

export const SuccessAlert = (props: SuccessAlertProps): JSX.Element => {
  const { content, isOpen, onClose, title, istop } = props

  useEffect(() => {
    if (isOpen && 'timeout' in props && props.timeout) {
      setTimeout(() => {
        onClose()
        if (props.timeoutCallback) {
          props.timeoutCallback()
        }
      }, props.timeout)
    }
  }, [isOpen])

  return (
    <CustomDialog open={isOpen} onClose={onClose} istop={istop}>
      <Container data-testid="success-alert-container">
        <Logo />
        <Title forwardedAs="h1" variant="XLargebold">
          {title}
        </Title>
        <SubTitle forwardedAs="p" variant="Small">
          <div
            dangerouslySetInnerHTML={{
              __html: String(content),
            }}
          />
        </SubTitle>
      </Container>
    </CustomDialog>
  )
}
