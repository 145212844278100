import { useTranslation } from 'react-i18next'
import { CircularProgress } from '@material-ui/core'

import {
  Button,
  Subtitle,
  SignUpMessage,
  NameContainer,
  FormContainer,
  PhoneContainer,
  InputContainer,
  ButtonContainer,
} from '../styles'
import { SignUpFormType } from '../contracts'
import { Input } from '../../../components/Input'
import { PhoneInput } from '../../../components/PhoneInput'
import useGAEventTracker from '../../../hooks/useGAEventTracker'

export const SignUpForm = ({
  name,
  phone,
  isLoading,
  handleNext,
  handleName,
  isDisabled,
  handlePhone,
  handleCountry,
}: SignUpFormType): JSX.Element => {
  const { t } = useTranslation()
  const GAEventsTracker = useGAEventTracker('webshop')
  const trackPhone = () => {
    GAEventsTracker('webshop_track_phone')
  }

  const trackName = () => {
    GAEventsTracker('webshop_track_name')
  }
  return (
    <>
      <Subtitle>{t('signUp.subtitle')}</Subtitle>
      <InputContainer
        onSubmit={(event) => {
          event.preventDefault()
          GAEventsTracker('webshop_track_otp')
          handleNext()
        }}
      >
        <NameContainer>
          <FormContainer>
            <Input
              value={name}
              onBlur={trackName}
              onChange={handleName}
              placeholder={t('signUp.namePlaceHolder')}
            />
          </FormContainer>
        </NameContainer>
        <PhoneContainer>
          <PhoneInput
            phone={phone}
            onBlur={trackPhone}
            onChangePhone={handlePhone}
            onChangeCountry={handleCountry}
          />
        </PhoneContainer>
        <SignUpMessage>{t('signUp.message')}</SignUpMessage>
        <ButtonContainer>
          <Button
            type="submit"
            data-testid="singup-button"
            disabled={isDisabled || isLoading}
          >
            {isLoading ? <CircularProgress size={24} /> : t('signUp.button')}
          </Button>
        </ButtonContainer>
      </InputContainer>
    </>
  )
}
