import { useEffect, useState } from 'react'

import {
  ServicePaymentMethod,
  getEnablePaymentMethods,
  Props as PropsStatusService,
} from '../services/enablePaymentMethods'

interface Response {
  isLoading: boolean
  enablePaymentMethods: ServicePaymentMethod[] | undefined
}

export const useGetEnablePaymentMethods = ({
  storeId,
}: PropsStatusService): Response => {
  const [isLoading, setIsLoading] = useState(false)
  const [enablePaymentMethods, setEnablePaymentMethods] = useState<
    ServicePaymentMethod[]
  >()

  useEffect(() => {
    if (!storeId) {
      return
    }
    setIsLoading(true)
    getEnablePaymentMethods({ storeId })
      .then((statusService) => {
        setEnablePaymentMethods(statusService)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }, [storeId])

  return { enablePaymentMethods, isLoading }
}
