import Axios from 'axios'

import { ICategory } from '../types/models'

export const getCategoriesList = async (
  storeId: string
): Promise<ICategory[]> => {
  try {
    const response = await Axios({
      headers: {
        'X-API-Key': process.env.REACT_APP_TREINTA_MICROSERVICES_API_KEY,
      },
      url: `${process.env.REACT_APP_TREINTA_MICROSERVICES_API_URL}/shop/products/categories?storeId=${storeId}`,
    })
    const { data } = response
    return data
  } catch (error: any) {
    return error
  }
  return []
}
