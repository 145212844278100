import { Dialog } from '@material-ui/core'

import styled from 'styled-components'
import { Typography } from '../Typography'
import { ReactComponent as RegisterSuccess } from '../../../assets/registerSuccess.svg'

const DIALOG_WIDTH = 328

export const CustomDialog = styled(Dialog)<{ istop: boolean }>`
  .MuiDialog-paperScrollPaper {
    border-radius: ${({ theme: { sizes } }) => sizes.borderRadius.xxlarge};
    position: ${({ istop }) => (istop ? 'absolute' : 'relative')};
    top: ${({ istop }) => (istop ? '3rem' : '')};
    max-width: ${DIALOG_WIDTH}px;
  }
`
export const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  padding: ${({ theme: { sizes } }) => sizes.spacing.large};
`
export const Logo = styled(RegisterSuccess)`
  margin-bottom: ${({ theme: { sizes } }) => sizes.spacing.medium};
`
export const Title = styled(Typography)`
  color: ${({ theme }) => theme.colors.primary900};
  margin-bottom: ${({ theme: { sizes } }) => sizes.spacing.small};
  text-align: center;
  width: 100%;
`
export const SubTitle = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral800};
  text-align: center;
  width: 100%;
`
