import { useState } from 'react'
import { ProviderResponse } from '../../components/Delivery/Providers/contracts'
import { getProviderListService } from '../../services/Shipment/providerList'
import { DeliveryStateType } from '../../types/models'

interface GetProvidersReturnType {
  getProviders: () => Promise<ProviderResponse[]>
  isLoading: boolean
}

export const useGetProviders = (
  state: DeliveryStateType
): GetProvidersReturnType => {
  const [isLoading, setIsLoading] = useState(false)
  const { origin, destination, authToken } = state

  const getProviders = async (): Promise<ProviderResponse[]> => {
    const parsedData = {
      origin: {
        address: origin.address,
        comments: '',
        latitude: origin.latitude,
        longitude: origin.longitude,
        country_id: origin.country_id,
        city_id: origin.city_id,
      },
      destination: {
        address: destination.address,
        comments: '',
        latitude: destination.latitude,
        longitude: destination.longitude,
        country_id: destination.country_id,
        city_id: destination.city_id,
      },
    }

    setIsLoading(true)
    const providers = await getProviderListService({
      authToken,
      origin: parsedData.origin,
      destination: parsedData.destination,
    })
    setIsLoading(false)
    return providers
  }

  return { getProviders, isLoading }
}
