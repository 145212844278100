import { useTranslation } from 'react-i18next'
import { useContext, useEffect, useRef } from 'react'

import {
  BuyerComments,
  CheckoutProducts,
  FormDeliveryMethod,
  FormPersonalInformation,
} from './components'
import { CheckoutStepperProps } from './contracts'
import useGAEventTracker from '../../hooks/useGAEventTracker'
import { DeliveryMethod } from '../../config/delivery-method'
import { useCatalogState } from '../../context/ShoppingCartContext'
import { Container, CustomDialog, Header, Icon, Title } from './styled'
import { CheckoutStepperFooterLoad } from './components/CheckoutStepperFooter'
import { ShoppingCartContext } from '../../context/ShoppingCartContext/ShoppingCartContext'
import { FormPaymentMethodLoad } from './components/FormPaymentMethod/FormPaymentMethod.load'

export const CheckoutStepper = ({
  isOpen,
  onClose,
}: CheckoutStepperProps): JSX.Element => {
  const { t } = useTranslation()
  const {
    state: { selectedDeliveryMethod, deliveryAddress, selectedDeliveyArea },
  } = useCatalogState()
  const {
    setCheckoutStep,
    state: { checkoutStep },
  } = useContext(ShoppingCartContext)

  const getStep = () => {
    switch (checkoutStep) {
      case 1:
        return (
          <>
            <FormPersonalInformation />
            <BuyerComments />
            <CheckoutProducts />
          </>
        )
      case 2:
        return <FormDeliveryMethod />
      case 3:
        return <FormPaymentMethodLoad />
      default:
        return <>1</>
    }
  }

  const GAEventsTracker = useGAEventTracker('webshop')
  const containerRef = useRef<HTMLDivElement | null>(null)
  const isDeliveryHome =
    Number(selectedDeliveryMethod) === DeliveryMethod.HOME_DELIVERY

  useEffect(() => {
    window.scrollTo({ top: 0 })
    setTimeout(() => {
      containerRef?.current?.scrollIntoView()
    }, 0)
  }, [])

  const goBackEvent = (step: number) => {
    switch (step) {
      case 2:
        if (
          selectedDeliveryMethod == DeliveryMethod.HOME_DELIVERY &&
          deliveryAddress &&
          !selectedDeliveyArea
        ) {
          GAEventsTracker('webshop_goback_deliveryinfo')
          break
        }
        if (
          selectedDeliveryMethod == DeliveryMethod.HOME_DELIVERY &&
          selectedDeliveyArea
        ) {
          GAEventsTracker('webshop_goback_deliverylocation')
          break
        }
        GAEventsTracker('webshop_goback_deliverytype')
        break
      case 3:
        GAEventsTracker('webshop_goback_sendorder')
        break
      default:
        break
    }
  }

  const handleBack = () => {
    if (checkoutStep === 1) {
      GAEventsTracker('webshop_goback_order')
      GAEventsTracker('webshop_goback_personaldata')
      onClose()
    } else {
      goBackEvent(checkoutStep)
      setCheckoutStep(checkoutStep - 1)
    }
  }

  return (
    <CustomDialog
      fullScreen
      open={isOpen}
      scroll="paper"
      onClose={() => {
        GAEventsTracker('webshop_go_back_cart')
        onClose()
      }}
    >
      <Container ref={containerRef} isDeliveryHome={isDeliveryHome}>
        <Header>
          <Icon onClick={handleBack} />
          <Title forwardedAs="h1" variant="XLargebold">
            {t('header.orderSummary')}
          </Title>
        </Header>
        {getStep()}
      </Container>
      <CheckoutStepperFooterLoad />
    </CustomDialog>
  )
}
