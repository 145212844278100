import styled from 'styled-components'
import BottomScrollContainer from '../common/BottomScrollContainer'
import { Typography } from '../common/Typography'

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100;
  padding: ${({ theme: { sizes } }) =>
    `${sizes.spacing.medium} ${sizes.spacing.medium} ${sizes.spacing.xsmall}`};
`

export const ButtonsContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: ${({ theme }) => theme.sizes.spacing.xsmall};
  width: 100%;
`

export const SortSelectorContainer = styled.div`
  height: 42px;
`

export const LayoutSelectorContainer = styled.div`
  width: 80px;
  z-index: 0;
`

export const CatalogContainer = styled(BottomScrollContainer)``

export const LoadingLabel = styled(Typography)`
  text-align: center;
`

export const MessageNotFound = styled(Typography).attrs(() => ({
  forwardedAs: 'h4',
}))`
  text-align: center;
  margin-top: ${({ theme }) => theme.sizes.spacing.large};
`
