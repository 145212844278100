import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useRouteMatch } from 'react-router-dom'

import { MainContainer } from './styles'
import Loading from '../../common/Loading'
import { countries } from '../../../config/countries'
import ConfirmShipmentResume from './ConfirmShipmentResume'
import ConfirmShipmentAlerts from './ConfirmShipmentAlerts'
import { shareMessageWhatsApp } from '../../../utils/share'
import useGAEventTracker from '../../../hooks/useGAEventTracker'
import { PaymentMethodOptions, StepNames } from '../../../types/models'
import { InfoDeliveryProps, initialState } from '../../Delivery/contracts'
import { ToastMessageAlert } from '../../common/ToastMessage/ToastMessageAlert'
import { useCancelShipmentService } from '../../../hooks/Shipment/useCancelShipmentService'
import { useGetShipmentResumeService } from '../../../hooks/Shipment/useGetShipmentResumeService'

const ConfirmShipment = ({
  state,
  setDeliveryState,
}: InfoDeliveryProps): JSX.Element => {
  const { t } = useTranslation()
  const { url } = useRouteMatch()
  const history = useHistory()
  const GAEventsTracker = useGAEventTracker('webshop')
  const [requestCancel, setRequestCancel] = useState({
    deliveryId: '',
    providerId: 0,
  })
  // component States
  const [alertShipmentIsOpen, setAlertShipmentIsOpen] = useState(false)
  const [alertCancelShipmentIsOpen, setAlertCancelShipmentIsOpen] = useState(
    false
  )
  const [openAlertCancelDrawer, setOpenAlertCancelDrawer] = useState(false)
  // states Services Hooks
  const [, setIsLoadedCancelShipment] = useState(false)
  const [, setHasErrorCancelShipment] = useState(false)
  // call Hooks Services
  const {
    resumeShipmentData,
    isLoadedResumeShipment,
    callGetShipmentResume,
  } = useGetShipmentResumeService({
    authToken: state.authToken,
  })
  useCancelShipmentService({
    requestCancel,
    authToken: state.authToken,
    setIsLoadedCancelShipment,
    setHasErrorCancelShipment,
  })

  // show Alert Confirm - new Delivery
  useEffect(() => {
    if (state.isNewDelivery) setAlertShipmentIsOpen(true)
  }, [])

  // call Default Services
  useEffect(() => {
    if (state.currentDeliveryId !== '')
      callGetShipmentResume(state.currentDeliveryId)
  }, [state.currentDeliveryId])

  // reset State Delivery
  useEffect(() => {
    resetDeliveryInformation()
  }, [resumeShipmentData])

  const resetDeliveryInformation = () => {
    setDeliveryState({
      ...initialState,
      storeId: state.storeId,
      authToken: state.authToken,
      currentStep: state.currentStep,
      depositTypeId: state.depositTypeId,
      currentDeliveryId: state.currentDeliveryId,
      userHasAccountData: state.userHasAccountData,
    })
  }
  
  const handleClickMyAccount = (): void => {
    GAEventsTracker('shop_webdelivery_guide_backtomenu')
    history.push(url + '/mylist')
    setDeliveryState({
      ...state,
      currentStep: StepNames.Address,
    })
  }

  const handleClickShareLink = (): void => {
    GAEventsTracker('shop_webdelivery_guide_track')
    const currentCountry = countries.get('Colombia')
    shareMessageWhatsApp({
      phone: currentCountry.code + String(resumeShipmentData?.customer?.phone),
      text: t('delivery.confirm.shareTrackingMessage', {
        customerName: resumeShipmentData?.customer?.name,
        trackingGuide: resumeShipmentData?.id,
        urlTracking: resumeShipmentData?.trackingLink,
      }),
    })
  }

  const handleClickCallDeliveryMan = (phoneNumber: string) => {
    GAEventsTracker('shop_webdelivery_guide_call')

    const customWindow: any = window
    const message = { option: 'tel', value: phoneNumber }
    customWindow.ReactNativeWebView.postMessage(JSON.stringify(message))
  }

  const handleClickPreventCancelShipment = (): void => {
    setOpenAlertCancelDrawer(false)
  }

  const handleClickCancelShipping = (): void => {
    GAEventsTracker('shop_webdelivery_guide_cancel')
    setOpenAlertCancelDrawer(true)
  }

  const handleClickConfirmCancelShipment = (): void => {
    setRequestCancel({
      deliveryId: String(resumeShipmentData?.id),
      providerId: Number(resumeShipmentData?.provider?.id),
    })
    setOpenAlertCancelDrawer(false)
    setAlertCancelShipmentIsOpen(true)
  }

  const handleClickToastAccount = (): void => {
    setDeliveryState({
      ...state,
      currentStep: StepNames.BankInfo,
    })
  }

  return (
    <>
      {!state.userHasAccountData &&
        resumeShipmentData?.deliveryMethodId ===
          PaymentMethodOptions.UponDelivery && (
          <ToastMessageAlert
            textItem={t('delivery.toasts.toastWithoutAccountDataResume')}
            handleClickToast={handleClickToastAccount}
          />
        )}
      <MainContainer>
        {isLoadedResumeShipment ? (
          <>
            <ConfirmShipmentResume
              {...{
                resumeShipmentData,
                isLoadedResumeShipment,
                handleClickMyAccount,
                handleClickShareLink,
                handleClickCallDeliveryMan,
                handleClickCancelShipping,
              }}
            />
            <ConfirmShipmentAlerts
              {...{
                state,
                setDeliveryState,
                alertShipmentIsOpen,
                alertCancelShipmentIsOpen,
                setAlertShipmentIsOpen,
                setAlertCancelShipmentIsOpen,
                openAlertCancelDrawer,
                handleClickPreventCancelShipment,
                handleClickConfirmCancelShipment,
              }}
            />
          </>
        ) : (
          <Loading />
        )}
      </MainContainer>
    </>
  )
}
export default ConfirmShipment
