import axios from 'axios'
import { ICategory, IProduct } from '../../types/models'

interface FetchProductsAndCategoriesResponse {
  products: IProduct[]
  categories: ICategory[]
}

export const fetchProductsAndCategories = async (
  storeId: string
): Promise<FetchProductsAndCategoriesResponse> => {
  try {
    const endpointUrl = `${process.env.REACT_APP_TREINTA_API_URL}/store/inventory/${storeId}`
    const {
      data: { categories, products },
    } = await axios.get(endpointUrl)

    return {
      categories,
      products,
    }
  } catch (error) {
    console.error('Error fetching products and categories: ', error)
    return {
      categories: [],
      products: [],
    }
  }
}
