import { useTranslation } from 'react-i18next'
import { CSSProperties, useState } from 'react'
import { Link, useRouteMatch } from 'react-router-dom'

import {
  AlertLabel,
  TextDiscount,
  MainContainer,
  ProductPicture,
  MetadataContainer,
  NotAvailableProduct,
  QtySelectorContainer,
  ProductPictureContainer,
} from './styles'
import {
  formatterCurrency,
  calculateDiscountPercent,
} from '../../../utils/functions'
import {
  ProductName,
  ProductPrices,
  OriginalPrice,
  DiscountPrice,
} from '../ProductAsListItem/styles'
import { ProductProps } from '../types'
import noPicture from './../../../assets/empty.svg'
import { STOCK_CONFIG } from '../../../config/stock-config'
import { useStoreConfig } from '../../../context/AppContext'
import { ShoppingCartProductsSet } from '../../../context/ShoppingCart/model'
import { CART_QUANTITY_SMALL_SHAPE } from '../../CartQuantitySelector/CartQuantitySelector'
import ContextCartQuantitySelector from '../../CartQuantitySelector/ContextCartQuantitySelector'

const ProductAsGridItem = ({
  country,
  product,
  onClick,
}: ProductProps): JSX.Element => {
  const { stockConfig } = useStoreConfig()
  const { t: translate } = useTranslation()
  const {
    path,
    params: { name },
  }: { path: string; params: { name: string } } = useRouteMatch()
  const [showAlerLabel, setShowAlerLabel] = useState<boolean>(false)

  const isDisabledProduct = () => {
    return stockConfig === STOCK_CONFIG.SHOW_DISABLED && product.stock === 0
  }

  const disabledStyles: CSSProperties = {
    pointerEvents: 'none',
    cursor: 'not-allowed',
  }

  const handleChange = (productSet: ShoppingCartProductsSet) => {
    const quantity = productSet[product.id]?.quantity || 0
    setShowAlerLabel(quantity >= product.stock)
  }

  return (
    <MainContainer
      isDisabled={isDisabledProduct()}
      data-testid="list-item-container"
      onClick={onClick}
    >
      <Link
        to={`${path.replace(':name', name)}/${product.id}`}
        style={isDisabledProduct() ? disabledStyles : {}}
      >
        <ProductPictureContainer>
          {product.discount && (
            <TextDiscount>
              {calculateDiscountPercent(
                product.discount?.originalPrice,
                product.discount?.finalPrice
              )}
            </TextDiscount>
          )}
          <ProductPicture
            src={product.imageUrl || noPicture}
            alt={product.name}
          />
        </ProductPictureContainer>

        <MetadataContainer>
          <ProductName>{product.name}</ProductName>
          <ProductPrices>
            <OriginalPrice>
              {formatterCurrency(country, product.price)}
            </OriginalPrice>
            {product.discount && (
              <DiscountPrice>
                {formatterCurrency(country, product.discount?.finalPrice)}
              </DiscountPrice>
            )}
          </ProductPrices>
          {showAlerLabel &&
            stockConfig !== STOCK_CONFIG.SHOW_NOT_LIMIT &&
            product.stock > 0 && (
              <AlertLabel>
                {translate('shoppingCart.stockAlertLabel', {
                  quantity: product.stock,
                })}
              </AlertLabel>
            )}
          {isDisabledProduct() && (
            <NotAvailableProduct>
              {translate('catalog.notAvailable')}
            </NotAvailableProduct>
          )}
        </MetadataContainer>
      </Link>
      <QtySelectorContainer>
        <ContextCartQuantitySelector
          product={product}
          shape={CART_QUANTITY_SMALL_SHAPE}
          onProductsSetUpdate={handleChange}
        />
      </QtySelectorContainer>
    </MainContainer>
  )
}

export default ProductAsGridItem
