import { OrdersState } from './model'

export const PUSH_ORDERS = 'ORDERS/PUSH_ORDERS'
export const SET_NEXT_PAGE = 'ORDERS/SET_NEXT_PAGE'
export const TURN_OFF_LOADER = 'ORDERS/TURN_OFF_LOADER'
export const TURN_ON_LOADER = 'ORDERS/TURN_ON_LOADER'

const ordersReducer = (
  state: OrdersState,
  action: { payload: any; type: string }
): OrdersState => {
  const { payload, type } = action

  switch (type) {
    case PUSH_ORDERS:
      return {
        ...state,
        orders: [...state.orders, ...payload],
      }
    case SET_NEXT_PAGE:
      return {
        ...state,
        nextPage: payload,
      }
    case TURN_ON_LOADER:
      return {
        ...state,
        loading: true,
      }
    case TURN_OFF_LOADER:
      return {
        ...state,
        loading: false,
      }
    default:
      return state
  }
}

export default ordersReducer
