import { InfoContainer, InfoWrapper, Label, Value } from './styles'

export type itemProps = {
  iconItem: React.ReactElement
  labelItem: string
  valueItem: string
  isHighlighted?: boolean
}

const ResumeItem = ({
  labelItem,
  iconItem,
  valueItem,
  isHighlighted,
}: itemProps): JSX.Element => {
  valueItem = valueItem !== 'undefined' ? valueItem : ''
  return (
    <InfoContainer>
      {iconItem}
      <InfoWrapper>
        <Label>{labelItem}:</Label>
        <Value isHighlighted={!!isHighlighted}>{valueItem}</Value>
      </InfoWrapper>
    </InfoContainer>
  )
}
export default ResumeItem
