import { useShoppingCart } from '../../hooks'
import { ICountry } from '../../../../types/models'
import { ShoppingListItem } from '../ShoppingListItem'

interface Props {
  country: ICountry
}

export const ShoppingList = ({ country }: Props): JSX.Element => {
  const { products } = useShoppingCart()
  return (
    <>
      {products.map((product: any) => (
        <ShoppingListItem
          product={product}
          country={country}
          key={product.id}
        />
      ))}
    </>
  )
}
