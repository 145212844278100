import { AxiosRequestConfig } from 'axios'

import httpRequest from '../../components/Delivery/httpClient'

const urlBaseService = `${process.env.REACT_APP_TREINTA_MICROSERVICES_API_URL}/delivery/payment-method`

export interface AccountTypes {
  id: number
  name: string
}
export interface PaymentMethodsType {
  id: number
  paymentMethod: string
  type: string
  accountTypes: AccountTypes[]
}

export const getPaymentMethodsList = async (
  authToken: string
): Promise<PaymentMethodsType[]> => {
  const requestData: AxiosRequestConfig = {
    url: urlBaseService,
    method: 'GET',
  }
  return httpRequest(requestData, authToken)
}
