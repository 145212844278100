import { HTMLAttributes } from 'react'
import { Typography } from '../Typography'
import { BaseButton, Icon } from './styles'

export enum ButtonVariants {
  Primary = 'primary',
  Secondary = 'secondary',
  Destructive = 'destructive',
  Ghost = 'ghost',
}

export enum ButtonSizes {
  Large = 'large',
  Medium = 'medium',
  Small = 'small',
}

export interface Props extends HTMLAttributes<HTMLElement> {
  text: string | number
  iconPath?: string
  className?: string
  variant?: ButtonVariants
  size?: ButtonSizes
  isLoading?: boolean
  disabled?: boolean
}

export const Button = ({
  text,
  className,
  iconPath,
  isLoading = false,
  disabled = false,
  variant = ButtonVariants.Primary,
  size = ButtonSizes.Medium,
  ...props
}: Props): JSX.Element => (
  <BaseButton
    className={className}
    variant={variant}
    size={size}
    isLoading={isLoading}
    disabled={disabled}
    {...props}
  >
    {iconPath && <Icon src={iconPath} />}
    <Typography forwardedAs="span">{text}</Typography>
  </BaseButton>
)
