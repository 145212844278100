import { useState } from 'react'

import { getHasBankInformation } from '../../services/BankInformation/hasBankInformationService'

interface Request {
  authToken: string
}
interface Response {
  isLoadingHasBankInfo: boolean
  hasErrorLoadBankInfo: boolean
  hasBankInformation: boolean
  getHasBankInfo: () => void
}

export const useHasBankInformationService = ({
  authToken,
}: Request): Response => {
  const [hasBankInformation, setHasBankInformation] = useState<boolean>(false)
  const [isLoadingHasBankInfo, setIsLoadingHasBankInfo] = useState(true)
  const [hasErrorLoadBankInfo, setHasErrorLoadBankInfo] = useState(false)

  const callService = () =>
    getHasBankInformation(authToken)
      .then((bankInfoResponse) => {
        setHasBankInformation(bankInfoResponse)
        setIsLoadingHasBankInfo(false)
      })
      .catch(() => {
        setHasErrorLoadBankInfo(true)
        setIsLoadingHasBankInfo(false)
      })
  return {
    hasBankInformation,
    hasErrorLoadBankInfo,
    isLoadingHasBankInfo,
    getHasBankInfo: callService,
  }
}
