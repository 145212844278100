import { useTranslation } from 'react-i18next'

import {
  Title,
  Divider,
  Message,
  Container,
  SuccessIcon,
  PendingIcon,
  DeclinedIcon,
  PaymentsLogo,
} from './styles'
import { StatusProps } from './types'
import { StatusSkeleton } from '../Skeleton'
import { TRANSACTION_STATUS } from './constants'

export const Status = ({ status }: StatusProps): JSX.Element => {
  const { t } = useTranslation()

  const getStatusIcon = (): JSX.Element => {
    switch (status) {
      case TRANSACTION_STATUS.Paid:
        return <SuccessIcon />
      case TRANSACTION_STATUS.New:
      case TRANSACTION_STATUS.Pending:
      case TRANSACTION_STATUS.InProgress:
        return <PendingIcon />
      case TRANSACTION_STATUS.Deleted:
      case TRANSACTION_STATUS.Declined:
      default:
        return <DeclinedIcon />
    }
  }

  const getStatusTitle = (): string => {
    switch (status) {
      case TRANSACTION_STATUS.Paid:
        return t('orderConfirmed.statusPayment.statusPaymentSuccessful')
      case TRANSACTION_STATUS.New:
      case TRANSACTION_STATUS.Pending:
      case TRANSACTION_STATUS.InProgress:
        return t('orderConfirmed.statusPayment.statusPaymentPending')
      case TRANSACTION_STATUS.Deleted:
      case TRANSACTION_STATUS.Declined:
      default:
        return t('orderConfirmed.statusPayment.statusPaymentDeclined')
    }
  }
  const getStatusMessage = (): string => {
    switch (status) {
      case TRANSACTION_STATUS.Paid:
        return t('orderConfirmed.purchaseStatus.statusMenssageSuccessful')
      case TRANSACTION_STATUS.New:
      case TRANSACTION_STATUS.Pending:
      case TRANSACTION_STATUS.InProgress:
        return t('orderConfirmed.purchaseStatus.statusMenssagePending')
      case TRANSACTION_STATUS.Deleted:
      case TRANSACTION_STATUS.Declined:
      default:
        return t('orderConfirmed.purchaseStatus.statusMenssageDeclined')
    }
  }

  return status ? (
    <Container>
      <Divider />
      {getStatusIcon()}
      <Title>{getStatusTitle()}</Title>
      <Message>{getStatusMessage()}</Message>
      <PaymentsLogo />
    </Container>
  ) : (
    <StatusSkeleton />
  )
}
