import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { REDIRECT_TIME } from '../constants'
import { Button } from '../../common/Button'
import { DepositTypeForm } from './DepositTypeForm'
import { useSplitIO } from '../../../hooks/useSplitIO'
import { SuccessAlert } from '../../common/SuccessAlert'
import { ToastMessage } from '../../common/ToastMessage'
import useGAEventTracker from '../../../hooks/useGAEventTracker'
import { convertMillisecondsToMinutes } from '../../../utils/functions'
import { useCreateDelivery } from '../../../hooks/Shipment/useCreateDelivery'
import {
  DeliveryStateType,
  PaymentMethodOptions,
  DepositOptions,
  StepNames,
} from '../../../types/models'
import {
  ButtonsContainer,
  ButtonWidth,
  CalendarIcon,
  CloseIcon,
  Content,
  InfoDescription,
  InfoSection,
  InfoTitle,
  ItemLayout,
  ItemText,
  Label,
  ListSection,
  MainContainer,
  Opacity,
  ProviderImage,
  ProviderImageContainer,
  RelativeWrapper,
  Title,
  Value,
} from './style'

export const KeepInMindDrawer = ({
  state,
  onCloseAlert,
  providerImage,
  setDeliveryState,
}: {
  state: DeliveryStateType
  onCloseAlert: () => void
  providerImage: string
  setDeliveryState: (newState: DeliveryStateType) => void
}): JSX.Element => {
  const { t } = useTranslation()
  const GAEventsTracker = useGAEventTracker('webshop')
  const { createDelivery } = useCreateDelivery(state)
  const [buttonStatus, setButtonStatus] = useState(false)
  const [depositTypeId, setDepositTypeId] = useState(DepositOptions.General)
  const [alertConfirmIsOpen, setAlertConfirmIsOpen] = useState(false)
  const { clientStateOn } = useSplitIO('shop_deliveryDepositTypeShopWeb')

  // set Default Deposit Type ID
  useEffect(() => {
    const defaultTypeId =
      Number(state.paymentMethod.method) === PaymentMethodOptions.Cash ||
      !clientStateOn
        ? DepositOptions.General
        : 0
    setDepositTypeId(defaultTypeId)
    setDeliveryState({
      ...state,
      depositTypeId: defaultTypeId,
    })
    setButtonStatus(defineButtonStatus())
  }, [clientStateOn])

  const handleCloseAlert = (): void => {
    GAEventsTracker(
      'shop_webdelivery_loc_notes_quot_back',
      '',
      clientStateOn ? 2 : 1
    )
    onCloseAlert()
  }

  const handleConfirmRequest = async (): Promise<void> => {
    GAEventsTracker(
      'shop_webdelivery_loc_notes_confirm',
      '',
      clientStateOn ? 2 : 1
    )
    setButtonStatus(true)
    const response = await createDelivery(Number(state.providerId))
    if (response) defineRedirectConfirm(response.id)
  }

  const defineButtonStatus = (): boolean => {
    return (
      clientStateOn &&
      state.paymentMethod.method === PaymentMethodOptions.UponDelivery &&
      depositTypeId <= 0
    )
  }

  const defineRedirectConfirm = (deliveryID: string): void => {
    const newStatus = {
      ...state,
      isNewDelivery: true,
      currentDeliveryId: deliveryID,
    }
    if (
      !state.userHasAccountData &&
      state.paymentMethod.method === PaymentMethodOptions.UponDelivery
    ) {
      setDeliveryState({
        ...newStatus,
      })
      setAlertConfirmIsOpen(true)
    } else {
      setDeliveryState({
        ...newStatus,
        currentStep: StepNames.Confirm,
      })
    }
  }

  const defineDepositType = () => {
    let htmlElement = <></>
    if (
      Number(state.paymentMethod.method) === PaymentMethodOptions.UponDelivery
    ) {
      htmlElement = clientStateOn ? (
        <DepositTypeForm
          {...{
            state,
            setDeliveryState,
            depositTypeId,
            setDepositTypeId,
          }}
        />
      ) : (
        <ToastMessage textItem={t('delivery.confirm.commissionPaymentText')} />
      )
    }
    return htmlElement
  }

  return (
    <MainContainer>
      <Opacity data-testid="opacity" onClick={handleCloseAlert} />

      <Content>
        <RelativeWrapper>
          <ProviderImageContainer>
            <ProviderImage src={providerImage} />
          </ProviderImageContainer>
          <CloseIcon onClick={handleCloseAlert} />
          <Title>{t('delivery.providers.keepInMind.title')}</Title>
          {/* show Deposit Form Or Toast */}
          {defineDepositType()}
          <ListSection>
            <ItemLayout>
              <CalendarIcon />
              <ItemText>
                <Label>
                  {t(
                    'delivery.providers.keepInMind.estimatedDeliveryTimeLabel'
                  )}
                </Label>
                <Value>
                  {state.selectedMarketRates.eta > 0 &&
                    t('delivery.providers.keepInMind.etaValue', {
                      minutes: convertMillisecondsToMinutes(
                        state.selectedMarketRates.eta
                      ),
                    })}
                </Value>
              </ItemText>
            </ItemLayout>

            <ItemLayout>
              <CalendarIcon />
              <ItemText>
                <Label>
                  {t('delivery.providers.keepInMind.maxPackageSize')}
                </Label>
                <Value>50x50x50 cms</Value>
              </ItemText>
            </ItemLayout>
          </ListSection>
          <InfoSection>
            <InfoTitle>
              {t('delivery.providers.keepInMind.returnsTitle')}
            </InfoTitle>
            <InfoDescription>
              <div
                dangerouslySetInnerHTML={{
                  __html: t('delivery.providers.keepInMind.returnsDescription'),
                }}
              />
            </InfoDescription>
          </InfoSection>
          <InfoSection>
            <InfoTitle>
              {t('delivery.providers.keepInMind.paymentMethodsDeliveryTitle')}
            </InfoTitle>
            <InfoDescription>
              <div
                dangerouslySetInnerHTML={{
                  __html: t(
                    'delivery.providers.keepInMind.paymentMethodsDeliveryDescription'
                  ),
                }}
              />
            </InfoDescription>
          </InfoSection>
          <InfoSection>
            <InfoTitle>
              {t('delivery.providers.keepInMind.cancelationsTitle')}
            </InfoTitle>
            <InfoDescription>
              <div
                dangerouslySetInnerHTML={{
                  __html: t(
                    'delivery.providers.keepInMind.cancelationsDescription'
                  ),
                }}
              />
            </InfoDescription>
          </InfoSection>
          <ButtonsContainer>
            <Button
              text={t('delivery.providers.keepInMind.confirmRequest') as string}
              style={ButtonWidth}
              onClick={handleConfirmRequest}
              disabled={buttonStatus}
            ></Button>
          </ButtonsContainer>
        </RelativeWrapper>
        {/* ALERT SUCCESS - REDIRECT TO BANK INFO FORM */}
        <SuccessAlert
          title={t(
            'delivery.confirms.confirmedShipment.bankFormModalConfirmedShipment'
          )}
          content={t(
            'delivery.confirms.confirmedShipment.bankFormModalConfirmedShipmentText'
          )}
          isOpen={alertConfirmIsOpen}
          istop={true}
          timeout={REDIRECT_TIME}
          onClose={() => {
            setAlertConfirmIsOpen(false)
            setDeliveryState({
              ...state,
              currentStep: StepNames.BankInfo,
            })
          }}
        />
      </Content>
    </MainContainer>
  )
}
