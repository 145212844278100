import { useTranslation } from 'react-i18next'

import { ExclamationIcon } from './styles'
import { REDIRECT_TIME } from '../../constants'
import { SuccessAlert } from '../../../common/SuccessAlert'
import { AlertDrawer } from '../../../common/AlertDrawer/AlertDrawer'

interface ResumeSentDeliveryAlertsProps {
  handleClickPreventRemoveShipment: () => void
  handleClickConfirmRemoveShipment: () => void
  handleCloseAlertSuccessRemove: () => void
  drawerRemoveIsOpen: boolean
  confirmRemoveIsOpen: boolean
}
const ResumeSentDeliveryAlerts = ({
  drawerRemoveIsOpen,
  confirmRemoveIsOpen,
  handleCloseAlertSuccessRemove,
  handleClickPreventRemoveShipment,
  handleClickConfirmRemoveShipment,
}: ResumeSentDeliveryAlertsProps): JSX.Element => {
  const { t } = useTranslation()

  return (
    <>
      {/* ALERT SUCCESS REMOVE SHIPMENT */}
      <SuccessAlert
        title={t('delivery.confirms.removeShipment.removeSuccessTitle')}
        content={t('delivery.confirms.removeShipment.removeSuccessText')}
        isOpen={confirmRemoveIsOpen}
        istop={true}
        timeout={REDIRECT_TIME}
        onClose={() => {
          handleCloseAlertSuccessRemove()
        }}
      />
      {/* ALERT DRAWER REMOVE SHIPMENT - CONFIRM */}
      {drawerRemoveIsOpen && (
        <AlertDrawer
          textTitle={t('delivery.drawers.removeShipment.title')}
          textSbTitle={''}
          textConfirm={t('delivery.drawers.removeShipment.content')}
          textLeftButton={t('delivery.confirm.alerts.remove')}
          textRightButton={t('delivery.confirm.alerts.cancel')}
          onEventLeftButton={handleClickConfirmRemoveShipment}
          onEventRightButton={handleClickPreventRemoveShipment}
          onEventOpacitySection={handleClickPreventRemoveShipment}
          eventLeft={'shop_webdelivery_guide_cancel_confirm'}
          eventRight={'shop_webdelivery_guide_cancel_reject'}
          iconAlert={<ExclamationIcon />}
        />
      )}
    </>
  )
}
export default ResumeSentDeliveryAlerts
