import styled from 'styled-components'

export const CustomButton = styled.button`
  align-items: center;
  justify-content: center;
  z-index: 1;
  cursor: pointer;
  border-radius: 50px;
  right: 1rem;
  align-self: flex-end;
  ${({ theme }) => `
    height: ${theme.sizes.spacing.xxxlarge};
    width: ${theme.sizes.spacing.xxxlarge};
    margin-right: ${theme.sizes.spacing.large};
    margin-bottom: ${theme.sizes.spacing.xsmall};
    background: ${theme.colors.tertiary600};
    border: 1px solid ${theme.colors.neutral300};
    box-shadow: ${theme.sizes.shadows.soft};
  `}
`
export const Icon = styled.img`
  width: ${({ theme }) => theme.sizes.spacing.large};
  cursor: pointer;
`
export const ButtomWrapper = styled.div`
  width: auto;
  bottom: 60px;
  right: 0px;
  display: flex;
  position: absolute;
  align-items: center;
  justify-content: flex-end;
`
