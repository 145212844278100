import styled from 'styled-components'
import { FormControl } from '@material-ui/core'

import { Typography } from '../../../../components/common/Typography'

export const ImgPaymentLogo = styled.img`
  width: 25%;
  margin-left: ${({ theme }) => theme.sizes.spacing.small};
`

export const CustomFormControl = styled(FormControl)`
  width: 100%;
  .MuiFormGroup-root {
    gap: 16px;
  }
  .MuiTypography-root {
    width: 100%;
  }
  .MuiFormControlLabel-label {
    width: 100%;
  }
`

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`
export const PaymentLabel = styled(Typography).attrs({
  forwardedAs: 'p',
  variant: 'Medium',
})`
  width: 100%;
`
