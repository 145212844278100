import { Badge } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { countries } from '../../../../config/countries'

import { useStyles } from './styles'
import { theme } from '../../../../config/color-palette'
import { useStoreConfig } from '../../../../context/AppContext'
import { Typography } from '../../../../components/common/Typography'
import { useShoppingCart } from '../../../../components/ShoppingCart/hooks'
import { ReactComponent as IconBasket } from './../../../../assets/basket.svg'
import { ShoppingList } from '../../../../components/ShoppingCart/components/ShoppingList'

export const CheckoutProducts = (): JSX.Element => {
  const { t } = useTranslation()
  const {
    assignTheme,
    store: { country },
  } = useStoreConfig()
  const palette = theme.get(assignTheme)
  const { products } = useShoppingCart()

  const classes = useStyles(palette)

  return (
    <div className={classes.mainContainer}>
      <Typography variant="Mediumbold">
        {t('sectionForm.labelProducts')}
      </Typography>
      {products.length > 0 ? (
        <div className={classes.productsListContainer}>
          <ShoppingList country={countries.get(country)} />
        </div>
      ) : (
        <div className={classes.voidCart}>
          <Badge color="error" variant="dot">
            <IconBasket className={classes.iconStyle} />
          </Badge>
          <Typography variant="Mediumbold">
            {t('sectionForm.emptyProducts')}
          </Typography>
        </div>
      )}
    </div>
  )
}
