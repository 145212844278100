import { useTranslation } from 'react-i18next'

import { FormPaymentMethodProps } from './contracts'
import { PaymentMethodAlert } from '../PaymentLinkAlert'
import { PaymentMethodOption } from '../PaymentMethodOption'
import { PaymentType } from '../../../../config/payment-type'
import { Group, Title, Container, CustomFormControl } from './styles'

export const FormPaymentMethod = ({
  paymentMethod,
  paymentMethods,
  changePaymentMethod,
  showPaymentLinkMethod,
}: FormPaymentMethodProps): JSX.Element => {
  const { t } = useTranslation()

  return (
    <Container>
      <Title>{t('sectionForm.paymentMethod')}</Title>
      <CustomFormControl>
        <Group value={paymentMethod} onChange={changePaymentMethod}>
          {paymentMethods?.map((option) => (
            <PaymentMethodOption {...option} key={option.value} />
          ))}
        </Group>
      </CustomFormControl>
      {paymentMethod === PaymentType.PaymentLink && showPaymentLinkMethod && (
        <PaymentMethodAlert />
      )}
    </Container>
  )
}
