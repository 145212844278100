import { ReactComponent as WhatsAppIcon } from './../../assets/iconWhatsapp.svg'
import { ContainerContact, ButtonContact } from './styles'
import { useTranslation } from 'react-i18next'
import useGAEventTracker from '../../hooks/useGAEventTracker'

export const ButtonWsOrderDetail = ({
  contactStore,
}: {
  contactStore: () => void
}): JSX.Element => {
  const { t } = useTranslation()
  const GAEventsTracker = useGAEventTracker('webshop')
  return (
    <ContainerContact>
      <WhatsAppIcon />
      <ButtonContact
        data-testid="contactButton"
        onClick={() => {
          GAEventsTracker('webshop_orders_whatsapp')
          contactStore()
        }}
      >
        {t('orderDetail.contactStore')}
      </ButtonContact>
    </ContainerContact>
  )
}
