import { format } from 'date-fns'

import { dateRangeObjectType } from '../contracts'
import { countries } from '../../../config/countries'
import { CountriesOptions } from '../../../types/models'
import { incrementDateHour } from '../../../utils/datefunctions'

const currentCountry = countries.get('Colombia')

export const formatHoursRangeByTimezone = (
  actualDate: string,
  rangeHours: string
): dateRangeObjectType => {
  let newInitialRangeDate = actualDate
  let newFinishRangeDate = actualDate
  const newDate = new Date(`${actualDate} 00:00`)
  const hours = rangeHours.split('-')
  if (hours.length == 2) {
    const startHourData = hours[0].split(':')
    const endHourData = hours[1].split(':')
    newInitialRangeDate = formatDatetimeByTimezone(
      newDate,
      Number(startHourData[0].trim()),
      Number(startHourData[1].trim())
    )
    newFinishRangeDate = formatDatetimeByTimezone(
      newDate,
      Number(endHourData[0].trim()),
      Number(endHourData[1].trim())
    )
  }
  return {
    newDate: format(new Date(newInitialRangeDate), 'yyyy-MM-dd'),
    newRange: `${format(new Date(newInitialRangeDate), 'HH:mm')} - ${format(
      new Date(newFinishRangeDate),
      'HH:mm'
    )}`,
  }
}

export const formatDatetimeByTimezone = (
  actualDate: Date,
  hours: number,
  minutes: number
): string => {
  const newDate = new Date(
    actualDate.getFullYear(),
    actualDate.getMonth(),
    actualDate.getDate(),
    hours,
    minutes
  )
  return incrementDateHour(newDate, incrementHourByTimezone())
}

export const incrementHourByTimezone = (): number => {
  let numberHours = 0
  // TODO: create case by Enabled Country
  switch (currentCountry.acronym) {
    case CountriesOptions.Colombia:
      numberHours = -5
      break
    default:
      break
  }
  return numberHours
}
