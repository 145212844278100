import { AxiosRequestConfig } from 'axios'

import httpRequest from '../../components/Delivery/httpClient'

export interface AccountDataRequest {
  owner_name: string
  email: string
  document_number: string
  account_number: string
  bank_id: number
  document_type_id: number
  account_type_id: number
  payment_method_id: number
}
export interface AccountDataResponse {
  id: string
  ownerName: string
  email: string
  userId: string
  documentNumber: string
  accountNumber: string
  createdAt: string
  updatedAt: string
  deletetAt: string
  bankId: number
  documentTypeId: number
  accountTypeId: number
  paymentMethodId: number
}

const urlBaseService = `${process.env.REACT_APP_TREINTA_MICROSERVICES_API_URL}/delivery/user-bank-account`

export const getAccountDataInformation = async (
  authToken: string
): Promise<AccountDataResponse> => {
  const requestData: AxiosRequestConfig = {
    url: urlBaseService,
    method: 'GET',
  }
  return httpRequest(requestData, authToken)
}

export const saveAccountDataInformation = async (
  authToken: string,
  accountDataRequest: AccountDataRequest | undefined
): Promise<AccountDataResponse> => {
  const requestData: AxiosRequestConfig = {
    url: `${urlBaseService}/update`,
    method: 'PUT',
    data: accountDataRequest,
  }
  return httpRequest(requestData, authToken)
}
