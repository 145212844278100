import { Label, Container, WrapperIcon } from './styles'
import { ReactComponent as IconPlace } from '../../../../assets/icons/place.svg'

interface Props {
  address?: string
}

export const Address = ({ address }: Props): JSX.Element => (
  <>
    {address && (
      <Container data-testid="address-container">
        <WrapperIcon>
          <IconPlace />
        </WrapperIcon>
        <Label>{address}</Label>
      </Container>
    )}
  </>
)
