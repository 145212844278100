import { useTranslation } from 'react-i18next'

import { RadioGroup, FormControlLabel } from '@material-ui/core'

import MenuSelect from '../../common/MenuSelect'
import { BankInformationPaymentProps } from '../contracts'
import { restrictInputNumbers } from '../../../utils/forms'
import { handleChangeInputField } from './changeValidations'
import { GreenRadio } from '../../../components/common/GreenElements'
import {
  AccountTypes,
  PaymentMethodsType,
} from '../../../services/BankInformation/paymentMethodsService'
import {
  ErrorMessage,
  SelectPlaceholder,
  InputContainer,
  LabelInputForm,
  LabelRadio,
  InputTextForm,
  InfoButton,
  SubTitle,
} from './styles'

const FormBankInformationFieldsPayment = ({
  handleClickNext,
  bankInformationData,
  bankInformationDataError,
  setBankInformationData,
  accountTypes,
  bankList,
  paymentMethodsData,
  paymentMethodType,
  defineContentSelectValue,
  isValidForm,
  validateAccountType,
  validatePaymentMethod,
  validateBank,
  validateAccountNumber,
  validateAccountNumberConfirm,
}: BankInformationPaymentProps): JSX.Element => {
  const DEFAULTTYPE = 'physical'
  const { t } = useTranslation()

  return (
    <>
      {/* Payment Method */}
      <SubTitle>
        {t('delivery.sectionForm.bankFormLabelPaymentMethod')}
      </SubTitle>
      <InputContainer>
        <RadioGroup
          name="radio-buttons-methods"
          onChange={(e) => {
            handleChangeInputField({
              event: e,
              isNumber: true,
              fieldName: 'paymentMethod',
              validationMethod: validatePaymentMethod,
              bankInformationData: bankInformationData,
              setBankInformationData: setBankInformationData,
            })
          }}
        >
          {paymentMethodsData?.map((paymentMethod: PaymentMethodsType) => (
            <FormControlLabel
              key={paymentMethod.id}
              value={paymentMethod.id}
              control={
                <GreenRadio
                  size="small"
                  checked={
                    bankInformationData.paymentMethod === paymentMethod.id
                  }
                />
              }
              label={<LabelRadio>{paymentMethod.paymentMethod}</LabelRadio>}
            />
          ))}

          {bankInformationDataError.paymentMethodError && (
            <ErrorMessage variant="XSmall">
              {t('delivery.sectionForm.errors.errorPaymentMethod')}
            </ErrorMessage>
          )}
        </RadioGroup>
      </InputContainer>

      {/* Account Type */}
      <SubTitle>{t('delivery.sectionForm.bankformLabelAccountType')}</SubTitle>
      <InputContainer>
        <RadioGroup
          defaultValue={bankInformationData.accountType}
          name="radio-buttons-accounttype"
          onChange={(e) => {
            handleChangeInputField({
              event: e,
              isNumber: true,
              fieldName: 'accountType',
              validationMethod: validateAccountType,
              bankInformationData: bankInformationData,
              setBankInformationData: setBankInformationData,
            })
          }}
        >
          {accountTypes?.map((accountType: AccountTypes) => (
            <FormControlLabel
              key={accountType.id}
              value={accountType.id}
              control={
                <GreenRadio
                  size="small"
                  checked={
                    Number(bankInformationData.accountType) ===
                    Number(accountType.id)
                  }
                />
              }
              label={<LabelRadio>{accountType.name}</LabelRadio>}
            />
          ))}
          {bankInformationDataError.accountTypeError && (
            <ErrorMessage variant="XSmall">
              {t('delivery.sectionForm.errors.errorPaymentMethod')}
            </ErrorMessage>
          )}
        </RadioGroup>
      </InputContainer>

      {/* select Bank */}
      {paymentMethodType === DEFAULTTYPE && bankList && (
        <InputContainer>
          <MenuSelect
            id="bankid-select"
            showLabel={true}
            defaultValue={String(bankInformationData.bankId)}
            displayEmpty={true}
            labelText={`${t('delivery.sectionForm.bankFormLabelSelectBank')}*`}
            menuItems={bankList}
            renderValue={(actualValue) => (
              <SelectPlaceholder>
                {defineContentSelectValue(
                  t('delivery.sectionForm.bankFormPlaceholderSelectBank'),
                  actualValue,
                  bankList
                )}
              </SelectPlaceholder>
            )}
            onChange={(e) => {
              handleChangeInputField({
                event: e,
                isNumber: true,
                fieldName: 'bankId',
                validationMethod: validateBank,
                bankInformationData: bankInformationData,
                setBankInformationData: setBankInformationData,
              })
            }}
          />
          {bankInformationDataError.bankError && (
            <ErrorMessage variant="XSmall">
              {t('delivery.sectionForm.errors.errorBank')}
            </ErrorMessage>
          )}
        </InputContainer>
      )}

      {/* Account Number OR Phone */}
      <InputContainer>
        <LabelInputForm>
          {paymentMethodType === DEFAULTTYPE
            ? `${t('delivery.sectionForm.bankFormLabelAccountNumber')}*`
            : `${t('delivery.sectionForm.bankFormLabelPhoneNumber')}*`}
        </LabelInputForm>
        <InputTextForm
          value={String(bankInformationData.accountNumber)}
          type="number"
          minLength={3}
          maxLength={25}
          id="accountnumber-input"
          onKeyPress={(e: React.KeyboardEvent<HTMLInputElement>) => {
            if (!restrictInputNumbers(e)) e.preventDefault()
          }}
          onChange={(e) => {
            handleChangeInputField({
              event: e,
              isNumber: false,
              fieldName: 'accountNumber',
              validationMethod: validateAccountNumber,
              bankInformationData: bankInformationData,
              setBankInformationData: setBankInformationData,
            })
          }}
          placeholder={
            paymentMethodType === DEFAULTTYPE
              ? t('delivery.sectionForm.bankFormPlaceholderAccountNumber')
              : t('delivery.sectionForm.bankFormPlaceholderPhoneNumber')
          }
        />
        {bankInformationDataError.accountNumberError && (
          <ErrorMessage variant="XSmall">
            {paymentMethodType === DEFAULTTYPE
              ? t('delivery.sectionForm.errors.errorAccountNumber')
              : t('delivery.sectionForm.errors.errorPhoneNumber')}
          </ErrorMessage>
        )}
      </InputContainer>

      {/* Confirm Account Number */}
      <InputContainer>
        <LabelInputForm>
          {paymentMethodType === DEFAULTTYPE
            ? `${t('delivery.sectionForm.bankFormLabelConfirmAccountNumber')}*`
            : `${t('delivery.sectionForm.bankFormLabelConfirmPhoneNumber')}*`}
        </LabelInputForm>
        <InputTextForm
          value={String(bankInformationData.accountNumberConfirm)}
          type="number"
          minLength={3}
          maxLength={25}
          id="accountnumberconfirm-input"
          onKeyPress={(e: React.KeyboardEvent<HTMLInputElement>) => {
            if (!restrictInputNumbers(e)) e.preventDefault()
          }}
          onChange={(e) => {
            handleChangeInputField({
              event: e,
              isNumber: false,
              fieldName: 'accountNumberConfirm',
              validationMethod: validateAccountNumberConfirm,
              bankInformationData: bankInformationData,
              setBankInformationData: setBankInformationData,
            })
          }}
          placeholder={
            paymentMethodType === DEFAULTTYPE
              ? t(
                  'delivery.sectionForm.bankFormPlaceholderConfirmAccountNumber'
                )
              : t('delivery.sectionForm.bankFormPlaceholderConfirmPhoneNumber')
          }
        />
        {bankInformationDataError.accountNumberConfirmError && (
          <ErrorMessage variant="XSmall">
            {paymentMethodType === DEFAULTTYPE
              ? t('delivery.sectionForm.errors.errorAccountNotMatch')
              : t('delivery.sectionForm.errors.errorPhoneNotMatch')}
          </ErrorMessage>
        )}
      </InputContainer>

      <InfoButton disabled={!isValidForm} onClick={handleClickNext}>
        {t('delivery.sectionForm.bankFormNext')}
      </InfoButton>
    </>
  )
}
export default FormBankInformationFieldsPayment
