import {
  lazy,
  Suspense,
  useState,
  Dispatch,
  useEffect,
  useContext,
  SetStateAction,
} from 'react'
import { useLocation } from 'react-router-dom'

import SearchBar from '../SearchBar'
import firebase from '../../firebase'
import { Storeinfo } from '../StoreInfo'
import { Recaptcha } from '../Recaptcha'
import { Main, Container } from './styles'
import { Categories } from '../Categories'
import { StoreHeader } from '../StoreHeader'
import { useQuery } from '../../hooks/useQuery'
import ProductsCatalog from '../ProductsCatalog'
import { countries } from '../../config/countries'
import ProductsSlideLoad from '../ProductsSlideLoad'
import LoadingPage from '../../modules/Pages/Loading'
import treintaLogo from '../../assets/treintaLogo.png'
import { useStoreConfig } from '../../context/AppContext'
import CatalogContext from '../../context/Catalog/context'
import useGAEventTracker from '../../hooks/useGAEventTracker'
import { IMetaDescription, IProduct } from '../../types/models'
import InactiveService from '../../modules/Pages/InactiveService'
import ShoppingCartContext from '../../context/ShoppingCart/context'
import ModalDetailProduct from '../../components-v0/ModalDetailProduct'

interface Props {
  metaDescription: Dispatch<SetStateAction<IMetaDescription>>
}

const ShoppingCart = lazy(() => import('../../components/ShoppingCart'))
const MAX_SEARCH_LENGTH = Number(process.env.REACT_APP_MAX_SEARCH_LENGTH) || 50
const MINIMUM_MARGIN_STICKY = 16

export const StoreContainer = ({ metaDescription }: Props): JSX.Element => {
  const {
    error,
    store,
    categories,
    filteredProducts,
    setIsFromMarketplace,
    setFilteredCategories,
    setIsUserAuthenticated,
  } = useStoreConfig()

  const {
    setDrawerOpen,
    setSummaryOpen,
    state: { isDrawerOpen },
  } = useContext(ShoppingCartContext)

  const query = useQuery()
  const {
    state: { products },
  } = useContext(CatalogContext)
  const location = useLocation()
  const sharedProduct = query.get('sharedProduct')
  const GAEventsTracker = useGAEventTracker('webshop')
  const queryParams = new URLSearchParams(location.search)

  const [openDialog, setOpenDialog] = useState<boolean>(false)
  const [isStickyHeader, setStickyHeader] = useState<boolean>(false)
  const isFromMarketplace = queryParams.get('origin') === 'marketplace'
  const [detailProduct, setDetailProduct] = useState<IProduct>({} as any)

  const signInHandleNext = async () => setSummaryOpen(true)

  const handleShowShoppinCart = (isOpen: boolean) => {
    GAEventsTracker('webshop_back_to_store')
    setDrawerOpen(isOpen)
  }

  const shoppinCartHandleNext = async (): Promise<void> => {
    firebase.auth().onAuthStateChanged(async (user) => {
      await setDrawerOpen(false)
      if (user) {
        setIsUserAuthenticated(true)
      } else {
        setIsUserAuthenticated(false)
      }
      signInHandleNext()
    })
  }

  useEffect(() => {
    if (categories) {
      const allCategories =
        categories.length > 0
          ? [
              {
                name: 'General',
              },
              ...categories,
            ]
          : [{ name: 'General' }]
      setFilteredCategories(allCategories)

      if (sharedProduct) {
        const sharedProductDetails = filteredProducts.filter(
          (product) => product.id === sharedProduct
        )[0]

        if (sharedProductDetails) {
          setDetailProduct(sharedProductDetails)
          setOpenDialog(true)
          if (isFromMarketplace) {
            setIsFromMarketplace(isFromMarketplace)
            GAEventsTracker(
              'marketplace_a_shop_views',
              `${store.id}_marketplace`
            )
          }
        }
      }
    }
  }, [categories])

  useEffect(() => {
    metaDescription({
      store_name: store?.name || '',
      store_title: store?.config?.title || '',
      imageUrl: store?.imageUrl || treintaLogo,
    })
  }, [store])

  const listenStickyHeader = () => {
    window.scrollY <= MINIMUM_MARGIN_STICKY
      ? setStickyHeader(false)
      : setStickyHeader(true)
  }

  useEffect(() => {
    listenStickyHeader()
    window.addEventListener('scroll', listenStickyHeader)
    return () => window.removeEventListener('scroll', listenStickyHeader)
  }, [])

  if (error) {
    return <InactiveService />
  }

  if (!store || !products) {
    return <LoadingPage />
  }

  const storeCountry = countries.get(store?.country)

  return (
    <Container>
      <StoreHeader
        isSticky={isStickyHeader}
        name={store.name}
        logo={store?.imageUrl}
        maxChars={MAX_SEARCH_LENGTH}
      />

      <Main>
        {!isStickyHeader && (
          <>
            <Storeinfo store={store} />
            <SearchBar maxChars={MAX_SEARCH_LENGTH} isSticky={isStickyHeader} />
            <Categories />
          </>
        )}
        <ProductsSlideLoad />
        <ProductsCatalog country={storeCountry} />
      </Main>

      <Recaptcha id="recaptcha-container" />
      <Suspense fallback={null}>
        {isDrawerOpen && (
          <ShoppingCart
            openCart={isDrawerOpen}
            handleNext={shoppinCartHandleNext}
            handleShowShoppingCart={handleShowShoppinCart}
          />
        )}
      </Suspense>
      <ModalDetailProduct
        store={store}
        openDialog={openDialog}
        details={detailProduct}
        setOpenDialog={setOpenDialog}
      />
    </Container>
  )
}
