import { useEffect, useContext, Dispatch, SetStateAction } from 'react'
import { Route, Switch } from 'react-router-dom'

import { Store } from '../modules/Store'
import { OrdersRouter } from './OrdersRouter'
import { getStoreData } from '../services/store'
import { getProductsList } from '../services/products'
import { useStoreConfig } from '../context/AppContext'
import { filterCategories } from '../utils/categories'
import CatalogContext from '../context/Catalog/context'
import ProductDetailPage from '../modules/ProductDetail'
import { getCategoriesList } from '../services/categories'
import { isBRDomain, isBRStore } from '../utils/functions'
import { IMetaDescription, IProduct } from '../types/models'
import { NewOrderConfirmed } from '../modules/newOrderConfirmed'
import { WhatsappButtonLoad } from '../components/WhatsappButton'
import { compareRangeWithCurrentDay } from '../utils/datefunctions'
import { LowerShoppingButtonLoad } from '../components/LowerShoppingButton'

interface Props {
  metaDescription: Dispatch<SetStateAction<IMetaDescription>>
}

export const StoreRouter = ({ metaDescription }: Props): JSX.Element => {
  const {
    setProducts: setNewProducts,
    setCategories: setNewCategories,
    setActiveStoreId,
  } = useContext(CatalogContext)
  const storeUrl = window.location.pathname.split('/')[1]
  const { setError, setStore, setStockConfig } = useStoreConfig()

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-extra-semi
    ;(async () => {
      const store = await getStoreData(storeUrl)
      if (store) {
        setActiveStoreId(store.id)
        validateBRDomain(store)
        const productsPromise = getProductsList({ storeId: store.id })
        const categoriesPromise = getCategoriesList(store.id)
        const [products, categories] = await Promise.all([
          productsPromise,
          categoriesPromise,
        ])
        const countryWithoutSpaces = store?.country?.trim()
        if (products) {
          const fullStoreData = {
            ...store,
            country: countryWithoutSpaces,
            urlName: storeUrl,
            imageUrl: store.image_url,
            config: JSON.parse(store.config),
          }
          setStore(fullStoreData)
          setStockConfig(fullStoreData.config.woStock)
          let filteredProductsWithExpiredDiscounts: IProduct[] = []
          filteredProductsWithExpiredDiscounts = products.map((product) => {
            if (
              product?.discount &&
              !compareRangeWithCurrentDay(
                product.discount.startDate,
                product.discount.endDate
              )
            ) {
              delete product.discount
            }
            return product
          })
          setNewProducts(filteredProductsWithExpiredDiscounts)
          const filteredCategories = filterCategories({
            categories,
          })
          setNewCategories(filteredCategories)
        } else {
          setError('Error')
        }
      } else {
        setError('Error')
      }
    })()
  }, [])

  const validateBRDomain = (store: any) => {
    if (!isBRDomain && isBRStore(store.country)) {
      const queryString = window.location.href.split(location.host)[1]
      window.location.replace(
        `${process.env.REACT_APP_BR_DOMAIN}${queryString}`
      )
    }
  }

  return (
    <Switch>
      <Route exact path="/:name">
        <Store metaDescription={metaDescription} />
        <WhatsappButtonLoad />
        <LowerShoppingButtonLoad />
      </Route>
      <Route path={'/:name/orders'} component={OrdersRouter} />
      <Route exact path="/:name/:productId" component={ProductDetailPage} />
      <Route
        exact
        component={NewOrderConfirmed}
        path="/:name/orderConfirmed/:transactionId"
      />
    </Switch>
  )
}

export default StoreRouter
