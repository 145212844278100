import { useTranslation } from 'react-i18next'
import firebase from '../../../../firebase'
import { Input } from '../../../../components/Input'
import { useState, useEffect, useContext } from 'react'
import { PhoneInput } from '../../../../components/PhoneInput'
import useGAEventTracker from '../../../../hooks/useGAEventTracker'
import { useCatalogState } from '../../../../context/ShoppingCartContext'
import {
  ErrorMessage,
  FormContainer,
  NameInputContainer,
  PhoneContainer,
  Title,
} from './styles'
import { StoreAuthContext } from '../../../../context/StoreAuthContext/StoreAuthContext'
import { useStoreConfig } from '../../../../context/AppContext'
import { countries } from '../../../../config/countries'
import { fetchUserSummary } from '../../../../context/Orders/services'

export const FormPersonalInformation = (): JSX.Element => {
  const { setValidateFormPersonalData } = useCatalogState()
  const { t } = useTranslation()
  const {
    setName,
    setPhone,
    setCountry,
    state: { phone, country, name },
  } = useContext(StoreAuthContext)
  const [trackName, setTrackName] = useState(false)
  const GAEventsTracker = useGAEventTracker('webshop')
  const [errorName, setErrorName] = useState<boolean>(false)
  const {
    isUserAuthenticated,
    setIsUserAuthenticated,
    store,
  } = useStoreConfig()
  const [userCountry, setuserCountry] = useState(countries.get(store.country))

  const getUserInfo = async () => {
    const { phone, first_name, country_name } = await fetchUserSummary()
    setName(first_name || '')
    setPhone(phone?.replace(countries.get(country_name).code, '') || '')
    const newCountry = countries.get(country_name)
    setuserCountry(newCountry)
  }

  useEffect(() => {
    firebase.auth().onAuthStateChanged(async (user) => {
      if (user) {
        setIsUserAuthenticated(true)
      } else {
        setIsUserAuthenticated(false)
      }
    })
  }, [])

  useEffect(() => {
    if (isUserAuthenticated) {
      getUserInfo()
    }
  }, [isUserAuthenticated])

  const onBlurHandleName = () => {
    if (!trackName) {
      GAEventsTracker('webshop_cart_name')
      setTrackName(true)
    }
  }
  const validateName = (): boolean => {
    if (name?.length > 3) {
      setErrorName(false)
      return false
    }
    if (name.length === 0) {
      setErrorName(false)
      return true
    }
    setErrorName(true)
    return true
  }

  const trackEvent = () => {
    GAEventsTracker('webshop_cart_phone')
  }

  const onFocusPhone = () => {
    GAEventsTracker('webshop_phone_personaldata')
  }

  const onFocusName = () => {
    GAEventsTracker('webshop_name_personaldata')
  }

  useEffect(() => {
    setValidateFormPersonalData(
      validateName() || !country.digits.includes(phone.length)
    )
  }, [name, phone, country])

  return (
    <>
      <Title forwardedAs="h1" variant="Mediumbold">
        {t('sectionForm.personalInformation')}
      </Title>
      <FormContainer>
        <NameInputContainer>
          <Input
            value={name}
            minLength={3}
            maxLength={80}
            id="name-input"
            onBlur={onBlurHandleName}
            onFocus={onFocusName}
            onChange={(e) => {
              setName(e.target.value.replace('/', ''))
            }}
            placeholder={t('sectionForm.namePlaceHolder')}
          />
          {errorName && (
            <ErrorMessage variant="XSmall">
              {t('sectionForm.errorMessage', {
                quantity: country.digits[0].toString(),
              })}
            </ErrorMessage>
          )}
        </NameInputContainer>
      </FormContainer>
      <PhoneContainer>
        <PhoneInput
          phone={phone}
          onBlur={trackEvent}
          onFocus={onFocusPhone}
          onChangePhone={setPhone}
          onChangeCountry={setCountry}
          defaultCountry={userCountry}
        />
      </PhoneContainer>
    </>
  )
}
