import { useTranslation } from 'react-i18next'

import {
  Row,
  Bike,
  Cash,
  Title,
  Address,
  Container,
  TextLabel,
  TextValue,
  Subcontainer,
} from './styles'
import { SummaryProps } from './types'
import { formatterCurrency } from '../../../../utils/functions'

export const Summary = ({
  address,
  country,
  deliveryType,
  totalPayment,
}: SummaryProps): JSX.Element => {
  const { t } = useTranslation()
  return (
    <Container>
      <Title>{t('orderConfirmed.purchaseSummary.orderDetail.title')}</Title>
      <Row>
        <Subcontainer>
          <Bike />
          <TextLabel>
            {t('orderConfirmed.purchaseSummary.orderDetail.deliveryMethod')}
          </TextLabel>
        </Subcontainer>
        <TextValue>{deliveryType}</TextValue>
      </Row>
      <Row>
        <Subcontainer>
          <Address />
          <TextLabel>
            {t('orderConfirmed.purchaseSummary.orderDetail.address')}
          </TextLabel>
        </Subcontainer>
        <TextValue>{address}</TextValue>
      </Row>
      <Row>
        <Subcontainer>
          <Cash />
          <TextLabel>
            {t('orderConfirmed.purchaseSummary.orderDetail.totalCheckout')}
          </TextLabel>
        </Subcontainer>
        <TextValue>{formatterCurrency(country, totalPayment)}</TextValue>
      </Row>
    </Container>
  )
}
