import styled from 'styled-components'

import mainTheme from '../../../styles/theme'
import { FontsName } from '../../../styles/fonts'

export const ButtonStyles = {
  color: mainTheme.colors.error500,
  alignSelf: 'center',
  border: 'none',
}

export const ButtonWidth = {
  maxWidth: 'none',
  width: '100%',
  alignSelf: 'center',
}

export const MainContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
`

export const Opacity = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.5);
`

export const Content = styled.section`
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 16px 16px 0px 0px;
  padding: 40px 16px 0 16px;
  position: absolute;
  width: 100%;
  height: 304px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  bottom: 0;
  z-index: 10;
`

export const Title = styled.h2`
  ${({ theme }) => theme.fonts[FontsName.Nunito].largebold}
  margin-bottom: ${({ theme: { sizes } }) => sizes.spacing.small};
  color: ${({ theme }) => theme.colors.secondary700};
`

export const Subtitle = styled.p`
  color: ${({ theme }) => theme.colors.neutral800};
  font-size: ${({ theme }) => theme.sizes.spacing.medium};
  font-weight: 400;
  margin: 0;
`

export const Confirmation = styled.p`
  color: ${({ theme }) => theme.colors.neutral800};
  font-size: ${({ theme }) => theme.sizes.spacing.medium};
  white-space: pre-line;
  text-align: center;
  font-weight: 400;
  margin: 0 0 34px 0;
`

export const ButtonsContainer = styled.div`
  margin-bottom: ${({ theme }) => theme.sizes.spacing.medium};
  grid-template-columns: 1fr 1fr;
  width: 100%;
  display: grid;
  grid-gap: 20px;
`
