import { OrderStatus } from './model'

export const OrderStatusTranslateHashMap = {
  [OrderStatus.Payed]: 'orderDetail.status.payed',
  [OrderStatus.Pending]: 'orderDetail.status.pending',
  [OrderStatus.Processing]: 'orderDetail.status.processing',
  [OrderStatus.Deleted]: 'orderDetail.status.deleted',
  [OrderStatus.New]: 'orderDetail.status.pending',
}

export const OrderStatusColorsHashMap = {
  [OrderStatus.Payed]: 'tertiary600',
  [OrderStatus.Pending]: 'primary700',
  [OrderStatus.Processing]: 'secondary500',
  [OrderStatus.Deleted]: 'error500',
  [OrderStatus.New]: 'primary700',
}
