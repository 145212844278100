import { ShoppingCartState } from './model'

export const UPDATE_PRODUCTS_SET_AT_SHOPPING_CART =
  'SHOPPING_CART/UPDATE_PRODUCTS_SET_AT_SHOPPING_CART'

export const TOGGLE_SHOPPING_CART_DRAWER =
  'SHOPPING_CART/TOGGLE_SHOPPING_CART_DRAWER'

export const TOGGLE_SHOPPING_SUMMARY_DRAWER =
  'SHOPPING_CART/TOGGLE_SHOPPING_SUMMARY_DRAWER'

const ShoppingCartReducer = (
  state: ShoppingCartState,
  action: { payload: any; type: string }
): ShoppingCartState => {
  const { payload, type } = action

  switch (type) {
    case UPDATE_PRODUCTS_SET_AT_SHOPPING_CART:
      return {
        ...state,
        productsSet: payload,
      }
    case TOGGLE_SHOPPING_CART_DRAWER:
      return {
        ...state,
        isDrawerOpen: payload,
      }
    case TOGGLE_SHOPPING_SUMMARY_DRAWER:
      return {
        ...state,
        isSummaryOpen: payload,
      }
    default:
      return state
  }
}

export default ShoppingCartReducer
