import { useHistory } from 'react-router-dom'
import useGAEventTracker from '../../hooks/useGAEventTracker'
import { GoToIcon, IconContainer, Label, MainContainer } from './styles'

export interface MenuLinkProps {
  Icon: React.FunctionComponent
  label: string
  goTo: string
  eventName: string
  onClose: () => void
}

const MenuLink = ({
  label,
  Icon,
  goTo,
  eventName,
  onClose,
}: MenuLinkProps): JSX.Element => {
  const history = useHistory()
  const GAEventsTracker = useGAEventTracker('webshop')
  return (
    <MainContainer
      onClick={(e) => {
        e.stopPropagation()
        history.push(goTo)
        GAEventsTracker(eventName)
        onClose()
      }}
    >
      <IconContainer>
        <Icon />
      </IconContainer>
      <Label>{label}</Label>
      <IconContainer>
        <GoToIcon />
      </IconContainer>
    </MainContainer>
  )
}

export default MenuLink
