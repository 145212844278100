import { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { shareMessageWhatsApp } from '../../../utils/share'
import { WhatsappButtonDelivery } from './WhatsappButtonDelivery'
import { NUMBER_PHONE_CX_TREINTA } from '../../../config/constants'
import { DeliveryContext } from '../../../context/Delivery/DeliveryContext'

export const WhatsappButtonDeliveryLoad = (): JSX.Element => {
  const { t } = useTranslation()
  const {
    state: { storeInfo },
  } = useContext(DeliveryContext)

  const handleClick = () => {
    shareMessageWhatsApp({
      text: t('delivery.messageWhatsapp', {
        storeName: storeInfo?.name,
        storeCountry: storeInfo?.country,
      }),
      phone: NUMBER_PHONE_CX_TREINTA,
    })
  }

  return <WhatsappButtonDelivery handleClick={handleClick} />
}
