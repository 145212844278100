import { TextToastMessageAlert, ToastContainer, WarningIcon } from './style'

export type itemProps = {
  textItem: string
  handleClickToast: () => void
}

export const ToastMessageAlert = ({
  textItem,
  handleClickToast,
}: itemProps): JSX.Element => {
  return (
    <ToastContainer onClick={handleClickToast}>
      <WarningIcon />
      <TextToastMessageAlert>
        <div
          dangerouslySetInnerHTML={{
            __html: textItem,
          }}
        />
      </TextToastMessageAlert>
    </ToastContainer>
  )
}
