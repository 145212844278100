import { CustomInput } from './styles'
import { customTextAreaType } from './contracts'

export const Input = ({
  type,
  onKeyUp,
  minLength,
  maxLength,
  onKeyPress,
  placeholder,
  ...rest
}: customTextAreaType): JSX.Element => {
  const customInputProps = {
    ...rest,
    onKeyUp: onKeyUp,
    type: type || 'text',
    onKeyPress: onKeyPress,
    minLength: minLength || 0,
    maxLength: maxLength || 32,
    placeholder: placeholder || '',
  }

  return <CustomInput {...customInputProps} />
}
