import { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { format } from 'date-fns'

import { Order } from '../../context/Orders/model'
import { useStoreConfig } from '../../context/AppContext'
import { countries } from '../../config/countries'
import { formatterCurrency } from '../../utils/functions'
import {
  LeftCointainer,
  LogoContainer,
  MainContainer,
  MetadataContainer,
  OrderPrice,
  OrderStatus,
  Subtitle,
} from './styles'
import { Typography } from '../../components/common/Typography'
import { useHistory, useRouteMatch } from 'react-router-dom'
import {
  OrderStatusColorsHashMap,
  OrderStatusTranslateHashMap,
} from '../../context/Orders/constants'
import useGAEventTracker from '../../hooks/useGAEventTracker'

export const formatOrderDate = (date: Date): string => {
  return format(date, 'dd LLL yyyy')
}

export const formatTransactionId = (id: string): string => {
  return `Pedido #${id.slice(0, 6).toUpperCase()}`
}

const UserOrder = ({
  id,
  createdAt,
  storeImage,
  storeName,
  total,
  deliveryPrice,
  transactionStatusId,
}: Order): ReactElement => {
  const { store } = useStoreConfig()
  const country = countries.get(store?.country)
  const history = useHistory()
  const { url } = useRouteMatch()
  const { t } = useTranslation()
  const GAEventsTracker = useGAEventTracker('webshop')
  return (
    <MainContainer
      onClick={() => {
        GAEventsTracker('webshop_menu_click_order')
        history.push(`${url}/${id}`)
      }}
    >
      <LeftCointainer>
        <LogoContainer src={storeImage} />
        <MetadataContainer>
          <Typography variant="Small">{storeName}</Typography>
          <Subtitle variant="XSmall">
            {`${formatTransactionId(id)} | ${formatOrderDate(createdAt)}`}
          </Subtitle>
        </MetadataContainer>
      </LeftCointainer>

      <div>
        <OrderPrice data-testid="total-order-price">
          {formatterCurrency(country, total + Number(deliveryPrice))}
        </OrderPrice>
        <OrderStatus
          color={OrderStatusColorsHashMap[transactionStatusId] as string}
          data-testid="order-status"
        >
          {t(OrderStatusTranslateHashMap[transactionStatusId])}
        </OrderStatus>
      </div>
    </MainContainer>
  )
}

export default UserOrder
