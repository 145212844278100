import styled from 'styled-components'
import { FontsName } from '../../../../styles/fonts'
import { Typography } from '../../../common/Typography'

export const Container = styled.li`
  display: flex;
  padding: ${({ theme }) => theme.sizes.spacing.medium} 0;

  &:not(:last-child) {
    border-bottom: 0.5px solid ${({ theme }) => theme.colors.neutral400};
  }
`

export const ContainerImage = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  margin-right: ${({ theme }) => theme.sizes.spacing.medium};
`

export const TextDiscount = styled(Typography)`
  ${({ theme }) => theme.fonts[FontsName.Nunito].xxsmallbold};
  background-color: ${({ theme: { colors } }) => colors.tertiary600};
  border-radius: ${({ theme: { sizes } }) => sizes.borderRadius.medium};
  padding: ${({ theme: { sizes } }) =>
    `${sizes.spacing.xxxsmall} ${sizes.spacing.xxsmall}`};
  color: ${({ theme: { colors } }) => colors.white};
  position: absolute;
  width: auto;
  top: 0px;
  right: 0px;
`

export const ProductImage = styled.img`
  width: 56px;
  height: 48px;
  ${({ theme }) => `
    border-radius: ${theme.sizes.borderRadius.large}
  `};
`

export const ProductInfo = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

export const ProductName = styled(Typography).attrs(() => ({
  variant: 'Small',
}))`
  ${({ theme }) => `
    color: ${theme.colors.neutral700};
  `};
`
export const ProductPrices = styled.div`
  width: 100%;
`
export const OriginalPrice = styled(Typography).attrs({
  variant: 'XSmall',
})<{ lineThrough?: boolean }>`
  color: ${({ theme: { colors } }) => colors.neutral600};
  text-decoration-line: ${({ lineThrough }) =>
    lineThrough ? 'line-through' : 'none'};
  display: inline-block;
  margin-right: 4px;
`
export const DiscountPrice = styled(Typography).attrs({
  variant: 'Smallbold',
})`
  color: ${({ theme: { colors } }) => colors.secondary700};
  display: inline-block;
`
export const ContainerButton = styled.div`
  display: flex;
  align-items: center;

  && > div {
    height: auto;
  }
`
export const AlertLabel = styled(Typography).attrs({
  variant: 'XSmall',
})`
  color: ${({ theme: { colors } }) => colors.error500};
  display: inline-block;
`
