import { actionTypes } from './contracts'
import { removeDuplicates } from '../../utils/functions'
import { IProductItemList, IProductTotal } from '../../types/models'

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const shoppingCartReducer = (state: any, action: any) => {
  switch (action.type) {
    case actionTypes.ADD_TO_CART: {
      const product = action.payload
      state.productsInCart.push(product)
      return {
        ...state,
        productsInCart: removeDuplicates(state.productsInCart, 'id'),
        closeOrder: false,
      }
    }
    case actionTypes.ADD_QUANTITY: {
      const product: IProductItemList = state.productsInCart.filter(
        (product: IProductItemList) => product?.id === action.payload
      )[0]
      product.quantity = (product?.quantity * 10 + 1 * 10) / 10
      product.total = product?.quantity * product?.price
      return {
        ...state,
        productsInCart: [...state.productsInCart],
      }
    }
    case actionTypes.REMOVE_QUANTITY: {
      const product: IProductItemList = state.productsInCart.filter(
        (product: IProductItemList) => product?.id === action.payload
      )[0]

      if (product.quantity > 1) {
        product.quantity = (product?.quantity * 10 - 1 * 10) / 10

        product.total = product?.quantity * product?.price
      } else {
        const deleteProduct = state.productsInCart.filter(
          (product: IProductItemList) => product?.id !== action.payload
        )
        return {
          ...state,
          productsInCart: deleteProduct,
        }
      }
      return {
        ...state,
        productsInCart: [...state.productsInCart],
      }
    }
    case actionTypes.ONCHANGE_QUANTITY: {
      const { id, quantity } = action.payload
      const product: IProductItemList = state.productsInCart.filter(
        (product: IProductItemList) => product?.id === id
      )[0]

      product.quantity = quantity
      product.total = product?.quantity * product?.price
      return {
        ...state,
        productsInCart: [...state.productsInCart],
      }
    }
    case actionTypes.CALCULATE_TOTAL: {
      let products: any = []
      const totals: any = []
      if (state.productsInCart.length > 0) {
        state.productsInCart.forEach((product: IProductItemList) =>
          products.push({ id: product.id, total: product.total })
        )
        products = removeDuplicates(products, 'id')
        products.forEach((item: IProductTotal) => totals.push(item.total))

        const calculateTotal = totals.reduce((a: number, b: number) => {
          return a + b
        })
        return {
          ...state,
          totalPayable: calculateTotal,
        }
      }
      return {
        ...state,
        totalPayable: 0,
      }
    }
    case actionTypes.ACTIVE_SHOPPING_CART: {
      return {
        ...state,
        activeShoppingCart: action.payload,
      }
    }
    case actionTypes.VALIDATE_FORM_PERSONAL_DATA:
      return {
        ...state,
        validateFormPersonalData: action.payload,
      }
    case actionTypes.VALIDATE_FORM_DELIVERY_METHOD:
      return {
        ...state,
        validateFormDeliveryMethod: action.payload,
      }
    case actionTypes.VALIDATE_ORDER_PRODUCTS:
      return {
        ...state,
        validateOrderProducts: action.payload,
      }
    case actionTypes.CLOSE_ORDER:
      return {
        productsInCart: [],
        totalPayable: 0,
        order: [],
        validateFormPersonalData: true,
        validateFormDeliveryMethod: true,
        validateOrderProducts: true,
        messageOrderDetails: '',
        closeOrder: true,
        buyersName: '',
        buyersPhone: '',
        buyersCountryId: 8,
        deliveryAddress: '',
        buyersComments: '',
        selectedDeliveryMethod: '',
        fetchError: false,
      }
    case actionTypes.BUYERS_NAME:
      return {
        ...state,
        buyersName: action.payload,
      }
    case actionTypes.BUYERS_PHONE:
      return {
        ...state,
        buyersPhone: action.payload,
      }
    case actionTypes.BUYERS_COUNTRY_ID:
      return {
        ...state,
        buyersCountryId: action.payload,
      }
    case actionTypes.DELIVERY_ADDRESS: {
      return {
        ...state,
        deliveryAddress: action.payload,
      }
    }
    case actionTypes.BUYERS_COMMENTS: {
      return {
        ...state,
        buyersComments: action.payload,
      }
    }
    case actionTypes.SELECTED_DELIVERY_METHOD: {
      return {
        ...state,
        selectedDeliveryMethod: action.payload,
      }
    }
    case actionTypes.FETCH_ERROR: {
      return {
        ...state,
        fetchError: action.payload,
      }
    }
    case actionTypes.SET_COUNTRY: {
      return {
        ...state,
        country: action.payload,
      }
    }
    case actionTypes.SET_OPEN_SHOPPING_CART: {
      return {
        ...state,
        openShoppingCart: action.payload,
      }
    }
    case actionTypes.SELECTED_PAYMENT_METHOD: {
      return {
        ...state,
        selectedPaymentMethod: action.payload,
      }
    }
    case actionTypes.SET_CHECKOUT_STEP: {
      return {
        ...state,
        checkoutStep: action.payload,
      }
    }
    case actionTypes.SET_SELECTED_DELIVERY_AREA: {
      return {
        ...state,
        selectedDeliveyArea: action.payload,
      }
    }
    default:
      return state
  }
}
