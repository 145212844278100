import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { FormControlLabel } from '@material-ui/core'

import {
  Title,
  Container,
  CustomFormControl,
  RadioGroupWrapper,
} from './styles'
import { StyledRadio } from '../../styles/StyledRadio'
import { useStoreConfig } from '../../../../context/AppContext'
import { DeliveryMethod } from '../../../../config/delivery-method'
import useGAEventTracker from '../../../../hooks/useGAEventTracker'
import { Typography } from '../../../../components/common/Typography'
import { useCatalogState } from '../../../../context/ShoppingCartContext'

export const FormDeliveryMethod = (): JSX.Element => {
  const {
    store: { config },
  } = useStoreConfig()
  const {
    setDeliveryAddress,
    setSelectedDeliveryMethod,
    setValidateFormDeliveryMethod,
    state: { selectedDeliveryMethod },
  } = useCatalogState()
  const { t } = useTranslation()
  const storePickUp = config?.storePickUp
  const [address] = useState<string>('')
  const GAEventsTracker = useGAEventTracker('webshop')
  const [trackDelivery, setTrackDelivery] = useState(false)
  const [trackStorePickup, setTrackStorePickup] = useState(false)

  const selectHandleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setSelectedDeliveryMethod(Number(event.target.value))
    GAEventsTracker('webshop_deliverytype')
    if (Number(event.target.value) === DeliveryMethod.HOME_DELIVERY) {
      if (!trackDelivery) {
        GAEventsTracker('webshop_delivery')
        setTrackDelivery(true)
      }
    }
    if (Number(event.target.value) === DeliveryMethod.PICK_UP_IN_STORE) {
      if (!trackStorePickup) {
        GAEventsTracker('webshop_pick_up')
        setTrackStorePickup(true)
      }
    }
  }

  useEffect(() => {
    setDeliveryAddress(address)
  }, [address])

  useEffect(() => {
    const validateAdress = (): boolean =>
      address && address.length > 5 ? false : true
    setValidateFormDeliveryMethod(
      selectedDeliveryMethod === DeliveryMethod.HOME_DELIVERY
        ? validateAdress()
        : false
    )
  }, [address, selectedDeliveryMethod])

  return storePickUp ? (
    <Container>
      <Title forwardedAs="h2" variant="Mediumbold">
        {t('sectionForm.deliveryMethod')}
      </Title>
      <CustomFormControl>
        <RadioGroupWrapper
          name="customized-radios"
          value={selectedDeliveryMethod}
          defaultValue="deliveryMethod"
          onChange={selectHandleChange}
        >
          <FormControlLabel
            value={1}
            control={<StyledRadio />}
            label={
              <Typography forwardedAs="p" variant="Medium">
                {t('sectionForm.pickUpInStore')}
              </Typography>
            }
          />
        </RadioGroupWrapper>
      </CustomFormControl>
    </Container>
  ) : (
    <></>
  )
}
