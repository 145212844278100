import { useEffect } from 'react'

import { Button, ButtonVariants } from '../Button'
import useGAEventTracker from '../../../hooks/useGAEventTracker'
import {
  ButtonsContainer,
  ButtonStyles,
  ButtonWidth,
  Confirmation,
  Content,
  MainContainer,
  Opacity,
  Subtitle,
  Title,
} from './style'

export type DrawerProps = {
  textTitle: string
  textSbTitle: string
  textConfirm: string
  textLeftButton: string
  textRightButton: string
  eventLeft: string
  eventRight: string
  eventOpacity?: string
  onEventLeftButton: () => void
  onEventRightButton: () => void
  onEventOpacitySection: () => void
  iconAlert: any
}

export const AlertDrawer = ({
  textTitle,
  textSbTitle,
  textConfirm,
  textLeftButton,
  textRightButton,
  eventLeft,
  eventRight,
  eventOpacity,
  onEventLeftButton,
  onEventRightButton,
  onEventOpacitySection,
  iconAlert,
}: DrawerProps): JSX.Element => {
  const GAEventsTracker = useGAEventTracker('webshop')

  useEffect(() => {
    window.scrollTo(0, document.body.scrollHeight)
  }, [])

  const handleClickOpacity = () => {
    if (eventOpacity !== '') GAEventsTracker(eventOpacity)
    onEventOpacitySection()
  }

  const handleClickLeftEvent = () => {
    if (eventLeft !== '') GAEventsTracker(eventLeft)
    onEventLeftButton()
  }
  const handleClickRightEvent = () => {
    if (eventRight !== '') GAEventsTracker(eventRight)
    onEventRightButton()
  }

  return (
    <MainContainer>
      <Opacity data-testid="opacity" onClick={handleClickOpacity} />

      <Content>
        {iconAlert}
        <Title>{textTitle}</Title>
        {textSbTitle != '' && <Subtitle>{textSbTitle}</Subtitle>}
        <Confirmation>
          <div
            dangerouslySetInnerHTML={{
              __html: textConfirm,
            }}
          />
        </Confirmation>

        <ButtonsContainer>
          <Button
            text={textLeftButton}
            onClick={handleClickLeftEvent}
            variant={ButtonVariants.Secondary}
            style={ButtonStyles}
          ></Button>
          <Button
            text={textRightButton}
            style={ButtonWidth}
            onClick={handleClickRightEvent}
          ></Button>
        </ButtonsContainer>
      </Content>
    </MainContainer>
  )
}
