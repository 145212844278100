import { useTranslation } from 'react-i18next'

import { Divider, Label, LabelTime, WrapperIcon } from '../styles'
import { ReactComponent as IconClockClose } from '../../../../../assets/icons/clock-close.svg'
import { formatTime } from '../utils'

interface Props {
  start: string
  end: string
  day: string
}

export const CloseTime = ({ start, end, day }: Props): JSX.Element => {
  const { t } = useTranslation()
  return (
    <>
      <WrapperIcon>
        <IconClockClose />
      </WrapperIcon>
      <Label isOpen={false}>{t('scheduler.close')}</Label>
      <Divider>·</Divider>
      <LabelTime>
        {t('scheduler.nextOpen', {
          day,
          start: formatTime(start),
          end: formatTime(end),
        })}
      </LabelTime>
    </>
  )
}
