import i18n from '../../translations'
import { PT_LANGUAGE } from '../../translations'
import { ReactComponent as TreintaShopLogo } from '../../assets/treintaShopLogo1.svg'
import { ReactComponent as TreintaShopPrLogo } from '../../assets/TrintaSHOP-Footer.svg'
import { ReactComponent as TreintaShopLogoBase } from '../../assets/treinta-menu-logo.svg'
import { ReactComponent as TreintaShopPrLogoBase } from '../../assets/TrintaSHOP-Header.svg'

export const getLogoFooterFromLanguage = (): any => {
  return i18n.language === PT_LANGUAGE ? TreintaShopPrLogo : TreintaShopLogo
}

export const getLogoHeaderFromLanguage = (): any => {
  return i18n.language === PT_LANGUAGE
    ? TreintaShopPrLogoBase
    : TreintaShopLogoBase
}
