import { useCallback, useContext } from 'react'

import CatalogContext from '../context/Catalog/context'

export const useHasDiscounts = (): boolean => {
  const {
    state: { products },
  } = useContext(CatalogContext)

  const hasDiscounts = useCallback(
    () => !!products?.find((product) => !!product.discount),
    [products]
  )

  return hasDiscounts()
}
