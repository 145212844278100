import styled from 'styled-components'
import Autocomplete from 'react-google-autocomplete'

import { FormControl } from '@material-ui/core'

import { Input } from '../../Input'
import { FontsName } from '../../../styles/fonts'
import { Typography } from '../../common/Typography'
import { ReactComponent as BackIconImage } from '../../../assets/arrowBack.svg'
import { ReactComponent as InputIconImage } from '../../../assets/icons/searchMini.svg'
import { ReactComponent as CloseIconImage } from '../../../assets/icons/simpleClose.svg'

export const AutoCompleteCities = styled(Autocomplete)`
  padding: ${({
    theme: { sizes },
  }) => `${sizes.spacing.xsmall} ${sizes.spacing.medium} 
    ${sizes.spacing.xsmall}  ${sizes.spacing.xxlarge} `};
  ${({ theme }) => theme.fonts[FontsName.Nunito].medium};
  border: ${({ theme }) => `0.5px solid ${theme.colors.neutral600}`};
  width: 100%;
  position: relative;
  border-radius: 4px;
  &:hover {
    border: ${({ theme }) => `1px solid ${theme.colors.neutral800}`};
  }
`

export const InputTextForm = styled(Input)`
  border: ${({ theme }) => `0.5px solid ${theme.colors.neutral600}`};
  padding: ${({ theme: { sizes } }) =>
    `${sizes.spacing.xsmall} ${sizes.spacing.medium} `};
  height: auto;
`

export const Title = styled(Typography)`
  ${({ theme }) => theme.fonts[FontsName.Nunito].largebold}
  color: ${({ theme }) => theme.colors.secondary700};
  margin-top: ${({ theme: { sizes } }) => sizes.spacing.small};
  margin-bottom: ${({ theme: { sizes } }) => sizes.spacing.large};
  flex-direction: row;
  align-items: center;
  display: flex;
  padding: 0 ${({ theme }) => theme.sizes.spacing.medium};
  width: 100%;
`
export const SubTitle = styled(Typography)`
  ${({ theme }) => theme.fonts[FontsName.Nunito].xlargebold}
  margin-bottom: ${({ theme: { sizes } }) => sizes.spacing.medium};
  color: ${({ theme }) => theme.colors.secondary700};
  padding: 0 ${({ theme }) => theme.sizes.spacing.medium};
`
export const FormContainer = styled(FormControl)`
  display: flex;
  padding: 0 ${({ theme }) => theme.sizes.spacing.medium};
  width: 100%;
  margin-bottom: 1rem;
  flex-direction: column;
  min-height: 100vh;
  padding-bottom: 1000px;
`

export const LabelInputForm = styled.label`
  ${({ theme }) => theme.fonts[FontsName.Nunito].mediumbold}
  margin-bottom: ${({ theme: { sizes } }) => sizes.spacing.xxsmall};
  color: ${({ theme }) => theme.colors.neutral800};
  width: 100%;
`
export const InputContainer = styled.form`
  margin-bottom: ${({ theme: { sizes } }) => sizes.spacing.medium};
  ${({ theme }) => theme.fonts[FontsName.Nunito].medium}
  color: ${({ theme }) => theme.colors.neutral700};
  width: 100%;
  padding: 0 ${({ theme }) => theme.sizes.spacing.medium};
`
export const ErrorMessage = styled(Typography)`
  margin-top: ${({ theme: { sizes } }) => sizes.spacing.xxsmall};
  color: ${({ theme }) => theme.colors.error500};
`

export const InfoButtonContainer = styled.div`
  width: 100%;
  box-shadow: 2px -4px 8px rgba(34, 38, 42, 0.05);
  padding: 8px 16px;
  background-color: ${({ theme }) => theme.colors.white};
  position: absolute;
  left: 0;
  bottom: 0;
`

export const InfoButton = {
  width: '100%',
  maxWidth: 'none',
}

export const BackIcon = styled(BackIconImage)`
  width: ${({ theme: { sizes } }) => sizes.spacing.medium};
  min-width: ${({ theme: { sizes } }) => sizes.spacing.medium};
  margin-right: ${({ theme: { sizes } }) => sizes.spacing.small};
  cursor: pointer;
  height: auto;
`
export const CloseIcon = styled(CloseIconImage)`
  width: ${({ theme: { sizes } }) => sizes.spacing.small};
  min-width: ${({ theme: { sizes } }) => sizes.spacing.small};
  margin-top: ${({ theme: { sizes } }) => sizes.spacing.xxsmall};
  position: absolute;
  cursor: pointer;
  height: auto;
  right: ${({ theme }) => theme.sizes.spacing.medium};
`
export const InputIcon = styled(InputIconImage)`
  top: 9.4rem;
  left: 1.8rem;
  position: absolute;
  height: auto;
  z-index: 9;
`

/* Form Shipping Information Second  */
export const LabelRadio = styled.div`
  margin-bottom: ${({ theme: { sizes } }) => sizes.spacing.medium};
  margin-left: ${({ theme: { sizes } }) => sizes.spacing.xsmall};
  width: 100%;
`
export const LabelCheckbox = styled.div`
  width: 100%;
`
export const TextLabelName = styled(Typography)`
  ${({ theme }) => theme.fonts[FontsName.Nunito].medium}
  margin-bottom: ${({ theme: { sizes } }) => sizes.spacing.xsmall};
  margin-top: ${({ theme: { sizes } }) => sizes.spacing.xsmall};
  color: ${({ theme }) => theme.colors.secondary700};
`
export const TextLabelDescription = styled(Typography)`
  ${({ theme }) => theme.fonts[FontsName.Nunito].xsmall}
  color: ${({ theme }) => theme.colors.neutral700};
  white-space: pre-line;
`
export const TextLabelCbDescription = styled(Typography)`
  ${({ theme }) => theme.fonts[FontsName.Nunito].medium}
  color: ${({ theme }) => theme.colors.secondary700};
  margin-left: ${({ theme: { sizes } }) => sizes.spacing.xsmall};
  display: flex;
`
export const TextLabelCbLink = styled.a`
  ${({ theme }) => theme.fonts[FontsName.Nunito].mediumbold}
  margin-left: ${({ theme: { sizes } }) => sizes.spacing.xxsmall};
  color: ${({ theme }) => theme.colors.info700};
  text-decoration: underline;
`
export const DrawerContent = styled(Typography)`
  ${({ theme }) => theme.fonts[FontsName.Nunito].medium}
  color: ${({ theme }) => theme.colors.neutral600};
`

export const Divider = styled.div`
  padding-bottom: 20%;
`

export const Spacing = styled.div`
  padding: 10% 0;
`
