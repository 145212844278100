import { useEffect, useState } from 'react'

import { Factory } from './components'
import { ISchedule } from '../../../../types/models'
import { getNextDayOpen, getCurrentDay } from './utils'

interface Props {
  storeSchedule: ISchedule[]
  isOpenAllDay?: boolean
}

export const Scheduler = ({
  storeSchedule,
  isOpenAllDay,
}: Props): JSX.Element => {
  const [hourIn, setHourIn] = useState('')
  const [hourOut, setHourOut] = useState('')
  const [isOpen, setIsOpen] = useState(false)
  const [nextOpenTime, setNextOpenTime] = useState<ISchedule | null>(null)

  useEffect(() => {
    if (!storeSchedule) return

    const date = new Date()
    const day = getCurrentDay(storeSchedule)

    if (day) {
      if (day.isOpen) {
        const scheduleInArray = day.scheduleIn.split(':')
        const scheduleOutArray = day.scheduleOut.split(':')
        const dateIn = new Date()
        dateIn.setHours(parseInt(scheduleInArray[0]))
        dateIn.setMinutes(parseInt(scheduleInArray[1]))
        const dateOut = new Date()
        dateOut.setHours(parseInt(scheduleOutArray[0]))
        dateOut.setMinutes(parseInt(scheduleOutArray[1]))
        if (date >= dateIn && date <= dateOut) {
          setIsOpen(true)
          setHourIn(
            dateIn.toLocaleString('en-US', {
              hour: 'numeric',
              minute: 'numeric',
              hour12: true,
            })
          )
          setHourOut(
            dateOut.toLocaleString('en-US', {
              hour: 'numeric',
              minute: 'numeric',
              hour12: true,
            })
          )
        } else {
          setIsOpen(false)
          hasNextDayOpen() && setNextOpenTime(getNextDayOpen(storeSchedule))
        }
      } else {
        setIsOpen(false)
        hasNextDayOpen() && setNextOpenTime(getNextDayOpen(storeSchedule))
      }
    }
  }, [storeSchedule])

  const hasNextDayOpen = () =>
    storeSchedule?.find((singleDay) => singleDay.isOpen)

  return (
    <Factory
      hourIn={hourIn}
      hourOut={hourOut}
      isOpen={isOpen}
      nextOpenTime={nextOpenTime}
      isOpenAllDay={isOpenAllDay}
      hasNextDayOpen={!!hasNextDayOpen()}
    />
  )
}
