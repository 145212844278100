export enum FontsName {
  Nunito = 'nunito',
}

export enum Variants {
  XXXLarge = 'xxxlarge',
  XXXLargebold = 'xxxlargebold',
  XXLarge = 'xxlarge',
  XXLargebold = 'xxlargebold',
  XLarge = 'xlarge',
  XLargebold = 'xlargebold',
  Large = 'large',
  Largebold = 'largebold',
  Medium = 'medium',
  Mediumbold = 'mediumbold',
  Small = 'small',
  Smallbold = 'smallbold',
  XSmall = 'xsmall',
  XSmallbold = 'xsmallbold',
  XXSmall = 'xxsmall',
  XXSmallbold = 'xxsmallbold',
}

export type FontVariant = keyof typeof Variants;

type FontDetails = {
  fontFamily: string
  fontSize: string
  lineHeight: string
  fontWeight: number
  fontStyle: string
}

export const fonts = {
  nunito: {
    [Variants.XXXLarge]: {
      fontFamily: 'Nunito Sans',
      fontSize: '2rem',
      lineHeight: '2.5rem',
      fontWeight: 400,
      fontStyle: 'normal',
    },
    [Variants.XXXLargebold]: {
      fontFamily: 'Nunito Sans',
      fontSize: '2rem',
      lineHeight: '2.5rem',
      fontWeight: 700,
      fontStyle: 'normal',
    },
    [Variants.XXLarge]: {
      fontFamily: 'Nunito Sans',
      fontSize: '1.5rem',
      lineHeight: '2rem',
      fontWeight: 400,
      fontStyle: 'normal',
    },
    [Variants.XXLargebold]: {
      fontFamily: 'Nunito Sans',
      fontSize: '1.5rem',
      lineHeight: '2rem',
      fontWeight: 700,
      fontStyle: 'normal',
    },
    [Variants.XLarge]: {
      fontFamily: 'Nunito Sans',
      fontSize: '1.25rem',
      lineHeight: '2rem',
      fontWeight: 400,
      fontStyle: 'normal',
    },
    [Variants.XLargebold]: {
      fontFamily: 'Nunito Sans',
      fontSize: '1.25rem',
      lineHeight: '2rem',
      fontWeight: 700,
      fontStyle: 'normal',
    },
    [Variants.Large]: {
      fontFamily: 'Nunito Sans',
      fontSize: '1.125rem',
      lineHeight: '1.5rem',
      fontWeight: 400,
      fontStyle: 'normal',
    },
    [Variants.Largebold]: {
      fontFamily: 'Nunito Sans',
      fontSize: '1.125rem',
      lineHeight: '1.5rem',
      fontWeight: 700,
      fontStyle: 'normal',
    },
    [Variants.Medium]: {
      fontFamily: 'Nunito Sans',
      fontSize: '1rem',
      lineHeight: '1.5rem',
      fontWeight: 400,
      fontStyle: 'normal',
    },
    [Variants.Mediumbold]: {
      fontFamily: 'Nunito Sans',
      fontSize: '1rem',
      lineHeight: '1.5rem',
      fontWeight: 700,
      fontStyle: 'normal',
    },
    [Variants.Small]: {
      fontFamily: 'Nunito Sans',
      fontSize: '0.875rem',
      lineHeight: '1.25rem',
      fontWeight: 400,
      fontStyle: 'normal',
    },
    [Variants.Smallbold]: {
      fontFamily: 'Nunito Sans',
      fontSize: '0.875rem',
      lineHeight: '1.25rem',
      fontWeight: 700,
      fontStyle: 'normal',
    },
    [Variants.XSmall]: {
      fontFamily: 'Nunito Sans',
      fontSize: '0.75rem',
      lineHeight: '1rem',
      fontWeight: 400,
      fontStyle: 'normal',
    },
    [Variants.XSmallbold]: {
      fontFamily: 'Nunito Sans',
      fontSize: '0.75rem',
      lineHeight: '1rem',
      fontWeight: 700,
      fontStyle: 'normal',
    },
    [Variants.XXSmall]: {
      fontFamily: 'Nunito Sans',
      fontSize: '0.625rem',
      lineHeight: '0.75rem',
      fontWeight: 400,
      fontStyle: 'normal',
    },
    [Variants.XXSmallbold]: {
      fontFamily: 'Nunito Sans',
      fontSize: '0.625rem',
      lineHeight: '0.75rem',
      fontWeight: 700,
      fontStyle: 'normal',
    },
  },
}

export type Fonts = {
  [FontsName.Nunito]: { [key in Variants]: FontDetails }
}
