import { useTranslation } from 'react-i18next'

import { AlertText, AlertTitle } from './styles'
import { AlertInfo } from '../../styles/AlertInfo/index'

export const PaymentMethodAlert = (): JSX.Element => {
  const { t } = useTranslation()
  return (
    <AlertInfo icon={false}>
      <AlertTitle>{t('formPaymentMethod.infoPaymentLinkTitle')}</AlertTitle>
      <AlertText>{t('formPaymentMethod.infoPaymentLink')}</AlertText>
    </AlertInfo>
  )
}
