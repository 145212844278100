import styled from 'styled-components'

import IconMenu from '../../../../assets/icons/hamburgerMenu.svg'

export const HamburgerMenu = styled.button`
  width: 20px;
  height: 18px;
  background-image: url(${IconMenu});
  background-repeat: no-repeat;
  border: 0;
  cursor: pointer;
  padding: 0;
  background-color: white;
`
