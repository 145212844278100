import { createStyles, withStyles, Theme } from '@material-ui/core/styles'
import { Alert } from '@material-ui/lab'

import { colors } from '../../../../styles/colors'

export const AlertInfo = withStyles((theme: Theme) =>
    createStyles({
        root: {
            backgroundColor: colors.info100,
            marginTop: theme.spacing(1),
        }
    })
)(Alert)