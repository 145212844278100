import { useContext, useEffect, useState } from 'react'

import MyOrders from '.'
import { updateOrdersUser } from '../../services/order'
import { getUserPhone } from '../../common/services/signIn'
import { getPhone } from '../../components-v0/UserOrdersList/helpers'
import { StoreAuthContext } from '../../context/StoreAuthContext/StoreAuthContext'
import LoadingPage from '../Pages/Loading'

export const MyOrdersLoad = (): JSX.Element => {
  const {
    state: { country },
  } = useContext(StoreAuthContext)
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const sendService = async () => {
    const userPhone = await getUserPhone()
    const phone = getPhone(userPhone || '', country)
    await updateOrdersUser(phone)
    setIsLoading(false)
  }

  useEffect(() => {
    sendService()
  }, [])

  return isLoading ? <LoadingPage /> : <MyOrders />
}
