import styled from 'styled-components'

import { FontsName } from '../../../styles/fonts'
import { Typography } from '../../common/Typography'
import { ReactComponent as WarningIconImage } from '../../../assets/icons/warning-icon.svg'

export const ToastContainer = styled.div`
  padding: ${({ theme: { sizes } }) => sizes.spacing.medium};
  background-color: ${({ theme }) => theme.colors.warning300};
  display: flex;
  align-items: center;
  justify-content: center;
`
export const WarningIcon = styled(WarningIconImage)`
  width: ${({ theme: { sizes } }) => sizes.spacing.xlarge};
  min-width: ${({ theme: { sizes } }) => sizes.spacing.xlarge};
  margin-right: ${({ theme: { sizes } }) => sizes.spacing.medium};
  cursor: pointer;
  height: auto;
`
export const TextToastMessage = styled(Typography)`
  ${({ theme }) => theme.fonts[FontsName.Nunito].small};
  color: ${({ theme }) => theme.colors.info700};
  margin-top: ${({ theme }) => theme.sizes.spacing.large};
  margin-bottom: ${({ theme }) => theme.sizes.spacing.xxxsmall};
  background-color: ${({ theme }) => theme.colors.info100};
  border-radius: ${({ theme: { sizes } }) => sizes.borderRadius.large};
  padding: ${({ theme }) =>
    `${theme.sizes.spacing.medium} ${theme.sizes.spacing.large}`};
  white-space: pre-line;
`
export const TextToastMessageAlert = styled(Typography)`
  ${({ theme }) => theme.fonts[FontsName.Nunito].medium};
  color: ${({ theme }) => theme.colors.warning700};
  white-space: pre-line;
  cursor: pointer;
`
