import styled from 'styled-components'
import { customInputType } from './contracts'

const getBorder = (append?: boolean, preppend?: boolean) => {
  if (!append && !preppend) {
    return '0.25rem'
  }

  if (append) {
    return '0px 4px 4px 0px'
  }

  return '4px 0px 0px 4px'
}

export const CustomInput = styled.input<customInputType>`
  flex: 1;
  width: 100%;
  height: 100%;
  margin: 0rem;
  font-size: ${({ theme }) => theme.sizes.spacing.medium};
  font-family: Nunito Sans;
  color: ${({ theme }) => theme.colors.secondary700};
  ::placeholder {
    color: #808080;
  }
  :invalid {
    border: 0.5px solid ${({ theme }) => theme.colors.error500};
    ${({ append }) => append && 'border-left: 0px'}
  }
  padding: 0.5rem 1rem;
  background: ${({ theme }) => theme.colors.white};
  border: ${({ withoutBorder, theme }) =>
    withoutBorder ? 'none' : `0.5px solid ${theme.colors.neutral600}`};
  min-height: ${({ withoutBorder }) => (withoutBorder ? '24px' : '22px')};
  border-radius: ${({ append, preppend }) => getBorder(append, preppend)};
  ${({ error, append, theme }) => {
    let styles = ''
    if (error) {
      styles = `border: 0.5px solid ${theme.colors.error500};`
    }
    if (append) {
      styles = `${styles}
      border-left: 0px;
      `
    }
    return styles
  }}
  ${({ append }) => append && 'border-left: 0rem'}
`
