import styled from 'styled-components'
import { Typography } from '../common/Typography'

export const MainContainer = styled.div`
  height: 100%;
  position: relative;
  width: 100%;
`

export const Image = styled.img`
  border-radius: ${({ theme: { sizes } }) => sizes.borderRadius.large};
  height: 100%;
  object-fit: contain;
  width: 100%;
`

export const FloatingContainer = styled.div`
  align-items: center;
  bottom: ${({ theme: { sizes } }) => sizes.spacing.small};
  display: flex;
  height: 28px;
  justify-content: center;
  padding-left: ${({ theme: { sizes } }) => sizes.spacing.large};
  padding-right: ${({ theme: { sizes } }) => sizes.spacing.large};
  position: absolute;
  width: 100%;
`

export const HelpLabelContainer = styled.div`
  align-items: center;
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: ${({ theme: { sizes } }) => sizes.borderRadius.small};
  cursor: pointer;
  display: flex;
  height: 100%;
  justify-content: center;
  width: 100%;

  &:hover {
    transform: scale(1.025);
  }
`

export const HelpLabel = styled(Typography).attrs({
  variant: 'XSmall',
})`
  color: ${({ theme: { colors } }) => colors.secondary500};
  margin-left: ${({ theme: { sizes } }) => sizes.spacing.xsmall};
`
