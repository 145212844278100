import { useEffect, useContext } from 'react'

import Providers from '../Providers/Providers'
import ConfirmShipment from '../ConfirmShipment'
import FormBankInformation from '../FormBankInformation'
import { FormShipping, FormShippingSecond } from '../FormShipping'
import { DeliverySummary } from '../DeliverySummary/DeliverySummary'
import { DeliveryContext } from '../../../context/Delivery/DeliveryContext'

const BaseDelivery = (): JSX.Element => {
  const { state, setDeliveryState } = useContext(DeliveryContext)
  useEffect(() => {
    loadActualStepView()
  }, [state.currentStep])

  const loadActualStepView = () => {
    switch (Number(state.currentStep)) {
      case 1:
        return (
          <FormShipping
            data-testid="form-shipping-test"
            state={state}
            setDeliveryState={setDeliveryState}
          />
        )
      case 2:
        return (
          <FormShippingSecond
            state={state}
            setDeliveryState={setDeliveryState}
          />
        )
      case 3:
        return <Providers state={state} setDeliveryState={setDeliveryState} />
      case 4:
        return (
          <DeliverySummary state={state} setDeliveryState={setDeliveryState} />
        )
      case 5:
        return (
          <ConfirmShipment state={state} setDeliveryState={setDeliveryState} />
        )
      case 6:
        return (
          <FormBankInformation
            state={state}
            setDeliveryState={setDeliveryState}
          />
        )
      default:
        return (
          <FormShipping state={state} setDeliveryState={setDeliveryState} />
        )
    }
  }
  return loadActualStepView()
}
export default BaseDelivery
