import styled from 'styled-components'

export const ProviderContainer = styled.li`
  width: 100%;
  border-bottom: 0.5px solid ${({ theme }) => theme.colors.neutral400};
  display: grid;
  grid-template-columns: 40px 1fr 100px 20px;
  grid-gap: 20px;
  padding-bottom: 16px;
  margin-bottom: 30px;
`

export const ImageSection = styled.section`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  overflow: hidden;
  align-self: center;
`

export const ProviderImage = styled.img`
  width: 100%;
  height: 100%;
`

export const InfoSection = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-self: center;
`

export const PriceSection = styled.section`
  align-self: center;
  text-align: right;
`

export const DeliveredAt = styled.p`
  margin: 0;
  padding: 0;
  font-weight: bold;
  font-size: ${({ theme }) => theme.sizes.spacing.medium};
`

export const Ensurance = styled.p`
  margin: 0;
  padding: 0;
  font-size: ${({ theme }) => theme.sizes.spacing.small};
  color: ${({ theme }) => theme.colors.neutral600};
`

export const DeliveredBy = styled.p`
  margin: 0;
  padding: 0;
  font-size: ${({ theme }) => theme.sizes.spacing.small};
  color: ${({ theme }) => theme.colors.neutral600};
`

export const ChevronSection = styled.section`
  align-self: center;
  display: flex;
  align-items: center;
`
