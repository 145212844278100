import styled from 'styled-components'
import { Link as ReactLink } from 'react-router-dom'

export const STORE_LOGO_STICKY_SIZE = 48
export const STORE_LOGO_NON_STICKY_SIZE = 32

export const Link = styled(ReactLink)`
  display: block;
`

export const Logo = styled.img<{ isSticky: boolean }>`
  ${({ theme, isSticky }) => `
    width: ${isSticky ? STORE_LOGO_NON_STICKY_SIZE : STORE_LOGO_STICKY_SIZE}px;
    height: ${isSticky ? STORE_LOGO_NON_STICKY_SIZE : STORE_LOGO_STICKY_SIZE}px;
    border: 0.031rem solid ${theme.colors.neutral500};
    border-radius: 50%;
    transition: all 0.25s ease;
`}
`
