import React, { useEffect, useState } from 'react'
import {
  createStyles,
  Theme,
  withStyles,
  WithStyles,
} from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import MuiDialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import Typography from '@material-ui/core/Typography'
import { IProduct, IProductItemList, IStore } from '../../types/models'
import { Grid, Box } from '@material-ui/core'
import { ReactComponent as IconBasket } from './../../assets/basket.svg'
import { countries } from '../../config/countries'
import { formatterCurrency } from '../../utils/functions'
import noPicture from './../../assets/emptyModalIcon.svg'
import { useCatalogState } from '../../context/ShoppingCartContext'
import { useStyles } from './styles'
import { useStoreConfig } from '../../context/AppContext'
import { STOCK_CONFIG } from '../../config/stock-config'
import useGAEventTracker from '../../hooks/useGAEventTracker'
import ReactImageMagnify from 'react-image-magnify'

const styles = (theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      width: '100%',
      height: 'auto',
      margin: 0,
      padding: 0,
    },
    closeButton: {
      top: theme.spacing(1),
      marginRight: 10,
      color: theme.palette.grey[500],
    },
    typographyTitleStyle: {
      fontSize: '1.3rem',
      fontWeight: 600,
      lineHeight: 1.2,
      letterSpacing: '0.0075em',
      textAlign: 'center',
    },

    gridContainerTitle: {
      flexGrow: 1,
      width: 'auto',
      padding: 5,
      marginTop: 5,
    },
  })

export interface DialogTitleProps extends WithStyles<typeof styles> {
  id: string
  children: React.ReactNode
  onClose: () => void
}

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
  const { children, classes, onClose, ...other } = props
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Grid container direction="row">
        <Grid xs={11} item className={classes.gridContainerTitle}>
          <Typography
            color="secondary"
            className={classes.typographyTitleStyle}
          >
            {children}
          </Typography>
        </Grid>
        <Grid item xs={1}>
          {onClose ? (
            <IconButton
              size="small"
              edge="start"
              aria-label="close"
              className={classes.closeButton}
              onClick={onClose}
            >
              <CloseIcon />
            </IconButton>
          ) : null}
        </Grid>
      </Grid>
    </MuiDialogTitle>
  )
})

const ModalDetailProduct = ({
  openDialog,
  setOpenDialog,
  details,
  store,
}: {
  openDialog: boolean
  setOpenDialog: any
  details: IProduct
  store: IStore
}): JSX.Element => {
  const props: any = {
    imageUrl: details?.imageUrl ? details?.imageUrl : noPicture,
  }
  const classes = useStyles(props)
  const [open, setOpen] = useState<boolean>(false)
  const {
    addToCart,
    calculateTotal,
    state,
    addQuantity,
  }: any = useCatalogState()
  const { stockConfig }: any = useStoreConfig()
  const country = countries.get(store.country)
  const productsInCart = state.productsInCart

  const disbledButton = (product: IProduct) => {
    if (
      stockConfig === STOCK_CONFIG.SHOW_DISABLED ||
      stockConfig === STOCK_CONFIG.DONT_SHOW
    ) {
      if (product.stock === 0) {
        return true
      }
    } else {
      return false
    }
  }

  const validateAddToCart = (productSelected: IProduct) => {
    const inCart = productsInCart.filter((product: IProductItemList) => {
      return product.id.includes(productSelected?.id)
    })
    if (inCart.length > 0) {
      addQuantity(productSelected?.id)
    } else {
      addToCart({
        id: productSelected?.id,
        name: productSelected?.name,
        price: productSelected?.price,
        imageUrl: productSelected?.imageUrl,
        notes: productSelected?.notes,
        quantity: 1,
        total: productSelected?.price,
      })
    }
    calculateTotal()
  }

  useEffect(() => {
    setOpen(openDialog)
  }, [openDialog, details])

  const GAEventsTracker = useGAEventTracker('webshop')

  return (
    <div>
      <Dialog
        maxWidth="lg"
        classes={{ paper: classes.dialogContainer }}
        onClose={() => {
          setOpen(false)
          setOpenDialog(false)
        }}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle
          id="customized-dialog-title"
          onClose={() => {
            setOpen(false)
            setOpenDialog(false)
          }}
        >
          {details?.name ? details?.name : ''}
        </DialogTitle>
        <DialogContent classes={{ root: classes.dialogContentStyle }}>
          <Grid
            container
            alignItems="center"
            justifyContent="center"
            className={classes.gridDialogContent}
          >
            <Grid item className={classes.gridPaperContainerStyle} xs={12}>
              <ReactImageMagnify
                imageStyle={{ borderRadius: 15 }}
                {...{
                  smallImage: {
                    alt: 'Product image | Treinta Shop',
                    isFluidWidth: true,
                    src: details?.imageUrl ? details?.imageUrl : noPicture,
                    srcSet: details?.imageUrl ? details?.imageUrl : noPicture,
                    sizes:
                      '(max-width: 400px) 90vw, (max-width: 1200px) 30vw, 360px',
                  },
                  largeImage: {
                    src: details?.imageUrl ? details?.imageUrl : noPicture,
                    width: 713,
                    height: 1000,
                  },
                  lensStyle: { backgroundColor: 'rgba(0,0,0,0.1)' },
                  isHintEnabled: true,
                  shouldHideHintAfterFirstActivation: false,
                  pressDuration: 50,
                  enlargedImagePosition: 'over',
                  hintTextMouse: 'Pasa el cursor para ampliar',
                  hintTextTouch: 'Mantenga presionado para ampliar',
                }}
              />
            </Grid>
            <Grid
              item
              xs
              container
              alignItems="center"
              direction="column"
              className={classes.gridContentStyle}
            >
              <Typography component="div" className={classes.detailTitleStyle}>
                <Box fontSize={16} fontWeight="fontWeightBold" lineHeight={2}>
                  Categoría:
                </Box>
              </Typography>
              <Typography
                component="div"
                className={classes.detailContentStyle}
              >
                <Box
                  fontSize={14}
                  fontWeight="fontWeightRegular"
                  lineHeight={1.2}
                >
                  {details?.categories ? details?.categories : 'General'}
                </Box>
              </Typography>
              {details?.notes ? (
                <>
                  <Typography
                    component="div"
                    className={classes.detailTitleStyle}
                  >
                    <Box
                      fontSize={16}
                      fontWeight="fontWeightBold"
                      lineHeight={2}
                    >
                      Descripción:
                    </Box>
                  </Typography>
                  <Typography
                    component="div"
                    className={classes.detailContentStyle}
                  >
                    <Box
                      fontSize={14}
                      fontWeight="fontWeightRegular"
                      lineHeight={1.2}
                    >
                      {details?.notes ? details?.notes : ''}
                    </Box>
                  </Typography>
                </>
              ) : (
                <></>
              )}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions classes={{ root: classes.dialogActionsStyle }}>
          <Button
            disabled={disbledButton(details)}
            classes={{
              root: classes.addIcon,
              contained: classes.addIconColor,
            }}
            variant="contained"
            startIcon={<IconBasket className={classes.iconStyle} />}
            onClick={() => {
              GAEventsTracker('webshop_product_select_add')

              setOpen(false)
              setOpenDialog(false)
              validateAddToCart(details)
            }}
          >
            {`AGREGAR ${formatterCurrency(country, details?.price)}`}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}
export default ModalDetailProduct
