import Axios from 'axios'

import {
  ServiceDetailOrder,
  OrderConfirmedResponse,
} from '../../../types/models'

export const getTransactionById = async (
  transactionId: string
): Promise<OrderConfirmedResponse | undefined> => {
  try {
    const response = await Axios({
      method: 'GET',
      headers: {
        'X-API-Key': process.env.REACT_APP_TREINTA_MICROSERVICES_API_KEY,
      },
      url: `${process.env.REACT_APP_TREINTA_MICROSERVICES_API_URL}/transaction/${transactionId}`,
    })
    const { data } = response
    return data
  } catch (error: any) {
    return undefined
  }
}

export const getTransactionDetail = async (
  transactionDetailId: string
): Promise<ServiceDetailOrder | undefined> => {
  try {
    const response = await Axios({
      method: 'GET',
      headers: {
        Authorization: process.env.REACT_APP_TOKEN,
        'X-API-Key': process.env.REACT_APP_TREINTA_MICROSERVICES_API_KEY,
      },
      url: `${process.env.REACT_APP_TREINTA_API_URL}/shop/transaction-details/${transactionDetailId}`,
    })
    const { data } = response
    return data
  } catch (error: any) {
    return undefined
  }
}
