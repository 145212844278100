import { useContext } from 'react'

import {
  Header,
  NavBar,
  ContainerLogo,
  ContainerMenu,
  ContainerShopingCartButton,
} from './styles'
import { Menu } from './components/Menu'
import { StoreLogo } from './components/StoreLogo'
import { useShoppingCart } from '../ShoppingCart/hooks'
import { useStoreConfig } from '../../context/AppContext'
import { ShopingCartButton } from './components/ShoppingCartButton'
import ShoppingCartContext from '../../context/ShoppingCart/context'
import SearchBar from '../SearchBar'

interface Props {
  name: string
  logo?: string
  isSticky: boolean
  maxChars: number
}

export const StoreHeader = ({
  logo,
  isSticky,
  name,
  maxChars,
}: Props): JSX.Element => {
  const {
    store: { urlName },
  } = useStoreConfig()
  const { handleClickShoppingCart } = useContext(ShoppingCartContext)
  const { totalProductsInCart } = useShoppingCart()

  return (
    <Header isSticky={isSticky}>
      <NavBar isSticky={isSticky}>
        <ContainerMenu isSticky={isSticky}>
          <Menu />
        </ContainerMenu>
        <ContainerLogo isSticky={isSticky}>
          {!isSticky ? (
            <StoreLogo
              logo={logo}
              name={name}
              url={urlName}
              isSticky={isSticky}
            />
          ) : (
            <SearchBar maxChars={maxChars} isSticky={isSticky} />
          )}
        </ContainerLogo>
        <ContainerShopingCartButton isSticky={isSticky}>
          <ShopingCartButton
            productsNumber={totalProductsInCart}
            setOpenShoppingCart={handleClickShoppingCart}
          />
        </ContainerShopingCartButton>
      </NavBar>
    </Header>
  )
}
