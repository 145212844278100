import {
  ICountry,
  IProductItemList,
  DeliveryPriceAreaResponse,
} from '../../types/models'
import { PaymentType } from '../../config/payment-type'
import { DeliveryMethod } from '../../config/delivery-method'

export type shoppingCartState = {
  order: any[]
  country: ICountry
  fetchError: false
  buyersName: string
  buyersPhone: string
  closeOrder: boolean
  totalPayable: number
  checkoutStep: number
  productsInCart: any[]
  buyersComments: string
  deliveryAddress: string
  buyersCountryId: number
  openShoppingCart: boolean
  activeShoppingCart: boolean
  messageOrderDetails: string
  validateOrderProducts: boolean
  validateFormPersonalData: boolean
  selectedPaymentMethod: PaymentType
  validateFormDeliveryMethod: boolean
  selectedDeliveryMethod: DeliveryMethod
  selectedDeliveyArea: DeliveryPriceAreaResponse | undefined
}

export interface shoppingCartContextType {
  state: shoppingCartState
  closeOrder: () => void
  createOrder: () => void
  calculateTotal: () => void
  addQuantity: (payload: string) => void
  setCountry: (payload: ICountry) => void
  setCountryId: (payload: number) => void
  setBuyersName: (payload: string) => void
  setFetchError: (payload: boolean) => void
  setBuyersPhone: (payload: string) => void
  removeQuantity: (payload: string) => void
  setCheckoutStep: (payload: number) => void
  setBuyersComments: (payload: string) => void
  setDeliveryAddress: (payload: string) => void
  addToCart: (payload: IProductItemList) => void
  setCountryPhoneCode: (payload: string) => void
  setOpenShoppingCart: (payload: boolean) => void
  setSelectedDeliveyArea: (
    payload: DeliveryPriceAreaResponse | undefined
  ) => void
  setValidateOrderProducts: (payload: boolean) => void
  setValidateFormPersonalData: (payload: boolean) => void
  onChangeQuantity: (id: string, quantity: number) => void
  setValidateFormDeliveryMethod: (payload: boolean) => void
  setSelectedDeliveryMethod: (payload: DeliveryMethod) => void
  setSelectedPaymentMethod: (paymentMethod: PaymentType) => void
}

export const actionTypes = {
  ADD_TO_CART: 'ADD_TO_CART',
  CLOSE_ORDER: 'CLOSE_ORDER',
  BUYERS_NAME: 'BUYERS_NAME',
  SET_COUNTRY: 'SET_COUNTRY',
  FETCH_ERROR: 'FETCH_ERROR',
  CREATE_ORDER: 'CREATE_ORDER',
  ADD_QUANTITY: 'ADD_QUANTITY',
  BUYERS_PHONE: 'BUYERS_PHONE',
  REMOVE_QUANTITY: 'REMOVE_QUANTITY',
  BUYERS_COMMENTS: 'BUYERS_COMMENTS',
  CALCULATE_TOTAL: 'CALCULATE_TOTAL',
  DELIVERY_ADDRESS: 'DELIVERY_ADDRESS',
  BUYERS_COUNTRY_ID: 'BUYERS_COUNTRY_ID',
  SET_CHECKOUT_STEP: 'SET_CHECKOUT_STEP',
  ONCHANGE_QUANTITY: 'ONCHANGE_QUANTITY',
  ACTIVE_SHOPPING_CART: 'ACTIVE_SHOPPING_CART',
  SET_OPEN_SHOPPING_CART: 'SET_OPEN_SHOPPING_CART',
  SELECTED_PAYMENT_METHOD: 'SELECTED_PAYMENT_METHOD',
  VALIDATE_ORDER_PRODUCTS: 'VALIDATE_ORDER_PRODUCTS',
  SELECTED_DELIVERY_METHOD: 'SELECTED_DELIVERY_METHOD',
  BUYERS_COUNTRY_PHONE_CODE: 'BUYERS_COUNTRY_PHONE_CODE',
  SET_SELECTED_DELIVERY_AREA: 'SET_SELECTED_DELIVERY_AREA',
  VALIDATE_FORM_PERSONAL_DATA: 'VALIDATE_FORM_PERSONAL_DATA',
  VALIDATE_FORM_DELIVERY_METHOD: 'VALIDATE_FORM_DELIVERY_METHOD',
}
