import { createGlobalStyle } from 'styled-components'

const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
  }

  body {
    margin: 0;
    padding: 0;
    position: relative;
    font-family: 'Nunito Sans', Helvetica, sans-serif, Arial;
    min-height: 100vh;
  }

  ul {
    list-style: none;
    margin: 0;
  }

  ul li {
    list-style: none;
  }

  a {
    text-decoration: none;
    cursor: pointer;
  }

  img {
    max-width: 100%;
    display: block;
    margin: auto auto;
  }

  h1, h2, h3, h4, h5, h6, p {
    margin: 0;
    padding: 0;
    border: 0;
    vertical-align: baseline;
  }

  .pac-container {
    border: 1px solid black;
    border-radius: 0px;
  }
  .pac-container .pac-icon {
    display: none
  }
  .pac-container .pac-item {
    font-size: 1.15rem !important;
    line-height: 1.5rem !important;
    padding: 0.5rem 1rem;
    border: none;
  }
`

export default GlobalStyle
