import { Trans } from 'react-i18next'
import { useContext, useEffect, useMemo } from 'react'

import {
  MainContainer,
  MessageNotFound,
  ButtonsContainer,
  CatalogContainer,
  SortSelectorContainer,
  LayoutSelectorContainer,
} from './styles'
import ProductsGrid from './ProductsGrid'
import ProductsList from './ProductsList'
import LayoutToggler from '../LayoutToggler'
import {
  LayoutType,
  LAYOUT_GRID_TYPE,
  LAYOUT_LIST_TYPE,
} from '../LayoutTypeButton/LayoutTypeButton'
import { ICountry } from '../../types/models'
import { getFilteredAndSortedProducts } from './helpers'
import { useStoreConfig } from '../../context/AppContext'
import ProductsSortSelector from '../ProductsSortSelector'
import CatalogContext from '../../context/Catalog/context'
import { STOCK_CONFIG } from '../../config/stock-config'
import { CORE_PRODUCT_ARCHIVED_FLAG } from '../../config/constants'

export interface ProductsCatalogProps {
  country: ICountry
  onActiveLayoutUpdate?: (newLayout: LayoutType) => void
}

const ProductsCatalog = ({
  country,
  onActiveLayoutUpdate,
}: ProductsCatalogProps): JSX.Element => {
  const {
    state: {
      search,
      products,
      isFinalPage,
      activeLayout,
      activeCategory,
      nextPageLoading,
      activeSortDirection,
    },
    setLayoutType,
    getMoreProducts,
  } = useContext(CatalogContext)
  const { store, stockConfig } = useStoreConfig()

  useEffect(() => {
    if (onActiveLayoutUpdate) {
      onActiveLayoutUpdate(activeLayout)
    }
  }, [activeLayout])

  const cleanProducts = useMemo(() => {
    let filterProducts = getFilteredAndSortedProducts({
      products: products || [],
      searchQuery: search || '',
      sortDirection: activeSortDirection,
      targetCategory: activeCategory,
    })

    if (stockConfig === STOCK_CONFIG.DONT_SHOW) {
      filterProducts = filterProducts.filter((product) => product.stock > 0)
    }

    return filterProducts.filter(
      (item) => !item.notes?.includes(CORE_PRODUCT_ARCHIVED_FLAG)
    )
  }, [activeCategory, activeSortDirection, products, search])

  const ProductsCatalogAsList = useMemo(() => {
    return <ProductsList country={country} products={cleanProducts} />
  }, [cleanProducts])

  const ProductsCatalogAsGrid = useMemo(() => {
    return <ProductsGrid country={country} products={cleanProducts} />
  }, [cleanProducts])

  const handleOnBottomScroll = () => {
    !isFinalPage ? getMoreProducts(store.id || '') : null
  }

  return (
    <MainContainer>
      {search && cleanProducts.length === 0 ? (
        <MessageNotFound>
          <Trans
            i18nKey="searchBar.notFoundProduct"
            values={{ name: search }}
            components={[<b key="1"></b>]}
          />
        </MessageNotFound>
      ) : (
        <>
          <ButtonsContainer>
            <SortSelectorContainer>
              <ProductsSortSelector />
            </SortSelectorContainer>
            <LayoutSelectorContainer>
              <LayoutToggler
                onChange={setLayoutType}
                selectedLayout={activeLayout}
              />
            </LayoutSelectorContainer>
          </ButtonsContainer>

          <CatalogContainer
            nextPageLoading={!!nextPageLoading}
            onBottomScroll={handleOnBottomScroll}
          >
            {activeLayout === LAYOUT_LIST_TYPE && ProductsCatalogAsList}
            {activeLayout === LAYOUT_GRID_TYPE && ProductsCatalogAsGrid}
          </CatalogContainer>
        </>
      )}
    </MainContainer>
  )
}

export default ProductsCatalog
