import { useState } from 'react'

import {
  getBanksList,
  BankListResponse,
} from '../../services/BankInformation/bankListService'

interface Request {
  authToken: string
}
interface Response {
  bankListData: BankListResponse[] | undefined
  isLoadedBankList: boolean
  hasErrorBankList: boolean
  callGetBankList: () => void
}

export const useGetBankListService = ({ authToken }: Request): Response => {
  const [bankListData, setBankListData] = useState<BankListResponse[]>()

  // states Services Hooks
  const [isLoadedBankList, setIsLoadedBankList] = useState(false)
  const [hasErrorBankList, setHasErrorBankList] = useState(false)

  const callService = () => {
    getBanksList(authToken)
      .then((bankListDataResponse) => {
        setBankListData(bankListDataResponse)
        setIsLoadedBankList(true)
      })
      .catch(() => {
        setIsLoadedBankList(false)
        setHasErrorBankList(true)
      })
  }

  return {
    bankListData,
    isLoadedBankList,
    hasErrorBankList,
    callGetBankList: callService,
  }
}
