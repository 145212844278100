import styled from 'styled-components'
import { Typography } from '../../components/common/Typography'

export const Container = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
`
export const Message = styled(Typography)`
  margin-top: 2rem;
  text-align: center;
  color: ${({ theme }) => theme.colors.neutral700};
`

export const OrdersContainer = styled.div`
  margin-top: ${({ theme }) => theme.sizes.spacing.xsmall};
`
