import { AxiosRequestConfig } from 'axios'

import { DeliveryLocationType } from '../../types/models'
import httpRequest from '../../components/Delivery/httpClient'

export interface CreateDeliveryRequestBody {
  authToken: string
  origin: DeliveryLocationType
  destination: DeliveryLocationType
  storeId: string
  providerId: number
  dispersionTypeId: number
  customer: {
    name: string
    country_code: string
    phone: string
    email: string
  }
  seller: {
    country_code: string
    phone: string
  }
  deliveryMethod: number
  saleValue: number
}

export const createDeliveryService = async ({
  authToken,
  origin,
  destination,
  storeId,
  providerId,
  deliveryMethod,
  dispersionTypeId,
  customer,
  saleValue,
  seller,
}: CreateDeliveryRequestBody): Promise<any> => {
  const baseUrl = process.env.REACT_APP_TREINTA_MICROSERVICES_API_URL

  const requestData: AxiosRequestConfig = {
    url: `${baseUrl}/delivery/create`,
    method: 'POST',
    data: {
      seller: seller,
      package_width: 10,
      package_height: 10,
      package_long: 10,
      location_origin: origin,
      location_destination: destination,
      declared_value: saleValue,
      sale_value: saleValue,
      store_id: storeId,
      provider_id: providerId,
      reference: `${seller.country_code}_${seller.phone}`,
      dispersion_type_id: dispersionTypeId,
      customer,
      delivery_method_id: deliveryMethod,
    },
  }

  return httpRequest(requestData, authToken)
}
