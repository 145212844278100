import styled from 'styled-components'
import { getLogoHeaderFromLanguage } from '../../common/services/utils'
import { ReactComponent as CloseIconBase } from '../../assets/app-menu-close.svg'

const APP_MENU_WIDTH = 260

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  padding-bottom: ${({ theme: { sizes } }) => sizes.spacing.medium};
  padding-left: ${({ theme: { sizes } }) => sizes.spacing.medium};
  padding-right: ${({ theme: { sizes } }) => sizes.spacing.medium};
  padding-top: ${({ theme: { sizes } }) => sizes.spacing.xlarge};
  width: ${APP_MENU_WIDTH}px;
`

export const TopContainer = styled.div`
  display: flex;
  flex-direction: column;
`

export const FooterContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  width: 100%;
`

export const CloseIcon = styled(CloseIconBase)`
  cursor: pointer;
`

export const TreintaShopLogo = styled(getLogoHeaderFromLanguage())`
  margin-bottom: ${({ theme: { sizes } }) => sizes.spacing.xlarge};
  margin-top: ${({ theme: { sizes } }) => sizes.spacing.xxlarge};
`

export const LinksContainer = styled.nav``

export const MenuLinks = styled.ul`
  padding: 0;
`
