import styled from 'styled-components'

import { FontsName } from '../../styles/fonts'

export const Container = styled.div<{ isSticky: boolean }>`
  z-index: 1;
  padding: ${({ theme, isSticky }) =>
    isSticky
      ? `0`
      : `0 ${theme.sizes.spacing.medium} ${theme.sizes.spacing.medium}`};
  ${({ isSticky }) =>
    isSticky ? `width: -webkit-fill-available;` : `width:auto;`};
`

export const WrapperBar = styled.div`
  position: relative;
  ${({ theme }) => `
    border-radius: ${theme.sizes.borderRadius.medium};
    border: 0.5px solid ${theme.colors.neutral400};
  `}

  & svg {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    ${({ theme }) => `
      left: ${theme.sizes.spacing.small};
    `}
  }
`

export const Input = styled.input`
  border: 0;
  width: 100%;
  height: 100%;
  outline: none;
  ${({ theme }) => `
    border-radius: ${theme.sizes.borderRadius.medium};
    background: ${theme.colors.neutral200};
    padding: ${theme.sizes.spacing.small} ${theme.sizes.spacing.small} ${theme.sizes.spacing.small} ${theme.sizes.spacing.xlarge};
  `}

  &::placeholder {
    ${({ theme }) => ({
      ...theme.fonts[FontsName.Nunito].medium,
    })}
  }
`
