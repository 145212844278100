import { useTranslation } from 'react-i18next'
import { StepNames } from '../../../types/models'
import useGAEventTracker from '../../../hooks/useGAEventTracker'
import { DeliverySummaryProps } from '../DeliverySummary/contracts'
import {
  Container,
  EditContainer,
  EditCopy,
  EditIcon,
  LocationInfo,
  Label,
  LocationsContainer,
  Value,
  PersonLocationIcon,
  SingleLocation,
  Summary,
  SummaryContainer,
} from './styles'

export const LocationDetails = ({
  state,
  setDeliveryState,
}: DeliverySummaryProps): JSX.Element => {
  const { t } = useTranslation()
  const GAEventsTracker = useGAEventTracker('webshop')

  const handleGoToEdit = () => {
    GAEventsTracker('shop_webdelivery_loc_quot_preview_fedit')
    setDeliveryState({
      ...state,
      currentStep: StepNames.Address,
    })
  }

  const showCustomerData = () => {
    const isPhoneFilled = state.customer.cellPhone.trim().length > 0
    const isNameFilled = state.customer.name.trim().length > 0
    return isPhoneFilled && isNameFilled
  }

  return (
    <Container>
      <SummaryContainer>
        <Summary>{t('delivery.summary.details.summary')}</Summary>
        <EditContainer onClick={handleGoToEdit}>
          <EditIcon />
          <EditCopy>{t('delivery.summary.details.editCopy')}</EditCopy>
        </EditContainer>
      </SummaryContainer>

      <LocationsContainer>
        <SingleLocation>
          <PersonLocationIcon />
          <LocationInfo>
            <Label>{t('delivery.summary.details.originAddressLabel')}:</Label>
            <Value>{state.origin.address}</Value>
          </LocationInfo>
        </SingleLocation>

        <SingleLocation>
          <PersonLocationIcon />
          <LocationInfo>
            <Label>
              {t('delivery.summary.details.destinationAddressLabel')}:
            </Label>
            <Value>{state.destination.address}</Value>
          </LocationInfo>
        </SingleLocation>

        {showCustomerData() && (
          <SingleLocation>
            <PersonLocationIcon />
            <LocationInfo>
              <Label>{t('delivery.summary.details.clientName')}:</Label>
              <Value>{state.customer.name}</Value>
            </LocationInfo>
          </SingleLocation>
        )}

        {showCustomerData() && (
          <SingleLocation>
            <PersonLocationIcon />
            <LocationInfo>
              <Label>{t('delivery.summary.details.clientPhoneNumber')}:</Label>
              <Value>{state.customer.cellPhone}</Value>
            </LocationInfo>
          </SingleLocation>
        )}
      </LocationsContainer>
    </Container>
  )
}
