import { useContext } from 'react'
import { CustomButton } from './styles'
import { CheckoutStepper } from '../../../../components-v0/CheckoutStepper'
import ShoppingCartContext from '../../../../context/ShoppingCart/context'

interface Props {
  productsNumber?: number
  setOpenShoppingCart: (open: boolean) => void
}

export const ShopingCartButton = ({
  productsNumber = 0,
  setOpenShoppingCart,
}: Props): JSX.Element => {
  const isEmpty = productsNumber === 0
  const {
    setSummaryOpen,
    state: { isSummaryOpen },
  } = useContext(ShoppingCartContext)

  const handleClick = () => {
    setOpenShoppingCart(true)
  }

  const handleSummaryClose = () => setSummaryOpen(false)

  return (
    <>
      <CustomButton
        isEmpty={isEmpty}
        text={productsNumber}
        onClick={handleClick}
        data-testid="shopping-cart-button"
      />
      {isSummaryOpen && (
        <CheckoutStepper isOpen={isSummaryOpen} onClose={handleSummaryClose} />
      )}
    </>
  )
}
