import styled from 'styled-components'
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown'
import { FontsName } from '../../styles/fonts'

export const Container = styled.div`
  padding-left: ${({ theme: { sizes } }) => sizes.spacing.medium};
  padding-right: ${({ theme: { sizes } }) => sizes.spacing.medium};
  padding-bottom: ${({ theme: { sizes } }) => sizes.spacing.medium};
  box-shadow: ${({ theme }) => theme.sizes.shadows.soft};
  background: ${({ theme }) => theme.colors.white};
  align-items: center;
  z-index: 1;
  position: sticky;
  top: 134px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: sticky;
  text-align: center;
  top: 134px;
  z-index: 1;
`

export const ArrowHeadIcon = styled(KeyboardArrowDown).attrs({
  style: {
    height: 18,
    marginLeft: 6,
    width: 18,
  },
})``

export const Button = styled.button`
  ${({ theme }) => theme.fonts[FontsName.Nunito].xsmall}
  color: ${({ theme }) => theme.colors.secondary500};
  background: ${({ theme }) => theme.colors.white};
  align-items: center;
  border: 0;
  cursor: pointer;
  display: flex;
  font-weight: bold;
  justify-content: center;
`

export const TogglerContainer = styled.div`
  padding-top: ${({ theme: { sizes } }) => sizes.spacing.small};
`
