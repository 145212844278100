import styled from 'styled-components'
import { Typography } from '../../components/common/Typography'
import { DEFAULT_STORE_IMAGE } from '../../config/order'
import { LogoContainerType } from './contracts'

export const MainContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: space-between;
  &:not(:last-child) {
    margin-bottom: ${({ theme }) => theme.sizes.spacing.xlarge};
  }
`
export const LeftCointainer = styled.div`
  display: flex;
  align-items: center;
  margin-right: ${({ theme }) => theme.sizes.spacing.xsmall};
`
export const LogoContainer = styled.div<LogoContainerType>`
  width: 40px;
  height: 40px;
  background-size: cover;
  background-position: center;
  background-color: ${({ theme }) => theme.colors.neutral200};
  border-radius: ${({ theme }) => theme.sizes.borderRadius.large};
  background-image: url(${({ src }) => (src ? src : DEFAULT_STORE_IMAGE)});
`
export const MetadataContainer = styled.div`
  display: flex;
  margin-left: 1rem;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
`
export const Subtitle = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral700};
`

export const OrderPrice = styled(Typography).attrs({
  variant: 'Smallbold',
  forwardedAs: 'span',
})`
  display: block;
  margin-bottom: ${({ theme }) => theme.sizes.spacing.xxsmall};
`

export const OrderStatus = styled(Typography).attrs({
  forwardedAs: 'span',
  variant: 'XSmallbold',
})<{ color: string }>`
  color: ${({ theme, color }) => (theme as any).colors[color]};
  text-align: right;
  display: block;
`
