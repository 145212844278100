import { useContext } from 'react'

import ShoppingCartContext from '../../context/ShoppingCart/context'

export const useShoppingCartproductsById = (id: number): number => {
  const {
    state: { productsSet: products },
  } = useContext(ShoppingCartContext)

  return products?.[id]?.quantity || 0
}
