import { useEffect, useState } from 'react'

import { IStore } from '../../types/models'
import { getStoreInfo } from '../../services/store'

interface UseGetStoreInfoServiceRequest {
  authToken: string
  storeUrlName: string
}

interface UseGetStoreInfoServiceResponse {
  errorStoreInfo: boolean
  storeInfo: IStore | null
  loadingStoreInfo: boolean
}

export const useGetStoreInfoService = ({
  authToken,
  storeUrlName,
}: UseGetStoreInfoServiceRequest): UseGetStoreInfoServiceResponse => {
  const [storeInfo, setStoreInfo] = useState<IStore | null>(null)
  const [errorStoreInfo, setErrorStoreInfo] = useState<boolean>(false)
  const [loadingStoreInfo, setLoadingStoreInfo] = useState(false)

  const getStoreInfoHandler = async () => {
    try {
      setErrorStoreInfo(false)
      const storeInfoResponse = await getStoreInfo(storeUrlName, authToken)
      setStoreInfo(storeInfoResponse)
    } catch (error) {
      setErrorStoreInfo(true)
    } finally {
      setLoadingStoreInfo(false)
    }
  }

  useEffect(() => {
    setLoadingStoreInfo(true)
    getStoreInfoHandler()
  }, [])

  return { storeInfo, loadingStoreInfo, errorStoreInfo }
}
