const REDIRECT_TIME = 3500
const SCROLL_LIMIT_PAGE = 8
const SEARCH_TIME_INTERVAL = 700
const NUMBER_DELIVERIES_CHALLENGE = 10
const NUMBER_DELIVERIES_FIRSTCHALLENGE = 3

export {
  REDIRECT_TIME,
  SCROLL_LIMIT_PAGE,
  SEARCH_TIME_INTERVAL,
  NUMBER_DELIVERIES_CHALLENGE,
  NUMBER_DELIVERIES_FIRSTCHALLENGE,
}
