export enum PaymentType {
  Cash = 1,
  Card = 2,
  Check = 3,
  BankTransfer = 4,
  Other = 5,
  Pending = 6,
  PaymentLink = 7,
  AgainstDelivery = 8,
  CoordinateWithTheClient = 9,
  OnlinePayments = 11,
}

export const PaymentMethods: { id: number; value: string; }[] = [
  {
    id: 1,
    value: 'Cash',
  },
  {
    id: 2,
    value: 'Card',
  },
  {
    id: 3,
    value: 'Check',
  },
  {
    id: 4,
    value: 'BankTransfer',
  },
  {
    id: 5,
    value: 'Other',
  },
  {
    id: 6,
    value: 'Pending',
  },
  {
    id: 7,
    value: 'PaymentLink',
  },
  {
    id: 8,
    value: 'AgainstDelivery',
  },
  {
    id: 9,
    value: 'CoordinateWithTheClient',
  },
  {
    id: 11,
    value: 'OnlinePayments',
  },
]
