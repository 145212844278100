import { makeStyles } from '@material-ui/core'
const useStyles = makeStyles((theme) => ({
  addIcon: {
    flexGrow: 1,
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    borderRadius: '10px',
    marginBottom: 5,
  },
  gridDialogContent: {
    marginTop: 0,
  },
  addIconColor: {
    '&:hover': {
      backgroundColor: '#00CC55',
      color: '#FFFFFF',
    },
    backgroundColor: '#00CC55',
    color: '#FFFFFF',
  },
  iconStyle: {
    fill: '#FFFFFF',
  },

  gridContentStyle: {
    flexGrow: 1,
    padding: 5,
  },
  detailTitleStyle: {
    color: '#2f4559',
    margin: '0.3rem',
    textAlign: 'left',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  detailContentStyle: {
    marginTop: 0,
    width: '100%',
    margin: '0.3rem',
    textAlign: 'left',
    overflow: 'hidden',
    wordWrap: 'break-word',
    textOverflow: 'ellipsis',
  },
  gridPaperContainerStyle: {
    marginTop: 0,
    flexGrow: 1,
    display: 'flex',
    alignContent: 'center',
    justifyContent: 'center',
  },

  dialogContentStyle: {
    padding: theme.spacing(2),
  },
  dialogActionsStyle: {
    margin: 0,
    padding: theme.spacing(1),
    marginBottom: 10,
    justifyContent: 'center',
  },

  dialogContainer: {
    width: '400px',
    maxWidth: '500px',
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
    margin: 'auto',
    padding: 0,
    border: 0,
    borderRadius: 10,
  },
}))

export { useStyles }
