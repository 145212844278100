import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'
import { Box, Typography } from '@material-ui/core'

import { useStyles } from './styles'

const Home = (): JSX.Element => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <>
      <Helmet>
        <meta
          name="description"
          content="Treinta Shop es gratis, fácil y seguro"
        />
      </Helmet>
      <Box className={classes.container}>
        <iframe src={t('config.homeUrl')} className={classes.iframeStyle}>
          <Typography> `${"Your browser doesn't support iframes"}` </Typography>
        </iframe>
      </Box>
    </>
  )
}

export default Home
