import React, { FC, useContext, useState } from 'react'
import { StockConfigType, STOCK_CONFIG } from '../config/stock-config'
import { StoreConfigContextType } from '../types/models'
import { PRODUCT_SHOW_TYPE } from '../config/product-show-type'
import { ACTIVE_INVENTORY_SECTION } from '../config/active-section'

const initialStore = {
  id: '',
  name: '',
  city: '',
  phone: '',
  email: '',
  config: {},
  address: '',
  imageUrl: '',
  country: 'Colombia',
}

const StoreConfigContext = React.createContext<StoreConfigContextType>(
  {} as StoreConfigContextType
)

interface StoreConfigProvierProps {
  defaultStockConfig?: StockConfigType
}

export const StoreConfigProvider: FC<StoreConfigProvierProps> = ({
  children,
  defaultStockConfig = 'SHOW_NOT_LIMIT',
}): JSX.Element => {
  const [products, setProducts] = useState<any>()
  const [search, setSearch] = useState<string>('')
  const [categories, setCategories] = useState<any>()
  const [store, setStore] = useState<any>(initialStore)
  const [stockConfig, setStockConfig] = useState<number>(
    STOCK_CONFIG[defaultStockConfig]
  )
  const [activeSection, setActiveSection] = useState<number>(
    ACTIVE_INVENTORY_SECTION.HOME
  )
  const [filteredProducts, setFilteredProducts] = useState<any>()
  const [productShowType, setProductShowType] = useState<number>(
    PRODUCT_SHOW_TYPE.LIST
  )
  const [assignTheme, setAssignTheme] = useState<string>('default')
  const [filteredCategories, setFilteredCategories] = useState<any>()
  const [authRedirectPath, setAuthRedirectPath] = useState<string>('')
  const [isFromMarketplace, setIsFromMarketplace] = useState<boolean>(false)
  const [isUserAuthenticated, setIsUserAuthenticated] = useState<
    boolean | undefined
  >()
  const [error, setError] = useState<string>('')

  const value = {
    store,
    search,
    products,
    categories,
    assignTheme,
    stockConfig,
    activeSection,
    productShowType,
    authRedirectPath,
    filteredProducts,
    isFromMarketplace,
    filteredCategories,
    isUserAuthenticated,
    error,
    setStore,
    setSearch,
    setProducts,
    setCategories,
    setAssignTheme,
    setStockConfig,
    setActiveSection,
    setProductShowType,
    setAuthRedirectPath,
    setFilteredProducts,
    setIsFromMarketplace,
    setFilteredCategories,
    setIsUserAuthenticated,
    setError,
  }
  return (
    <StoreConfigContext.Provider value={value}>
      {children}
    </StoreConfigContext.Provider>
  )
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useStoreConfig = () => useContext(StoreConfigContext)
