import { useEffect, useState } from 'react'
import { useHistory, useRouteMatch } from 'react-router-dom'

import Loading from '../../common/Loading'
import { AccountLengthType } from '../contracts'
import { validateEmail } from '../../../utils/forms'
import { menuType } from '../../common/MenuSelect/contracts'
import useGAEventTracker from '../../../hooks/useGAEventTracker'
import FormBankInformationFields from './FormBankInformationFields'
import { StepNames, AccountTypesOptions } from '../../../types/models'
import { BankListResponse } from '../../../services/BankInformation/bankListService'
import { AccountTypes } from '../../../services/BankInformation/paymentMethodsService'
import { useGetBankListService } from '../../../hooks/BankInformation/useGetBankListService'
import { useGetDocumentTypesService } from '../../../hooks/BankInformation/useGetDocumentTypesService'
import { useGetPaymentMethodsService } from '../../../hooks/BankInformation/useGetPaymentMethodsService'
import {
  useGetAccountDataService,
  useSaveAccountDataService,
} from '../../../hooks/BankInformation/useGetAccountDataService'
import {
  InfoDeliveryProps,
  defaultBankInformationData,
  defaultBankDataError,
} from '../../Delivery/contracts'
import { AccountDataRequest } from '../../../services/BankInformation/accountDataService'
const FormBankInformation = ({
  state,
  setDeliveryState,
}: InfoDeliveryProps): JSX.Element => {
  const DEFAULTTYPE = 'physical'
  const history = useHistory()
  const { url } = useRouteMatch()
  const GAEventsTracker = useGAEventTracker('webshop')
  const [isValidForm, setIsValidForm] = useState(true)
  const [alertInfoBankIsOpen, setAlertInfoBankIsOpen] = useState(false)
  const [openAlertWsaveDrawer, setOpenAlertWsaveDrawer] = useState(false)
  const [bankList, setBankList] = useState<BankListResponse[]>()
  const [accountTypes, setAccountTypes] = useState<AccountTypes[]>([])
  const [accountDataState, setAccountDataState] = useState<AccountDataRequest>()
  const [paymentMethodType, setPaymentMethodType] = useState<string>(
    DEFAULTTYPE
  )
  const [bankInformationData, setBankInformationData] = useState(
    defaultBankInformationData
  )
  const [bankInformationDataError, setBankInformationDataError] = useState(
    defaultBankDataError
  )

  // load Hook - Account Data Service
  const {
    accountData,
    isLoadedAccountData,
    callGetAccountDataInformation,
  } = useGetAccountDataService({
    authToken: state.authToken,
  })

  // load Hook - Document Types Service
  const {
    documentTypesData,
    isLoadedDocumentTypes,
    callGetDocumentTypesList,
  } = useGetDocumentTypesService({
    authToken: state.authToken,
  })
  // load Hook - Bank List Service
  const {
    bankListData,
    isLoadedBankList,
    callGetBankList,
  } = useGetBankListService({
    authToken: state.authToken,
  })
  // load Hook - Payment Methods Service
  const {
    paymentMethodsData,
    isLoadedPaymentMethods,
    callGetPaymentMethodsList,
  } = useGetPaymentMethodsService({
    authToken: state.authToken,
  })

  // load Hook - Save Account Service
  const { callSaveAccountDataInformation } = useSaveAccountDataService(
    state.authToken
  )

  // call Default Services
  useEffect(() => {
    callGetBankList()
    callGetDocumentTypesList()
    callGetPaymentMethodsList()
    callGetAccountDataInformation()
  }, [])

  // call Save Account State
  useEffect(() => {
    if (accountDataState) callSaveAccountDataInformation(accountDataState)
  }, [accountDataState])

  // validate Payment Method Type
  useEffect(() => {
    definePaymentMethodType()
  }, [bankInformationData.paymentMethod])

  // validate Active Save Form
  useEffect(() => {
    setIsValidForm(validateBankInformationForm())
  }, [bankInformationData])

  // define Account Types By Payment Method
  useEffect(() => {
    const filterPaymentMethod = paymentMethodsData?.filter(
      (paymentMethod) => paymentMethod.id === bankInformationData.paymentMethod
    )
    const filterAccountTypes = filterPaymentMethod
      ? filterPaymentMethod[0]?.accountTypes
      : []
    setAccountTypes(filterAccountTypes)
  }, [bankInformationData.paymentMethod, paymentMethodsData])

  // define Banks By Account Type
  useEffect(() => {
    defineBankForVirtualAccount()
    if (
      bankInformationData.paymentMethod === 1 &&
      bankListData &&
      bankListData.length > 0
    ) {
      const banksType = bankListData.filter((bank) => {
        return (
          bank.accountTypes &&
          bank.accountTypes.includes(String(bankInformationData.accountType))
        )
      })
      setBankList(banksType)
    }
  }, [bankInformationData.accountType])

  // set Actual User Account Informations
  useEffect(() => {
    setBankInformationData({
      ...bankInformationData,
      holderName: String(defineDataValue(accountData?.ownerName)),
      holderDocumentType: String(defineDataValue(accountData?.documentTypeId)),
      holderDocumentNumber: String(
        defineDataValue(accountData?.documentNumber)
      ),
      accountNumber: String(defineDataValue(accountData?.accountNumber)),
      accountNumberConfirm: String(defineDataValue(accountData?.accountNumber)),
      holderEmail: String(defineDataValue(accountData?.email)),
      bankId: Number(defineDataValue(accountData?.bankId)),
      paymentMethod: Number(defineDataValue(accountData?.paymentMethodId)),
      accountType: Number(defineDataValue(accountData?.accountTypeId)),
    })
  }, [accountData])

  // validate Account Number After Change Bank o Account Number
  useEffect(() => {
    validateAccountNumber()
  }, [bankInformationData.bankId])

  const defineContentSelectValue = (
    placeholder: string,
    actualValue: string,
    selectObject: any
  ): string => {
    let item = []
    if (selectObject.length > 0) {
      item = selectObject.find(
        (menu: menuType) => String(menu.id) === actualValue
      )
    }
    return item ? item.name : placeholder
  }

  const defineBankForVirtualAccount = (): void => {
    let newBankId = 0
    switch (bankInformationData.accountType) {
      case AccountTypesOptions.Nequi:
        newBankId = 27
        break
      case AccountTypesOptions.Daviplata:
        newBankId = 28
        break
      default:
        break
    }
    if (newBankId > 0)
      setBankInformationData({
        ...bankInformationData,
        bankId: newBankId,
      })
  }

  const defineDataValue = (dataValue: string | number | undefined) => {
    return dataValue !== null && String(dataValue).length > 0 ? dataValue : ''
  }

  const definePaymentMethodType = (): void => {
    const actualMethod = paymentMethodsData?.filter(
      (method: any) => method.id === bankInformationData.paymentMethod
    )
    const pmethodType = actualMethod
      ? actualMethod[0]?.type.toLowerCase()
      : DEFAULTTYPE
    setPaymentMethodType(pmethodType)
  }

  const defineAccountLength = (): AccountLengthType => {
    let lengthValues = ['10']
    if (
      bankListData &&
      bankListData.length > 0 &&
      bankInformationData.bankId > 0 &&
      paymentMethodType === DEFAULTTYPE
    ) {
      const actualBank = bankListData?.filter(
        (bank) => bank.id === Number(bankInformationData.bankId)
      )
      const accountLength = actualBank[0]?.characters
      lengthValues = accountLength.split('-')
    }
    return {
      min: Number(lengthValues[0]),
      max: Number(lengthValues[1] ? lengthValues[1] : lengthValues[0]),
    }
  }

  /* validate Section */
  const updateFormStatusError = (
    errorName: string,
    errorValue: boolean
  ): boolean => {
    setBankInformationDataError({
      ...bankInformationDataError,
      [errorName]: errorValue,
    })
    return errorValue
  }

  const validateHolderName = (holderName = ''): boolean => {
    holderName = holderName !== '' ? holderName : bankInformationData.holderName
    return updateFormStatusError(
      'holderNameError',
      String(holderName).length < 3
    )
  }

  const validateDocumentType = (holderDocumentType = 0): boolean => {
    holderDocumentType =
      holderDocumentType !== 0
        ? holderDocumentType
        : Number(bankInformationData.holderDocumentType)
    return updateFormStatusError(
      'holderDocumentTypeError',
      !(Number(holderDocumentType) > 0)
    )
  }

  const validateDocumentNumber = (holderDocumentNumber = ''): boolean => {
    holderDocumentNumber =
      holderDocumentNumber !== ''
        ? holderDocumentNumber
        : bankInformationData.holderDocumentNumber
    return updateFormStatusError(
      'holderDocumentNumberError',
      String(holderDocumentNumber).length < 3
    )
  }

  const validateHolderEmail = (holderEmail = ''): boolean => {
    holderEmail =
      holderEmail !== '' ? holderEmail : bankInformationData.holderEmail
    return updateFormStatusError(
      'holderEmailError',
      !validateEmail(String(holderEmail))
    )
  }

  const validatePaymentMethod = (paymentMethod = 0): boolean => {
    paymentMethod =
      paymentMethod !== 0 ? paymentMethod : bankInformationData.paymentMethod
    return updateFormStatusError('paymentMethodError', !(paymentMethod > 0))
  }

  const validateAccountType = (accountType = 0): boolean => {
    accountType =
      accountType !== 0 ? accountType : bankInformationData.accountType
    return updateFormStatusError('accountTypeError', !(accountType > 0))
  }

  const validateBank = (idBank = 0): boolean => {
    idBank = idBank !== 0 ? idBank : bankInformationData.bankId
    return updateFormStatusError(
      'bankError',
      paymentMethodType === DEFAULTTYPE && idBank === 0
    )
  }

  const validateAccountNumber = (accountNumber = ''): boolean => {
    accountNumber =
      accountNumber !== '' ? accountNumber : bankInformationData.accountNumber
    const lengthAccount = defineAccountLength()
    const validateAccount =
      (paymentMethodType === DEFAULTTYPE && bankInformationData.bankId !== 0) ||
      paymentMethodType !== DEFAULTTYPE
    const isValidAccount =
      accountNumber !== undefined &&
      validateAccount &&
      (String(accountNumber).length < lengthAccount.min ||
        String(accountNumber).length > lengthAccount.max)
    return updateFormStatusError('accountNumberError', isValidAccount)
  }

  const validateAccountNumberConfirm = (accountNumber = ''): boolean => {
    accountNumber =
      accountNumber !== ''
        ? accountNumber
        : bankInformationData.accountNumberConfirm
    return updateFormStatusError(
      'accountNumberConfirmError',
      String(bankInformationData.accountNumber) !== String(accountNumber)
    )
  }

  const validateBankInformationForm = (): boolean => {
    const isValid =
      !validateHolderName() &&
      !validateDocumentType() &&
      !validateDocumentNumber() &&
      !validateHolderEmail() &&
      !validatePaymentMethod() &&
      !validateAccountType() &&
      !validateBank() &&
      !validateAccountNumber() &&
      !validateAccountNumberConfirm()
    setIsValidForm(isValid)
    return isValid
  }

  /* handle Click Section */
  const handleClickNext = () => {
    if (validateBankInformationForm()) {
      GAEventsTracker('shop_webdelivery_bank_save')
      setAccountDataState({
        owner_name: String(bankInformationData?.holderName),
        document_number: String(bankInformationData?.holderDocumentNumber),
        account_number: String(bankInformationData?.accountNumber),
        email: String(bankInformationData?.holderEmail),
        document_type_id: Number(bankInformationData?.holderDocumentType),
        bank_id: Number(bankInformationData?.bankId),
        payment_method_id: Number(bankInformationData?.paymentMethod),
        account_type_id: Number(bankInformationData?.accountType),
      })
      setAlertInfoBankIsOpen(true)
    }
  }
  const handleClickBack = () => {
    GAEventsTracker('shop_webdelivery_bank_close')
    setDeliveryState({
      ...state,
      currentStep: StepNames.Address,
    })
    history.push(url + '/mylist')
  }

  const handleClickClose = () => {
    GAEventsTracker('shop_webdelivery_bank_open')
    setOpenAlertWsaveDrawer(true)
  }

  const handleClickExitEditForm = () => {
    setOpenAlertWsaveDrawer(false)
    setDeliveryState({
      ...state,
      currentStep: StepNames.Address,
    })
    history.push(url + '/mylist')
  }

  const handleClickContinueEditForm = () => {
    setOpenAlertWsaveDrawer(false)
  }
  return (
    <>
      {isLoadedDocumentTypes &&
      isLoadedAccountData &&
      isLoadedBankList &&
      isLoadedPaymentMethods ? (
        <>
          <FormBankInformationFields
            {...{
              state,
              setDeliveryState,
              handleClickNext,
              handleClickBack,
              handleClickClose,
              handleClickExitEditForm,
              handleClickContinueEditForm,
              bankInformationData,
              bankInformationDataError,
              setBankInformationData,
              setAlertInfoBankIsOpen,
              alertInfoBankIsOpen,
              openAlertWsaveDrawer,
              accountTypes,
              bankList,
              paymentMethodsData,
              paymentMethodType,
              documentTypesData,
              defineContentSelectValue,
              isValidForm,
              validateHolderName,
              validateHolderEmail,
              validateDocumentType,
              validateDocumentNumber,
              validateAccountType,
              validatePaymentMethod,
              validateBank,
              validateAccountNumber,
              validateAccountNumberConfirm,
            }}
          />
        </>
      ) : (
        <Loading />
      )}
    </>
  )
}
export default FormBankInformation
