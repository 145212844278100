export const validateLengthPhone = (phone: string, ml: number): boolean => {
  let lgok = true
  if (phone !== '') {
    if (phone?.length < ml && phone?.length > 0) {
      lgok = true
    } else if (phone?.length === ml) {
      lgok = false
    } else {
      lgok = false
    }
  } else if (phone === '') {
    return false
  }
  return lgok
}

export const stockInput = (value: string | number): boolean => {
  const toCheck = /^\d{0,4}([.,]\d{0,5})?$/
  return toCheck.test(String(value))
}

export const validateEmail = (email: string): boolean => {
  const regexEmail = /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/i
  return regexEmail.test(email)
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const restrictInputNumbers = (e: any): boolean => {
  const key = window.event ? e.which : e.keyCode
  return key < 48 || key > 57 ? false : true
}
