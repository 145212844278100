import styled from 'styled-components'
import { FontsName } from '../../styles/fonts'
import { Typography } from '../common/Typography'

export const DrawerContent = styled.div`
  overflow-y: auto;
  max-height: 60vh;
`
export const List = styled.ul`
  padding: 0;
`
export const ListItem = styled.li`
  display: flex;
  justify-content: space-between;
  cursor: pointer;

  &:not(:last-child) {
    margin-bottom: ${({ theme }) => theme.sizes.spacing.medium};
  }
`

export const CategoryLabel = styled(Typography).attrs({
  variant: 'Medium',
})<{ isBold?: boolean }>`
  ${({ isBold, theme }) => isBold && theme.fonts[FontsName.Nunito].mediumbold}
`
