import { FC, ReactNode, ElementType } from 'react'

import { Variants, FontsName, FontVariant } from '../../../styles/fonts'
import { BaseTypography } from './styles'

export interface Props {
  children: ReactNode
  forwardedAs?: ElementType
  className?: string
  variant?: FontVariant
  font?: FontsName
}

export const Typography: FC<Props> = ({
  children,
  className,
  variant = 'Medium',
  font = FontsName.Nunito,
  forwardedAs = 'p',
  ...props
}) => (
  <BaseTypography
    className={className}
    font={font}
    variant={variant}
    as={forwardedAs}
    {...props}
  >
    {children}
  </BaseTypography>
)

export { Variants }
