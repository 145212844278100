import debounce from 'lodash.debounce'
import { useTranslation } from 'react-i18next'
import { useCallback, useContext, useState } from 'react'

import { Container, Input, WrapperBar } from './styles'
import CatalogContext from '../../context/Catalog/context'
import useGAEventTracker from '../../hooks/useGAEventTracker'
import { ReactComponent as IconSearch } from '../../assets/icons/search.svg'

interface Props {
  isSticky: boolean
  maxChars?: number
  setCategoryNameSelected?: any
}

const SearchBar = ({ isSticky, maxChars }: Props): JSX.Element => {
  const { t } = useTranslation()
  const {
    setSearch,
    state: { search },
  } = useContext(CatalogContext)
  const [localSearch, setLocalSearch] = useState(search)
  const GAEventsTracker = useGAEventTracker('webshop')

  const searchProducts = (key: string) => {
    setSearch(key)
  }

  const debouncedChangeHandler = useCallback(
    debounce((key) => searchProducts(key), 2000),
    []
  )

  const handleChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target
    setLocalSearch(value)
    debouncedChangeHandler(value)
  }

  const handleBlurInput = () => GAEventsTracker('webshop_search')

  return (
    <Container isSticky={isSticky}>
      <WrapperBar>
        <IconSearch />
        <Input
          type="text"
          value={localSearch}
          maxLength={maxChars}
          onBlur={handleBlurInput}
          onChange={handleChangeInput}
          placeholder={t('searchBar.placeholderSearch')}
        />
      </WrapperBar>
    </Container>
  )
}
export default SearchBar
