import { useTranslation } from 'react-i18next'

import { Divider, Label, LabelTime, WrapperIcon } from '../styles'
import { ReactComponent as IconClockOpen } from '../../../../../assets/icons/clock-open.svg'

interface Props {
  start: string
  end: string
}

export const OpenTime = ({ start, end }: Props): JSX.Element => {
  const { t } = useTranslation()

  return (
    <>
      <WrapperIcon>
        <IconClockOpen />
      </WrapperIcon>
      <Label isOpen>{t('scheduler.open')}</Label>
      <Divider>·</Divider>
      <LabelTime>{`${start} - ${end}`}</LabelTime>
    </>
  )
}
