import { ChangeEvent, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { DeliverySummaryProps } from '../DeliverySummary/contracts'
import {
  Container,
  ErrorMessage,
  InputContainer,
  InputTextForm,
  LabelInputForm,
  Subtitle,
} from './styles'

export const SummaryForm = ({
  state,
  setDeliveryState,
}: DeliverySummaryProps): JSX.Element => {
  const { t } = useTranslation()

  const [hasEdit, setHasEdit] = useState(false)
  const [finalFields, setFinalFields] = useState({
    name: state.customer.name,
    cellPhone: state.customer.cellPhone,
  })

  const handleTextChange = (
    option: 'name' | 'cellPhone',
    event: ChangeEvent<HTMLInputElement>
  ) => {
    const value = event.target.value
    setDeliveryState({
      ...state,
      customer: {
        ...state.customer,
        [option]: value,
      },
    })
    setFinalFields({ ...finalFields, [option]: value })
    setHasEdit(true)
  }

  const customerFieldsFilled = () => {
    if (hasEdit) {
      return
    }

    const isPhoneFilled = state.customer.cellPhone.trim().length > 0
    const isNameFilled = state.customer.name.trim().length > 0
    return isPhoneFilled && isNameFilled
  }

  return (
    <Container>
      {!customerFieldsFilled() && (
        <>
          <Subtitle>{t('delivery.summary.finalFields')}</Subtitle>
          <InputContainer>
            <LabelInputForm htmlFor="name-input">
              {t('delivery.summary.nameFieldLabel')}*
            </LabelInputForm>
            <InputTextForm
              id="name-input"
              value={finalFields.name}
              defaultValue={finalFields.name}
              onChange={(event) => handleTextChange('name', event)}
              placeholder={t('delivery.summary.nameFieldPlaceholder')}
            />
          </InputContainer>
        </>
      )}

      {!customerFieldsFilled() && (
        <>
          <InputContainer>
            <LabelInputForm htmlFor="phone-input">
              {t('delivery.summary.phoneFieldLabel')}*
            </LabelInputForm>
            <InputTextForm
              id="phone-input"
              value={finalFields.cellPhone}
              defaultValue={finalFields.cellPhone}
              onChange={(event) => handleTextChange('cellPhone', event)}
              placeholder={t('delivery.summary.phoneFieldPlaceholder')}
            />
            {finalFields.cellPhone.length !== 10 && (
              <ErrorMessage variant="XSmall">
                {t('delivery.sectionForm.errors.errorLengthPhone')}
              </ErrorMessage>
            )}
          </InputContainer>
        </>
      )}
    </Container>
  )
}
