import { useTranslation } from 'react-i18next'

import {
  Container,
  ContainerTerms,
  Info,
  LinkTerms,
  SecureText,
  Logo,
  CreatedBy,
  LinkCreatedBy,
  LockIcon,
  ContainerSecure,
} from './styles'

export const Footer = (): JSX.Element => {
  const { t } = useTranslation()

  return (
    <Container data-testid="footer-container">
      <Info>
        <ContainerSecure>
          <LockIcon />
          <SecureText>
            {t('footer.buy')}
            <SecureText primaryColor>{t('footer.secure')}</SecureText>
          </SecureText>
        </ContainerSecure>
        <LinkCreatedBy
          href="https://treinta.co/shop"
          target="_blank"
          rel="noreferrer"
        >
          <CreatedBy>{t('footer.createdBy')}</CreatedBy>
          <Logo />
        </LinkCreatedBy>
      </Info>
      <ContainerTerms>
        <LinkTerms
          href={t('footer.linkTerms')}
          target="_blank"
          rel="noreferrer"
        >
          {t('footer.terms')}
        </LinkTerms>
      </ContainerTerms>
    </Container>
  )
}
