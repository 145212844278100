import { SignUp } from './SignUp'
import {
  signInWithPhone,
  createNewBuyerUser,
} from '../../common/services/signIn'
import firebase from '../../firebase'
import { SpinnerContainer } from './styles'
import { ICountry } from '../../types/models'
import { CircularProgress } from '@material-ui/core'
import { useContext, useEffect, useState } from 'react'
import { useStoreConfig } from '../../context/AppContext'
import { useHistory, useRouteMatch } from 'react-router-dom'
import { StoreAuthContext } from '../../context/StoreAuthContext/StoreAuthContext'

export const SignUpLoad = (): JSX.Element => {
  const history = useHistory()
  const { url } = useRouteMatch()
  const {
    setName,
    setPhone,
    setCountry,
    state: { phone, country, name },
  } = useContext(StoreAuthContext)
  const [step, setStep] = useState<number>(1)
  const [isLoading, setIsLoading] = useState(false)
  const [isDisabled, setIsDisabled] = useState<boolean>(true)
  const {
    authRedirectPath,
    isUserAuthenticated,
    setIsUserAuthenticated,
  } = useStoreConfig()
  const [
    showSuccessRegisterModal,
    setShowSuccessRegisterModal,
  ] = useState<boolean>(false)

  const handlePhone = (phone: string) => setPhone(phone)

  const handleSuccessRegisterModal = () => setShowSuccessRegisterModal(false)

  const handleCountry = (country: ICountry) => setCountry(country)

  const handleSignInWithPhone = async () => {
    setIsLoading(true)
    const fullPhone = `${country.code}${phone}`
    await signInWithPhone(fullPhone, 'recaptcha-container')
    setStep(2)
    setIsLoading(false)
  }

  const handleName = (event: React.ChangeEvent<HTMLInputElement>) =>
    setName(event.target.value)

  const handleNext = () => {
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        setIsUserAuthenticated(true)
        createNewBuyerUser(name, user.uid, phone, country.id)
      } else {
        setIsUserAuthenticated(false)
      }
    })
  }

  const goBack = () => setStep(1)

  useEffect(() => {
    if (!name.trim().length || !phone.trim().length) {
      setIsDisabled(true)
    } else {
      setIsDisabled(false)
    }
    return
  }, [name, phone, country])

  useEffect(() => {
    if (isUserAuthenticated) {
      if (authRedirectPath && authRedirectPath !== '') {
        history.push(authRedirectPath)
      } else {
        history.push(url.replace('/signin', ''))
      }
    }
  }, [isUserAuthenticated])

  const props = {
    step,
    name,
    phone,
    goBack,
    isLoading,
    isDisabled,
    handleNext,
    handleName,
    handlePhone,
    handleCountry,
    handleSignInWithPhone,
    showSuccessRegisterModal,
    handleSuccessRegisterModal,
  }

  return isUserAuthenticated === undefined ? (
    <SpinnerContainer>
      <CircularProgress />
    </SpinnerContainer>
  ) : (
    <SignUp {...props} />
  )
}
