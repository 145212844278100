import styled from 'styled-components'
import { Typography } from '../../../../components/common/Typography'

export const Container = styled.div`
  min-height: ${({
    theme: {
      sizes: { spacing },
    },
  }) => spacing.large};
  width: 100%;
  padding: ${({
    theme: {
      sizes: { spacing },
    },
  }) => spacing.medium};
  display: flex;
  align-items: center;
  justify-content: flex-start;
`
export const Title = styled(Typography).attrs({ variant: 'Largebold' })`
  color: #000000;
`
