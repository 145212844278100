export const theme = new Map()

/*
  primary:'#',
  complementary:'#',
  contrast:'#',
  secondary:'#',
  landing:'#',
*/

theme.set('default', {
  primary: '#FFDA2F',
  complementary: '#FFE66E',
  contrast: '#2F4559',
  secondary: '#F2F2F2',
  landing: '#2F4559',
})

theme.set('gray', {
  primary: '#EDEDED',
  complementary: '#D0CCC9',
  contrast: '#000000',
  secondary: '#F2F2F2',
  landing: '#000000',
})

theme.set('purple', {
  primary: '#3E08B3',
  complementary: '#7230FF',
  contrast: '#3E08B3',
  secondary: '#F2F2F2',
  landing: '#FFFFFF ',
})

theme.set('lightBlue', {
  primary: '#05D1DE',
  complementary: '#005580',
  contrast: '#037880',
  secondary: '#F2F2F2',
  landing: '#FFFFFF',
})

theme.set('darkBlue', {
  primary: '#0078B3',
  complementary: '#037880',
  contrast: '#005580',
  secondary: '#F2F2F2',
  landing: '#FFFFFF ',
})

theme.set('softOrange', {
  primary: '#FF9717',
  complementary: '#FFB017',
  contrast: '#0078B3',
  secondary: '#F2F2F2',
  landing: '#000000',
})

theme.set('mediumOrange', {
  primary: '#FF5117',
  complementary: '#FF9717',
  contrast: '#00B389',
  secondary: '#F2F2F2',
  landing: '#000000',
})

theme.set('strongOrange', {
  primary: '#FF173E',
  complementary: '#FF5117',
  contrast: '#00B322',
  secondary: '#F2F2F2',
  landing: '#FFFFFF ',
})

theme.set('green', {
  primary: '#00B322',
  complementary: '#00B389',
  contrast: '#00B322',
  secondary: '#F2F2F2',
  landing: '#FFFFFF ',
})

theme.set('dark', {
  primary: '#000000',
  complementary: '#404040',
  contrast: '#000000',
  secondary: '#F2F2F2',
  landing: '#FFFFFF',
})
