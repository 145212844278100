import axios from 'axios'

export interface Props {
  storeId: string
}

export interface ServicePaymentMethod {
  id: number
  store_id: string
  payment_type_id: number
  status: boolean
  created_at: string
  updated_at: string
  deleted_at: string
}

export const getEnablePaymentMethods = async ({
  storeId,
}: Props): Promise<ServicePaymentMethod[]> => {
  const result: { data: ServicePaymentMethod[] } = await axios.get(
    `${process.env.REACT_APP_TREINTA_API_URL}/payment-methods/${storeId}`,
    {
      headers: {
        Authorization: `Bearer ${process.env.REACT_APP_TOKEN}`,
      },
    }
  )

  return result.data
}
