import iconWhatsapp from '../../../assets/iconWS.svg'

import { ButtomWrapper, CustomButton, Icon } from './styles'

export const WhatsappButtonDelivery = ({
  handleClick,
}: {
  handleClick: () => void
}): JSX.Element => (
  <ButtomWrapper>
    <CustomButton data-testid="buttonWhatsappDelivery" onClick={handleClick}>
      <Icon src={iconWhatsapp} alt="whatsapp" />
    </CustomButton>
  </ButtomWrapper>
)
