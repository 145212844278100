import { useContext, useEffect } from 'react'
import CatalogContext from '../../context/Catalog/context'
import SortSelector from '../PriceSortSelector'
import { PriceSortingDirection } from '../PriceSortSelector/PriceSortSelector.types'

export interface ProductsSortSelectorProps {
  onUpdatePriceSortingDirection?: (newCriteria: PriceSortingDirection) => void
}

const ProductsSortSelector = ({
  onUpdatePriceSortingDirection,
}: ProductsSortSelectorProps): JSX.Element => {
  const {
    state: { activeSortDirection },
    setActiveSortDirection,
  } = useContext(CatalogContext)

  useEffect(() => {
    if (onUpdatePriceSortingDirection) {
      onUpdatePriceSortingDirection(activeSortDirection)
    }
  }, [activeSortDirection])

  return (
    <SortSelector
      activeDirection={activeSortDirection}
      onChange={setActiveSortDirection}
    />
  )
}

export default ProductsSortSelector
