import { useState } from 'react'

import {
  CreateDeliveryRequestBody,
  createDeliveryService,
} from '../../services/Shipment/createDelivery'
import { countries } from '../../config/countries'
import { DeliveryStateType, DepositOptions } from '../../types/models'

interface CreateDeliveryReturnType {
  createDelivery: (providerId: number) => Promise<any>
  isLoading: boolean
}

export const useCreateDelivery = (
  state: DeliveryStateType
): CreateDeliveryReturnType => {
  const [isLoading, setIsLoading] = useState(false)
  const {
    origin,
    destination,
    authToken,
    storeId,
    customer,
    paymentMethod,
  } = state

  const createDelivery = async (providerId: number): Promise<any> => {
    setIsLoading(true)
    const currentCountry = countries.get('Colombia')
    const countryCode = currentCountry.code.replace('+', '')

    const parsedData: CreateDeliveryRequestBody = {
      authToken,
      origin: {
        address: origin.address,
        comments: origin.addressDetail,
        latitude: origin.latitude,
        longitude: origin.longitude,
        country_id: origin.country_id,
        city_id: origin.city_id,
      },
      destination: {
        address: destination.address,
        comments: destination.addressDetail,
        latitude: destination.latitude,
        longitude: destination.longitude,
        country_id: destination.country_id,
        city_id: destination.city_id,
      },
      storeId,
      providerId,
      dispersionTypeId:
        state.depositTypeId > 0 ? state.depositTypeId : DepositOptions.General,
      customer: {
        name: customer.name,
        phone: customer.cellPhone,
        country_code: countryCode,
        email: '',
      },
      seller: {
        phone: String(state.storeInfo?.phone),
        country_code: countryCode,
      },
      saleValue: state.paymentMethod.saleValue,
      deliveryMethod: paymentMethod.method,
    }

    try {
      const response = await createDeliveryService(parsedData)
      setIsLoading(false)

      return response
    } catch (err) {
      console.log(err)
      return null
    }
  }

  return { createDelivery, isLoading }
}
