import { AxiosRequestConfig } from 'axios'

import { DeliveryLocationType } from '../../types/models'
import httpRequest from '../../components/Delivery/httpClient'

interface ProviderListRequestBody {
  authToken: string
  origin: DeliveryLocationType
  destination: DeliveryLocationType
}

export const getProviderListService = async ({
  authToken,
  origin,
  destination,
}: ProviderListRequestBody): Promise<any> => {
  const baseUrl = process.env.REACT_APP_TREINTA_MICROSERVICES_API_URL

  const requestData: AxiosRequestConfig = {
    url: `${baseUrl}/delivery/quotes`,
    method: 'POST',
    data: {
      package_width: 10,
      package_height: 10,
      package_long: 10,
      location_origin: origin,
      location_destination: destination,
    },
  }

  return httpRequest(requestData, authToken)
}
