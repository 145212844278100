import { useTranslation } from 'react-i18next'

import {
  ItemContainer,
  ItemWrapper,
  TrashIcon,
  TextSent,
  TextUserShipment,
  TextDateShipment,
  ButtonViewShipment,
} from '../styles'
import { formatItemDate } from '../../../../utils/datefunctions'
import { ListChipmentType } from '../../../../services/Shipment/listShipmentService'

interface DeliveryListProps {
  listShipmentData: ListChipmentType[]
  handleClickViewShipment: (shipmentId: string) => void
  handleClickRemoveShipment: (shipmentId: string) => void
}

const SentDeliveryList = ({
  listShipmentData,
  handleClickViewShipment,
  handleClickRemoveShipment,
}: DeliveryListProps): JSX.Element => {
  const trashStatus = [3, 4]
  const { t } = useTranslation()

  return (
    <>
      {listShipmentData.map((shipmentData: ListChipmentType) => (
        <ItemContainer key={shipmentData.id}>
          {trashStatus.indexOf(shipmentData.statusId) !== -1 && (
            <TrashIcon
              onClick={() => handleClickRemoveShipment(shipmentData.id)}
            ></TrashIcon>
          )}
          <ItemWrapper>
            <TextSent>
              <TextUserShipment>{shipmentData.customerName}</TextUserShipment>
              <TextDateShipment>
                {formatItemDate(shipmentData.createdAt, 'dd/MM/yyyy')}
              </TextDateShipment>
            </TextSent>
            <ButtonViewShipment
              onClick={() => {
                handleClickViewShipment(shipmentData.id)
              }}
            >
              {t('delivery.viewShipment')}
            </ButtonViewShipment>
          </ItemWrapper>
        </ItemContainer>
      ))}
    </>
  )
}
export default SentDeliveryList
