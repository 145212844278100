import styled from 'styled-components'

import { FontsName } from '../../../../styles/fonts'
import { ReactComponent as ExclamationIconImage } from '../../../../assets/icons/exclamationred-icon.svg'

export const SentItemsContainer = styled.div`
  margin-bottom: ${({ theme: { sizes } }) => sizes.spacing.xxlarge};
  max-height: 80vh;
  overflow: scroll;
`

export const TextSentItemsTitle = styled.div`
  margin-bottom: ${({ theme: { sizes } }) => sizes.spacing.small};
  ${({ theme }) => theme.fonts[FontsName.Nunito].xlargebold};
  color: ${({ theme }) => theme.colors.secondary700};
`

export const TextSentItemsEmpty = styled.div`
  ${({ theme }) => theme.fonts[FontsName.Nunito].xsmall};
  color: ${({ theme }) => theme.colors.neutral800};
`

export const ExclamationIcon = styled(ExclamationIconImage)`
  align-self: center;
  margin-bottom: 18px;
  width: 52px;
  height: auto;
  color: green;
  fill: green;
`

export const ListContainer = styled.ul`
  padding: 0;
  list-style: none;
  height: auto;
`
