import { useTranslation } from 'react-i18next'
import { MainContainer, Label } from './styles'
import { Input } from '../../../../components/TextArea'
import { useCatalogState } from '../../../../context/ShoppingCartContext'

export const BuyerComments = (): JSX.Element => {
  const { t: translate } = useTranslation()
  const {
    setBuyersComments,
    state: { buyersComments },
  } = useCatalogState()

  return (
    <MainContainer>
      <Label>{translate('sectionForm.comments')}</Label>
      <Input
        maxLength={240}
        value={buyersComments}
        onChange={(e) => setBuyersComments(e.target.value)}
        placeholder={translate('sectionForm.commentsPlaceholder')}
      />
    </MainContainer>
  )
}
