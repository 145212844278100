import { useState, FormEvent } from 'react'
import { useHistory, useRouteMatch } from 'react-router-dom'

import { StepNames } from '../../../types/models'
import { countries } from '../../../config/countries'
import { formatterCurrency } from '../../../utils/functions'
import { PaymentMethodOptions } from '../../../types/models'
import useGAEventTracker from '../../../hooks/useGAEventTracker'
import { FormShippingSecondFields } from './FormShippingSecondFields'
import {
  defaultPaymentData,
  defaultShippingDataError,
  InfoDeliveryProps,
} from '../contracts'

export const FormShippingSecond = ({
  state,
  setDeliveryState,
}: InfoDeliveryProps): JSX.Element => {
  const history = useHistory()
  const { url } = useRouteMatch()
  const currentCountry = countries.get('Colombia')
  const GAEventsTracker = useGAEventTracker('webshop')
  const [isDeclared, setIsDeclared] = useState(false)
  const [drawerIsOpen, setDrawerIsOpen] = useState(false)
  const [formattedValue, setFormattedValue] = useState('')
  const [paymentData, setPaymentData] = useState(defaultPaymentData)

  const preventDefault = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()
  }

  /* validate Section */
  const validateShippingForm = (): boolean => {
    defaultShippingDataError.declareError = !isDeclared
    defaultShippingDataError.paymentMethodError = !(paymentData.method > 0)
    defaultShippingDataError.saleValueError =
      paymentData.method === PaymentMethodOptions.UponDelivery &&
      paymentData.saleValue === 0
    return (
      defaultShippingDataError.declareError ||
      defaultShippingDataError.paymentMethodError ||
      defaultShippingDataError.saleValueError
    )
  }

  /* handle Click Section */
  const handleClickNext = (): void => {
    GAEventsTracker('shop_webdelivery_loc_notes_quot_yes')
    setDeliveryState({
      ...state,
      currentStep: StepNames.ProviderList,
      paymentMethod: paymentData,
    })
  }

  const handleClickBack = (): void => {
    GAEventsTracker('shop_webdelivery_loc_sscreen_back')
    setDeliveryState({ ...state, currentStep: state.currentStep - 1 })
  }

  const handleClickClose = (): void => {
    GAEventsTracker('shop_webdelivery_loc_sscreen_close')
    setDeliveryState({ ...state, currentStep: state.currentStep - 1 })
    history.push(url + '/mylist')
  }
  const handleDrawerStatus = (newStatus: boolean): void => {
    setDrawerIsOpen(newStatus)
  }

  /* handle Change Section */
  const selectHandleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    let saleValue = paymentData.saleValue
    let eventValue = 'shop_webdelivery_loc_sscreen_upondeliv'
    // reset values Payment Method Cash
    if (Number(event.target.value) === PaymentMethodOptions.Cash) {
      eventValue = 'shop_webdelivery_loc_sscreen_cash'
      setFormattedValue('')
      saleValue = 0
    }
    GAEventsTracker(eventValue)
    setPaymentData({
      ...paymentData,
      method: Number(event.target.value),
      saleValue: saleValue,
    })
  }

  const handleBlurOnInputChange = () => {
    setFormattedValue(
      String(formatterCurrency(currentCountry, Number(paymentData.saleValue)))
    )
  }

  const handleChangeOnInputChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const saleValue = e.target.value.replace(/[^0-9]/g, '')
    setPaymentData({
      ...paymentData,
      saleValue: Number(saleValue),
    })
    setFormattedValue(saleValue)
  }

  const checkHandleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked)
      GAEventsTracker('shop_webdelivery_loc_sscreen_allowed')
    setIsDeclared(event.target.checked)
  }

  return (
    <FormShippingSecondFields
      {...{
        handleClickBack,
        handleClickClose,
        handleClickNext,
        handleDrawerStatus,
        handleBlurOnInputChange,
        handleChangeOnInputChange,
        preventDefault,
        checkHandleChange,
        validateShippingForm,
        selectHandleChange,
        defaultShippingDataError,
        paymentData,
        PaymentMethodOptions,
        drawerIsOpen,
        formattedValue,
      }}
    />
  )
}
