import { useTranslation } from 'react-i18next'

import { BankInformationProps } from '../contracts'
import FormBankInformationFieldsAlerts from './FormBankInformationFieldsAlerts'
import FormBankInformationFieldsHolder from './FormBankInformationFieldsHolder'
import FormBankInformationFieldsPayment from './FormBankInformationFieldsPayment'
import {
  AlertMessage,
  MainContainer,
  WrapperContainer,
  FormContainer,
  TextAlert,
  BackIcon,
  CloseIcon,
  InfoIcon,
  Header,
  Title,
} from './styles'

const FormBankInformationFields = ({
  state,
  setDeliveryState,
  handleClickNext,
  handleClickBack,
  handleClickClose,
  handleClickExitEditForm,
  handleClickContinueEditForm,
  bankInformationData,
  bankInformationDataError,
  setBankInformationData,
  setAlertInfoBankIsOpen,
  alertInfoBankIsOpen,
  openAlertWsaveDrawer,
  accountTypes,
  bankList,
  paymentMethodsData,
  paymentMethodType,
  documentTypesData,
  defineContentSelectValue,
  isValidForm,
  validateHolderName,
  validateHolderEmail,
  validateDocumentType,
  validateDocumentNumber,
  validateAccountType,
  validatePaymentMethod,
  validateBank,
  validateAccountNumber,
  validateAccountNumberConfirm,
}: BankInformationProps): JSX.Element => {
  const { t } = useTranslation()
  return (
    <MainContainer>
      <AlertMessage>
        <InfoIcon />
        <TextAlert>{t('delivery.sectionForm.bankFormReminder')}</TextAlert>
      </AlertMessage>
      <WrapperContainer>
        <Header>
          <BackIcon onClick={handleClickBack} />
          <Title forwardedAs="h1" variant="XLargebold">
            {t('delivery.sectionForm.bankFormTitle')}
          </Title>
          <CloseIcon onClick={handleClickClose} />
        </Header>
        <FormContainer>
          <FormBankInformationFieldsHolder
            {...{
              bankInformationData,
              bankInformationDataError,
              setBankInformationData,
              documentTypesData,
              defineContentSelectValue,
              validateHolderName,
              validateHolderEmail,
              validateDocumentType,
              validateDocumentNumber,
            }}
          />
          <FormBankInformationFieldsPayment
            {...{
              handleClickNext,
              bankInformationData,
              bankInformationDataError,
              setBankInformationData,
              accountTypes,
              bankList,
              paymentMethodsData,
              paymentMethodType,
              defineContentSelectValue,
              isValidForm,
              validateAccountType,
              validatePaymentMethod,
              validateBank,
              validateAccountNumber,
              validateAccountNumberConfirm,
            }}
          />
        </FormContainer>
        <FormBankInformationFieldsAlerts
          {...{
            state,
            setDeliveryState,
            setAlertInfoBankIsOpen,
            alertInfoBankIsOpen,
            openAlertWsaveDrawer,
            handleClickExitEditForm,
            handleClickContinueEditForm,
          }}
        />
      </WrapperContainer>
    </MainContainer>
  )
}
export default FormBankInformationFields
