import { useState } from 'react'

import {
  getDeliveryUserInformation,
  DeliveryInformationRequest,
} from '../../services/Shipment/deliveryUserInformationService'

interface DeliveryInformationResponse {
  totalDelivery: number
  totalDeliveryMonthly: number
}
interface GetDeliveryInformationResponse {
  getDeliveryUser: () => void
  userDeliveries: DeliveryInformationResponse | undefined
  isLoadingUserDeliveries: boolean
}

export const useDeliveryUserInformationService = ({
  authToken,
  storeId,
}: DeliveryInformationRequest): GetDeliveryInformationResponse => {
  const [isLoadingUserDeliveries, setIsLoadingUserDeliveries] = useState(true)
  const [userDeliveries, setUserDeliveries] = useState<any>()

  const getDeliveryUser = async (): Promise<void> => {
    const userInformation = await getDeliveryUserInformation({
      authToken,
      storeId,
    })
    setIsLoadingUserDeliveries(false)
    setUserDeliveries(userInformation)
  }

  return { getDeliveryUser, userDeliveries, isLoadingUserDeliveries }
}
