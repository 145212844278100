import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles({
  mainContainer: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  title: {
    fontWeight: 700,
    fontSize: '1rem',
  },
  productsListContainer: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  iconStyle: {
    fill: '#EDEDED',
  },
  voidCart: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
  },
})
