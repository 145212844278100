import { ProviderResponse } from '../Delivery/Providers/contracts'
import { ProviderItem } from '../ProviderItem/ProviderItem'
import { ListContainer } from './styles'

export const ProvidersList = ({
  providers,
  onSelectProvider,
}: {
  providers: ProviderResponse[]
  onSelectProvider: (provider: ProviderResponse) => void
}): JSX.Element => {
  const handleSelectProvider = (provider: ProviderResponse) => {
    onSelectProvider(provider)
  }

  return (
    <ListContainer>
      {providers.map((provider, index) => (
        <ProviderItem
          provider={provider}
          onSelectProvider={handleSelectProvider}
          key={index}
        />
      ))}
    </ListContainer>
  )
}
