import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import {
  PriceSortingDirection,
  PriceSortingDirectionTranslationKeys,
  PRICE_ASC_SORT_CRITERIA,
  PRICE_DESC_SORT_CRITERIA,
  PRICE_NONE_SORT_CRITERIA,
} from './PriceSortSelector.types'
import {
  ArrowHeadIcon,
  BackToDefaultLabel,
  Label,
  MainButton,
  SortAscIcon,
  SortDescIcon,
} from './styles'
import RadioButton from '../RadioButton'
import BottomDrawer from '../common/BottomDrawer'
import { OptionContainer } from '../RadioButton/styles'

export interface PriceSortSelectorProps {
  activeDirection: PriceSortingDirection
  onChange: (newDirection: PriceSortingDirection) => void
}

const PriceSortSelector = ({
  activeDirection,
  onChange,
}: PriceSortSelectorProps): JSX.Element => {
  const { t: translate } = useTranslation()
  const [isDrawerOpen, setDrawerState] = useState(false)

  const handleOnChange = (newDirection: PriceSortingDirection) => {
    onChange(newDirection)
    setDrawerState(false)
  }

  return (
    <>
      <MainButton
        data-testid="price-sort-selector-container"
        onClick={() => setDrawerState(true)}
      >
        {activeDirection === PRICE_ASC_SORT_CRITERIA && <SortAscIcon />}
        {activeDirection === PRICE_DESC_SORT_CRITERIA && <SortDescIcon />}
        {activeDirection === PRICE_NONE_SORT_CRITERIA && <SortDescIcon />}

        <Label data-testid="price-sort-selector-active-label">
          {translate(PriceSortingDirectionTranslationKeys[activeDirection])}
        </Label>

        <ArrowHeadIcon />
      </MainButton>

      <BottomDrawer
        open={isDrawerOpen}
        onClose={() => setDrawerState(false)}
        title={translate('catalog.orderBy')}
      >
        <OptionContainer>
          <RadioButton
            dataTestId="price-sort-selector-option-for-asc"
            label={translate(PriceSortingDirectionTranslationKeys.ASC)}
            onSelect={() => handleOnChange(PRICE_ASC_SORT_CRITERIA)}
            selected={activeDirection === PRICE_ASC_SORT_CRITERIA}
            size={16}
          />
        </OptionContainer>

        <OptionContainer>
          <RadioButton
            dataTestId="price-sort-selector-option-for-desc"
            onSelect={() => handleOnChange(PRICE_DESC_SORT_CRITERIA)}
            selected={activeDirection === PRICE_DESC_SORT_CRITERIA}
            label={translate(PriceSortingDirectionTranslationKeys.DESC)}
            size={16}
          />
        </OptionContainer>

        {activeDirection !== PRICE_NONE_SORT_CRITERIA && (
          <div onClick={() => handleOnChange(PRICE_NONE_SORT_CRITERIA)}>
            <BackToDefaultLabel data-testid="price-sort-selector-option-for-none">
              {translate('catalog.resetOrderToDefault')}
            </BackToDefaultLabel>
          </div>
        )}
      </BottomDrawer>

      <span
        data-testid="price-sort-selector-drawer-spy"
        style={{ display: 'none' }}
      >
        {isDrawerOpen ? 'open' : 'closed'}
      </span>
    </>
  )
}

export default PriceSortSelector
