import {
  ImageProduct,
  ContainerItems,
  ContainerProduct,
  ContainerDescription,
  Text,
  TextQuantity,
  TextPrice,
} from './styles'
import noPicture from './../../assets/empty.svg'
import { formatterCurrency } from '../../utils/functions'
import { DetailOrder, ICountry } from '../../types/models'

export const OrderDetailProduct = ({
  product,
  country,
}: {
  product: DetailOrder
  country: ICountry
}): JSX.Element => {
  const { imageUrl, name, quantity, sale_price } = product

  return (
    <ContainerItems>
      <ContainerProduct>
        <ImageProduct src={imageUrl ? imageUrl : noPicture} />
        <ContainerDescription>
          <Text forwardedAs="p" variant="Small">
            {name}
          </Text>
          <TextQuantity
            forwardedAs="p"
            variant="XSmall"
          >{`X ${quantity}`}</TextQuantity>
        </ContainerDescription>
      </ContainerProduct>
      <ContainerProduct>
        <TextPrice forwardedAs="p" variant="Smallbold">
          {`${formatterCurrency(country, sale_price * quantity)} ${
            country.currency
          }`}
        </TextPrice>
      </ContainerProduct>
    </ContainerItems>
  )
}
