import Axios from 'axios'

import { DiscountResponse } from '../types/models'
import { DEFAULT_PAGE, DEFAULT_SIZE } from '../utils/constants'

export const getDiscountsList = async (
  storeId: string
): Promise<DiscountResponse[]> => {
  try {
    const response = await Axios({
      method: 'GET',
      headers: {
        'X-API-Key': process.env.REACT_APP_TREINTA_MICROSERVICES_API_KEY,
      },
      url: `${process.env.REACT_APP_TREINTA_MICROSERVICES_API_URL}/discounts/?limit=${DEFAULT_SIZE}&page=${DEFAULT_PAGE}&store=${storeId}&filter=onTime`,
    })
    const { data } = response
    return data
  } catch (error: any) {
    console.log('error ProductsList: ', error)
  }
  return []
}
