export const sizes = {
  borderRadius: {
    xxlarge: '1rem',
    xlarge: '0.75rem',
    large: '0.5rem',
    medium: '0.25rem',
    small: '0.125rem',
  },
  shadows: {
    soft: '0.125rem 0.25rem 0.5rem rgba(34, 38, 42, 0.05)',
    medium:
      '0.125rem 0.25rem 0.5rem rgba(34, 38, 42, 0.03), 0.25rem 0.5rem 1rem rgba(34, 38, 42, 0.05)',
    hard:
      '0.125rem 0.25rem 0.5rem rgba(34, 38, 42, 0.05), 0.25rem 0.5rem 1.5rem rgba(34, 38, 42, 0.1)',
    softInverted: '0.125rem -0.25rem 0.5rem rgba(34, 38, 42, 0.05)',
    mediumInverted:
      '0.125rem -0.25rem 0.5rem rgba(34, 38, 42, 0.03), 0.25rem -0.5rem 1rem rgba(34, 38, 42, 0.05)',
    hardInverted:
      '0.125rem -0.25rem 0.5rem rgba(34, 38, 42, 0.05), 0.25rem -0.5rem 1.5rem rgba(34, 38, 42, 0.1)',
  },
  spacing: {
    xxxlarge: '3rem',
    xxlarge: '2.5rem',
    xlarge: '2rem',
    large: '1.5rem',
    medium: '1rem',
    small: '0.75rem',
    xsmall: '0.5rem',
    xxsmall: '0.25rem',
    xxxsmall: '0.125rem',
  },
  maxWidthApp: '500px',
}

export type Sizes = {
  borderRadius: { [key in keyof typeof sizes.borderRadius]: string }
  shadows: { [key in keyof typeof sizes.shadows]: string }
  spacing: { [key in keyof typeof sizes.spacing]: string }
  maxWidthApp: string
}
