import * as rax from 'retry-axios'
import Axios, { AxiosInstance, AxiosRequestConfig } from 'axios'

import {
  MAX_RETRIES,
  STATUS_RETRY,
  METHODS_RETRY,
  MAX_RETRIES_NORESPONSE,
} from '../../common/services/constants'

const addRetry = (instance: AxiosInstance): void => {
  const raxConfig = {
    instance,
    retry: MAX_RETRIES,
    statusCodesToRetry: STATUS_RETRY,
    httpMethodsToRetry: METHODS_RETRY,
    noResponseRetries: MAX_RETRIES_NORESPONSE,
  }
  instance.defaults.raxConfig = raxConfig
  rax.attach(instance)
}

const httpClient = (authToken: string | null): AxiosInstance => {
  const baseUrl = process.env.REACT_APP_TREINTA_MICROSERVICES_API_URL
  const apiSecret = process.env.REACT_APP_TREINTA_MICROSERVICES_API_KEY
  const clientAxios = Axios.create({
    baseURL: baseUrl,
    headers: {
      Authorization: authToken ? `Bearer ${authToken}` : '',
      'X-Api-Key': apiSecret || '',
    },
  })
  addRetry(clientAxios)
  return clientAxios
}

const httpRequest = async (
  requestData: AxiosRequestConfig,
  authToken: string
): Promise<any> => {
  try {
    const { data } = await httpClient(authToken).request(requestData)
    return data
  } catch (err) {
    console.log('ERROR REQUEST: ', err)
    throw err
  }
}

export default httpRequest
