import { ReactNode } from 'react'
import { Color } from '../../styles/colors'
import { Label, MainContainer, RadioContainer, WhitePill } from './styles'

interface RadioButtonBaseProps {
  dataTestId?: string
  onSelect: () => void
  selected: boolean
  selectedColor?: Color
  size: number
}

interface RadioButtonWithStringLabelProps extends RadioButtonBaseProps {
  label: string
}

interface RadioButtonWithChildrenProps extends RadioButtonBaseProps {
  children: ReactNode
}

export type RadioButtonProps =
  | RadioButtonWithStringLabelProps
  | RadioButtonWithChildrenProps

const RadioButton = (props: RadioButtonProps): JSX.Element => {
  const {
    dataTestId = 'radio-button-container',
    onSelect,
    selected,
    selectedColor = 'tertiary600',
    size,
  } = props

  return (
    <MainContainer
      data-testid={dataTestId}
      onClick={() => {
        if (!selected) {
          onSelect()
        }
      }}
    >
      <RadioContainer
        data-testid="radio-button-colored-pill"
        selected={selected}
        selectedColor={selectedColor}
        size={size}
      >
        <WhitePill />
      </RadioContainer>

      {'label' in props && (
        <Label data-testid="radio-button-default-label">{props.label}</Label>
      )}
      {'children' in props && props.children}
    </MainContainer>
  )
}

export default RadioButton
