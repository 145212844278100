import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useRouteMatch } from 'react-router-dom'

import { Button } from '../../common/Button'
import { DeliverySummaryProps } from './contracts'
import { countries } from '../../../config/countries'
import { SummaryForm } from '../SummaryForm/SummaryForm'
import { updateStoreInfo } from '../../../services/store'
import { shareMessageWhatsApp } from '../../../utils/share'
import { formatterCurrency } from '../../../utils/functions'
import useGAEventTracker from '../../../hooks/useGAEventTracker'
import { ReceiptDetails } from '../ReceiptDetails/ReceiptDetails'
import { AlertDrawer } from '../../common/AlertDrawer/AlertDrawer'
import { LocationDetails } from '../LocationDetails/LocationDetails'
import { KeepInMindDrawer } from '../KeepInMindDrawer/KeepInMindDrawer'
import { PaymentMethodOptions, StepNames } from '../../../types/models'
import { PaymentMethodDetails } from '../PaymentMethodDetails/PaymentMethodDetails'
import {
  ButtonContainer,
  Divider,
  Header,
  Icon,
  IconClose,
  MainWrapper,
  ShareContainer,
  ShareCopy,
  Title,
  WhatsappIcon,
  AlertDoorIcon,
  dynamicFill,
} from './styles'

export const DeliverySummary = ({
  state,
  setDeliveryState,
}: DeliverySummaryProps): JSX.Element => {
  const { t } = useTranslation()
  const { url } = useRouteMatch()
  const history = useHistory()
  const GAEventsTracker = useGAEventTracker('webshop')

  const [openAlertDrawer, setOpenAlertDrawer] = useState(false)
  const [openKeepInMindDrawer, setOpenKeepInMindDrawer] = useState(false)
  const currentCountry = countries.get('Colombia')

  const phoneNumber = currentCountry.code + state.customer.cellPhone

  const paymentMethodLabelMap = {
    [PaymentMethodOptions.Cash]: t('delivery.shareMessage.cash'),
    [PaymentMethodOptions.UponDelivery]: t(
      'delivery.shareMessage.uponDelivery'
    ),
  }

  const saveUpdatedStoreAddress = () => {
    if (state.origin.address && state.origin.city) {
      const newStoreAddress = state.origin.address.split(
        `, ${state.origin.city}`
      )[0]
      updateStoreInfo(
        {
          id: state.storeId,
          city: state.origin.city,
          address: newStoreAddress,
        },
        state.authToken
      )
    }
  }

  const onClose = () => {
    GAEventsTracker('shop_webdelivery_loc_quot_preview_close')
    setOpenAlertDrawer(true)
  }

  const handleRequestDelivery = () => {
    GAEventsTracker('shop_webdelivery_loc_quot_preview_select')
    saveUpdatedStoreAddress()
    setOpenKeepInMindDrawer(true)
  }

  const handleGoBack = () => {
    GAEventsTracker('shop_webdelivery_loc_quot_preview_back')
    setDeliveryState({
      ...state,
      currentStep: StepNames.ProviderList,
    })
  }

  const handleCloseAlertDrawer = () => {
    setOpenAlertDrawer(false)
    setOpenKeepInMindDrawer(false)
  }

  const handleExit = () => {
    history.push(url + '/mylist')
    setDeliveryState({
      ...state,
      currentStep: StepNames.Address,
    })
  }

  const showSaleValue = (): string => {
    return state.paymentMethod.method === PaymentMethodOptions.UponDelivery
      ? `${t('delivery.shareMessage.saleCost')}: ${formatterCurrency(
          currentCountry,
          Number(state.paymentMethod.saleValue)
        )}`
      : ''
  }

  const calculateTotalValue = (): string => {
    const totalValue =
      state.paymentMethod.method === PaymentMethodOptions.UponDelivery &&
      Number(state.paymentMethod.saleValue) > 0
        ? Number(state.paymentMethod.saleValue) +
          Number(state.selectedMarketRates.deliveryPrice)
        : Number(state.selectedMarketRates.deliveryPrice)
    return String(formatterCurrency(currentCountry, totalValue))
  }

  const handleShare = () => {
    if (!state.customer.cellPhone.length) {
      return
    }

    const message = `${t('delivery.shareMessage.hi')} 👋
${t('delivery.shareMessage.thisIs')} 📦

${t('delivery.shareMessage.originCity')}: ${state.origin.city}\n
${t('delivery.shareMessage.originAddress')}: ${state.origin.address}\n
${t('delivery.shareMessage.destinationAddress')}: ${state.destination.address}
${t('delivery.shareMessage.paymentMethod')}: ${
      paymentMethodLabelMap[state.paymentMethod.method]
    }

${showSaleValue()}
${t('delivery.shareMessage.deliveryCost')}: ${formatterCurrency(
      currentCountry,
      Number(state.selectedMarketRates.deliveryPrice)
    )}
${t('delivery.shareMessage.totalToPay')}: ${calculateTotalValue()}

*${t('delivery.shareMessage.haveANiceDay')}* 👍`

    shareMessageWhatsApp({ text: message, phone: phoneNumber })
    GAEventsTracker('shop_webdelivery_loc_quot_preview_share')
  }

  const isDisabled = () =>
    !state.customer.name.trim().length ||
    state.customer.cellPhone.trim().length !== 10

  const shareDisabled = () => {
    return state.customer.cellPhone.trim().length !== 10
  }

  return (
    <MainWrapper>
      <Header>
        <Icon onClick={handleGoBack} />
        <Title forwardedAs="h1" variant="XLargebold">
          {t('delivery.summary.headerTitle')}
        </Title>
        <IconClose onClick={onClose} />
      </Header>

      <SummaryForm state={state} setDeliveryState={setDeliveryState} />

      <LocationDetails state={state} setDeliveryState={setDeliveryState} />
      <Divider />
      <PaymentMethodDetails state={state} setDeliveryState={setDeliveryState} />
      <Divider />
      <ReceiptDetails state={state} />
      <Divider />

      <ShareContainer onClick={handleShare}>
        <WhatsappIcon fill={dynamicFill(shareDisabled())} />
        <ShareCopy disabled={shareDisabled()}>
          {t('delivery.summary.share')}
        </ShareCopy>
      </ShareContainer>

      <ButtonContainer>
        <Button
          style={{ width: '100%' }}
          text={t('delivery.summary.requestDelivery') as string}
          onClick={handleRequestDelivery}
          disabled={isDisabled()}
        />
      </ButtonContainer>
      {openAlertDrawer && (
        <AlertDrawer
          textTitle={t('delivery.providers.alerts.title')}
          textSbTitle={t('delivery.providers.alerts.message')}
          textConfirm={t('delivery.providers.alerts.confirmation')}
          textLeftButton={t('delivery.providers.alerts.exit')}
          textRightButton={t('delivery.providers.alerts.cancel')}
          onEventLeftButton={handleExit}
          onEventRightButton={handleCloseAlertDrawer}
          onEventOpacitySection={handleCloseAlertDrawer}
          eventLeft={'shop_webdelivery_loc_quot_prev_close_ys'}
          eventRight={'shop_webdelivery_loc_quot_prev_close_no'}
          eventOpacity={'shop_webdelivery_loc_quot_prev_close_no2'}
          iconAlert={<AlertDoorIcon />}
        />
      )}

      {openKeepInMindDrawer && (
        <KeepInMindDrawer
          state={state}
          onCloseAlert={handleCloseAlertDrawer}
          providerImage={state.selectedMarketRates.providerImg}
          setDeliveryState={setDeliveryState}
        />
      )}
    </MainWrapper>
  )
}
