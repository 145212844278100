import styled from 'styled-components'

import { colors } from '../../../styles/colors'
import { FontsName } from '../../../styles/fonts'
import { Typography } from '../../common/Typography'

export const DISABLED_OPACITY = 0.65

export const DiscountItemWrapper = styled.div`
  margin-left: ${({ theme: { sizes } }) => sizes.spacing.xsmall};
`

export const DiscountItem = styled.div`
  width: 144px;
  position: relative;
  text-align: center;
  padding: ${({ theme: { sizes } }) => sizes.spacing.xsmall};
  border-radius: ${({ theme: { sizes } }) => sizes.borderRadius.xlarge};
  border: ${({ theme: { colors } }) => `0.5px solid ${colors.neutral400}`};
`

/* Discount Header */
export const DiscountHeader = styled.div`
  width: 132px;
  display: flex;
  position: absolute;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding: ${({ theme: { sizes } }) => sizes.spacing.xxsmall};
`
export const TextDays = styled(Typography)`
  ${({ theme }) => theme.fonts[FontsName.Nunito].xsmallbold};
  margin-top: ${({ theme: { sizes } }) => sizes.spacing.xxxsmall};
  margin-right: ${({ theme: { sizes } }) => sizes.spacing.xxsmall};
  color: ${colors.tertiary600};
  background-color: ${colors.white};
  box-shadow: 2px 4px 8px rgba(34, 38, 42, 0.03),
    4px 8px 16px rgba(34, 38, 42, 0.04);
  border-radius: ${({ theme: { sizes } }) => sizes.borderRadius.large};
`
export const TextDiscount = styled(Typography)`
  color: ${colors.white};
  background-color: ${colors.tertiary600};
  padding: ${({ theme: { sizes } }) =>
    `${sizes.spacing.xxxsmall} ${sizes.spacing.xxsmall}`};
  ${({ theme }) => theme.fonts[FontsName.Nunito].xsmallbold};
  border-radius: ${({ theme: { sizes } }) => sizes.borderRadius.medium};
`

/* Discount Image */
export const DiscountImage = styled.img`
  width: 96px;
  height: 78px;
  display: inherit;
  object-fit: contain;
  margin-top: ${({ theme: { sizes } }) => sizes.spacing.xxlarge};
  margin-bottom: ${({ theme: { sizes } }) => sizes.spacing.xxsmall};
  border-radius: ${({ theme: { sizes } }) => sizes.borderRadius.large};
`

/* Discount Name */
export const DiscountName = styled.div`
  width: 100%;
  color: ${colors.neutral700};
  ${({ theme }) => theme.fonts[FontsName.Nunito].small}
  justify-content: center;
`

/* Discount Price */
export const DiscountPrice = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
`
export const TextRealPrice = styled(Typography)`
  ${({ theme }) => theme.fonts[FontsName.Nunito].xsmall};
  margin-right: ${({ theme: { sizes } }) => sizes.spacing.xsmall};
  color: ${colors.neutral600};
  text-decoration: line-through;
`
export const TextDiscountPrice = styled(Typography)`
  ${({ theme }) => theme.fonts[FontsName.Nunito].smallbold};
  line-height: ${({ theme: { sizes } }) => sizes.spacing.large};
  color: ${colors.tertiary600};
`

/* Discount Price */
export const DiscountButton = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`
