import { useState } from 'react'

import {
  ListChipmentType,
  getListShipmentService,
} from '../../services/Shipment/listShipmentService'

interface Request {
  authToken: string
  storeId: string
}
interface Response {
  isLoadingListShipment: boolean
  hasErrorListShipment: boolean
  numberResults: number
  getListShipment: (currentPage: number) => void
  listShipmentData: ListChipmentType[]
  refetchShipmentList: () => void
}

export const useListShipmentService = ({
  authToken,
  storeId,
}: Request): Response => {
  const [listShipmentData, setListShipmentData] = useState<ListChipmentType[]>(
    []
  )
  const [isLoadingListShipment, setIsLoadingListShipment] = useState(false)
  const [hasErrorListShipment, setHasErrorListShipment] = useState(false)
  const [numberResults, setNumberResults] = useState(0)

  const callService = async (currentPage: number) => {
    setIsLoadingListShipment(true)

    try {
      const documentTypesDataResponse = await getListShipmentService(
        currentPage,
        authToken,
        storeId
      )
      setNumberResults(documentTypesDataResponse.length)
      if (documentTypesDataResponse.length > 0) {
        setListShipmentData(listShipmentData.concat(documentTypesDataResponse))
      }

      setIsLoadingListShipment(false)
    } catch (err) {
      console.log(err)
      setHasErrorListShipment(true)
      setIsLoadingListShipment(false)
    }
  }

  const refetchShipmentList = async () => {
    setIsLoadingListShipment(true)
    setListShipmentData([])

    try {
      const documentTypesDataResponse = await getListShipmentService(
        1,
        authToken,
        storeId
      )
      setNumberResults(documentTypesDataResponse.length)
      if (documentTypesDataResponse.length > 0) {
        setListShipmentData(documentTypesDataResponse)
      }

      setIsLoadingListShipment(false)
    } catch (err) {
      console.log(err)
      setHasErrorListShipment(true)
      setIsLoadingListShipment(false)
    }
  }

  return {
    listShipmentData,
    hasErrorListShipment,
    isLoadingListShipment,
    numberResults,
    getListShipment: callService,
    refetchShipmentList,
  }
}
