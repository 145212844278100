import Carousel from 'react-multi-carousel'
import 'react-multi-carousel/lib/styles.css'
import { useTranslation } from 'react-i18next'

import { SLIDE_PROPS } from './constants'
import { countries } from '../../config/countries'
import { MainProductsSlide, TextTitle } from './styles'
import ProductDiscount from '../Product/ProductDiscount'
import { useStoreConfig } from '../../context/AppContext'
import { DiscountResponse, ProductDiscountItem } from '../../types/models'

interface DiscountsProps {
  discounts: DiscountResponse[]
}

const ProductsSlide = ({ discounts }: DiscountsProps): JSX.Element => {
  const { store } = useStoreConfig()
  const { t: translate } = useTranslation()
  const country = countries.get(store.country)

  const productsDiscountList = (): ProductDiscountItem[] => {
    let listProducts: ProductDiscountItem[] = []
    if (discounts.length > 0) {
      discounts.forEach(({ productsDiscounts, startDate, endDate }) => {
        if (productsDiscounts.length > 0) {
          productsDiscounts.forEach((productDiscount) => {
            listProducts = listProducts.concat({
              ...productDiscount,
              endDate,
              startDate,
            })
          })
        }
      })
    }
    return listProducts
  }

  return (
    <MainProductsSlide data-testid="slide-items-container">
      <TextTitle>{translate('discounts.title')}</TextTitle>
      <Carousel
        responsive={SLIDE_PROPS}
        removeArrowOnDeviceType={['tablet', 'mobile', 'miniMobile']}
      >
        {productsDiscountList().map((product) => (
          <ProductDiscount
            key={product.id}
            product={product}
            country={country}
            endDate={product?.endDate}
          />
        ))}
      </Carousel>
    </MainProductsSlide>
  )
}

export default ProductsSlide
