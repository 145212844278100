import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import {
  ICountry,
  ServiceDetailOrder,
  OrderConfirmedResponse,
} from '../../types/models'
import { Error } from './components/Error'
import { Header } from './components/Header'
import { Status } from './components/Status'
import { Footer } from './components/Footer'
import { SummaryLoad } from './components/Summary'
import { countries } from '../../config/countries'
import { MainContainer, ScreenContainer } from './styles'
import { ProductListLoad } from './components/ProductList/ProductList.load'
import { useGetStoreInfoService } from '../../hooks/Store/useGetStoreInfoService'

export const NewOrderConfirmed = (): JSX.Element => {
  const [isError, setIsError] = useState<boolean>(false)
  const [status, setStatus] = useState<number | null>(null)
  const [order, setOrder] = useState<OrderConfirmedResponse | undefined>(
    undefined
  )
  const [orderDetail, setOrderDetail] = useState<
    ServiceDetailOrder | undefined
  >(undefined)
  const {
    name,
    transactionId,
  }: { transactionId: string; name: string } = useParams()
  const [currenCountry, setCurrenCountry] = useState<ICountry>(
    countries.get('Colombia')
  )

  const {
    storeInfo,
    errorStoreInfo,
    loadingStoreInfo,
  } = useGetStoreInfoService({
    storeUrlName: name,
    authToken: process.env.REACT_APP_TOKEN || '',
  })

  useEffect(() => {
    if (storeInfo) {
      const country = countries.get(storeInfo.country)
      setCurrenCountry(country)
    }
  }, [storeInfo])

  useEffect(() => {
    if (errorStoreInfo) {
      setIsError(true)
    } else {
      setIsError(false)
    }
  }, [errorStoreInfo])

  return (
    <ScreenContainer>
      {isError ? (
        <Error />
      ) : (
        <MainContainer>
          <Header />
          <SummaryLoad
            setOrder={setOrder}
            country={currenCountry}
            handleError={setIsError}
            transactionId={transactionId}
            storeInfoIsLoading={loadingStoreInfo}
          />
          <ProductListLoad
            setStatus={setStatus}
            country={currenCountry}
            handleError={setIsError}
            transactionId={transactionId}
            setOrderDetail={setOrderDetail}
          />
          <Status status={status} />
          {storeInfo && order && (
            <Footer
              name={name}
              order={order}
              country={currenCountry}
              orderDetail={orderDetail}
              storeId={storeInfo?.id || ''}
              phoneNumber={
                storeInfo?.phone
                  ? `${currenCountry.code}${storeInfo.phone}`
                  : ''
              }
            />
          )}
        </MainContainer>
      )}
    </ScreenContainer>
  )
}
