import styled from 'styled-components'

export const SkeletonBox = styled.div`
  height: 1em;
  width: 100%;
  overflow: hidden;
  position: relative;
  display: inline-block;
  background-color: #dddbdd;
  border-radius: ${({ theme: { sizes } }) => sizes.borderRadius.large};

  &::after {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: absolute;
    transform: translateX(-100%);
    background-image: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0) 0,
      rgba(255, 255, 255, 0.2) 20%,
      rgba(255, 255, 255, 0.5) 60%,
      rgba(255, 255, 255, 0)
    );
    animation: shimmer 2s infinite;
    content: '';
  }

  @keyframes shimmer {
    100% {
      transform: translateX(100%);
    }
  }
`

export const Container = styled.div`
  width: 100%;
  padding: ${({ theme: { sizes } }) => sizes.spacing.medium};
`

export const Title = styled.div`
  width: 50%;
  margin-bottom: ${({ theme: { sizes } }) => sizes.spacing.xlarge};
`
export const Subtitle = styled.div`
  width: 50%;
  margin-bottom: ${({ theme: { sizes } }) => sizes.spacing.medium};
`
export const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: ${({ theme: { sizes } }) => sizes.spacing.medium};
`
export const Item30 = styled.div`
  width: 30%;
`
export const Item40 = styled.div`
  width: 40%;
`
export const Cube = styled(SkeletonBox)`
  width: 56px;
  height: 48px;
  margin-right: ${({ theme: { sizes } }) => sizes.spacing.medium};
`
export const InfoContainer = styled.div`
  width: 30%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
`
export const Center = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
`
export const MarginBottom = styled.div`
  width: 100%;
  margin-bottom: ${({ theme }) => theme.sizes.spacing.small};
`
export const Cube2 = styled(SkeletonBox)`
  width: 56px;
  height: 48px;
`
