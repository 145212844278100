import styled from 'styled-components'
import { FontsName } from '../../../styles/fonts'
import { Typography } from '../../common/Typography'
import BannerChallenge from '../../../assets/banners/banner-challenge.png'
import { ReactComponent as BackIconImage } from '../../../assets/arrowBack.svg'
import { ReactComponent as MyAccountIconImage } from '../../../assets/myAccount.svg'
import { ReactComponent as TrashIconImage } from '../../../assets/icons/trash-icon.svg'

export const MainContainer = styled.div`
  padding: ${({
    theme: { sizes },
  }) => `${sizes.spacing.large} ${sizes.spacing.medium} 
    ${sizes.spacing.xsmall} ${sizes.spacing.medium}`};
  margin: 0 auto;
  display: flex;
  height: 100vh;
  overflow: hidden;
  flex-direction: column;
  position: relative;
`
// Header Styles
export const ResumeHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

// Banner Styles
export const ResumeBanner = styled.div`
  margin-top: ${({ theme: { sizes } }) => sizes.spacing.xxxlarge};
  background-image: url(${BannerChallenge});
  background-repeat: no-repeat;
  background-size: contain;
  cursor: pointer;
  display: flex;
  width: 100%;
  min-height: 104px;
  height: auto;
`
export const SectionTextBanner = styled.div`
  padding-top: ${({ theme: { sizes } }) => sizes.spacing.xsmall};
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  white-space: pre-line;
  width: 100%;
  height: 105px;
`
export const TextDescription = styled(Typography)`
  ${({ theme }) => theme.fonts[FontsName.Nunito].small}
  color: ${({ theme }) => theme.colors.secondary500};
  display: inline-block;
`
export const TextNumberSents = styled(Typography)`
  ${({ theme }) => theme.fonts[FontsName.Nunito].mediumbold}
  padding: ${({ theme: { sizes } }) => `${sizes.spacing.xxxsmall} 2.2rem`};
  background-color: ${({ theme }) => theme.colors.secondary500};
  color: ${({ theme }) => theme.colors.white};
  border-radius: 40px;
`
export const TextTYC = styled(Typography)`
  ${({ theme }) => theme.fonts[FontsName.Nunito].small}
  margin-top: ${({ theme: { sizes } }) => sizes.spacing.xxsmall};
  color: ${({ theme }) => theme.colors.secondary500};
`
export const TextCongratulations = styled(Typography)`
  ${({ theme }) => theme.fonts[FontsName.Nunito].largebold}
  margin-bottom: ${({ theme: { sizes } }) => sizes.spacing.xxxsmall};
  color: ${({ theme }) => theme.colors.secondary700};
`
export const TextWinner = styled(Typography)`
  ${({ theme }) => theme.fonts[FontsName.Nunito].small}
  color: ${({ theme }) => theme.colors.secondary700};
`
// Resume Styles
export const ResumeStore = styled.div`
  margin-top: ${({ theme: { sizes } }) => sizes.spacing.xxlarge};
  margin-bottom: ${({ theme: { sizes } }) => sizes.spacing.medium};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`
export const ResumeStoreTitle = styled.div`
  ${({ theme }) => theme.fonts[FontsName.Nunito].xlargebold}
`
export const ResumeStoreSubTitle = styled.div`
  ${({ theme }) => theme.fonts[FontsName.Nunito].small}
`
export const ShipmentButtonContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  box-shadow: 2px -4px 8px rgba(34, 38, 42, 0.05);
  padding: 8px 16px;
`
export const ShipmentButtonStyles = {
  width: '100%',
  maxWidth: 'none',
}

export const SectionBack = styled.div`
  ${({ theme }) => theme.fonts[FontsName.Nunito].smallbold}
  color: ${({ theme }) => theme.colors.secondary700};
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
`
export const SectionMyAccount = styled.div`
  ${({ theme }) => theme.fonts[FontsName.Nunito].smallbold}
  color: ${({ theme }) => theme.colors.tertiary600};
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
`
export const ImageStore = styled.img`
  border: ${({ theme }) => `0.5px solid ${theme.colors.neutral500}`};
  width: 48px;
  height: 48px;
  border-radius: 50%;
`
export const BackIcon = styled(BackIconImage)`
  width: ${({ theme: { sizes } }) => sizes.spacing.medium};
  min-width: ${({ theme: { sizes } }) => sizes.spacing.medium};
  margin-right: ${({ theme: { sizes } }) => sizes.spacing.small};
  height: auto;
`

export const MyAccountIcon = styled(MyAccountIconImage)`
  width: ${({ theme: { sizes } }) => sizes.spacing.medium};
  min-width: ${({ theme: { sizes } }) => sizes.spacing.medium};
  margin-left: ${({ theme: { sizes } }) => sizes.spacing.small};
  cursor: pointer;
  height: auto;
`

// Item List Styles
export const ItemContainer = styled.div`
  margin-bottom: ${({ theme: { sizes } }) => sizes.spacing.medium};
  width: 100%;
  display: flex;
  position: relative;
  align-items: center;
`
export const ItemWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`
export const TextSent = styled(Typography)`
  ${({ theme }) => theme.fonts[FontsName.Nunito].smallbold}
`
export const TextUserShipment = styled(Typography)`
  ${({ theme }) => theme.fonts[FontsName.Nunito].mediumbold}
  margin-bottom: ${({ theme: { sizes } }) => sizes.spacing.xxsmall};
`
export const TextDateShipment = styled(Typography)`
  ${({ theme }) => theme.fonts[FontsName.Nunito].medium}
  color: ${({ theme }) => theme.colors.neutral600};
`
export const ButtonViewShipment = styled.button`
  ${({ theme }) => theme.fonts[FontsName.Nunito].mediumbold}
  border: 0.5px solid ${({ theme }) => theme.colors.neutral500};
  border-radius: ${({ theme: { sizes } }) => sizes.borderRadius.medium};
  background-color: ${({ theme }) => theme.colors.white};
  padding: ${({ theme }) =>
    `${theme.sizes.spacing.xsmall} ${theme.sizes.spacing.medium}`};
  cursor: 'pointer';
`
export const TrashIcon = styled(TrashIconImage)`
  margin-right: ${({ theme: { sizes } }) => sizes.spacing.medium};
  width: ${({ theme: { sizes } }) => sizes.spacing.large};
  height: ${({ theme: { sizes } }) => sizes.spacing.large};
  cursor: pointer;
`

export const DrawerContent = styled(Typography)`
  ${({ theme }) => theme.fonts[FontsName.Nunito].medium}
  color: ${({ theme }) => theme.colors.neutral600};
  white-space: pre-line;
`
