import { useMemo, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { OrderDetail } from './OrderDetail'
import { countries } from '../../config/countries'
import { useStoreConfig } from '../../context/AppContext'
import { useHistory, useParams, useRouteMatch } from 'react-router-dom'
import { fetchData, fetchOrderDetail } from '../../services/order'
import { ServiceDetailOrder } from '../../types/models'

export const OrderDetailLoad = (): JSX.Element => {
  const { store, setStore }: any = useStoreConfig()
  const { t } = useTranslation()
  const history = useHistory()
  const { url } = useRouteMatch()
  const { orderId, name }: { orderId: string; name: string } = useParams()
  const [data, setData] = useState<ServiceDetailOrder | null>()
  const country = countries.get(store.country)
  const phoneNumber = country.code + store.phone

  useEffect(() => {
    if (!store.name) {
      fetchData(name).then((res) => {
        setStore(res.store)
      })
    }
  }, [name])

  useEffect(() => {
    if (orderId) {
      fetchOrderDetail(orderId).then((res) => {
        setData(res)
      })
    }
  }, [orderId])

  const buttonBack = () => {
    history.push(url.replace(`/${orderId}`, ''))
  }

  const contactStore = () => {
    window.open(
      `https://wa.me/${phoneNumber}?text=${t('orderDetail.whatsappMessage')}`
    )
  }

  const {
    address,
    transactionCount,
    deliveryPrice,
    deliveryTypeId,
  } = useMemo(() => {
    if (data) {
      const {
        order_address,
        transaction_count,
        delivery_type_id,
        delivery_price,
      } = data.transaction
      return {
        address: order_address,
        transactionCount: transaction_count,
        deliveryPrice: delivery_price,
        deliveryTypeId: delivery_type_id,
      }
    }
    return {
      address: '',
      transactionCount: '',
      deliveryPrice: 0,
      deliveryTypeId: 0,
    }
  }, [data])

  const totalOrder = useMemo(() => {
    if (data) {
      return data.transactionDetails.reduce((total: number, product: any) => {
        const totalProduct = Number(product.sale_price) * product.quantity
        return total + totalProduct
      }, 0)
    }
    return 0
  }, [data])

  return (
    <OrderDetail
      data={data ? data.transactionDetails : []}
      country={country}
      totalOrder={totalOrder}
      address={address}
      transactionCount={transactionCount}
      buttonBack={buttonBack}
      contactStore={contactStore}
      deliveryPrice={deliveryPrice}
      deliveryTypeId={deliveryTypeId}
    />
  )
}
