import styled from 'styled-components'
import { ReactComponent as LeftArrowIcon } from '../../assets/left-arrow.svg'

const SCREENS_HEADER_HEIGHT = 56

export const MainContainer = styled.div`
  align-items: center;
  display: flex;
  height: ${SCREENS_HEADER_HEIGHT}px;
  justify-content: flex-start;
  padding-left: ${({ theme: { sizes } }) => sizes.spacing.large};
  padding-right: ${({ theme: { sizes } }) => sizes.spacing.large};
  width: 100%;
`

export const GoBackIcon = styled(LeftArrowIcon)`
  cursor: pointer;
  margin-right: ${({ theme: { sizes } }) => sizes.spacing.large};
`
