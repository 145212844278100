import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useRouteMatch, useHistory } from 'react-router-dom'

import {
  MainContainer,
  ResumeHeader,
  ResumeStore,
  ResumeStoreTitle,
  ResumeStoreSubTitle,
  ShipmentButtonStyles,
  SectionBack,
  SectionMyAccount,
  DrawerContent,
  BackIcon,
  MyAccountIcon,
  ShipmentButtonContainer,
} from './styles'
import { Button } from '../../common/Button'
import { redirectUrl } from '../utils/redirect'
import { StepNames } from '../../../types/models'
import BottomDrawer from '../../common/BottomDrawer'
import ResumeSentDelivery from './ResumeSentDelivery'
import ResumeBannerDelivery from './ResumeBannerDelivery'
import { InfoDeliveryProps } from '../../Delivery/contracts'
import { generateContentText } from '../../../utils/functions'
import useGAEventTracker from '../../../hooks/useGAEventTracker'
import { WhatsappButtonDeliveryLoad } from '../WhatsappButtonDelivery'
import { ToastMessageAlert } from '../../common/ToastMessage/ToastMessageAlert'
import { useHasBankInformationService } from '../../../hooks/BankInformation/useHasBankInformationService'

const MyResumeDelivery = ({
  state,
  setDeliveryState,
}: InfoDeliveryProps): JSX.Element => {
  const history = useHistory()
  const { t } = useTranslation()
  const { url } = useRouteMatch()
  const GAEventsTracker = useGAEventTracker('webshop')
  const [drawerIsOpen, setDrawerIsOpen] = useState(false)
  const {
    hasBankInformation,
    isLoadingHasBankInfo,
    getHasBankInfo,
  } = useHasBankInformationService({
    authToken: state.authToken,
  })
  // load User Bank Information after request AuthToken
  useEffect(() => {
    // validate User Has Account Bank Information
    if (state.authToken && !state.userHasAccountData) getHasBankInfo()
  }, [state.authToken])

  useEffect(() => {
    // validate User Has Account Bank Information
    if (state.authToken && !state.userHasAccountData) getHasBankInfo()
  }, [state.authToken])

  // set Status User Has Bank Information
  useEffect(() => {
    if (!isLoadingHasBankInfo)
      setDeliveryState({ ...state, userHasAccountData: hasBankInformation })
  }, [isLoadingHasBankInfo])

  const handleClickNewShipment = (): void => {
    GAEventsTracker('shop_webdelivery_menu_quote')
    setDeliveryState({
      ...state,
      currentStep: StepNames.Address,
    })
    redirectUrl(history, url)
  }

  const handleClickBack = () => {
    GAEventsTracker('shop_webdelivery_menu_back')
    const splitUrl = url.split('/')
    splitUrl.pop()
    splitUrl.pop()
    const redirectionPath = splitUrl.join('/')
    history.push(redirectionPath)
    location.reload()
  }

  const handleClickMyAccount = (): void => {
    GAEventsTracker('shop_webdelivery_menu_account')
    setDeliveryState({
      ...state,
      currentStep: StepNames.BankInfo,
    })
    redirectUrl(history, url)
  }

  const handleClickToastAccount = (): void => {
    setDeliveryState({
      ...state,
      currentStep: StepNames.BankInfo,
    })
    redirectUrl(history, url)
  }

  const handleDrawerStatus = (newStatus: boolean): void => {
    setDrawerIsOpen(newStatus)
  }

  return (
    <>
      {!isLoadingHasBankInfo && !state.userHasAccountData && (
        <ToastMessageAlert
          textItem={t('delivery.toasts.toastWithoutAccountData')}
          handleClickToast={handleClickToastAccount}
        />
      )}
      <MainContainer data-testid="myResume-container">
        <ResumeHeader>
          <SectionBack onClick={handleClickBack}>
            <BackIcon />
            {t('delivery.treintaShop')}
          </SectionBack>
          <SectionMyAccount onClick={handleClickMyAccount}>
            {t('delivery.myAccount')}
            <MyAccountIcon />
          </SectionMyAccount>
        </ResumeHeader>

        <ResumeBannerDelivery {...{ state, handleDrawerStatus }} />

        <ResumeStore>
          <ResumeStoreTitle>{t('delivery.myDeliveries')}</ResumeStoreTitle>
          <ResumeStoreSubTitle>
            {t('delivery.myDeliveriesSubtitle')}
          </ResumeStoreSubTitle>
        </ResumeStore>
        <ResumeSentDelivery state={state} setDeliveryState={setDeliveryState} />
        <WhatsappButtonDeliveryLoad />
        <ShipmentButtonContainer>
          <Button
            data-testid="newshipment-button"
            text={t('delivery.quoteNewDelivery') as string}
            onClick={handleClickNewShipment}
            style={ShipmentButtonStyles}
          />
        </ShipmentButtonContainer>

        <BottomDrawer
          title={t('delivery.drawers.challengesShop.title')}
          open={drawerIsOpen}
          onClose={() => handleDrawerStatus(false)}
        >
          <DrawerContent data-testid="challenge-terms-drawer-content">
            <div
              dangerouslySetInnerHTML={{
                __html: generateContentText(
                  t('delivery.drawers.challengesShop.content', {
                    returnObjects: true,
                  })
                ),
              }}
            />
          </DrawerContent>
        </BottomDrawer>
      </MainContainer>
    </>
  )
}
export default MyResumeDelivery
