import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import {
  Title,
  BackIcon,
  SubTitle,
  CloseIcon,
  InfoButton,
  ErrorMessage,
  InputTextForm,
  FormContainer,
  InputContainer,
  LabelInputForm,
  InfoButtonContainer,
  Spacing,
} from './styles'
import { Button } from '../../common/Button'
import { FormShippingProps, AddressType } from '../contracts'
import InputAutoComplete from '../../common/InputAutoComplete'

export const FormShippingFields = ({
  handleClickBack,
  handleClickClose,
  handleChangeAddress,
  handleChangeAddressDetail,
  handleClickNext,
  preventDefault,
  findAddressSuggestions,
  isValidForm,
  canShowError,
  openOrigin,
  openDestin,
  originData,
  destinationData,
  customerData,
  defaultAddressData,
  originAddressParams,
  shippingDataError,
  setOriginAddressParams,
  setCanShowError,
  setShippingDataError,
  setCustomerData,
  setOpenOrigin,
  setOpenDestin,
  setDestinationAddressParams,
  originAddressSuggest,
  destinationAddressSuggest,
  destinationAddressParams,
}: FormShippingProps): JSX.Element => {
  const { t } = useTranslation()
  const [oAddressModified, setOAddressModified] = useState(false)
  const [dAddressModified, setDAddressModified] = useState(false)

  useEffect(() => {
    setShippingDataError({
      ...shippingDataError,
      originAddresError: oAddressModified,
      destinationAddressError: dAddressModified,
    })
  }, [oAddressModified, dAddressModified])

  const handleChangeAddressField = (
    newValue: any,
    reason: string,
    addressType: AddressType
  ) => {
    if (reason === 'select-option') {
      addressType === 'origin'
        ? setOAddressModified(false)
        : setDAddressModified(false)
    } else if (reason === 'clear') {
      addressType === 'origin'
        ? setOAddressModified(true)
        : setDAddressModified(true)
    }
    handleChangeAddress(newValue, addressType)
    setCanShowError(false)
  }
  const handleChangeInputField = (
    e: React.ChangeEvent<{ value: string }>,
    inputName: string
  ) => {
    setCustomerData({
      ...customerData,
      [inputName]: e.target.value,
    })
  }
  const handleOpenAddressField = (isOpen: boolean, showError: boolean) => {
    setOpenOrigin(isOpen)
    setCanShowError(showError)
  }

  const handleOriginAddressChangeField = (
    e: React.ChangeEvent<{ value: string }>
  ): void => {
    if (e) {
      const { value } = e.target
      setOriginAddressParams({
        ...defaultAddressData,
        address: value,
      })
      if (value.length > 3) findAddressSuggestions(value, 'origin')
    }
  }

  const handleDestinationAddressChangeField = (
    e: React.ChangeEvent<{ value: string }>
  ): void => {
    if (e) {
      const { value } = e.target
      setDestinationAddressParams({
        ...defaultAddressData,
        address: value,
      })
      if (value.length > 3) findAddressSuggestions(value, 'destination')
    }
  }

  const handleOnKeyDownAddress = (addressType: AddressType): void => {
    if (addressType === 'origin') setOAddressModified(true)
    if (addressType === 'destination') setDAddressModified(true)
  }

  return (
    <FormContainer>
      <Title>
        <BackIcon data-testid="back-icon" onClick={handleClickBack} />
        {t('delivery.sectionForm.shippingFormTitle')}
        <CloseIcon data-testid="close-icon" onClick={handleClickClose} />
      </Title>
      <SubTitle>{t('delivery.sectionForm.shippingFormSendTitle')}</SubTitle>

      {/* origin Address Field */}
      <InputContainer autoComplete="off" onSubmit={preventDefault}>
        <InputAutoComplete
          showLabel={true}
          showIcon={false}
          open={openOrigin}
          id={'oaddress-input'}
          dataTestId="oaddress-input-testId"
          value={String(originData?.address)}
          suggestValues={originAddressSuggest}
          inputValue={originAddressParams?.address}
          showError={
            oAddressModified ||
            (shippingDataError.originAddresError && canShowError)
          }
          labelText={
            t('delivery.sectionForm.shippingFormLabelOriginAddress') + '*'
          }
          errorText={
            oAddressModified
              ? t('delivery.sectionForm.errors.errorAddressNoAutocomplete')
              : t('delivery.sectionForm.errors.errorOriginAddress')
          }
          placeHolderText={t(
            'delivery.sectionForm.shippingFormPlaceholderOriginAddress'
          )}
          onInputChange={(e) => {
            handleOriginAddressChangeField(e)
          }}
          onChange={(_, newValue: any, reason: string) => {
            handleChangeAddressField(newValue, reason, 'origin')
          }}
          onKeyDown={() => {
            handleOnKeyDownAddress('origin')
          }}
          onOpen={() => handleOpenAddressField(true, true)}
          onClose={() => handleOpenAddressField(false, false)}
        />
      </InputContainer>

      {/* origin Address Detail Field */}
      <InputContainer autoComplete="off" onSubmit={preventDefault}>
        <LabelInputForm htmlFor="oaddressdetail-input">
          {t('delivery.sectionForm.shippingFormLabelODetailAddress')}
        </LabelInputForm>
        <InputTextForm
          value={String(originData?.addressDetail)}
          maxLength={100}
          id="oaddressdetail-input"
          data-testid="oaddressdetail-input-testId"
          onChange={(e) => handleChangeAddressDetail(e.target.value, 'origin')}
          placeholder={t(
            'delivery.sectionForm.shippingFormPlaceholderODetailAddress'
          )}
        />
      </InputContainer>

      {/* destination Address Field */}
      <InputContainer autoComplete="off" onSubmit={preventDefault}>
        <InputAutoComplete
          showLabel={true}
          showIcon={false}
          open={openDestin}
          id={'daddress-input'}
          dataTestId="daddress-input-testId"
          value={destinationData.address}
          suggestValues={destinationAddressSuggest}
          inputValue={destinationAddressParams?.address}
          showError={shippingDataError.destinationAddressError}
          labelText={
            t('delivery.sectionForm.shippingFormLabelDestinationAddress') + '*'
          }
          errorText={
            !destinationData?.address
              ? t('delivery.sectionForm.errors.errorAddressNoAutocomplete')
              : t('delivery.sectionForm.errors.errorDestinationAddress')
          }
          placeHolderText={t(
            'delivery.sectionForm.shippingFormPlaceholderDestinationAddress'
          )}
          onInputChange={(e) => {
            handleDestinationAddressChangeField(e)
          }}
          onChange={(_, newValue: any, reason: string) => {
            handleChangeAddressField(newValue, reason, 'destination')
          }}
          onKeyDown={() => {
            handleOnKeyDownAddress('destination')
          }}
          onOpen={() => {
            setOpenDestin(true)
          }}
          onClose={() => {
            setOpenDestin(false)
          }}
        />
      </InputContainer>

      {/* destination Address Detail Field */}
      <InputContainer autoComplete="off" onSubmit={preventDefault}>
        <LabelInputForm>
          {t('delivery.sectionForm.shippingFormLabelDDetailAddress')}
        </LabelInputForm>
        <InputTextForm
          value={String(destinationData?.addressDetail)}
          maxLength={100}
          id="daddressdetail-input"
          data-testid="daddressdetail-input-testId"
          onChange={(e) =>
            handleChangeAddressDetail(e.target.value, 'destination')
          }
          placeholder={t(
            'delivery.sectionForm.shippingFormPlaceholderDDetailAddress'
          )}
        />
      </InputContainer>

      <SubTitle forwardedAs="h1" variant="Mediumbold">
        {t('delivery.sectionForm.shippingFormAddressTitle')}
      </SubTitle>

      {/* name Field */}
      <InputContainer autoComplete="off" onSubmit={preventDefault}>
        <LabelInputForm htmlFor="name-input">
          {t('delivery.sectionForm.shippingFormLabelName')}
        </LabelInputForm>
        <InputTextForm
          value={customerData.name}
          minLength={3}
          maxLength={40}
          id="name-input"
          data-testid="name-input-testId"
          onChange={(e) => handleChangeInputField(e, 'name')}
          placeholder={t('delivery.sectionForm.shippingFormPlaceholderName')}
        />
        {shippingDataError.nameError && (
          <ErrorMessage variant="XSmall">
            {t('delivery.sectionForm.error.errorName')}
          </ErrorMessage>
        )}
      </InputContainer>

      {/* phone Field */}
      <InputContainer>
        <LabelInputForm htmlFor="phone-input">
          {t('delivery.sectionForm.shippingFormLabelPhone')}
        </LabelInputForm>
        <InputTextForm
          id="phone-input"
          data-testid="phone-input-testId"
          type="number"
          value={customerData.cellPhone}
          minLength={3}
          maxLength={80}
          onChange={(e) => handleChangeInputField(e, 'cellPhone')}
          placeholder={t('delivery.sectionForm.shippingFormPlaceholderPhone')}
        />
        {customerData.cellPhone.length > 0 &&
          customerData.cellPhone.length !== 10 && (
            <ErrorMessage variant="XSmall">
              {t('delivery.sectionForm.errors.errorLengthPhone')}
            </ErrorMessage>
          )}
      </InputContainer>

      <Spacing />

      {/* next Button Form */}
      <InfoButtonContainer>
        <Button
          text={t('delivery.sectionForm.shippingFormNext') as string}
          style={InfoButton}
          disabled={!isValidForm}
          onClick={handleClickNext}
        />
      </InfoButtonContainer>
    </FormContainer>
  )
}
