interface IStoreConfig {
  catalogUrl: string
  color: string
  colors: string
  description: string
  onboardingStep: number
  productShowType: number
  title: string
  woStock: number
  delivery: boolean
  deliveryPrice: number
  storePickUp: boolean
  storeSchedule?: ISchedule[]
}

export interface DeliveryPriceAreaResponse {
  id: string
  storeId: string
  createdAt: string
  updatedAt: string
  deletedAt?: string | null
  nameArea: string
  price: number
  isActive: boolean
}

export interface ISchedule {
  id: string
  day: string
  scheduleIn: string
  scheduleOut: string
  isOpen: boolean
}

export interface IStore {
  id?: string
  name: string
  city?: string
  urlName: string
  address?: string
  country?: string
  email?: string
  phone?: string
  imageUrl?: string
  config?: IStoreConfig
  fetchError?: boolean
}

export interface IProduct {
  id: string
  name: string
  sku: string
  price: number
  cost?: number
  stock: number
  notes?: string
  transactions?: string
  categories: string
  imageUrl?: string
  isVisible?: boolean
  isOffline?: boolean
  createdAt: string
  updatedAt: string
  deletedAt: string
  storeId: string
  measureId?: string
  originId: number
  discount?: DiscountProduct
  images?: string
}

export interface IProductItemList {
  id: string
  name: string
  price: number
  imageUrl?: string
  notes?: string
  quantity: number
  total: number
  stock: number
}

export interface IProductTotal {
  id: string
  total: number
}

export const ALL_PRODUCTS_CATEGORY: ICategory = {
  name: 'Ver todos',
}
export const DISCOUNTS_CATEGORY: ICategory = {
  name: 'Descuentos',
}

export interface ICategory {
  name: string
  products?: string
  imageUrl?: string
}

export interface IPalette {
  primary: string
  secondary: string
  contrast: string
  complementary: string
}

export interface ICountry {
  id: number
  name: string
  acronym: string
  code: string
  digits: number[]
  support: string
  locale: string
  currency: string
  decimals: boolean
  whatsappUrl: string
  timeZone: string
  flag?: NodeRequire
}

export interface IMetaDescription {
  store_name: string
  store_title: string
  imageUrl: string
}

export interface DetailOrder {
  id: string
  product_id: string
  quantity: number
  sale_price: number
  name: string
  imageUrl: string
  image_url?: string
}

export interface ServiceTransaction {
  transaction_count: string
  order_address: any
  delivery_price: number
  delivery_type_id: number
  transaction_status_id: number
}
export interface ServiceDetailOrder {
  transaction: ServiceTransaction
  transactionDetails: DetailOrder[]
}
export interface StoreConfigContextType {
  assignTheme: string
  setAssignTheme: (newState: string) => void
  store: IStore
  setStore: (newState: string) => void
  products: Array<IProduct>
  setProducts: (newState: string) => void
  categories: Array<ICategory>
  setCategories: (newState: any) => void
  filteredProducts: Array<IProduct>
  setFilteredProducts: (newState: string) => void
  filteredCategories: Array<ICategory>
  setFilteredCategories: (newState: any) => void
  productShowType: number
  setProductShowType: (newState: number) => void
  stockConfig: number
  setStockConfig: (newState: number) => void
  activeSection: number
  setActiveSection: (newState: number) => void
  search: string
  setSearch: (newState: string) => void
  isFromMarketplace: boolean
  setIsFromMarketplace: (newState: boolean) => void
  isUserAuthenticated: boolean | undefined
  setIsUserAuthenticated: (isUserAuthenticated: boolean) => void
  authRedirectPath: string
  setAuthRedirectPath: (newState: string) => void
  error: string
  setError: (error: string) => void
}

export interface ProtectedRouteType {
  path: string
  component: any
  exact?: boolean
  redirectPath: string
  unAuthRedirectPath: string
}

export interface StoreAuthStateType {
  name: string
  phone: string
  country: ICountry
  userHasBeenCreated: boolean | null
}

export interface StoreAuthContextType {
  state: StoreAuthStateType
  setName: (name: string) => void
  setPhone: (phone: string) => void
  setCountry: (contry: ICountry) => void
  setUserHasBeenCreated: (HasBeenCreated: boolean) => void
}

/* Delivery Interfaces */
export enum StepNames {
  Address = 1,
  PaymentMethod = 2,
  ProviderList = 3,
  Summary = 4,
  Confirm = 5,
  BankInfo = 6,
}
export enum PaymentMethodOptions {
  Cash = 1,
  UponDelivery = 2,
}
export enum DepositOptions {
  Standard = 1,
  Express = 2,
  General = 3,
}

export enum AccountTypesOptions {
  CheckAccount = 1,
  SaveAccount = 2,
  Nequi = 3,
  Daviplata = 4,
}

export enum CountriesOptions {
  Colombia = 'CO',
  Brasil = 'BR',
}

export type SplitIOTreatmentOptions =
  | 'shop_deliveryDepositTypeShopWeb'
  | 'shop_deliveryBannerRewardShopWeb'

export interface PaymentMethodType {
  method: PaymentMethodOptions
  saleValue: number
}

export interface DeliveryLocationType {
  address: string
  country_id: string
  city_id: string
  longitude: number
  latitude: number
  addressDetail?: string
  formatted_address?: string
  comments?: string
  id?: string
  city?: string
  label?: string
  country?: string
}
export interface DeliveryCustomerType {
  name: string
  cellPhone: string
}
export interface MarketRatesType {
  providerId: string
  providerName: string
  providerImg: string
  collectionDate?: string
  collectionTime?: string
  deliveryDate?: string
  deliveryTime?: string
  deliveryPrice?: number
  eta: number
}

export interface DeliveryStateType {
  currentStep: number
  currentDeliveryId: string
  isNewDelivery: boolean
  paymentMethod: PaymentMethodType
  origin: DeliveryLocationType
  destination: DeliveryLocationType
  customer: DeliveryCustomerType
  selectedMarketRates: MarketRatesType
  storeId: string
  userId: string
  authToken: string
  userHasAccountData: boolean
  depositTypeId: number
  providerId: string
  storeInfo: Partial<IStore>
}

export interface DeliveryContextType {
  state: DeliveryStateType
  setDeliveryState: (newState: DeliveryStateType) => void
}
// Discounts Interfaces / Types
export type ProductItem = {
  id: string
  sku: string
  name: string
  stock: number
  image_url: string
}

export type ProductDiscountItem = {
  id: string
  endDate?: string
  startDate?: string
  finalPrice: number
  product: ProductItem
  originalPrice: number
}

export interface DiscountResponse {
  id: string
  name: string
  status: number
  description: string
  endDate: string
  startDate: string
  createdAt: string
  createdBy: string
  productsDiscounts: ProductDiscountItem[]
}

export type DiscountProduct = Omit<DiscountResponse, 'productsDiscounts'> & {
  finalPrice: number
  originalPrice: number
}

export type GetProductsListRequest = {
  page?: number
  size?: number
  storeId: string
  search?: string
  category?: string
}
export interface OrderConfirmedResponse {
  id: string
  uid: string | null
  date: string
  description: string
  value: number
  lastReminder: string | null
  paymentsData: number | null
  isOffline: number
  transactionCount: string
  orderAddress: string
  deliveryPrice: number
  createdAt: string
  updatedAt: string
  deletedAt: string | null
  userId: string
  storeId: string
  paymentTypeId: number
  transactionStatusId: number
  transactionTypeId: number
  contactId: string
  originId: number
  deliveryTypeId: number
  treintaFeesPayment: number | null
}

export interface ProductsOrderResponse {
  transaction: any
  transactionDetails: any
}

export interface createOrderPayloadRequest {
  createNewAccount?: boolean
  contact: {
    name: string
    phone: string
    country_id: number
    document?: string
    document_type_id?: number
    customer_type_id?: number
    origin_id?: number
    location?: {
      country_id: number
      city_id: string
      address: string
      address_comments: string
    }
  }
  store: {
    id: string
  }
  transaction: {
    order_address: {
      address: string
    }
    value: number
    description: string
    delivery_type: string
    origin_type: string
    payment_type_id: number
  }
  transaction_details?: [
    {
      sale_price: number
      product_id: string
      quantity: number
    }
  ]
}
