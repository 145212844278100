import styled from 'styled-components'
import { FormControl, FormControlLabel } from '@material-ui/core'
import { Typography } from '../../../../components/common/Typography'

export const Title = styled(Typography)`
  margin-bottom: 1rem;
  color: ${({ theme }) => theme.colors.secondary700};
`
export const FormContainer = styled(FormControl)`
  display: flex;
  width: 100%;
  margin-bottom: 1rem;
  flex-direction: column;
`
export const PhoneContainer = styled.div`
  width: 100%;
`
export const InputContainer = styled.form`
  width: 100%;
  display: flex;
  margin-bottom: 8;
  align-items: center;
  flex-direction: column;
`
export const CheckBoxWrapper = styled(FormControlLabel)`
  margin-bottom: 30px;
  color: ${({ theme }) => theme.colors.neutral800};
`
export const ErrorMessage = styled(Typography)`
  margin-top: 0.25rem;
  color: ${({ theme }) => theme.colors.error500};
`
export const NameInputContainer = styled.div`
  width: 100%;
  margin-bottom: 1rem;
`
