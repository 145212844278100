interface ShareParams {
  text: string
  phone: string
}
export const shareMessageWhatsApp = (sharedParams: ShareParams): void => {
  const urlWhatsApp = `https://api.whatsapp.com/send?text=${encodeURI(
    String(sharedParams.text).replace(/[#%+^&[\]\\]/g, '')
  )}&phone=${sharedParams.phone}`
  window.open(urlWhatsApp)
}
