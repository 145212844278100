import styled from 'styled-components'
import { ReactComponent as AscSortIconBase } from '../../assets/sort-asc.svg'
import { ReactComponent as DescSortIconBase } from '../../assets/sort-desc.svg'
import { Typography } from '../common/Typography'
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown'

export const MainButton = styled.button`
  align-items: center;
  background-color: ${({ theme: { colors } }) => colors.white};
  border-radius: ${({ theme: { sizes } }) => sizes.borderRadius.medium};
  border: ${({ theme: { colors } }) => `1px solid ${colors.neutral600}`};
  cursor: pointer;
  display: flex;
  height: 100%;
  justify-content: space-between;
  padding-bottom: ${({ theme: { sizes } }) => sizes.spacing.xsmall};
  padding-left: ${({ theme: { sizes } }) => sizes.spacing.small};
  padding-right: ${({ theme: { sizes } }) => sizes.spacing.small};
  padding-top: ${({ theme: { sizes } }) => sizes.spacing.xsmall};
`

export const SortAscIcon = styled(AscSortIconBase)``

export const SortDescIcon = styled(DescSortIconBase)``

export const Label = styled(Typography).attrs({
  variant: 'Small',
})`
  margin-left: ${({ theme: { sizes } }) => sizes.spacing.small};
  margin-right: ${({ theme: { sizes } }) => sizes.spacing.small};
  color: ${({ theme: { colors } }) => colors.secondary500};
`

export const ArrowHeadIcon = styled(KeyboardArrowDown).attrs({
  style: {
    height: 18,
    width: 18,
  },
})`
  fill: ${({ theme: { colors } }) => colors.secondary700}!important;
`

export const BackToDefaultLabel = styled(Typography).attrs({
  variant: 'Smallbold',
})`
  cursor: pointer;
  margin-bottom: ${({ theme: { sizes } }) => sizes.spacing.small};
  text-align: center;
  text-decoration: underline;
  width: 100%;
`
