import { useTranslation } from 'react-i18next'

import {
  MainContainer,
  HeaderContainer,
  TitleContainer,
  SectionMyAccount,
  TextTitleSection,
  TextHeader,
  TextStatus,
  TextContact,
  ConfirmSection,
  ConfirmSectionWB,
  CancelSection,
  ImageSection,
  ImageSectionContact,
  DataSectionContact,
  DataSectionHeader,
  ProviderImage,
  WhatsAppIcon,
  ArrowGreenIcon,
  PhoneIcon,
  DateIcon,
  ListIcon,
  PaymentIcon,
  WhatsAppLink,
  CancelButton,
  DollarIcon,
  DeliveryStatus,
  ContactInfo,
} from './styles'
import ResumeItem from './ResumeItem'
import Loading from '../../common/Loading'
import { countries } from '../../../config/countries'
import { ConfirmShipmentResumeProps } from '../contracts'
import { formatHoursRangeByTimezone } from '../utils/date'
import { formatterCurrency } from '../../../utils/functions'
import { defineDeliveryStatusName } from '../utils/transform'
import { ToastMessage } from '../../common/ToastMessage/ToastMessage'
import { PaymentMethodOptions, DepositOptions } from '../../../types/models'

const ConfirmShipmentResume = ({
  resumeShipmentData,
  isLoadedResumeShipment,
  handleClickMyAccount,
  handleClickShareLink,
  handleClickCancelShipping,
  handleClickCallDeliveryMan,
}: ConfirmShipmentResumeProps): JSX.Element => {
  const { t } = useTranslation()
  const currentCountry = countries.get('Colombia')

  const formatResumeValues = (actualValue: number): string => {
    let formattedValue = '$ 0'
    if (actualValue > 0) {
      formattedValue = String(
        formatterCurrency(currentCountry, Number(actualValue))
      )
    }
    return formattedValue
  }

  const defineDepositTypeDescription = (): string => {
    let typeDescription = t('delivery.toasts.toastWithoutAccountDataResume')
    switch (Number(resumeShipmentData?.dispersionTypeId)) {
      case DepositOptions.Standard:
        typeDescription = `${t(
          'delivery.providers.keepInMind.consignmentTypeStandard'
        )}
        ${t('delivery.providers.keepInMind.consignmentTypeStandardText')}`
        break
      case DepositOptions.Express:
        typeDescription = `${t(
          'delivery.providers.keepInMind.consignmentTypeExpress'
        )}
        * ${t('delivery.providers.keepInMind.consignmentTypeExpressText')}`
        break
      default:
        break
    }
    return typeDescription
  }
  const startObjectTime = formatHoursRangeByTimezone(
    String(resumeShipmentData?.etas?.startDate),
    String(resumeShipmentData?.etas?.startTime)
  )

  const endObjectTime = formatHoursRangeByTimezone(
    String(resumeShipmentData?.etas?.endDate),
    String(resumeShipmentData?.etas?.endTime)
  )

  const definePaymentMethodName = (): string => {
    return resumeShipmentData?.deliveryMethodId ===
      PaymentMethodOptions.UponDelivery
      ? t('delivery.sectionForm.shippingFormPaymentMethodTypeAD')
      : t('delivery.sectionForm.shippingFormPaymentMethodTypeCash')
  }

  const defineDealerValue = (): number => {
    return resumeShipmentData && resumeShipmentData?.costs?.saleValue > 0
      ? Number(resumeShipmentData?.costs?.saleValue) +
          Number(resumeShipmentData?.costs?.deliveryValue)
      : Number(resumeShipmentData?.costs?.deliveryValue)
  }

  return (
    <>
      {isLoadedResumeShipment ? (
        <MainContainer>
          <HeaderContainer>
            <ImageSection>
              <ProviderImage src={resumeShipmentData?.provider?.imageUrl} />
            </ImageSection>
            <DataSectionHeader>
              <TextHeader>
                {t('delivery.confirm.title', { code: resumeShipmentData?.id })}
              </TextHeader>
              <SectionMyAccount onClick={handleClickMyAccount}>
                {t('delivery.confirm.myShippings')}
                <ArrowGreenIcon />
              </SectionMyAccount>
            </DataSectionHeader>
          </HeaderContainer>
          {/* show Toast By Deposit Type */}
          {resumeShipmentData?.deliveryMethodId ===
            PaymentMethodOptions.UponDelivery &&
            Number(resumeShipmentData?.dispersionTypeId) > 0 && (
              <ToastMessage textItem={defineDepositTypeDescription()} />
            )}
          <ConfirmSection>
            <TextTitleSection>
              {t('delivery.confirm.pickupTitle')}
            </TextTitleSection>

            <ResumeItem
              labelItem={t('delivery.confirm.dateCollectionLabel')}
              iconItem={<DateIcon />}
              valueItem={startObjectTime?.newDate}
            />
            <ResumeItem
              labelItem={t('delivery.confirm.timeCollectionLabel')}
              iconItem={<DateIcon />}
              valueItem={startObjectTime?.newRange}
            />
          </ConfirmSection>

          <ConfirmSection>
            <TextTitleSection>
              {t('delivery.confirm.resumeTitle')}
            </TextTitleSection>
            <ResumeItem
              labelItem={t('delivery.confirm.timeDeliveryLabel')}
              iconItem={<DateIcon />}
              valueItem={endObjectTime?.newRange}
            />
            <ResumeItem
              labelItem={t('delivery.confirm.shippingGuideLabel')}
              iconItem={<ListIcon />}
              valueItem={String(resumeShipmentData?.id)}
            />
            <ResumeItem
              labelItem={t('delivery.confirm.paymentLabel')}
              iconItem={<PaymentIcon />}
              valueItem={definePaymentMethodName()}
            />
            {resumeShipmentData?.deliveryMethodId ===
              PaymentMethodOptions.UponDelivery && (
              <ResumeItem
                labelItem={t('delivery.confirm.dateEstimatedDeposit')}
                iconItem={<ListIcon />}
                valueItem={String(resumeShipmentData?.etas.dispersion)}
              />
            )}
          </ConfirmSection>

          <ConfirmSectionWB>
            <TextTitleSection>
              {t('delivery.confirm.costTitle')}
            </TextTitleSection>
            {resumeShipmentData?.deliveryMethodId ===
              PaymentMethodOptions.UponDelivery && (
              <ResumeItem
                labelItem={t('delivery.confirm.saleValuelabel')}
                iconItem={<DollarIcon />}
                valueItem={formatResumeValues(
                  Number(resumeShipmentData?.costs?.saleValue)
                )}
              />
            )}
            <ResumeItem
              labelItem={t('delivery.confirm.deliveryValueLabel')}
              iconItem={<DollarIcon />}
              valueItem={formatResumeValues(
                Number(resumeShipmentData?.costs?.deliveryValue)
              )}
            />
            <ResumeItem
              labelItem={t('delivery.confirm.dealerPaymentLabel')}
              iconItem={<DollarIcon />}
              valueItem={formatResumeValues(defineDealerValue())}
              isHighlighted={true}
            />
          </ConfirmSectionWB>

          <ConfirmSectionWB>
            <TitleContainer>
              <TextTitleSection>
                {t('delivery.confirm.statusTitle')}
              </TextTitleSection>
              <DeliveryStatus>
                {defineDeliveryStatusName(Number(resumeShipmentData?.statusId))}
              </DeliveryStatus>
            </TitleContainer>
            <TextStatus>
              *{t('delivery.confirm.cancelDeliveryMessage')}
            </TextStatus>
          </ConfirmSectionWB>

          <ConfirmSectionWB>
            <WhatsAppLink onClick={() => handleClickShareLink()}>
              <WhatsAppIcon />
              {t('delivery.confirm.shareTrackingLink')}
            </WhatsAppLink>
          </ConfirmSectionWB>

          {resumeShipmentData?.courier?.name && (
            <ConfirmSectionWB>
              <TextTitleSection>
                {t('delivery.confirm.contactTitle')}
              </TextTitleSection>
              <ContactInfo>
                <ImageSectionContact>
                  <ProviderImage src={resumeShipmentData?.provider?.imageUrl} />
                </ImageSectionContact>
                <DataSectionContact
                  onClick={() =>
                    handleClickCallDeliveryMan(
                      resumeShipmentData?.courier?.phone
                    )
                  }
                >
                  <TextContact>
                    {resumeShipmentData?.courier?.name} <br />
                    {resumeShipmentData?.courier?.phone}
                  </TextContact>
                  <PhoneIcon />
                </DataSectionContact>
              </ContactInfo>
            </ConfirmSectionWB>
          )}

          {resumeShipmentData?.statusId !== 4 && (
            <CancelSection>
              <CancelButton onClick={() => handleClickCancelShipping()}>
                {t('delivery.confirm.cancelDelivery')}
              </CancelButton>
            </CancelSection>
          )}
        </MainContainer>
      ) : (
        <Loading />
      )}
    </>
  )
}
export default ConfirmShipmentResume
