import styled from 'styled-components'

export const Header = styled.header<{ isSticky: boolean }>`
  padding: ${({ theme }) => theme.sizes.spacing.medium};

  ${({ isSticky }) =>
    isSticky &&
    `
    position: sticky;
    top: 0px;
    z-index: 1;
    background: white;
  `}
`

export const NavBar = styled.nav<{ isSticky: boolean }>`
  align-items: center;
  height: 48px;
  ${({ isSticky }) =>
    isSticky
      ? `
      display: flex;
      `
      : `  
      display: grid;
      grid-template-columns:repeat(5, 1fr);
      grid-template-rows: repeat(1, 1fr);`}
`

export const ContainerMenu = styled.div<{ isSticky: boolean }>`
  ${({ isSticky }) =>
    isSticky
      ? `
      flex:0.10;
      `
      : `  
      grid-area: 1 / 1 / 2 / 2;`}
`

export const ContainerLogo = styled.div<{ isSticky: boolean }>`
  ${({ isSticky }) =>
    isSticky
      ? `
      flex:0.75;
      `
      : `  
      grid-area: 1 / 3 / 2 / 4;`}
  display: flex;
  justify-content: center;
`

export const ContainerShopingCartButton = styled.div<{ isSticky: boolean }>`
  ${({ isSticky }) =>
    isSticky
      ? `
      flex:0.15;
      `
      : `  
      grid-area: 1 / 5 / 2 / 6;`}
  display: flex;
  justify-content: flex-end;
`
