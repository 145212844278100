import styled from 'styled-components'

import { ButtonProps } from './types'
import { Typography } from '../../../../components/common/Typography'

export const ButtonPrimary = styled.button<ButtonProps>`
  opacity: 1;
  width: 100%;
  height: 40px;
  height: 100%;
  border: none;
  margin: 2px 0;
  margin: 8px 0;
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme: { colors } }) => colors.primary500};
  border-radius: ${({ theme: { sizes } }) => sizes.borderRadius.medium};
`
export const ButtonSecondary = styled.button<ButtonProps>`
  opacity: 1;
  width: 100%;
  height: 40px;
  height: 100%;
  margin: 2px 0;
  margin: 8px 0;
  display: flex;
  cursor: pointer;
  border: solid 1px;
  background: white;
  align-items: center;
  justify-content: center;
  border-radius: ${({ theme: { sizes } }) => sizes.borderRadius.medium};
`

export const Container = styled.div`
  bottom: 0px;
  width: 100%;
  position: absolute;
  background-color: white;
  box-shadow: 2px -4px 8px rgba(34, 38, 42, 0.05);
  padding: ${({ theme }) => theme.sizes.spacing.medium};
`
export const Message = styled(Typography).attrs({ variant: 'XSmall' })`
  text-align: center;
  color: ${({ theme }) => theme.colors.neutral700};
  margin-bottom: ${({ theme }) => theme.sizes.spacing.small};
`
export const BackButton = styled(ButtonSecondary)`
  min-height: 40px;
`
export const ButtonLabel = styled(Typography).attrs({ variant: 'Mediumbold' })``

export const ConfirmButton = styled(ButtonPrimary)`
  min-height: 40px;
`
