import axios from 'axios'
import firebase from '../../firebase'

declare global {
  interface Window {
    recaptcha: any
    recaptchaVerifier: any
    confirmationResult: any
  }
}

export const setupRecaptcha = (container: string): any =>
  (window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(container, {
    size: 'invisible',
  }))

export const signInWithPhone = (
  phoneNumber: string,
  recaptchaContainer: string,
  retry = false
): Promise<any> => {
  if (retry || !window.recaptcha) {
    setupRecaptcha(recaptchaContainer)
  }

  const appVerifier = window.recaptchaVerifier

  return firebase
    .auth()
    .signInWithPhoneNumber(phoneNumber, appVerifier)
    .then((confirmationResult: any) => {
      window.confirmationResult = confirmationResult
      return true
    })
    .catch((error) => {
      window.recaptchaVerifier.render().then(function (widgetId: any) {
        grecaptcha.reset(widgetId)
      })
      return error
    })
}

export const isValidSMSCode = (code: string): Promise<any> =>
  window.confirmationResult
    .confirm(code)
    .then(() => true)
    .catch(() => false)

export const validateUserWithPhone = async (
  phone: string
): Promise<boolean> => {
  try {
    const response = await axios({
      method: 'GET',
      headers: {
        authorization: `Bearer ${process.env.REACT_APP_TOKEN}`,
      },
      url: `${process.env.REACT_APP_TREINTA_API_URL}/shop/user-exist/${phone}`,
    })

    return response.data
  } catch (err) {
    console.log('Error bringing the user exists validation: ', err)
    return false
  }
}

export const createNewBuyerUser = async (
  name: string,
  uid: string,
  phone: string,
  countryId: number
): Promise<boolean> => {
  try {
    const { data } = await axios({
      data: { name, uid, phone, countryId },
      method: 'POST',
      headers: {
        authorization: `Bearer ${process.env.REACT_APP_TOKEN}`,
      },
      url: `${process.env.REACT_APP_TREINTA_API_URL}/user/buyer`,
    })

    return data.id ? true : false
  } catch (error) {
    return false
  }
}

export const getTokenFirebase = async (): Promise<string | null> => {
  const response = await firebase
    ?.auth()
    ?.currentUser?.getIdToken(/* forceRefresh */ true)

  return response ?? null
}

export const getUserPhone = async (): Promise<string | null | undefined> => {
  const phone = await firebase?.auth()?.currentUser?.phoneNumber
  return phone
}
