import { Address } from './components/Address'
import { Scheduler } from './components/Scheduler'
import { Container, StoreName } from './styles'

interface Props {
  store: any
}

export const Storeinfo = ({ store }: Props): JSX.Element => {
  const { name, address, config, city } = store
  const completeAddress = city ? `${address}, ${city}` : address

  return (
    <Container >
      <StoreName>{name}</StoreName>
      <Scheduler
        storeSchedule={config?.storeSchedule}
        isOpenAllDay={config?.isOpenAllDay}
      />
      <Address address={completeAddress} />
    </Container>
  )
}
