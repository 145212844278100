import {
  ICountry,
  ServiceDetailOrder,
  OrderConfirmedResponse,
} from '../../../types/models'
import { formatterCurrency } from '../../../utils/functions'
import { DeliveryMethod } from '../../../config/delivery-method'
import useGAEventTracker from '../../../hooks/useGAEventTracker'
import { RedirectToWhatsAppAPI } from '../../../components-v0/CheckoutStepper/components/PaymentMethodButton/types'

interface GetOrderDetails extends ServiceDetailOrder {
  translations: any
  country: ICountry
  order: OrderConfirmedResponse
}

export const getOrderDetails = ({
  order,
  country,
  transaction,
  translations,
  transactionDetails,
}: GetOrderDetails): string => {
  const itemsOrder = () => {
    let items = ''
    for (const item of transactionDetails) {
      items += `\n${item.name}\t\tX${item.quantity}`
    }
    return items
  }

  const getTotalProducts = () => {
    const total = transactionDetails.reduce(
      (prev, current) => prev + current.sale_price,
      0
    )
    return total || 0
  }

  const {
    welcome,
    contactInfo,
    contactAddress,
    contactOrderItems,
    contactOrderPrice,
    contactOrderNumber,
    contactTotalPayable,
    contactDeliveryType,
    contactDeliveryPrice,
    contactDeliveryTypeOptions,
  } = translations

  const address = JSON.parse(order?.orderAddress)?.address
  const messageObject = {
    welcome: welcome,
    orderNumber: contactOrderNumber.replace(
      '_orderNumber_',
      transaction.transaction_count
    ),
    contactInfo: contactInfo,
    contactAddress:
      address.length > 0 ? contactAddress.replace('_address_', address) : '',
    textPaymentMethod: `\n${translations.paymentMethodOnlinePayment}`,
    contactDeliveryType: contactDeliveryType,
    contactDeliveryTypeValue:
      transaction.delivery_type_id === DeliveryMethod.HOME_DELIVERY
        ? contactDeliveryTypeOptions.delivery
        : contactDeliveryTypeOptions.pickUp,
    contactOrderItems: contactOrderItems.replace('_items_', itemsOrder()),
    totalProducts: contactOrderPrice.replace(
      '_products_',
      formatterCurrency(country, getTotalProducts())
    ),
    deliveryPrice: order?.deliveryPrice
      ? contactDeliveryPrice.replace(
          '_price_',
          formatterCurrency(country, order.deliveryPrice)
        )
      : '',
    totalPayable: contactTotalPayable.replace(
      '_total_',
      formatterCurrency(
        country,
        Number(getTotalProducts() + (order?.deliveryPrice || 0))
      )
    ),
  }

  return Object.values(messageObject).join(' ')
}

export const redirectToWhatsAppAPI = ({
  storeId,
  phoneNumber,
  windowReference,
  isFromMarketplace,
  messageOrderDetails,
}: RedirectToWhatsAppAPI): void => {
  const GAEventsTracker = useGAEventTracker('webshop')
  windowReference?.location.assign(
    `https://api.whatsapp.com/send?phone=${phoneNumber}&text=${encodeURI(
      messageOrderDetails
    )}`
  )

  GAEventsTracker('web_shop_order_confirmed_send_order', storeId)
  if (isFromMarketplace) {
    GAEventsTracker('marketplace_a_shop_order_send', `${storeId}_marketplace`)
  }
}
