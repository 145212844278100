import { useState, useEffect } from 'react'

import { getStoreInfo } from '../../services/store'
import { DeliveryStateType } from '../../types/models'

interface UseGetStoreInfoServiceRequest {
  state: DeliveryStateType
  setDeliveryState: (deliveryData: DeliveryStateType) => void
}

interface UseGetStoreInfoServiceResponse {
  isStoreLoaded: boolean
}

export const useStoreInitConfig = ({
  state,
  setDeliveryState,
}: UseGetStoreInfoServiceRequest): UseGetStoreInfoServiceResponse => {
  const { authToken } = state
  const urlName = window.location.pathname.split('/')[1]
  const [isStoreLoaded, setIsStoreLoaded] = useState(false)

  useEffect(() => {
    if (authToken && urlName && !state.storeInfo?.id) callService()
  }, [authToken])

  const callService = () => {
    getStoreInfo(urlName, authToken)
      .then((storeInfoResponse) => {
        setDeliveryState({
          ...state,
          storeInfo: storeInfoResponse,
        })
      })
      .finally(() => setIsStoreLoaded(true))
  }
  return { isStoreLoaded }
}
