export const colors = {
  // PRIMARY
  primary100: '#FFF6D6',
  primary200: '#FFEEB3',
  primary300: '#FEE280',
  primary400: '#FED74D',
  primary500: '#FECB18',
  primary600: '#E5B201',
  primary700: '#BC9201',
  primary800: '#705700',
  primary900: '#3D2F00',
  primaryGradient: 'linear-gradient(109.67deg, #FECB18 2.09%, #E5B201 97.19%)',
  // SECONDARY
  secondary100: '#ECF3F9',
  secondary200: '#A9C0D6',
  secondary300: '#6A8EAF',
  secondary400: '#42688A',
  secondary500: '#2F4559',
  secondary600: '#233443',
  secondary700: '#1A2732',
  secondaryGradient:
    'linear-gradient(109.67deg, #42688A 2.09%, #2F4559 97.19%)',
  // TERTIARY
  tertiary100: '#D7F4E3',
  tertiary200: '#A4E5BF',
  tertiary300: '#70DB9D',
  tertiary400: '#33CC73',
  tertiary500: '#0AC257',
  tertiary600: '#009940',
  tertiary700: '#00662B',
  tertiary800: '#004D20',
  tertiary900: '#003315',
  tertiaryGradient: 'linear-gradient(109.67deg, #14B858 2.09%, #009940 97.19%)',
  // NEUTRAL
  white: '#FFFFFF',
  neutral100: '#FBFBFB',
  neutral200: '#F4F5F6',
  neutral300: '#ECEDEF',
  neutral400: '#E0E3E5',
  neutral500: '#C7CCD1',
  neutral600: '#8F99A3',
  neutral700: '#67737E',
  neutral800: '#454D54',
  // SUCCESS
  success100: '#EBF9F1',
  success300: '#D7F4E3',
  success500: '#0AC257',
  success700: '#00662B',
  success900: '#003315',
  // WARNING
  warning100: '#FEF4E6',
  warning300: '#FEEACD',
  warning500: '#F2930D',
  warning700: '#A86200',
  warning900: '#4D2D00',
  // ERROR
  error100: '#FFF0F2',
  error300: '#FFE0E6',
  error500: '#DF2040',
  error700: '#971128',
  error900: '#37060E',
  // INFO
  info100: '#E7F0FE',
  info300: '#C0D7FC',
  info500: '#176BF3',
  info700: '#0946AA',
  info900: '#052861',
}

export type Colors = {
  [key in keyof typeof colors]: string
}

export type Color = keyof typeof colors
