import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import {
  Message,
  Container,
  BackButton,
  ButtonLabel,
  ConfirmButton,
} from './styles'
import { FooterProps } from './types'
import {
  getOrderDetails,
  redirectToWhatsAppAPI,
} from '../../service/whatsappMessage'
import useGAEventTracker from '../../../../hooks/useGAEventTracker'

export const Footer = ({
  name,
  order,
  country,
  storeId,
  phoneNumber,
  orderDetail,
}: FooterProps): JSX.Element => {
  const history = useHistory()
  const { t } = useTranslation()

  const back = () => {
    const GAEventsTracker = useGAEventTracker('webshop')
    GAEventsTracker('webshop_order_confirmed_backtocatalog', storeId)
    history.push(`/${name}`)
  }

  const sendWhatsappMessage = () => {
    if (order && orderDetail) {
      const windowReference = window.open()
      const messageOrder = getOrderDetails({
        order,
        country,
        ...orderDetail,
        translations: t('sectionForm.messageOrder', { returnObjects: true }),
      })

      const messageOrderDetails = messageOrder.replace(/[#%+^&[\]\\]/g, '')

      redirectToWhatsAppAPI({
        storeId,
        phoneNumber,
        windowReference,
        isFromMarketplace: true,
        messageOrderDetails,
      })

      history.push(`/${name}`)
    }
  }

  return (
    <Container>
      <Message>{t('orderConfirmed.footerMessage')}</Message>
      <BackButton onClick={back} data-testid="back-button">
        <ButtonLabel>
          {t('orderConfirmed.btnMenssage.btnStatusPurchaseBackCatalog')}
        </ButtonLabel>
      </BackButton>
      <ConfirmButton
        onClick={sendWhatsappMessage}
        data-testid="redirect-button"
      >
        <ButtonLabel>
          {t('orderConfirmed.btnMenssage.btnContactStore')}
        </ButtonLabel>
      </ConfirmButton>
    </Container>
  )
}
