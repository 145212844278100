import { FormEvent } from 'react'

import { DeliveryStateType } from '../../types/models'
import { menuType } from '../../components/common/MenuSelect/contracts'
import { BankListResponse } from '../../services/BankInformation/bankListService'
import { suggestType } from '../../components/common/InputAutoComplete/contracts'
import { ShipmentResumeResponse } from '../../services/Shipment/shipmentResumeService'
import {
  PaymentMethodsType,
  AccountTypes,
} from '../../services/BankInformation/paymentMethodsService'

/* general Type */
export type AddressType = 'origin' | 'destination'

export type InfoDeliveryProps = {
  state: DeliveryStateType
  setDeliveryState: (deliveryData: DeliveryStateType) => void
}

export interface UseRouteMacth {
  url: string
  params: { name: string }
}

export interface AccountLengthType {
  min: number
  max: number
}

export interface CustomerDataType {
  name: string
  cellPhone: string
}

export interface DefaultAddressDataType {
  address: string
  language: string
  addressDetail: string
  components: string
}

export interface DefaultPaymentDataType {
  method: number
  saleValue: number
}

export interface FormShippingProps {
  handleClickBack: () => void
  handleClickClose: () => void
  handleChangeAddress: (
    newValue: suggestType,
    addressType: 'origin' | 'destination'
  ) => void
  handleChangeAddressDetail: (
    newValue: string,
    addressType: 'origin' | 'destination'
  ) => void
  handleClickNext: () => void
  preventDefault: (event: FormEvent<HTMLFormElement>) => void
  findAddressSuggestions: (address: string, addressType: string) => void
  isValidForm: boolean
  canShowError: boolean
  openOrigin: boolean
  openDestin: boolean
  originData: suggestType
  destinationData: suggestType
  customerData: CustomerDataType
  defaultAddressData: DefaultAddressDataType
  originAddressParams: DefaultAddressDataType
  shippingDataError: ShippingDataErrorType
  setOriginAddressParams: (object: DefaultAddressDataType) => void
  setShippingDataError: (object: ShippingDataErrorType) => void
  setCanShowError: (canShow: boolean) => void
  setCustomerData: (object: CustomerDataType) => void
  setOpenOrigin: (isOpen: boolean) => void
  setOpenDestin: (isOpen: boolean) => void
  setDestinationAddressParams: (object: DefaultAddressDataType) => void
  originAddressSuggest: suggestType[]
  destinationAddressSuggest: suggestType[]
  destinationAddressParams: DefaultAddressDataType
}
export interface FormShippingSecondProps {
  handleClickBack: () => void
  handleClickClose: () => void
  handleClickNext: () => void
  handleDrawerStatus: (newStatus: boolean) => void
  handleBlurOnInputChange: () => void
  handleChangeOnInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  preventDefault: (event: FormEvent<HTMLFormElement>) => void
  checkHandleChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  validateShippingForm: () => boolean
  selectHandleChange: (event: React.ChangeEvent<{ value: unknown }>) => void
  defaultShippingDataError: ShippingDataErrorType
  paymentData: DefaultPaymentDataType
  PaymentMethodOptions: any
  formattedValue: string
  drawerIsOpen: boolean
}

export interface BankInformationHolderProps {
  documentTypesData: menuType[]
  bankInformationData: BankInformationType
  bankInformationDataError: BankInformationErrorType
  defineContentSelectValue: (
    placeholder: string,
    actualValue: string,
    selectObject: any
  ) => string
  setBankInformationData: (object: any) => void
  validateHolderName: (value: string | undefined) => void
  validateHolderEmail: (value: string | undefined) => void
  validateDocumentType: (value: number | undefined) => void
  validateDocumentNumber: (value: string | undefined) => void
}

export interface BankInformationPaymentProps {
  paymentMethodType: string
  isValidForm: boolean
  accountTypes: AccountTypes[]
  bankList: BankListResponse[] | undefined
  paymentMethodsData: PaymentMethodsType[] | undefined
  bankInformationData: BankInformationType
  bankInformationDataError: BankInformationErrorType
  defineContentSelectValue: (
    placeholder: string,
    actualValue: string,
    selectObject: any
  ) => string
  handleClickNext: () => void
  validateAccountType: (value: number | undefined) => void
  validatePaymentMethod: (value: number | undefined) => void
  validateBank: (value: number | undefined) => void
  validateAccountNumber: (value: string | undefined) => void
  validateAccountNumberConfirm: (value: string | undefined) => void
  setBankInformationData: (object: any) => void
}

export interface BankInformationAlertsProps {
  state: DeliveryStateType
  setDeliveryState: (deliveryData: DeliveryStateType) => void
  setAlertInfoBankIsOpen: (isOpen: boolean) => void
  handleClickExitEditForm: () => void
  handleClickContinueEditForm: () => void
  alertInfoBankIsOpen: boolean
  openAlertWsaveDrawer: boolean
}

export interface BankInformationProps
  extends BankInformationHolderProps,
    BankInformationPaymentProps,
    BankInformationAlertsProps {
  handleClickBack: () => void
  handleClickClose: () => void
}

/* confirm Shipment Types */
export type ConfirmShipmentResumeProps = {
  isLoadedResumeShipment: boolean
  resumeShipmentData: ShipmentResumeResponse | undefined
  handleClickShareLink: () => void
  handleClickCancelShipping: () => void
  handleClickMyAccount: () => void
  handleClickCallDeliveryMan: (phoneNumber: string) => void
}
export type ConfirmShipmentAlertsProps = {
  state: DeliveryStateType
  alertShipmentIsOpen: boolean
  alertCancelShipmentIsOpen: boolean
  openAlertCancelDrawer: boolean
  setDeliveryState: (deliveryData: DeliveryStateType) => void
  setAlertCancelShipmentIsOpen: (isOpen: boolean) => void
  setAlertShipmentIsOpen: (isOpen: boolean) => void
  handleClickPreventCancelShipment: () => void
  handleClickConfirmCancelShipment: () => void
}

/* default Object Values */
export const defaultPaymentData: DefaultPaymentDataType = {
  method: 0,
  saleValue: 0,
}
export const defaultOriginData = {
  id: '0',
  country_id: '',
  country: '',
  address: '',
  addressDetail: '',
  label: '',
  city_id: '',
  formatted_address: '',
  city: '',
  latitude: 0,
  longitude: 0,
}
export const defaultDestinationData = {
  id: '0',
  country_id: '',
  country: '',
  address: '',
  addressDetail: '',
  city_id: '',
  formatted_address: '',
  city: '',
  label: '',
  latitude: 0,
  longitude: 0,
}
export const defaultAddressData: DefaultAddressDataType = {
  address: '',
  addressDetail: '',
  language: 'es',
  components: 'country:CO',
}
export const defaultCityData = {
  country_id: '',
  city_id: '',
  formatted_address: '',
}
export const defaultCustomerData = {
  name: '',
  cellPhone: '',
}
export const defaultMarketData = {
  providerName: '',
  providerId: '0',
  providerImg: '',
  collectionDate: '',
  collectionTime: '',
  deliveryDate: '',
  deliveryTime: '',
  deliveryPrice: 0,
  eta: 0,
}

export const defaultStoreData = {
  address: '',
  city: '',
  config: undefined,
  country: '',
  email: '',
  id: '',
  image_url: null,
  name: '',
  phone: '',
}

export const initialState: DeliveryStateType = {
  currentStep: 1,
  currentDeliveryId: '',
  isNewDelivery: false,
  userHasAccountData: true,
  paymentMethod: defaultPaymentData,
  origin: defaultOriginData,
  destination: defaultDestinationData,
  customer: defaultCustomerData,
  selectedMarketRates: defaultMarketData,
  storeInfo: defaultStoreData,
  depositTypeId: 0,
  storeId:
    process.env.NODE_ENV === 'development'
      ? String(process.env.REACT_APP_INTERNAL_STORE_DEV)
      : '',
  userId: process.env.NODE_ENV === 'development' ? 'ABCD1234' : '',
  authToken:
    process.env.NODE_ENV === 'development'
      ? String(process.env.REACT_APP_INTERNAL_TOKEN_DEV)
      : '',
  providerId: '',
}

export type ChangeParamsType = {
  event: React.ChangeEvent<{ value: string }>
  isNumber: boolean
  fieldName: string
  validationMethod: (value: any) => void
  bankInformationData: BankInformationType
  setBankInformationData: (bankInformationData: BankInformationType) => void
}

export type ShippingDataErrorType = {
  cityError: boolean
  nameError: boolean
  phoneError: boolean
  originAddresError: boolean
  originAddressDetailError: boolean
  destinationAddressError: boolean
  destinationAddressDetailError: boolean
  paymentMethodError: boolean
  declareError: boolean
  saleValueError: boolean
}

export const defaultShippingDataError: ShippingDataErrorType = {
  cityError: false,
  nameError: false,
  phoneError: false,
  originAddresError: false,
  originAddressDetailError: false,
  destinationAddressError: false,
  destinationAddressDetailError: false,
  paymentMethodError: false,
  declareError: false,
  saleValueError: false,
}

export type BankInformationType = {
  holderName: string
  holderEmail: string
  accountNumber: string
  holderDocumentNumber: string
  accountNumberConfirm: string
  holderDocumentType: string
  paymentMethod: number
  accountType: number
  bankId: number
}

export type BankInformationErrorType = {
  holderNameError: boolean
  holderDocumentTypeError: boolean
  holderDocumentNumberError: boolean
  holderEmailError: boolean
  paymentMethodError: boolean
  accountTypeError: boolean
  bankError: boolean
  accountNumberError: boolean
  accountNumberConfirmError: boolean
}

export type dateRangeObjectType = {
  newDate: string
  newRange: string
}

export const defaultBankInformationData: BankInformationType = {
  holderName: '',
  holderDocumentType: '',
  holderDocumentNumber: '',
  holderEmail: '',
  accountNumber: '',
  accountNumberConfirm: '',
  bankId: 0,
  paymentMethod: 0,
  accountType: 0,
}
export const defaultBankDataError: BankInformationErrorType = {
  holderNameError: false,
  holderDocumentTypeError: false,
  holderDocumentNumberError: false,
  holderEmailError: false,
  paymentMethodError: false,
  accountTypeError: false,
  bankError: false,
  accountNumberError: false,
  accountNumberConfirmError: false,
}
