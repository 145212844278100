import styled from 'styled-components'
import { Typography } from '../common/Typography'

interface WithActiveProps {
  active: boolean
}

export const MainContainer = styled.button<WithActiveProps>`
  align-items: center;
  background-color: ${({ active, theme: { colors } }) => {
    if (!active) {
      return colors.white
    }

    return colors.primary500
  }};
  border-radius: ${({ theme: { sizes } }) => sizes.borderRadius.medium};
  border: ${({ active, theme: { colors } }) => {
    if (active) {
      return 'none'
    }

    return `1px solid ${colors.neutral600}`
  }};
  cursor: pointer;
  display: flex;
  padding-bottom: ${({ theme: { sizes } }) => sizes.spacing.xsmall};
  padding-left: ${({ theme: { sizes } }) => sizes.spacing.small};
  padding-right: ${({ theme: { sizes } }) => sizes.spacing.small};
  padding-top: ${({ theme: { sizes } }) => sizes.spacing.xsmall};
  white-space: nowrap;
`

export const Label = styled(Typography).attrs({
  variant: 'XSmallbold',
})<WithActiveProps>`
  color: ${({ active, theme: { colors } }) =>
    active ? colors.primary900 : colors.neutral600};
`
