import styled from 'styled-components'
import { textFieldType } from './contracts'
import { Typography } from '../common/Typography'
import { MenuItem, TextField } from '@material-ui/core'

export const MainContainer = styled.div`
  display: flex;
  margin-bottom: 0.5rem;
  flex-direction: column;
`
export const SelectContainer = styled.div`
  height: 40px;
  display: flex;
  font-size: 1rem;
  background: ${({ theme }) => theme.colors.white};
`
export const ErrorMessage = styled(Typography)`
  margin-top: 0.25rem;
  color: ${({ theme }) => theme.colors.error500};
`
export const SelectWrapper = styled(TextField)<textFieldType>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 0px 0px 16px;
  font-family: Nunito Sans;
  & .MuiSelect-selectMenu {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  & .MuiInput-underline:before {
    content: none;
    transition: none;
    border-bottom: none;
  }
  & .MuiInput-underline:after {
    border-bottom: none;
    transition: none;
  }
  & .MuiSvgIcon-root {
    padding-right: 8px;
  }
  & .MuiSelect-select {
    height: 22px;
    border-radius: 4px 0px 0px 4px;
    padding: 0.5rem 2rem 0.5rem 1rem;
    ${({ error, theme }) =>
      error
        ? `border: 0.5px solid ${theme.colors.error500};`
        : `border: 0.5px solid ${theme.colors.neutral600};`}
  }
`

export const SelectItemContainer = styled(MenuItem)`
  justify-content: start;
  font-family: Nunito Sans;
`
export const SelectItemWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`
export const FlagWrapper = styled.img`
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  margin-right: 0.25rem;
`

export const CodeWrapper = styled.p`
  font-size: 1rem;
  margin: 0px 0.25rem 0px 0px;
`
