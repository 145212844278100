import { format, addHours, differenceInCalendarDays } from 'date-fns'

const daysFormatDate = 'yyyy-MM-dd'

export const compareDateGreaterCurrentDay = (itemDate: string): boolean =>
  calculateDaysBetweenDates(itemDate) >= 0

export const compareDateLessCurrentDay = (itemDate: string): boolean =>
  calculateDaysBetweenDates(itemDate) <= 0

export const compareRangeWithCurrentDay = (
  startDate: string,
  endDate?: string
): boolean => {
  const validateStart = startDate ? compareDateLessCurrentDay(startDate) : false
  const validateEnd =
    endDate !== null
      ? compareDateGreaterCurrentDay(endDate || String(new Date()))
      : true
  return validateStart && validateEnd
}

export const calculateDaysBetweenDates = (itemDate: string): number => {
  let numberDays = 0
  if (itemDate !== 'undefined') {
    const formatDate = format(new Date(itemDate), daysFormatDate)
    const todayDate = format(new Date(), daysFormatDate)
    numberDays = differenceInCalendarDays(
      new Date(formatDate),
      new Date(todayDate)
    )
  }
  return numberDays
}

export const formatItemDate = (itemDate: string, newFormat: string): string => {
  return itemDate ? format(new Date(itemDate), newFormat, {}).toUpperCase() : ''
}

export const parseStringToDate = (itemDate: string): Date => {
  return itemDate ? new Date(itemDate) : new Date()
}

export const incrementDateHour = (
  baseDate: Date,
  numHours: number,
  newFormat = 'yyyy-MM-dd HH:mm'
): string => {
  const newDate = addHours(baseDate, numHours)
  return format(newDate, newFormat)
}
