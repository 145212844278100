import Paper from '@material-ui/core/Paper'
import TextField from '@material-ui/core/TextField'
import { makeStyles } from '@material-ui/core/styles'
import Autocomplete from '@material-ui/lab/Autocomplete'
import InputAdornment from '@material-ui/core/InputAdornment'

import { autoCompleteProps, suggestType } from './contracts'
import {
  InputIcon,
  ErrorMessage,
  LabelInputForm,
  InputContainer,
} from './styles'

import mainTheme from '../../../styles/theme'

const textStyles = makeStyles({
  inputRoot: {
    color: mainTheme.colors.secondary700,
  },
  formControlRoot: {
    minHeight: '22px',
    position: 'relative',
    color: mainTheme.colors.neutral700,
    borderRadius: mainTheme.sizes.borderRadius.medium,
    border: `0.5px solid ${mainTheme.colors.neutral600}`,
    padding: `${mainTheme.sizes.spacing.xxsmall} ${mainTheme.sizes.spacing.medium}`,
    '& label.Mui-focused': {
      color: mainTheme.colors.neutral700,
    },
    '&:hover': {
      borderRadius: `${mainTheme.sizes.borderRadius.medium} 
        ${mainTheme.sizes.borderRadius.medium} 0 0`,
      border: '1px solid #1A2732',
    },
    '& .MuiAutocomplete-inputRoot': {
      paddingRight: mainTheme.sizes.spacing.xxxsmall,
    },
  },
  paper: ({ existItems }: { existItems: boolean }) => ({
    width: '380px',
    borderRadius: '0',
    position: 'absolute',
    margin: '5px 0 0 -16px',
    display: 'inline-block',
    border: existItems ? `1px solid ${mainTheme.colors.secondary700}` : '',
    boxShadow:
      '2px 4px 8px rgba(34, 38, 42, 0.03), 4px 8px 16px rgba(34, 38, 42, 0.04)',
  }),
})

const CustomPaper = (props: any) => {
  return <Paper elevation={1} {...props} />
}

const InputAutoComplete = ({
  id,
  open,
  value,
  onOpen,
  onClose,
  onChange,
  onKeyDown,
  showIcon,
  showLabel,
  showError,
  labelText,
  errorText,
  dataTestId,
  inputValue,
  defaultValue,
  suggestValues,
  onInputChange,
  placeHolderText,
}: autoCompleteProps): JSX.Element => {
  const textClasses = textStyles({ existItems: suggestValues.length > 0 })
  return (
    <InputContainer>
      {showLabel && <LabelInputForm htmlFor={id}>{labelText}</LabelInputForm>}
      <Autocomplete
        id={id}
        freeSolo
        open={open}
        autoComplete
        value={value}
        onOpen={onOpen}
        onClose={onClose}
        onKeyDown={onKeyDown}
        data-testid={dataTestId}
        includeInputInList
        onChange={onChange}
        classes={textClasses}
        options={suggestValues}
        inputValue={inputValue}
        filterOptions={(x) => x}
        defaultValue={defaultValue}
        PaperComponent={CustomPaper}
        onInputChange={onInputChange}
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        getOptionLabel={(option: suggestType) => option.label!}
        renderInput={(params: any) => {
          return (
            <TextField
              {...params}
              label=""
              variant="standard"
              placeholder={placeHolderText}
              classes={{ root: textClasses.formControlRoot }}
              InputProps={{
                ...params.InputProps,
                type: 'search',
                name: 'daddress-input',
                disableUnderline: true,
                endAdornment: false,
                startAdornment: showIcon ? (
                  <InputAdornment position="start">
                    <InputIcon />
                  </InputAdornment>
                ) : (
                  ''
                ),
              }}
            />
          )
        }}
      />
      {showError && <ErrorMessage variant="XSmall">{errorText}</ErrorMessage>}
    </InputContainer>
  )
}
export default InputAutoComplete
