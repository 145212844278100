export interface OrderFromBackend {
  id: string
  store_image: string | null
  store_name: string
  total: number
  created_at: string | null
  delivery_price: number | null
  transaction_status_id: OrderStatus
}

export interface Order {
  id: string
  storeImage: string | null
  storeName: string
  total: number
  createdAt: Date
  deliveryPrice: number | null
  transactionStatusId: OrderStatus
}

export interface OrdersState {
  loading: boolean
  nextPage: number | null
  orders: Order[]
}
export interface OrdersContext {
  getMoreOrders: () => void
  getOrders: () => void
  state: OrdersState
}

export enum OrderStatus {
  'Payed' = 1,
  'Pending' = 2,
  'Processing' = 3,
  'Deleted' = 4,
  'New' = 5,
}

export enum DeliveryTypeId {
  'HOME_DELIVERY' = 1,
  'STORE_PICKUP' = 2,
}
