import { AxiosRequestConfig } from 'axios'

import httpRequest from '../../components/Delivery/httpClient'

export type EtasType = {
  startDate: string
  startTime: string
  endDate: string
  endTime: string
  dispersion: string
}
export type CourierType = {
  name: string
  phone: string
}
export type CustomerType = {
  name: string
  phone: string
}

export type CostsType = {
  saleValue: number
  deliveryValue: number
  discountValue: number
}
export type ProviderType = {
  id: number
  name: string
  imageUrl: string
}

export interface ShipmentResumeResponse {
  id: string
  reference: string
  trackingLink: string
  statusId: number
  deliveryMethodId: number
  dispersionTypeId: number
  etas: EtasType
  courier: CourierType
  customer: CustomerType
  costs: CostsType
  provider: ProviderType
}

const urlBaseService = `${process.env.REACT_APP_TREINTA_MICROSERVICES_API_URL}/delivery`

export const getShipmentResume = async (
  idShipment: string,
  authToken: string
): Promise<ShipmentResumeResponse> => {
  const requestData: AxiosRequestConfig = {
    url: `${urlBaseService}/${idShipment}`,
    method: 'GET',
  }
  return httpRequest(requestData, authToken)
}
