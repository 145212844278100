import { IProduct } from '../../types/models'
import { ShoppingCartProductsSet } from './model'

export const getUpdatedProductsQuantitySet = ({
  previousProductsSet,
  product,
  newQuantity,
}: {
  previousProductsSet: ShoppingCartProductsSet
  product: IProduct
  newQuantity: number
}): ShoppingCartProductsSet => {
  if (!product?.id) {
    return previousProductsSet
  }

  if (newQuantity === 0) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { [product.id]: _, ...restProducts } = previousProductsSet
    return restProducts
  }

  const newSet = { ...previousProductsSet }
  newSet[product.id] = {
    ...product,
    quantity: newQuantity,
  }
  return newSet
}
