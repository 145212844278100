import { createStyles, makeStyles, RadioGroup } from '@material-ui/core'
import { FormControl } from '@material-ui/core'
import { Typography } from '../../../../components/common/Typography'
import styled from 'styled-components'

export const Container = styled.div`
  height: 100%;
  display: flex;
  margin-bottom: ${({ theme: { sizes } }) => sizes.spacing.large};
  flex-direction: column;
`
export const Title = styled(Typography)`
  color: ${({ theme }) => theme.colors.secondary700};
  margin-bottom: ${({ theme: { sizes } }) => sizes.spacing.medium};
  margin-top: ${({ theme: { sizes } }) => sizes.spacing.large};
`
export const Text = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral800};
`

export const RadioGroupWrapper = styled(RadioGroup)`
  margin-bottom: ${({ theme: { sizes } }) => sizes.spacing.medium};
`

export const CustomFormControl = styled(FormControl)`
  .MuiFormGroup-root {
    gap: 16px;
  }
`

const useStyles = makeStyles((theme) =>
  createStyles({
    formControl: {
      flexGrow: 1,
      borderRadius: 8,
      marginTop: theme.spacing(1),
      backgroundColor: '#EDEDED',
    },
    typographyTitleStyle: {
      color: '#000000',
      fontWeight: 'bold',
      marginBottom: '16px',
      marginTop: theme.spacing(2),
    },
    containerInput: {
      display: 'flex',
    },
  })
)

export { useStyles }
