import styled from 'styled-components'

import { Typography } from '../../../../components/common/Typography'

export const FirstRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`

export const SubContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`

export const Container = styled.div`
  display: flex;
  padding: 0 1rem;
  align-items: center;
  justify-content: space-between;
  margin-bottom: ${({ theme }) => theme.sizes.spacing.medium};
`

export const Image = styled.img`
  width: 56px;
  height: 48px;
  margin-right: 16px;
  object-fit: contain;
  ${({ theme }) => `
    border-radius: ${theme.sizes.borderRadius.large}
    `};
  background-color: ${({ theme: { colors } }) => colors.neutral200};
`

export const Title = styled(Typography).attrs({ variant: 'Small' })`
  color: ${({ theme: { colors } }) => colors.neutral700};
`

export const Price = styled(Typography).attrs({ variant: 'Smallbold' })`
  color: ${({ theme: { colors } }) => colors.secondary700};
`

export const Quantity = styled(Typography).attrs({ variant: 'Smallbold' })`
  color: ${({ theme: { colors } }) => colors.secondary700};
`
