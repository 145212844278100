import styled from 'styled-components'
import { Color } from '../../styles/colors'
import { Typography } from '../common/Typography'

export const MainContainer = styled.div`
  align-items: center;
  cursor: pointer;
  display: inline-flex;
`

interface RadioContainerProps {
  selectedColor: Color
  selected: boolean
  size: number
}

export const RadioContainer = styled.div<RadioContainerProps>`
  align-items: center;
  border-radius: 50%;
  display: flex;
  height: ${({ size }) => size}px;
  justify-content: center;
  position: relative;
  width: ${({ size }) => size}px;

  background-color: ${({ selected, selectedColor, theme: { colors } }) => {
    if (selected) {
      return colors[selectedColor]
    }

    return colors.white
  }};
  border: ${({ selected, theme: { colors } }) => {
    if (selected) {
      return 'none'
    }

    return `1px solid ${colors.neutral600}`
  }};
`

export const WhitePill = styled.div`
  background-color: ${({ theme: { colors } }) => colors.white};
  border-radius: 50%;
  height: 50%;
  width: 50%;
`

export const Label = styled(Typography).attrs({
  variant: 'Medium',
})`
  color: ${({ theme: { colors } }) => colors.neutral800};
  margin-left: ${({ theme: { sizes } }) => sizes.spacing.medium};
`

export const OptionContainer = styled.div`
  margin-bottom: ${({ theme: { sizes } }) => sizes.spacing.large};
`
