import { useTranslation } from 'react-i18next'
import useGAEventTracker from '../../../hooks/useGAEventTracker'
import { DeliverySummaryProps } from '../DeliverySummary/contracts'
import { PaymentMethodOptions, StepNames } from '../../../types/models'
import {
  Container,
  EditContainer,
  EditCopy,
  EditIcon,
  InfoContainer,
  InfoWrapper,
  Label,
  PaymentMethod,
  PaymentMethodIcon,
  PaymentMethodWrapper,
  Value,
} from './styles'

export const PaymentMethodDetails = ({
  state,
  setDeliveryState,
}: DeliverySummaryProps): JSX.Element => {
  const { t } = useTranslation()
  const GAEventsTracker = useGAEventTracker('webshop')

  const getDeliveryLabel = () => {
    return state.paymentMethod.method === PaymentMethodOptions.Cash
      ? t('delivery.summary.details.cashMethod')
      : t('delivery.summary.details.uponDeliveryMethod')
  }

  const handleGoToEdit = () => {
    GAEventsTracker('shop_webdelivery_loc_quot_preview_sedit')
    setDeliveryState({
      ...state,
      currentStep: StepNames.PaymentMethod,
    })
  }

  return (
    <Container>
      <PaymentMethodWrapper>
        <PaymentMethod>
          {t('delivery.summary.details.paymentMethod')}
        </PaymentMethod>
        <EditContainer onClick={handleGoToEdit}>
          <EditIcon />
          <EditCopy>{t('delivery.summary.details.editCopy')}</EditCopy>
        </EditContainer>
      </PaymentMethodWrapper>

      <InfoContainer>
        <PaymentMethodIcon />
        <InfoWrapper>
          <Label>{t('delivery.summary.details.paymentMethod')}:</Label>
          <Value>{getDeliveryLabel()}</Value>
        </InfoWrapper>
      </InfoContainer>
    </Container>
  )
}
