import { ISchedule } from '../../../../types/models'

export const formatTime = (time: string): string => {
  const [hour, minutes] = time.split(':')
  const date = new Date()
  date.setHours(parseInt(hour))
  date.setMinutes(parseInt(minutes))

  return date.toLocaleString('en-US', {
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  })
}

export const getCurrentDay = (storeSchedule: ISchedule[]): ISchedule => {
  const sundayIndexDay = 7
  const date = new Date()
  const currentIndexDay = date.getDay() || sundayIndexDay

  return storeSchedule.find(
    (daySchedule) => daySchedule.id === currentIndexDay.toString()
  ) as ISchedule
}

export const getNextDayOpen = (storeSchedule: ISchedule[]): ISchedule => {
  const currentDayObject = getCurrentDay(storeSchedule)

  let currentNextDayObject = [
    ...storeSchedule.slice(+currentDayObject.id),
    ...storeSchedule.slice(0, +currentDayObject.id),
  ].find((day) => day.isOpen) as ISchedule

  currentNextDayObject = {
    ...currentNextDayObject,
    day: getWeekDayShort(currentNextDayObject.day),
  }

  return currentNextDayObject
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const getWeekDayShort = (day: any) => {
  const weekdaysShort = {
    Domingo: 'Dom.',
    Lunes: 'Lun.',
    Martes: 'Mart.',
    Miércoles: 'Miérc.',
    Jueves: 'Juev.',
    Viernes: 'Vier.',
    Sábado: 'Sáb.',
  }

  return (weekdaysShort as any)[day]
}
