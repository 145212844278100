import { FC, useReducer, useEffect, useContext } from 'react'

import {
  ICountry,
  IProductItemList,
  DeliveryPriceAreaResponse,
} from '../../types/models'
import { countries } from '../../config/countries'
import { PaymentType } from '../../config/payment-type'
import { shoppingCartReducer } from './ShoppingCartReducer'
import { ShoppingCartContext } from './ShoppingCartContext'
import { actionTypes, shoppingCartState } from './contracts'
import { DeliveryMethod } from '../../config/delivery-method'

export const shoppinCartDefaultInitialState: shoppingCartState = {
  order: [],
  buyersName: '',
  buyersPhone: '',
  totalPayable: 0,
  checkoutStep: 1,
  closeOrder: false,
  fetchError: false,
  productsInCart: [],
  buyersCountryId: 1,
  buyersComments: '',
  deliveryAddress: '',
  messageOrderDetails: '',
  openShoppingCart: false,
  activeShoppingCart: false,
  validateOrderProducts: false,
  selectedDeliveyArea: undefined,
  validateFormPersonalData: false,
  validateFormDeliveryMethod: false,
  country: countries.get('Colombia'),
  selectedPaymentMethod: PaymentType.Pending,
  selectedDeliveryMethod: DeliveryMethod.PICK_UP_IN_STORE,
}

interface CatalogStateProps {
  initialState?: shoppingCartState
}

export const CatalogState: FC<CatalogStateProps> = ({
  children,
  initialState = shoppinCartDefaultInitialState,
}) => {
  const [state, dispatch] = useReducer(shoppingCartReducer, initialState)

  useEffect(() => {
    if (state.productsInCart.length > 0) {
      dispatch({ type: actionTypes.ACTIVE_SHOPPING_CART, payload: true })
    } else {
      dispatch({ type: actionTypes.ACTIVE_SHOPPING_CART, payload: false })
    }
  }, [state.productsInCart])

  const addToCart = (payload: IProductItemList) => {
    dispatch({ type: actionTypes.ADD_TO_CART, payload })
  }

  const addQuantity = (payload: string) => {
    dispatch({ type: actionTypes.ADD_QUANTITY, payload })
  }

  const removeQuantity = (payload: string) => {
    dispatch({ type: actionTypes.REMOVE_QUANTITY, payload })
  }

  const calculateTotal = () => {
    dispatch({ type: actionTypes.CALCULATE_TOTAL })
  }

  const onChangeQuantity = (id: string, quantity: number) => {
    dispatch({
      type: actionTypes.ONCHANGE_QUANTITY,
      payload: { id, quantity },
    })
  }

  const createOrder = () => {
    dispatch({ type: actionTypes.CREATE_ORDER })
  }

  const setValidateFormPersonalData = (payload: boolean) => {
    dispatch({
      type: actionTypes.VALIDATE_FORM_PERSONAL_DATA,
      payload,
    })
  }

  const setValidateFormDeliveryMethod = (payload: boolean) => {
    dispatch({
      type: actionTypes.VALIDATE_FORM_DELIVERY_METHOD,
      payload,
    })
  }

  const setValidateOrderProducts = (payload: boolean) => {
    dispatch({
      type: actionTypes.VALIDATE_ORDER_PRODUCTS,
      payload,
    })
  }

  const closeOrder = () => {
    dispatch({ type: actionTypes.CLOSE_ORDER })
  }

  const setBuyersName = (payload: string) => {
    dispatch({
      type: actionTypes.BUYERS_NAME,
      payload,
    })
  }

  const setDeliveryAddress = (payload: string) => {
    dispatch({
      type: actionTypes.DELIVERY_ADDRESS,
      payload,
    })
  }

  const setBuyersPhone = (payload: string) => {
    dispatch({
      type: actionTypes.BUYERS_PHONE,
      payload,
    })
  }

  const setCountryId = (payload: number) => {
    dispatch({
      type: actionTypes.BUYERS_COUNTRY_ID,
      payload,
    })
  }

  const setCountryPhoneCode = (payload: string) => {
    dispatch({
      type: actionTypes.BUYERS_COUNTRY_PHONE_CODE,
      payload,
    })
  }

  const setBuyersComments = (payload: string) => {
    dispatch({
      type: actionTypes.BUYERS_COMMENTS,
      payload,
    })
  }

  const setSelectedDeliveryMethod = (payload: number) => {
    dispatch({
      type: actionTypes.SELECTED_DELIVERY_METHOD,
      payload,
    })
  }

  const setFetchError = (payload: boolean) => {
    dispatch({
      type: actionTypes.FETCH_ERROR,
      payload,
    })
  }

  const setCountry = (payload: ICountry) => {
    dispatch({
      type: actionTypes.SET_COUNTRY,
      payload,
    })
  }

  const setOpenShoppingCart = (payload: boolean) => {
    dispatch({
      type: actionTypes.SET_OPEN_SHOPPING_CART,
      payload,
    })
  }

  const setSelectedPaymentMethod = (payload: PaymentType) => {
    dispatch({
      type: actionTypes.SELECTED_PAYMENT_METHOD,
      payload,
    })
  }

  const setSelectedDeliveyArea = (
    payload: DeliveryPriceAreaResponse | undefined
  ) => {
    dispatch({
      type: actionTypes.SET_SELECTED_DELIVERY_AREA,
      payload,
    })
  }

  const setCheckoutStep = (payload: number) => {
    dispatch({
      type: actionTypes.SET_CHECKOUT_STEP,
      payload,
    })
  }

  return (
    <ShoppingCartContext.Provider
      value={{
        state,
        addToCart,
        closeOrder,
        addQuantity,
        setCountry,
        createOrder,
        setCountryId,
        setBuyersName,
        setFetchError,
        removeQuantity,
        calculateTotal,
        setBuyersPhone,
        setCheckoutStep,
        onChangeQuantity,
        setBuyersComments,
        setDeliveryAddress,
        setCountryPhoneCode,
        setOpenShoppingCart,
        setSelectedDeliveyArea,
        setValidateOrderProducts,
        setSelectedPaymentMethod,
        setSelectedDeliveryMethod,
        setValidateFormPersonalData,
        setValidateFormDeliveryMethod,
      }}
    >
      {children}
    </ShoppingCartContext.Provider>
  )
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useCatalogState = () => useContext(ShoppingCartContext)
