export enum TextLabels {
  OriginType = 'SHOP',
  DefaultPrice = 'GRATIS',
  ButtonName = 'FINALIZAR PEDIDO',
  DisabledButtonName = 'CARGANDO',
}

export enum OriginIds {
  Shop = 2,
}

export enum CreateOrder {
  Path = '/shop/transaction',
  PaymentTypeId = 6,
}

export const DEFAULT_STORE_IMAGE =
'https://image.flaticon.com/icons/png/512/609/609752.png'
