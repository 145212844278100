import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Summary } from '.'
import { SummarySkeleton } from '../Skeleton'
import { ExtendedTransaction, SummaryLoadProps } from './types'
import { DeliveryTypeId } from '../../../../context/Orders/model'
import { getTransactionById } from '../../service/orderConfirmed'

export const SummaryLoad = ({
  country,
  setOrder,
  handleError,
  transactionId,
  storeInfoIsLoading,
}: SummaryLoadProps): JSX.Element => {
  const { t } = useTranslation()
  const [transaction, setTransaction] = useState<ExtendedTransaction>()
  const [isLoading, setisLoading] = useState<boolean>(storeInfoIsLoading)

  const getSummary = async () => {
    setisLoading(true)
    const response = await getTransactionById(transactionId)
    if (!response) {
      handleError(true)
    } else {
      setOrder(response)
      setTransaction({
        ...response,
        country,
        deliveryType:
          response?.deliveryTypeId === DeliveryTypeId.HOME_DELIVERY
            ? t('orderConfirmed.purchaseSummary.orderDetail.deliveryTypeHome')
            : t(
                'orderConfirmed.purchaseSummary.orderDetail.deliveryTypePickUp'
              ),
        totalPayment: response?.value || 0,
        address: JSON.parse(response?.orderAddress).address || '',
      })
    }
    setisLoading(false)
  }

  useEffect(() => {
    getSummary()
  }, [])

  if (isLoading) {
    return <SummarySkeleton />
  }

  if (!transaction) {
    handleError(true)
  } else {
    handleError(false)
  }

  return transaction ? <Summary {...transaction} /> : <></>
}
