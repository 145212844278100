import { useState } from 'react'
import useGAEventTracker from '../../../../hooks/useGAEventTracker'
import AppMenu from '../../../AppMenu'
import { HamburgerMenu } from './styles'

export const Menu = (): JSX.Element => {
  const GAEventsTracker = useGAEventTracker('webshop')
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)

  const handleClickOpen = () => {
    GAEventsTracker('webshop_hamburguer_menu')
    setIsDrawerOpen(true)
  }

  const handleClickClose = () => {
    GAEventsTracker('webshop_menu_close')
    setIsDrawerOpen(false)
  }

  return (
    <>
      <HamburgerMenu aria-label="menu" onClick={handleClickOpen} />
      <AppMenu isOpen={isDrawerOpen} onClose={handleClickClose} />
    </>
  )
}
