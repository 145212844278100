import { FC, useEffect, useReducer } from 'react'

import CatalogReducer, {
  SET_SEARCH,
  SET_IS_FINAL_PAGE,
  SET_CATALOG_LAYOUT,
  SET_ACTIVE_STORE_ID,
  SET_NEXT_PAGE_LOADING,
  SET_CATALOG_NEXT_PAGE,
  TURN_ON_CATALOG_LOADER,
  TURN_OFF_CATALOG_LOADER,
  ADD_PRODUCTS_TO_CATALOG,
  SET_CATALOG_ACTIVE_CATEGORY,
  SET_CATALOG_AVAILABLE_CATEGORIES,
  SET_CATALOG_PRICING_SORT_DIRECTION,
} from './reducer'
import CatalogContext from './context'
import { CatalogState as ICatalogState } from './model'
import { fetchProductsAndCategories } from './services'
import { getProductsList } from '../../services/products'
import {
  LayoutType,
  LAYOUT_LIST_TYPE,
} from '../../components/LayoutTypeButton/LayoutTypeButton'
import {
  PriceSortingDirection,
  PRICE_NONE_SORT_CRITERIA,
} from '../../components/PriceSortSelector/PriceSortSelector.types'
import { ALL_PRODUCTS_CATEGORY, ICategory, IProduct } from '../../types/models'

export const catalogDefaultState: ICatalogState = {
  nextPage: 1,
  loading: false,
  products: null,
  search: undefined,
  isFinalPage: false,
  nextPageLoading: false,
  availableCategories: [],
  activeStoreId: 'TEST_STORE_ID',
  activeLayout: LAYOUT_LIST_TYPE,
  activeCategory: ALL_PRODUCTS_CATEGORY,
  activeSortDirection: PRICE_NONE_SORT_CRITERIA,
}

interface CatalogStateProps {
  initialState?: ICatalogState
}

export const CatalogState: FC<CatalogStateProps> = ({
  children,
  initialState = catalogDefaultState,
}) => {
  const [state, dispatch] = useReducer(CatalogReducer, initialState)

  const getProductsAndCategories = async (storeId: string) => {
    if (!state.loading) {
      dispatch({
        payload: {},
        type: TURN_ON_CATALOG_LOADER,
      })
      dispatch({
        payload: storeId,
        type: SET_ACTIVE_STORE_ID,
      })
      try {
        const { categories, products } = await fetchProductsAndCategories(
          storeId
        )
        dispatch({
          payload: categories,
          type: SET_CATALOG_AVAILABLE_CATEGORIES,
        })
        dispatch({
          payload: products,
          type: ADD_PRODUCTS_TO_CATALOG,
        })
      } catch (error) {
        console.error('Error fetching store products: ', error)
      }
      dispatch({
        payload: {},
        type: TURN_OFF_CATALOG_LOADER,
      })
    }
  }

  const setActiveCategory = (newCategory: ICategory) => {
    dispatch({
      payload: newCategory,
      type: SET_CATALOG_ACTIVE_CATEGORY,
    })
    setNextPage(0)
  }

  const setLayoutType = (newLayout: LayoutType) => {
    dispatch({
      payload: newLayout,
      type: SET_CATALOG_LAYOUT,
    })
  }

  const setActiveSortDirection = (newDirection: PriceSortingDirection) => {
    dispatch({
      payload: newDirection,
      type: SET_CATALOG_PRICING_SORT_DIRECTION,
    })
  }

  const setProducts = (products: IProduct[]) => {
    dispatch({
      payload: products,
      type: ADD_PRODUCTS_TO_CATALOG,
    })
  }

  const setCategories = (categories: ICategory[]) => {
    dispatch({
      payload: categories,
      type: SET_CATALOG_AVAILABLE_CATEGORIES,
    })
  }

  const setNextPageLoading = (loading: boolean) => {
    dispatch({
      payload: loading,
      type: SET_NEXT_PAGE_LOADING,
    })
  }

  const setNextPage = (page: number) => {
    dispatch({
      payload: page,
      type: SET_CATALOG_NEXT_PAGE,
    })
  }

  const setIsFinalPage = (payload: boolean) => {
    dispatch({
      payload: payload,
      type: SET_IS_FINAL_PAGE,
    })
  }

  const getMoreProducts = async (storeId: string) => {
    setIsFinalPage(false)
    setNextPageLoading(true)
    const nextPage = state.nextPage ? state.nextPage + 1 : 1
    const paginatedProducts = await getProductsList({
      storeId,
      page: nextPage,
      search: state.search,
      category: state?.activeCategory?.name || undefined,
    })

    if (paginatedProducts.length === 0) {
      setIsFinalPage(true)
    }

    if (nextPage === 1) {
      setProducts(paginatedProducts)
    } else {
      const newProducts: IProduct[] =
        state.products && nextPage !== 1
          ? [...state.products, ...paginatedProducts]
          : paginatedProducts
      setProducts(newProducts)
    }
    setNextPage(nextPage)
    setNextPageLoading(false)
  }

  const setActiveStoreId = (storeId: string) => {
    dispatch({
      payload: storeId,
      type: SET_ACTIVE_STORE_ID,
    })
  }

  useEffect(() => {
    if (
      state.activeCategory &&
      state?.activeStoreId !== 'TEST_STORE_ID' &&
      state.nextPage !== 1
    ) {
      setIsFinalPage(false)
      getMoreProducts(state.activeStoreId)
    }
  }, [state.activeCategory, state.activeStoreId, state.search])

  const setSearch = (keyToSearch: string) => {
    setIsFinalPage(false)
    setNextPage(0)
    dispatch({
      type: SET_SEARCH,
      payload: keyToSearch,
    })
  }

  return (
    <CatalogContext.Provider
      value={{
        state,
        setSearch,
        setProducts,
        setNextPage,
        setLayoutType,
        setCategories,
        setIsFinalPage,
        getMoreProducts,
        setActiveStoreId,
        setActiveCategory,
        setNextPageLoading,
        setActiveSortDirection,
        getProductsAndCategories,
      }}
    >
      {children}
    </CatalogContext.Provider>
  )
}
