import styled from 'styled-components'

export const ContainerContact = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: sticky;
  left: 20%;
  right: 20%;
  bottom: 40px;
  margin-bottom: ${({ theme }) => theme.sizes.spacing.medium};
`
export const ButtonContact = styled.button`
  cursor: pointer;
  border: none;
  color: ${({ theme }) => theme.colors.tertiary600};
  font-weight: bold;
  background-color: transparent;
`
