import Axios from 'axios'

export const getSuggestionCities = async (requestParams: {
  [key: string]: string | number
}): Promise<any> => {
  try {
    const response = await Axios({
      url: `${process.env.REACT_APP_API_GOOGLEMAPS_URL}/place/autocomplete/json`,
      params: {
        key: process.env.REACT_APP_API_GOOGLEMAPS_KEY,
        ...requestParams,
      },
      headers: {
        'Content-Type':
          'application/x-www-form-urlencoded; charset=UTF-8;application/json',
      },
    })
    const { data } = response
    return data
  } catch (error: any) {
    console.log('error: ', error)
    return error
  }
}

export const getAddressGeoLocation = async (requestParams: {
  [key: string]: string | number
}): Promise<any> => {
  try {
    const response = await Axios({
      url: `${process.env.REACT_APP_API_GOOGLEMAPS_URL}/geocode/json`,
      params: {
        key: process.env.REACT_APP_API_GOOGLEMAPS_KEY,
        ...requestParams,
      },
      headers: {
        'Content-Type':
          'application/x-www-form-urlencoded; charset=UTF-8;application/json',
      },
    })
    const { data } = response
    return data
  } catch (error: any) {
    console.log('error data address: ', error)
    return error
  }
}

export const findPlaceFromText = async (text: string): Promise<any> => {
  try {
    const response = await Axios({
      method: 'GET',
      url: `${process.env.REACT_APP_API_GOOGLEMAPS_URL}/place/findplacefromtext/json`,
      params: {
        key: process.env.REACT_APP_API_GOOGLEMAPS_KEY,
        input: text,
        inputtype: 'textquery',
        fields: 'formatted_address',
      },
      headers: {
        'Content-Type':
          'application/x-www-form-urlencoded; charset=UTF-8;application/json',
      },
    })

    const { data } = response
    return data
  } catch (error) {
    console.log('error findPlaceFromText', error)
    return error
  }
}
