import styled from 'styled-components'
import { Typography } from '../../common/Typography'
import { ReactComponent as DollarSign } from '../../../assets/icons/dollar-icon.svg'

export const Container = styled.div`
  width: 100%;
`

export const DetailContainer = styled.div`
  display: grid;
  grid-template-columns: 20px 1fr;
  margin-top: ${({ theme }) => theme.sizes.spacing.medium};
`

export const DetailInfo = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const Label = styled(Typography)`
  font-size: ${({ theme }) => theme.sizes.spacing.small};
  font-weight: 400;
  color: ${({ theme }) => theme.colors.neutral700};
  align-self: center;
`

export const Value = styled(Typography)<{ finalValue?: boolean }>`
  font-size: ${({ theme }) => theme.sizes.spacing.small};
  font-weight: 700;
  color: ${({ theme, finalValue }) =>
    finalValue ? theme.colors.tertiary600 : theme.colors.secondary600};
`

export const DollarIcon = styled(DollarSign)`
  align-self: center;
  justify-self: center;
`
