import { ICountry } from '../../types/models'

export interface phoneValidationDrawerType {
  phone: string
  title: string
  isOpen: boolean
  handleNext: () => void
  handleClose: () => void
  country: ICountry
}

export enum CONFIG {
  Anchor = 'bottom',
}
