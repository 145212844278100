import styled from 'styled-components'

import { FontsName } from '../../../styles/fonts'

export const IsLoading = styled.div`
  ${({ theme }) => theme.fonts[FontsName.Nunito].medium};
  color: ${({ theme }) => theme.colors.info700};
  display: flex;
  height: 100vh;
  justify-content: center;
  align-items: center;
`
