import styled from 'styled-components'

export const Main = styled.main`
  display: flex;
  flex-direction: column;
`

export const Container = styled.div`
  min-height: ${({ theme: { layout } }) =>
    `calc(100vh - ${
      layout.footer.height + layout.stickyBottomContainer.height
    }px)`};
`
