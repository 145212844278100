import styled from 'styled-components'
import { Typography } from '../../common/Typography'
import { ReactComponent as BagIcon } from '../../../assets/icons/bag-icon.svg'
import { ReactComponent as PencilIcon } from '../../../assets/icons/edit-icon.svg'

export const Container = styled.div``

export const PaymentMethod = styled(Typography)`
  font-size: ${({ theme }) => theme.sizes.spacing.medium};
  font-weight: 700;
`

export const PaymentMethodWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 80px;
`

export const EditContainer = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  cursor: pointer;
`

export const EditIcon = styled(PencilIcon)`
  align-self: center;
`

export const EditCopy = styled(Typography)`
  color: ${({ theme }) => theme.colors.tertiary600};
  font-size: ${({ theme }) => theme.sizes.spacing.medium};
  font-weight: 700;
`

export const InfoContainer = styled.div`
  display: grid;
  grid-template-columns: 20px 1fr;
  margin-top: ${({ theme }) => theme.sizes.spacing.medium};
`

export const InfoWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const Label = styled(Typography)`
  font-size: ${({ theme }) => theme.sizes.spacing.small};
  font-weight: 400;
  color: ${({ theme }) => theme.colors.neutral700};
`

export const Value = styled(Typography)`
  font-size: ${({ theme }) => theme.sizes.spacing.small};
  font-weight: 700;
  color: ${({ theme }) => theme.colors.secondary600};
`

export const PaymentMethodIcon = styled(BagIcon)`
  align-self: center;
`
