import {
  Row,
  Cube,
  Cube2,
  Item30,
  Item40,
  Center,
  Subtitle,
  Container,
  SkeletonBox,
  InfoContainer,
  MarginBottom,
} from './styles'

export const SummarySkeleton = (): JSX.Element => (
  <Container data-testid="summary-skeleton">
    <Subtitle>
      <SkeletonBox />
    </Subtitle>
    <Row>
      <Item30>
        <SkeletonBox />
      </Item30>
      <Item30>
        <SkeletonBox />
      </Item30>
    </Row>
    <Row>
      <Item30>
        <SkeletonBox />
      </Item30>
      <Item30>
        <SkeletonBox />
      </Item30>
    </Row>
    <Row>
      <Item30>
        <SkeletonBox />
      </Item30>
      <Item30>
        <SkeletonBox />
      </Item30>
    </Row>
    <Row>
      <Item40>
        <SkeletonBox />
      </Item40>
    </Row>
  </Container>
)

export const ProductsSkeleton = (): JSX.Element => (
  <Container data-testid="products-skeleton">
    <div>
      <Item40 />
      <Row>
        <Cube />
        <InfoContainer>
          <Item40>
            <SkeletonBox />
          </Item40>
          <Item40>
            <SkeletonBox />
          </Item40>
        </InfoContainer>
        <Subtitle>
          <SkeletonBox />
        </Subtitle>
      </Row>
    </div>
    <div>
      <Item40 />
      <Row>
        <Cube />
        <InfoContainer>
          <Item40>
            <SkeletonBox />
          </Item40>
          <Item40>
            <SkeletonBox />
          </Item40>
        </InfoContainer>
        <Subtitle>
          <SkeletonBox />
        </Subtitle>
      </Row>
    </div>
    <div>
      <Item40 />
      <Row>
        <Cube />
        <InfoContainer>
          <Item40>
            <SkeletonBox />
          </Item40>
          <Item40>
            <SkeletonBox />
          </Item40>
        </InfoContainer>
        <Subtitle>
          <SkeletonBox />
        </Subtitle>
      </Row>
    </div>
  </Container>
)

export const StatusSkeleton = (): JSX.Element => (
  <Container data-testid="status-skeleton">
    <Center>
      <MarginBottom>
        <Center>
          <Cube2 />
        </Center>
      </MarginBottom>
      <Item30>
        <SkeletonBox />
      </Item30>
      <Item40>
        <SkeletonBox />
      </Item40>
      <Item30>
        <SkeletonBox />
      </Item30>
    </Center>
  </Container>
)
