import styled from 'styled-components'

export const MainContainer = styled.div`
  border-bottom: ${({ theme: { colors } }) => `0.5px solid ${colors.neutral200}`};
  display: flex;
  gap: 8px;
  overflow: scroll;
  padding-bottom: ${({ theme: { sizes } }) => sizes.spacing.medium};
  padding-left: ${({ theme: { sizes } }) => sizes.spacing.medium};
  padding-right: ${({ theme: { sizes } }) => sizes.spacing.medium};
  width: 100%;

  &::-webkit-scrollbar {
    display: none;
  }
`
