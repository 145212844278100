import { useEffect, useState, useContext } from 'react'

import { DeliveryContext } from '../../context/Delivery/DeliveryContext'
import { useStoreInitConfig } from '../../hooks/InitConfig/useStoreInitConfig'
import { StepNames } from '../../types/models'

export const useDeliveryInitConfig = (): boolean => {
  const [isInitialized, setIsInitialized] = useState<boolean>(false)
  const { state, setDeliveryState } = useContext(DeliveryContext)

  const { isStoreLoaded } = useStoreInitConfig({
    state,
    setDeliveryState,
  })

  // effect - request WebView Data
  useEffect(() => {
    window.addEventListener('message', setWebviewProperties)
  }, [])
  // effect - validate Set StoreInfoID - after authToken/storeID initialized
  useEffect(() => {
    if (isStoreLoaded && state.storeInfo?.id) {
      setIsInitialized(true)
    }
  }, [isStoreLoaded, state.storeInfo])

  // defined Default Data Message - Direct Access Web
  const defaultMessageValues = () => {
    return {
      data: {
        token: state.authToken,
        storeID: state.storeId,
        userID: state.userId,
        goToStep: StepNames.Address,
      },
    }
  }

  const setWebviewProperties = (message: {
    data: {
      token: string
      storeID: string
      userID: string
      goToStep: StepNames
    }
  }) => {
    const dataMessage =
      process.env.NODE_ENV === 'development' ? defaultMessageValues() : message
    const {
      data: { token, storeID, userID, goToStep },
    } = dataMessage

    if (token && storeID && userID) {
      setDeliveryState({
        ...state,
        authToken: token,
        storeId: storeID,
        userId: userID,
        currentStep: goToStep > 0 ? goToStep : state.currentStep,
      })
      window.removeEventListener('message', setWebviewProperties)
    }
  }

  useEffect(() => {
    const { authToken, storeId, storeInfo: storeInfoData } = state

    if (authToken && storeId && storeInfoData?.id) setIsInitialized(true)
  }, [state.authToken, state.storeId, state.storeInfo?.id])

  return isInitialized
}
