import axios from 'axios'
import { getTokenFirebase } from '../../common/services/signIn'
import { Order, OrderFromBackend } from './model'
const API_HOST = process.env.REACT_APP_TREINTA_API_URL

interface FetchOrdersAxiosResponse {
  nextPage: number | null
  items: OrderFromBackend[]
}

interface FetchOrdersResponse {
  nextPage: number | null
  items: Order[]
}

interface fetchUserSummaryResponse {
  phone: string
  first_name: string
  country_name: string
}

export const transformBackendOrders = (items: OrderFromBackend[]): Order[] => {
  return items.map(
    ({
      id,
      created_at,
      store_image,
      store_name,
      total,
      delivery_price,
      transaction_status_id,
    }) => ({
      id,
      createdAt: created_at ? new Date(created_at) : new Date(),
      storeImage: store_image,
      storeName: store_name,
      total,
      deliveryPrice: delivery_price,
      transactionStatusId: transaction_status_id,
    })
  )
}

export const fetchOrders = async (
  page: number | null
): Promise<FetchOrdersResponse> => {
  const token = await getTokenFirebase()
  const {
    data: { items, nextPage },
  }: { data: FetchOrdersAxiosResponse } = await axios.get(
    `${API_HOST}/shop/orders?page=${page}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  )

  return {
    items: transformBackendOrders(items),
    nextPage,
  }
}

export const fetchUserSummary = async (): Promise<fetchUserSummaryResponse> => {
  const token = await getTokenFirebase()
  const {
    data: { phone, first_name, country_name },
  } = await axios({
    method: 'GET',
    url: `${API_HOST}/shop/orders/user-summary`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })

  return { phone, first_name, country_name }
}
