import styled from 'styled-components'

export const ThumbnailsContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: ${({ theme: { sizes } }) => sizes.spacing.xlarge};
`

export const Thumbnail = styled.img`
  object-fit: contain;
  width: ${({ theme: { sizes } }) => sizes.spacing.xxxlarge};
  height: ${({ theme: { sizes } }) => sizes.spacing.xxxlarge};
  background-color: ${({ theme }) => theme.colors.neutral200};
  margin: ${({ theme: { sizes } }) => `0 ${sizes.spacing.xxsmall}`};
  border-radius: ${({ theme: { sizes } }) => sizes.borderRadius.large};
`
