import Axios from 'axios'
import { getTokenFirebase } from '../common/services/signIn'
import { createOrderPayloadRequest, ServiceDetailOrder } from '../types/models'
import { getAddressOrderDetail } from '../utils/functions'

enum CREATE_ORDER {
  PaymentTypeId = 6,
  AnonymousPath = '/shop/guest-transaction',
}

enum UPDATE_USER {
  Path = 'shop/new-user-transactions',
}

interface createOrderResponse {
  transaction: {
    order_address: string
    value: number
    description: string
    delivery_type: string
    origin_type: string
    payment_type_id: number
    id: string
    contact_id: string
    store_id: string
    user_id: string
    transaction_status_id: number
    origin_id: number
    transaction_type_id: number
    date: number
    created_at: string
    updated_at: string
    transaction_count: number
    deleted_at: string
  }
  transactionDetails: [
    {
      cost: number
      product_id: string
      quantity: number
      id: string
      transaction_id: string
      created_at: string
      updated_at: string
      deleted_at: string
    }
  ]
}

export const sendServiceOrder = async (
  payload: createOrderPayloadRequest
): Promise<createOrderResponse | void> => {
  try {
    const response = await Axios({
      method: 'post',
      data: payload,
      url: `${process.env.REACT_APP_TREINTA_MICROSERVICES_API_URL}${CREATE_ORDER.AnonymousPath}`,
      headers: {
        'x-api-key': process.env.REACT_APP_TREINTA_MICROSERVICES_API_KEY,
      },
    })
    const { data } = response
    return data
  } catch (error) {
    return
  }
}

export const fetchData = async (storeUrl: string): Promise<any> => {
  const { data } = await Axios.get(
    `${process.env.REACT_APP_TREINTA_API_URL}/store/inventory/${storeUrl}`
  )
  return data
}

export const fetchOrderDetail = async (
  transactionId: string
): Promise<ServiceDetailOrder> => {
  const token = await getTokenFirebase()
  const { data } = await Axios.get(
    `${process.env.REACT_APP_TREINTA_API_URL}/shop/transaction-details/${transactionId}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  )
  return { ...data, transaction: getAddressOrderDetail(data) }
}

export const updateOrdersUser = async (phone: string): Promise<boolean> => {
  try {
    const payload = { phone }
    const path = UPDATE_USER.Path
    const token = await getTokenFirebase()
    const response = await Axios.put(
      `${process.env.REACT_APP_TREINTA_API_URL}/${path}`,
      payload,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )

    return response.status === 200
  } catch (error) {
    return false
  }
}
