import styled from 'styled-components'

import { Typography } from '../../common/Typography'
import { ReactComponent as GoBackIcon } from '../../../assets/arrowBack.svg'
import { ReactComponent as DoorIcon } from '../../../assets/icons/door-icon.svg'
import { ReactComponent as CloseIcon } from '../../../assets/icons/header-close.svg'
import { ReactComponent as ExclamationIcon } from '../../../assets/icons/exclamation-icon.svg'
import { ReactComponent as CarrierIconImage } from '../../../assets/icons/carrier-icon.svg'

export const Container = styled.div`
  width: 100%;
  height: 100vh;
  position: relative;
`

export const MainWrapper = styled.div`
  padding: 0rem 1rem;
`

export const Header = styled.div`
  display: grid;
  grid-template-columns: 35px 1fr 15px;
  margin-top: 1rem;
  margin-bottom: 24px;
`

export const Icon = styled(GoBackIcon)`
  margin-right: 20px;
  color: ${({ theme }) => theme.colors.secondary700};
  align-self: center;
`

export const IconClose = styled(CloseIcon)`
  margin-right: 20px;
  color: ${({ theme }) => theme.colors.secondary700};
  align-self: center;
`

export const Title = styled(Typography)`
  color: ${({ theme }) => theme.colors.secondary700};
  align-self: center;
`

export const Toaster = styled.section`
  width: 100%;
  padding: 18px 0 8px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.info100};
`

export const Message = styled.span`
  font-size: ${({ theme }) => theme.sizes.spacing.small};
  color: ${({ theme }) => theme.colors.info700};
  font-weight: 400;
  margin-left: 18px;
  max-width: 288px;
`

export const ToasterIcon = styled(ExclamationIcon)`
  align-self: center;
`

export const LoadingMessage = styled(Typography)`
  font-size: ${({ theme }) => theme.sizes.spacing.medium};
  width: 100%;
  text-align: center;
`

export const AlertDoorIcon = styled(DoorIcon)`
  align-self: center;
  margin-bottom: 18px;
`

export const EmptyResponse = styled.div`
  width: 100%;
  height: 85vh;
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: center;
  justify-content: center;
`
export const TextEmptyResponse = styled(Typography)`
  font-size: ${({ theme }) => theme.sizes.spacing.medium};
  color: ${({ theme }) => theme.colors.neutral700};
  text-align: center;
  width: 100%;
`
export const CarrierIcon = styled(CarrierIconImage)`
  margin-bottom: ${({ theme: { sizes } }) => sizes.spacing.large};
  align-self: center;
`
