import {
  ALL_PRODUCTS_CATEGORY,
  DISCOUNTS_CATEGORY,
  ICategory,
  IProduct,
} from '../../types/models'
import { deleteTildes } from '../../utils/functions'
import {
  PriceSortingDirection,
  PRICE_ASC_SORT_CRITERIA,
  PRICE_DESC_SORT_CRITERIA,
  PRICE_NONE_SORT_CRITERIA,
} from '../PriceSortSelector/PriceSortSelector.types'

type GetProductsFilteredAndSortedInput = {
  products: IProduct[]
  searchQuery: string
  sortDirection: PriceSortingDirection
  targetCategory: ICategory | null
}

export const getFilteredAndSortedProducts = ({
  products,
  searchQuery,
  sortDirection,
  targetCategory,
}: GetProductsFilteredAndSortedInput): IProduct[] => {
  let filteredAndSortedProducts = [...products]
  filteredAndSortedProducts = getProductsFilteredByCategory(
    filteredAndSortedProducts,
    targetCategory
  )
  filteredAndSortedProducts = getProductsFilteredBySearchQuery(
    filteredAndSortedProducts,
    searchQuery
  )
  filteredAndSortedProducts = getProductsSortedByPrice(
    filteredAndSortedProducts,
    sortDirection
  )
  return filteredAndSortedProducts
}

export const getProductsWithDiscount = (products: IProduct[]): IProduct[] => {
  return products.filter((product) => !!product.discount)
}

export const getProductsFilteredByCategory = (
  products: IProduct[],
  targetCategory: ICategory | null
): IProduct[] => {
  if (!targetCategory || targetCategory.name === ALL_PRODUCTS_CATEGORY.name) {
    return products
  } else if (targetCategory.name === DISCOUNTS_CATEGORY.name) {
    return getProductsWithDiscount(products)
  }

  let cleanedProducts = [...products]
  cleanedProducts = cleanedProducts.filter(
    (product) => product.categories === targetCategory?.name
  )

  return cleanedProducts
}

export const getProductsFilteredBySearchQuery = (
  products: IProduct[],
  searchQuery: string
): IProduct[] => {
  if (!searchQuery) {
    return products
  }

  const normalizedSearchQuery = deleteTildes(searchQuery.toLowerCase().trim())

  let cleanedProducts = [...products]
  cleanedProducts = cleanedProducts?.filter((product) => {
    return deleteTildes(product.name.toLowerCase().trim()).includes(
      normalizedSearchQuery
    )
  })

  return cleanedProducts
}

export const getProductsSortedByPrice = (
  products: IProduct[],
  sortDirection: PriceSortingDirection
): IProduct[] => {
  if (sortDirection === PRICE_NONE_SORT_CRITERIA) {
    return products.sort((a, b) => (a.name > b.name ? 1 : -1))
  }

  let cleanedProducts = [...products]

  if (sortDirection === PRICE_ASC_SORT_CRITERIA) {
    cleanedProducts = cleanedProducts.sort((a, b) =>
      a.price > b.price ? 1 : -1
    )
  }

  if (sortDirection === PRICE_DESC_SORT_CRITERIA) {
    cleanedProducts = cleanedProducts.sort((a, b) =>
      a.price < b.price ? 1 : -1
    )
  }

  return cleanedProducts
}
