import styled from 'styled-components'

export const Button = styled.button`
  width: 100%;
  height: 40px;
  border: none;
  display: flex;
  cursor: pointer;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5rem;
  align-items: center;
  text-transform: none;
  border-radius: 0.25rem;
  justify-content: center;
  color: ${({ theme }) => theme.colors.primary900};
  background-color: ${({ theme }) => theme.colors.primary500};
  :disabled {
    color: ${({ theme }) => theme.colors.neutral600};
    background-color: ${({ theme }) => theme.colors.neutral300};
  }
  .icon {
    height: 1rem;
    line-height: 1.5rem;
  }
`
