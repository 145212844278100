import { ThumbnailsProps } from './types'
import { thumbnailImagesFormatter } from './helpers'
import { Thumbnail, ThumbnailsContainer } from './styles'

export const Thumbnails = ({
  images,
  handleThumbnail,
}: ThumbnailsProps): JSX.Element => {
  const formattedImages = thumbnailImagesFormatter(images)
  return formattedImages ? (
    <ThumbnailsContainer>
      {formattedImages.map((item, index) => (
        <Thumbnail
          src={item}
          alt={item}
          key={`thumb-${index}`}
          onClick={() => handleThumbnail(item)}
        />
      ))}
    </ThumbnailsContainer>
  ) : (
    <></>
  )
}
