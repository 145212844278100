import ProductAsGridItem from '../../Product/ProductAsGridItem'
import { ProductsCatalogProps } from '../types'
import { MainContainer } from './styles'

const ProductsList = ({
  country,
  products,
}: ProductsCatalogProps): JSX.Element => {
  return (
    <MainContainer>
      {products.map((product) => (
        <ProductAsGridItem
          country={country}
          key={`productGrid.item.${product.id}`}
          onClick={() => console.log('OnClick')}
          product={product}
        />
      ))}
    </MainContainer>
  )
}

export default ProductsList
