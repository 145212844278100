import { AxiosRequestConfig } from 'axios'

import httpRequest from '../../components/Delivery/httpClient'

const urlBaseService = `${process.env.REACT_APP_TREINTA_MICROSERVICES_API_URL}/delivery/user-bank-account/valid`

export const getHasBankInformation = async (
  authToken: string
): Promise<boolean> => {
  const requestData: AxiosRequestConfig = {
    url: urlBaseService,
    method: 'GET',
  }
  return httpRequest(requestData, authToken)
}
