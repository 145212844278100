import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useRouteMatch } from 'react-router-dom'
import { useBottomScrollListener } from 'react-bottom-scroll-listener'

import SentDeliveryList from './SentDeliveryList'
import { redirectUrl } from '../../utils/redirect'
import { InfoDeliveryProps } from '../../contracts'
import { StepNames } from '../../../../types/models'
import ResumeSentDeliveryAlerts from './ResumeSentDeliveryAlerts'
import useGAEventTracker from '../../../../hooks/useGAEventTracker'
import { useListShipmentService } from '../../../../hooks/Shipment/useListShipmentService'
import { useRemoveShipmentService } from '../../../../hooks/Shipment/useRemoveShipmentService'
import {
  SentItemsContainer,
  TextSentItemsTitle,
  TextSentItemsEmpty,
  ListContainer,
} from './styles'

const ResumeSentDelivery = ({
  state,
  setDeliveryState,
}: InfoDeliveryProps): JSX.Element => {
  const history = useHistory()
  const { t } = useTranslation()
  const { url } = useRouteMatch()
  const [deliveryId, setDeliveryId] = useState('')
  const [currentPage, setCurrentPage] = useState(1)
  const GAEventsTracker = useGAEventTracker('webshop')
  // status Alerts
  const [drawerRemoveIsOpen, setDrawerRemoveIsOpen] = useState(false)
  const [confirmRemoveIsOpen, setConfirmRemoveIsOpen] = useState(false)
  const {
    getListShipment,
    listShipmentData,
    numberResults,
    isLoadingListShipment,
    refetchShipmentList,
  } = useListShipmentService({
    authToken: state.authToken,
    storeId: state.storeId,
  })
  const { removeShipment } = useRemoveShipmentService({
    deliveryId: deliveryId,
    authToken: state.authToken,
    callback: () => refetchShipmentList(),
  })
  const scrollRef = useBottomScrollListener<HTMLDivElement>(() => {
    if (numberResults > 0) {
      setCurrentPage(currentPage + 1)
      getListShipment(currentPage)
    }
  })

  // Call shipment list first time
  useEffect(() => {
    if (state.authToken && state.storeId && listShipmentData.length === 0) {
      getListShipment(currentPage)
    }
  }, [state.authToken, state.storeId])

  const handleClickViewShipment = (deliveryId: string): void => {
    GAEventsTracker('shop_webdelivery_menu_view_quote')
    setDeliveryState({
      ...state,
      currentStep: StepNames.Confirm,
      currentDeliveryId: deliveryId,
    })
    redirectUrl(history, url)
  }

  const handleClickRemoveShipment = (deliveryId: string) => {
    setDeliveryId(deliveryId)
    setDrawerRemoveIsOpen(true)
  }

  const handleClickPreventRemoveShipment = (): void => {
    setDrawerRemoveIsOpen(false)
  }
  const handleClickConfirmRemoveShipment = (): void => {
    GAEventsTracker('shop_webdelivery_menu_view_delete')
    setDrawerRemoveIsOpen(false)
    setConfirmRemoveIsOpen(true)
    removeShipment()
  }
  const handleCloseAlertSuccessRemove = (): void => {
    setConfirmRemoveIsOpen(false)
  }

  return (
    <>
      <TextSentItemsTitle>{t('delivery.orderShipped')}</TextSentItemsTitle>
      <SentItemsContainer
        id="sentitems-container"
        data-testid="sentitems-container"
        ref={scrollRef}
      >
        {!isLoadingListShipment && listShipmentData.length > 0 ? (
          <ListContainer>
            <SentDeliveryList
              {...{
                listShipmentData,
                handleClickViewShipment,
                handleClickRemoveShipment,
              }}
            />
          </ListContainer>
        ) : (
          <TextSentItemsEmpty>
            {t('delivery.withoutShipmentActive')}
          </TextSentItemsEmpty>
        )}
        <ResumeSentDeliveryAlerts
          {...{
            drawerRemoveIsOpen,
            confirmRemoveIsOpen,
            handleCloseAlertSuccessRemove,
            handleClickPreventRemoveShipment,
            handleClickConfirmRemoveShipment,
          }}
        />
      </SentItemsContainer>
    </>
  )
}
export default ResumeSentDelivery
