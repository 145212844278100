import { useTranslation } from 'react-i18next'

import MenuSelect from '../../common/MenuSelect'
import { BankInformationHolderProps } from '../contracts'
import { handleChangeInputField } from './changeValidations'
import {
  ErrorMessage,
  SelectPlaceholder,
  InputContainer,
  LabelInputForm,
  InputTextForm,
  SubTitle,
} from './styles'

const FormBankInformationFieldsHolder = ({
  bankInformationData,
  bankInformationDataError,
  setBankInformationData,
  documentTypesData,
  defineContentSelectValue,
  validateHolderName,
  validateHolderEmail,
  validateDocumentType,
  validateDocumentNumber,
}: BankInformationHolderProps): JSX.Element => {
  const { t } = useTranslation()

  return (
    <>
      <SubTitle>{t('delivery.sectionForm.bankFormIDSubtitle')}</SubTitle>

      {/* Name */}
      <InputContainer>
        <LabelInputForm>
          {t('delivery.sectionForm.bankFormLabelHolderName')}*
        </LabelInputForm>
        <InputTextForm
          value={bankInformationData.holderName}
          minLength={3}
          maxLength={40}
          id="name-input"
          onChange={(e) =>
            handleChangeInputField({
              event: e,
              isNumber: false,
              fieldName: 'holderName',
              validationMethod: validateHolderName,
              bankInformationData: bankInformationData,
              setBankInformationData: setBankInformationData,
            })
          }
          placeholder={t('delivery.sectionForm.bankFormPlaceholderHolderName')}
        />
        {bankInformationDataError.holderNameError && (
          <ErrorMessage variant="XSmall">
            {t('delivery.sectionForm.errors.errorHolderName')}
          </ErrorMessage>
        )}
      </InputContainer>

      {/* Document Type */}
      <InputContainer>
        <MenuSelect
          id="typeid-select"
          showLabel={true}
          defaultValue={String(bankInformationData.holderDocumentType)}
          labelText={`${t('delivery.sectionForm.bankFormLabelTypeID')}*`}
          menuItems={documentTypesData}
          displayEmpty={true}
          renderValue={(actualValue: any) => (
            <SelectPlaceholder>
              {defineContentSelectValue(
                t('delivery.sectionForm.bankFormPlaceholderTypeID'),
                actualValue,
                documentTypesData
              )}
            </SelectPlaceholder>
          )}
          onChange={(e) =>
            handleChangeInputField({
              event: e,
              isNumber: true,
              fieldName: 'holderDocumentType',
              validationMethod: validateDocumentType,
              bankInformationData: bankInformationData,
              setBankInformationData: setBankInformationData,
            })
          }
        />
      </InputContainer>

      {/* Document Number */}
      <InputContainer>
        <LabelInputForm>
          {t('delivery.sectionForm.bankFormLabelNumberID')}*
        </LabelInputForm>
        <InputTextForm
          value={bankInformationData.holderDocumentNumber}
          minLength={3}
          maxLength={25}
          id="numberid-input"
          onChange={(e) =>
            handleChangeInputField({
              event: e,
              isNumber: false,
              fieldName: 'holderDocumentNumber',
              validationMethod: validateDocumentNumber,
              bankInformationData: bankInformationData,
              setBankInformationData: setBankInformationData,
            })
          }
          placeholder={t('delivery.sectionForm.bankFormPlaceholderNumberID')}
        />
        {bankInformationDataError.holderDocumentNumberError && (
          <ErrorMessage variant="XSmall">
            {t('delivery.sectionForm.errors.errorDocNumber')}
          </ErrorMessage>
        )}
      </InputContainer>

      {/* Email */}
      <InputContainer>
        <LabelInputForm>
          {t('delivery.sectionForm.bankFormLabelEmail')}*
        </LabelInputForm>
        <InputTextForm
          value={bankInformationData.holderEmail}
          minLength={3}
          maxLength={40}
          id="email-input"
          onChange={(e) =>
            handleChangeInputField({
              event: e,
              isNumber: false,
              fieldName: 'holderEmail',
              validationMethod: validateHolderEmail,
              bankInformationData: bankInformationData,
              setBankInformationData: setBankInformationData,
            })
          }
          placeholder={t('delivery.sectionForm.bankFormPlaceholderEmail')}
        />
        {bankInformationDataError.holderEmailError && (
          <ErrorMessage variant="XSmall">
            {t('delivery.sectionForm.errors.errorEmail')}
          </ErrorMessage>
        )}
      </InputContainer>
    </>
  )
}
export default FormBankInformationFieldsHolder
