import { useContext } from 'react'

import { ProductInCart } from '../../context/ShoppingCart/model'
import ShoppingCartContext from '../../context/ShoppingCart/context'

interface UseShoppingCart {
  products: ProductInCart[]
  totalPayable: number
  totalDiscounts: number
  totalProductsInCart: number
  removeExpiredDiscounts: () => void
}

export const useShoppingCart = (): UseShoppingCart => {
  const {
    state: { productsSet },
    removeExpiredDiscounts,
  } = useContext(ShoppingCartContext)

  const products = Object.values(productsSet)

  const totalPayable = products.reduce((prev, curr) => {
    return curr.quantity * curr.price + prev
  }, 0)

  const totalDiscounts = products.reduce((prev, curr) => {
    return curr.discount
      ? (curr.discount.originalPrice - curr.discount.finalPrice) *
          curr.quantity +
          prev
      : 0 * curr.price + prev
  }, 0)

  const totalProductsInCart = products.reduce(
    (prev, curr) => prev + curr.quantity,
    0
  )
  return {
    products,
    totalPayable,
    totalDiscounts,
    totalProductsInCart,
    removeExpiredDiscounts,
  }
}
