import { useTranslation } from 'react-i18next'

import {
  CustomButton,
  ButtomWrapper,
  ContentText,
  ContentCuantity,
  LabelButton,
  ProductsPrice,
  QuantityProducts,
} from './styles'
import { ICountry } from '../../types/models'
import { formatterCurrency } from '../../utils/functions'
import useGTMEventTracker from '../../hooks/useGTMEventTracker'

interface Props {
  productsNumber: number
  setOpenShoppingCart: (open: boolean) => void
  totalPayable: number
  country: ICountry
}

export const LowerShoppingButton = ({
  productsNumber,
  setOpenShoppingCart,
  totalPayable,
  country,
}: Props): JSX.Element => {
  const { t } = useTranslation()
  const GTMEventTracker = useGTMEventTracker()

  const isEmpty = productsNumber === 0
  const handleClick = () => {
    GTMEventTracker('click', 'click_shopping_car', 1)
    setOpenShoppingCart(true)
  }
  return (
    <>
      {!isEmpty && (
        <ButtomWrapper>
          <CustomButton data-testid="button-cart" onClick={handleClick}>
            <ContentText>
              <ContentCuantity>
                <QuantityProducts variant="XSmallbold">
                  {productsNumber}
                </QuantityProducts>
              </ContentCuantity>
              <LabelButton>
                {`${t('shoppingCart.goToShoppingCart')}`}
              </LabelButton>
              <ProductsPrice>
                {`${formatterCurrency(country, totalPayable)}`}
              </ProductsPrice>
            </ContentText>
          </CustomButton>
        </ButtomWrapper>
      )}
    </>
  )
}
